import { styled } from '@mui/material';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export const ResearchContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.gray,
  height: '100%',
  width: '100%',
}));

export const ResearchTabsWrapper = styled(Grid)(({ theme }) => ({
  margin: 0,
  overflowY: 'scroll',
  height: '100%',
  width: '100%',
  padding: theme.spacing(7.5),
  gap: theme.spacing(7.5),
}));

export const ResearchSectionStyled = styled(Paper)(({ theme }) => ({
  height: '45vh',
  minHeight: theme.spacing(100),
  padding: theme.spacing(7.5),

  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const ResearchQuartilesSectionStyled = styled('div')(({ theme }) => ({
  height: '90vh',
  minHeight: theme.spacing(100),
  padding: theme.spacing(12, 10),
  marginTop: theme.spacing(-5),

  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));
