import { SelectAction, SelectState } from 'common/components/BingMap/local-state/SelectedPropertiesContext';
import { ButtonAction, ButtonState } from './ButtonStateContext';
import { IdAction, IdState } from './PropertyIdContext';

export function propertyIdReducer(state: IdState, action: IdAction): {
  id: string | null;
} {
  const newState = { ...state };
  // new value can be null
  if (action.newValue !== undefined) {
    newState.id = action.newValue;
  }

  return {
    id: newState.id,
  };
}

export function buttonStateReducer(state: ButtonState, action: ButtonAction): {
  isDisabled: boolean;
} {
  const newState = { ...state };
  if (action.newValue !== state.isDisabled) {
    newState.isDisabled = action.newValue;
  }

  return {
    isDisabled: newState.isDisabled,
  };
}

export function selectedPropertiesReducer(state: SelectState, action: SelectAction): SelectState {
  const newState = { ...state };
  switch (action.type) {
    case 'add':
      newState.selected.push(action.propID);
      return newState;
    case 'remove':
      newState.selected = newState.selected.filter((item) => item !== action.propID);
      return newState;
    case 'remove-all':
      newState.selected = [];
      return newState;
    default:
      return state;
  }
}
