import { styled } from '@mui/material';

import TableCell from '@mui/material/TableCell';

import SortIcon from 'common/components/icons/SortIcon';
import { ROW_HEIGHT } from '../table-data';

export const TableWrapperStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: `calc(100% - ${ROW_HEIGHT}px)`,

  '.ReactVirtualized__Grid': {
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  padding: theme.spacing(3.5, 4),
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
}));

export const TableHeadCellStyled = styled(TableCellStyled)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TableBodyCellStyled = styled(TableCellStyled)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',
  padding: theme.spacing(0),

  '& :last-child': {
    borderBottom: 0,
  },
}));

export const SortIconStyled = styled(SortIcon)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
}));
