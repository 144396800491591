import { memo } from 'react';

import { Table, TableBody } from '@mui/material';

import { useForm } from 'react-hook-form';

import { REQUIRED_TABLE } from 'containers/input-property/utils/constants';
import { Inputs } from 'containers/input-property/components/mapTab/map/utils/constants';
import { Spinner } from 'common/components/spinner/Spinner';
import { useRequireTableFunctions } from './hooks/useRequireTableFunctions';

import RowRenderer from './RowRenderer';
import ButtonsContainer from './Buttons/ButtonsContainer';

import {
  SpinnerWrapper,
  StyledTableWrapper,
  TableTitleStyled,
} from '../styled';

const RequiredTable = (): JSX.Element => {
  const {
    register,
    control,
    reset,
  } = useForm<Inputs>();

  const { submitHandler, isLoading } = useRequireTableFunctions(reset);

  return (
    <form onSubmit={submitHandler} id="form-modify">
      <ButtonsContainer control={control} />
      <TableTitleStyled>
        {REQUIRED_TABLE.TITLE.toUpperCase()}
        <SpinnerWrapper>
          {isLoading && <Spinner size="small" color="primary" />}
        </SpinnerWrapper>
      </TableTitleStyled>
      <StyledTableWrapper>
        <Table>
          <TableBody>
            <RowRenderer register={register} />
          </TableBody>
        </Table>
      </StyledTableWrapper>
    </form>
  );
};

export default memo(RequiredTable);
