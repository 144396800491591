import { memo } from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import { useInfoTablePopup } from 'containers/benchmark/local-state/hooks';

import { BENCHMARK } from 'containers/benchmark/utils/constants';
import { BACKDROP_TIMEOUT } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/constants';
import InfoTable from 'containers/benchmark/components/infoTable/table/InfoTable';

import { ModalTitleStyled } from 'containers/buy-sell-credits/admin/table/table-row-editor/styled';
import { BenchmarkTableHeaderStyled, InfoTableSectionStyled } from 'containers/benchmark/utils/styled';
import { useCreateBencmarkAnalyticsTableData } from 'containers/benchmark/lib/mapped-table-data';
import { Box } from '@mui/material';
import ExportDataMenu from './ExportDataMenu';

const BenchmarkInfoTable = (): JSX.Element => {
  const { state, dispatch } = useInfoTablePopup();

  const { bencmarkInfo } = useCreateBencmarkAnalyticsTableData();

  const handleClose = (): void => dispatch({ type: 'close' });

  return (
    <Modal
      open={state.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={BACKDROP_TIMEOUT}
    >
      <Fade in={state.open}>
        <InfoTableSectionStyled>
          <BenchmarkTableHeaderStyled>
            <ModalTitleStyled variant="h5" display="flex">
              {BENCHMARK.INFO_TABLE_TITLE.toUpperCase()}
              <Box marginLeft={8}>
                <ExportDataMenu />
              </Box>
            </ModalTitleStyled>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="large" />
            </IconButton>
          </BenchmarkTableHeaderStyled>
          <InfoTable data={bencmarkInfo} />
        </InfoTableSectionStyled>
      </Fade>
    </Modal>
  );
};

export default memo(BenchmarkInfoTable);
