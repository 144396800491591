import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    root: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: PALETTE.MEDIUM_BLUE,
        borderWidth: 1,
      },
      '&:hover fieldset': {
        borderColor: `${PALETTE.MEDIUM_BLUE} !important`,
      },
    },
  },
};
