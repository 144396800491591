import { styled } from '@mui/material';

import { PINS_SIZE } from 'common/theme/constants';

export const MapStyled = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(4),
}));

export const FooterStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

export const FooterItemStyled = styled('div')(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(8),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  svg: {
    maxWidth: `${PINS_SIZE}px`,
    maxHeight: `${PINS_SIZE}px`,
    marginRight: theme.spacing(2),
  },
}));
