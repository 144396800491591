import { Tooltip, Typography } from '@mui/material';
import { cloneElement, FC } from 'react';
import useTooltip from '../../lib/hooks';

type PropsType = {
  text: string;
  children: JSX.Element;
};

const TooltipWrap: FC<PropsType> = ({ text, children }) => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();
  return (
    <Tooltip placement="top" open={isOpen} title={<Typography>{text}</Typography>} arrow>
      {cloneElement(children, {
        onMouseMove: onShowTooltip,
        onMouseOut: onHideTooltip,
      })}
    </Tooltip>
  );
};

export default TooltipWrap;
