import { memo } from 'react';
import { LINKS } from '../constants';
import LinkItem from './LinkItem';

// TODO: resolve problem with tabs

export const Links = memo((): JSX.Element => (
  <>
    {LINKS.map((link) => <LinkItem key={link.text} instance={link} />) }
  </>
));
