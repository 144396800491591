import { styled } from '@mui/material';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import { MAX_WIDTH_MENU } from './constants';

export const FormControlWrapperStyled = styled(FormControl, {
  shouldForwardProp: (propName) => propName !== '$isDownMd',
})<{ $isDownMd: boolean; }>(({ theme, $isDownMd }) => ({
  width: $isDownMd ? '60%' : theme.spacing(75),
  display: 'flex',
  alignItems: 'flex-start',
  paddingLeft: '30px',
}));

export const ButtonStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== '$isDownMd',
})<{ $isDownMd: boolean; }>(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: theme.spacing(10.5),
  width: '310px',
}));

export const ButtonStyledBenchmark = styled(ButtonStyled)({
  width: '175px',
});

export const ButtonStyledFilters = styled(ButtonStyled)({
  width: '175px',
});

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.background.gray}`,
  gap: theme.spacing(2),
}));

export const MenuItemDetailStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.background.gray}`,
  gap: theme.spacing(2),
  pointerEvents: 'none',
  cursor: 'default',
  '&:hover': {
    background: 'none',
  },
}));

export const MenuStyled = styled(Menu)(({ theme }) => ({

  '.MuiPopover-paper': {
    width: `${MAX_WIDTH_MENU}px`,

    ul: {
      maxHeight: `${MAX_WIDTH_MENU}px`,
      padding: 0,
    },
  },

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.secondary.light,
}));
