import { InputAdornment, TextField } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useEffect, useState,
} from 'react';
import { TableDispatchContext } from '../../model/index';
import { BasicCell } from '../BasicCell/InputCell';

type PropsType = { value: string; index: number; disabled: boolean };

const CellDollar: FC<PropsType> = ({ value, index, disabled }) => {
  const [input, setInput] = useState<string>('');
  const dispatch = useContext(TableDispatchContext);

  useEffect(() => {
    setInput(Number(Math.round(+value)).toLocaleString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onTypeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: inputValue } = event.target;

    if (Number.isNaN(Number(inputValue)) && index !== 8) {
      return;
    }

    if (index === 8 && inputValue.match(/^[0-9 -]*$/)) {
      setInput(inputValue);
    }

    if (index !== 8) {
      setInput(inputValue);
    }
  };

  const onFocusHandler = (): void => {
    const processedInput = input.replace(/[,.\s]/g, '');
    setInput(processedInput);
  };

  const onBlur = (): void => {
    setInput(Number(input).toLocaleString());
    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: {
        index,
        value: input,
      },
    });
  };

  return (
    <BasicCell isColored={disabled}>
      <TextField
        disabled={disabled}
        onFocus={onFocusHandler}
        onBlur={onBlur}
        value={input}
        onChange={onTypeHandler}
        fullWidth
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </BasicCell>
  );
};

export default CellDollar;
