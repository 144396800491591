import { memo } from 'react';

import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { FormControl } from 'containers/input-property/components/mapTab/map/utils/constants';

import ModifyButtons from './ModifyButtons';
import InsertButton from './InsertButton';

const ButtonsContainer = ({ control }:{control: FormControl}):JSX.Element => {
  const { state: idState } = usePropertyId();

  return (idState.id ? <ModifyButtons /> : <InsertButton control={control} />);
};

export default memo(ButtonsContainer);
