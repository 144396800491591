import { styled } from '@mui/material';

type ExtendedDividerWrapper = {
  $spacingTop?: number;
  $spacingBottom?: number;
};
export const DividerWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== '$spacingTop' && propName !== '$spacingBottom',
})<ExtendedDividerWrapper>(
  ({ theme, $spacingTop, $spacingBottom }) => ({
    paddingTop: theme.spacing($spacingTop || 0),
    paddingBottom: theme.spacing($spacingBottom || 0),
  }),
);
