import { memo } from 'react';

import { useAuth } from 'containers/login/lib/auth';
import { ROUTE } from 'common/components/routes/utils/constants';
import { USER_ROLES } from 'containers/main/constants';
import { TABS } from './utils/constants';

import { TabListStyled, TabStyled } from './styled';

interface TabListComponentProps {
  onChange:(_: React.SyntheticEvent<Element, Event>, newValue: string) => void;
  value: string;
}

export const TabListComponent = memo(({ onChange, value }: TabListComponentProps) => {
  const { user } = useAuth();

  return (
    <TabListStyled
      value={value}
      onChange={onChange}
      variant="scrollable"
    >
      <TabStyled
        label={TABS.BUY_AND_SELL.toUpperCase()}
        value={ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT}
      />
      {/** these features in progress, It will be added as soon as possible */}
      {/* <TabStyled
        label={TABS.PAYMENT_AND_INVOICES.toUpperCase()}
        value={ROUTE.ACCOUNT.BUY_AND_SELL.PAYMENT_AND_INVOICES}
      /> */}
      {/* <TabStyled
        label={TABS.BILLING_HISTORY.toUpperCase()}
        value={ROUTE.ACCOUNT.BUY_AND_SELL.BILLING_HISTORY}
      /> */}
      {user?.role === USER_ROLES.USER && (
        <TabStyled
          label={TABS.PROFILE.toUpperCase()}
          value={ROUTE.ACCOUNT.BUY_AND_SELL.PROFILE}
        />
      )}
      {user?.role === USER_ROLES.ADMIN && (
        <TabStyled
          label={TABS.ADMIN.toUpperCase()}
          value={ROUTE.ACCOUNT.BUY_AND_SELL.ADMIN}
        />
      )}
    </TabListStyled>
  );
});
