import React from 'react';
import { hydrate, render } from 'react-dom';
import { AuthProvider } from 'containers/login/lib/auth';
import { ReactQueryProvider } from 'common/components/providers/ReactQueryProvider';
import { AppSnackbarProvider } from 'common/components/providers/AppSnackbarProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';

function RootComponent(): JSX.Element {
  return (
    <AppSnackbarProvider>
      <React.StrictMode>
        <ReactQueryProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ReactQueryProvider>
      </React.StrictMode>
    </AppSnackbarProvider>
  );
}

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<RootComponent />, rootElement);
} else {
  render(<RootComponent />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
