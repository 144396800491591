import React from 'react';

export enum ActionType {
  BuyCredits = 'buy credits',
  CompsInvoices = 'comps and invoices',
  PaymentCreditCart = 'payment credit cart',
  TransferComps = 'transfer comps',
  Subscription = 'subscribe',
  Archive = 'archive',
  Modify = 'modify',
  Credits = 'credits',
  Payment = 'payment',
  Close = 'close',
  Default = ''
}
export type Action = {type: ActionType} | {type : unknown}
export type Dispatch = (action: Action) => void
export type State = {toggle: boolean, title: ActionType}
export type ProviderProps = {children: React.ReactNode}
export interface IModalContextType { state: State, dispatch : Dispatch }

export const ModalContext = React.createContext< {state : State, dispatch : Dispatch}| undefined>(undefined);
