import defaultTheme from '../defaultTheme';
import { FONTS } from '../fonts';
import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    root: {
      fontFamily: FONTS.OPEN_SANS_SEMIBOLD,
      fontSize: 14,
      borderRadius: 6,
    },
    containedPrimary: {
      backgroundColor: PALETTE.WHITE,
      '&:hover': {
        backgroundColor: PALETTE.BLUE,
      },
    },
    containedSecondary: {
      backgroundColor: PALETTE.MEDIUM_BLUE,
      padding: '12px 27px',
      lineHeight: 1.3,
      '&:hover': {
        backgroundColor: PALETTE.BLUE,
        opacity: 1,
      },
    },
    outlinedSecondary: {
      fontFamily: FONTS.OPEN_SANS_SEMIBOLD,
      color: PALETTE.MEDIUM_BLUE,
      borderColor: PALETTE.TRANSPARENT,
      backgroundColor: PALETTE.WHITE,
      padding: '12px 50px',
      '&:hover': {
        color: PALETTE.WHITE,
        borderColor: PALETTE.WHITE,
        opacity: 1,
      },
      textSecondary: {
        color: PALETTE.BLUE,
      },
      startIcon: {
        marginRight: defaultTheme.spacing,
      },
    },
  },
};
