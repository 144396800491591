import { memo } from 'react';
import Typography from '@mui/material/Typography';
import { getYear } from '../helpers';
import { TEXT } from '../constants';

export const Year = memo((): JSX.Element => (
  <Typography noWrap variant="subtitle2">
    {`${getYear()}-${TEXT.PORTAL}`}
  </Typography>
));
