import { AxiosError } from 'axios';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import {
  QueryFunctionContext,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { RadioSelectionValue } from '../components/list/utils/types';

import * as accountApi from './api';
import { BenchmarkAnalyticsModel } from './models/BencmarkAnalytics.model';
import { BenchmarkAnalyticsInfoModel } from './models/BencmarkAnalyticsInfo.model';
import { BenchmarkAnalyticsSubscriptionModel } from './models/BencmarkAnalyticsSubscription.model';
import { ClientProperty } from './models/ClientProperty.model';
import { QueryKey } from './utils/query-keys';
import {
  GetBenchmarkAnalytics,
  GetCompsets,
  PostSubscriptionResult,
  AxiosResponseDataError,
} from './utils/types';

export function useGetPropertyNames(): UseQueryResult<string[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QueryKey.PropertyNames,
    accountApi.getPropertyNames,
    {
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData(QueryKey.PropertyNames),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetPropertyBy({ name }: { name: string; }): UseQueryResult<ClientProperty, Error> {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.ClientProperty, name],
    (
      input: QueryFunctionContext<string[], ClientProperty>,
    ) => accountApi.getClientPropertyBy(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QueryKey.ClientProperty, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetCompsetsPropertyBy(name: string): GetCompsets {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Compsets, name],
    (
      input: QueryFunctionContext<string[], BenchmarkAnalyticsInfoModel>,
    ) => accountApi.getClientCompsetsPropertyBy(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QueryKey.Compsets, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetCompsetsPropertyByAlgo(name: string): GetCompsets {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Compsets, name],
    (
      input: QueryFunctionContext<string[], BenchmarkAnalyticsInfoModel>,
    ) => accountApi.getClientCompsetsPropertyByAlgo(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QueryKey.Compsets, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function usePostSubscriptionPropertyBy(name: string): PostSubscriptionResult {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Subscription, name],
    (
      input: QueryFunctionContext<string[], BenchmarkAnalyticsSubscriptionModel>,
    ) => accountApi.postbenchmarkAnalyticsSubscription(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QueryKey.Subscription, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetBenchmarkAnalytics(
  {
    selection,
    propName,
    callback,
  }: GetBenchmarkAnalytics,
): UseQueryResult<BenchmarkAnalyticsModel[], AxiosError<AxiosResponseDataError>> {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Benchmark, propName, selection],
    (
      input: QueryFunctionContext<string[], BenchmarkAnalyticsModel[]>,
    ) => accountApi.getbenchmarkAnalytics({
      propName: input.queryKey[1],
      selection: input.queryKey[2] as RadioSelectionValue,
    }),
    {
      enabled: !!selection && !!propName && typeof callback === 'function',
      initialData: () => queryClient.getQueryData([QueryKey.Benchmark, propName, selection]),
    },
  );
}
