import { styled } from '@mui/material';

import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import SortIcon from 'common/components/icons/SortIcon';

import { COLUMN_TITLES, HEIGHT, WIDTH } from '../table-data';

export const TableWrapperStyled = styled('div')(({ theme }) => ({
  width: WIDTH,
  height: HEIGHT,
  minHeight: '30vh',
  maxHeight: '50vh',
  overflow: 'hidden',

  '.ReactVirtualized__Grid': {
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  padding: theme.spacing(3.5, 4),
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
}));

export const TableHeadCellStyled = styled(TableCellStyled)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:last-child': {
    borderTopRightRadius: theme.shape.borderRadius,
    maxWidth: `calc(${COLUMN_TITLES[COLUMN_TITLES.length - 1].width}px - 1px)`,
  },
}));

export const TableBodyCellStyled = styled(TableCellStyled)({
  justifyContent: 'left',
  width: '100%',

});

export const SortIconStyled = styled(SortIcon)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  margin: `${theme.spacing(0, 0, 0, 0)}!important`,
  padding: theme.spacing(2),
  backgroundColor: 'transparent!important',
}));
