import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    root: {
      color: PALETTE.LIGHT_GRAY,
    },
    colorPrimary: {
      '&.Mui-checked': {
        color: PALETTE.MEDIUM_BLUE,
      },
      '&.Mui-disabled': {
        color: PALETTE.GRAY,
      },
    },
  },
};
