import { PALETTE } from 'common/theme/palette/palette';
import theme from 'common/theme';

export const STYLE = {
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    '*::-webkit-scrollbar': {
      width: '20px',
      height: '20px',
      backgroundColor: PALETTE.LIGHTER_GRAY,
    },
  },
} as const;

export const STYLE_BOTTOM_LEFT_GRID: React.CSSProperties = {
  borderRight: `1px solid ${theme.palette.primary.light}`,
} as const;

export const STYLE_TOP_LEFT_GRID: React.CSSProperties = {
  borderBottom: `1px solid ${theme.palette.primary.light}`,
  borderRight: `1px solid ${theme.palette.primary.light}`,
} as const;

export const STYLE_TOP_RIGHT_GRID: React.CSSProperties = {
  borderBottom: `1px solid ${theme.palette.primary.light}`,
} as const;
