import { createContext } from 'react';

export type SortByNameAction = { fieldName: string; direction: 'asc' | 'desc'; };

export type SortByNameDispatch = (action: SortByNameAction) => void;

export type SortByNameState = { fieldName: string; direction: 'asc' | 'desc'; };

export const SortByNameContext = createContext<
  { state: SortByNameState; dispatch: SortByNameDispatch; } | undefined
>(undefined);

createContext<(SortByNameState & SortByNameDispatch) | undefined>(undefined);
