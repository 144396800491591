import React from 'react';

import { IModalContextType, ModalContext } from './ModalContext';

export function useModalContext(): IModalContextType {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error('ModalContext must be used within a BuyAndSellDataCreditsProvider');
  }

  return context;
}
