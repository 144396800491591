import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ResetIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 12 12">
    {/* eslint-disable-next-line max-len */}
    <path d="M9.76716 2.23366C8.80049 1.26699 7.47383 0.666992 6.00049 0.666992C3.05383 0.666992 0.673828 3.05366 0.673828 6.00033C0.673828 8.94699 3.05383 11.3337 6.00049 11.3337C8.48716 11.3337 10.5605 9.63366 11.1538 7.33366H9.76716C9.22049 8.88699 7.74049 10.0003 6.00049 10.0003C3.79383 10.0003 2.00049 8.20699 2.00049 6.00033C2.00049 3.79366 3.79383 2.00033 6.00049 2.00033C7.10716 2.00033 8.09383 2.46033 8.81383 3.18699L6.66716 5.33366H11.3338V0.666992L9.76716 2.23366Z" />
  </SvgIcon>
);

export default React.memo(ResetIcon);
