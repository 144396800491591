import { createContext } from 'react';
import { IColumn } from '../utils/table-data-columns';

export interface FilterDataContext {
    word: string;
    column: IColumn;
    values: string[];
}

export type FilterDataAction = { column?: string; values?: string[]; word?: string; };
export type FilterDataDispatch = (action: Partial<FilterDataAction>) => void;
export type FilterDataState = { word: string; column: string; values: string[] };

export const FilterDataContext = createContext<
    { state: FilterDataState; dispatch: FilterDataDispatch; } | undefined
    >(undefined);
