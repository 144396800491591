import { createContext } from 'react';

export interface IEditRowContext {
  rowToEditId: string;
  setRowToEdit: React.Dispatch<React.SetStateAction<string>>;
}
export type SetEditRowAction =
  { type: 'update'; id: string; fireUpdate: boolean; } |
  { type: 'decline'; };

export type SetEditRowDispatch = (action: SetEditRowAction) => void;
export type SetEditRowState = { id: string; fireUpdate: boolean; };

export const SetEditRowContext = createContext<
  { state: SetEditRowState; dispatch: SetEditRowDispatch; } | undefined
>(undefined);

createContext<(SetEditRowState & SetEditRowDispatch) | undefined>(undefined);
