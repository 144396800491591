import React from 'react';

export type Action = {
  currentCompsetsPropertyEvent?: React.MouseEvent<HTMLElement> | null,
  currentCompsetsProperty?: string | null,
  currentCompsetsId?: string | null
}
export type State = {
  currentCompsetsPropertyEvent: React.MouseEvent<HTMLElement> | null,
  currentCompsetsProperty?: string | null,
  currentCompsetsId?: string | null
}
export type Dispatch = (action: Action) => void
export interface ICurrentCompsetsPropertyContext {state: State, dispatch: Dispatch}

export const CurrentCompsetsPropertyContext = React.createContext<{
  state: State,
  dispatch: Dispatch
} | undefined>(undefined);
