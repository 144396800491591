export const TEXT = {
  TITLE: 'Multifamily Comps',
  CONTACT_ANALYST:
    'Contact Analyst Team to answer questions, generate reports, or use our desktop application:',
  SUPPORT: 'Support',
  CONTACT_TEL: '1-980-308-5222',
  CONTACT_TEL_LINK: 'tel:+19803085222',
  CONTACT_EMAIL: 'support@multifamilycomps.com',
  CONTACT_EMAIL_LINK: 'mailto:support@multifamilycomps.com',
  TEXT_US: 'Drop us a line!',
  SEND: 'Send',
  PLACEHOLDER_NAME: 'Name',
  PLACEHOLDER_EMAIL: 'Email',
  PLACEHOLDER_MESSAGE: 'Message',
  COMPS_VIDEO: 'Comps Overview Video',
  BENCHMARKS_VIDEO: 'Benchmarks Overview Video',
} as const;

export type VideoType = 'Comps Overview Video' | 'Benchmarks Overview Video' | '';
