/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const GiveMeMoneyIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M21.4101 14.7306L18.7506 18.2085C18.2184 18.9044 17.3924 19.3126 16.5164 19.3126H11.5781C11.1898 19.3126 10.875 18.9978 10.875 18.6095C10.875 18.2211 11.1898 17.9063 11.5781 17.9063H15.8583C16.7782 17.9063 17.5486 17.1535 17.531 16.2337C17.5139 15.3424 16.7861 14.6251 15.8906 14.6251H10.9688C9.55562 13.2663 7.37489 13.1073 5.77959 14.2468L5.25 14.6251V22.5938H17.478C18.9135 22.5938 20.2505 21.8639 21.0268 20.6564L23.7708 16.3879C23.9204 16.1551 24 15.8842 24 15.6074C24 14.2271 22.2485 13.6342 21.4101 14.7306Z" />
    <path d="M3.14063 13.2188H0.703126C0.314813 13.2188 0 13.5336 0 13.9219V23.2969C0 23.6852 0.314813 24 0.703126 24H3.14063C3.52894 24 3.84376 23.6852 3.84376 23.2969V13.9219C3.84376 13.5336 3.52894 13.2188 3.14063 13.2188Z" />
    <path d="M13.4208 3.88623C13.2778 3.99592 13.1989 4.162 13.1718 4.30604C13.1344 4.50475 13.1818 4.68934 13.2926 4.77625C13.3317 4.80686 13.3747 4.83695 13.4208 4.86653V3.88623Z" />
    <path d="M13.9219 11.7188C17.1528 11.7188 19.7813 9.09023 19.7813 5.85938C19.7813 2.62852 17.1528 0 13.9219 0C10.691 0 8.0625 2.62852 8.0625 5.85938C8.0625 9.09023 10.691 11.7188 13.9219 11.7188ZM12.1482 7.77511C12.2898 7.55855 12.5804 7.49761 12.7971 7.63945C13.0502 7.80506 13.2089 7.87687 13.4208 7.90664V5.92706C13.1214 5.78986 12.8976 5.65781 12.714 5.51377C12.325 5.20866 12.1474 4.67934 12.2505 4.13227C12.3628 3.53611 12.7778 3.06178 13.3334 2.89444C13.3628 2.88558 13.3919 2.87747 13.4208 2.86983V2.57812C13.4208 2.31928 13.6307 2.10938 13.8895 2.10938C14.1484 2.10938 14.3583 2.31928 14.3583 2.57812V2.81845C14.8134 2.89725 15.1351 3.12131 15.2909 3.30014C15.4609 3.49533 15.4405 3.79144 15.2453 3.96145C15.0512 4.13058 14.7572 4.11136 14.5867 3.91903C14.576 3.9082 14.5015 3.83648 14.3583 3.78366V5.29828C14.4415 5.32917 14.5247 5.35913 14.6063 5.388C15.4302 5.67928 15.8885 6.44203 15.7467 7.28602C15.6364 7.94203 15.1372 8.60733 14.3583 8.80491V9.14062C14.3583 9.39947 14.1484 9.60938 13.8895 9.60938C13.6307 9.60938 13.4208 9.39947 13.4208 9.14062V8.84991C13.0423 8.81719 12.7286 8.71495 12.2838 8.424C12.0672 8.28225 12.0065 7.99177 12.1482 7.77511Z" />
    <path d="M14.8223 7.13085C14.8531 6.94761 14.8657 6.51097 14.3584 6.29736V7.80304C14.6221 7.652 14.7796 7.38491 14.8223 7.13085Z" />
  </SvgIcon>
);

export default React.memo(GiveMeMoneyIcon);
