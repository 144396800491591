import { memo } from 'react';

import { ROUTE } from 'common/components/routes/utils/constants';
import { TABS } from 'containers/input-property/utils/constants';

import { useTabDisabled } from 'containers/input-property/utils/useTabDisabled';

import { TabStyled, TabListStyled } from './utils/styled';

interface TabListComponentProps {
  onChange:(_: React.SyntheticEvent<Element, Event>, newValue: string) => void;
  value: string;
}

const TabListComponent = ({ onChange, value }: TabListComponentProps):JSX.Element => {
  const isDisabled = useTabDisabled();

  return (
    <TabListStyled
      value={value}
      onChange={onChange}
      variant="scrollable"
    >
      <TabStyled
        label={TABS.DISPLAY_INPUT_PROPERTY_MAP}
        value={ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT}
      />
      <TabStyled
        label={TABS.DISPLAY_INPUT_PROPERTY_DETAIL}
        value={ROUTE.ACCOUNT.INPUT_PROPERTY.DETAIL}
        disabled={isDisabled}
      />
    </TabListStyled>
  );
};

export default memo(TabListComponent);
