import { createContext } from 'react';
import { IColumn } from '../utils/table-data-columns';

export interface ITableColumnMenuContext {
  filterBy: IColumn | null;
  setFilterColumn: React.Dispatch<React.SetStateAction<IColumn | null>>;
}

export type FilterAction = { newValue: IColumn; };
export type FilterDispatch = (action: FilterAction) => void;
export type FilterState = { column: IColumn; };

export const TableColumnMenuContext = createContext<
  { state: FilterState; dispatch: FilterDispatch; } | undefined
>(undefined);

createContext<(FilterState & FilterDispatch) | undefined>(undefined);
