import { memo, useMemo } from 'react';

import { useSelectedProperties } from 'common/components/BingMap/local-state/hooks';
import { useCellElement } from 'containers/input-property/components/detailsTab/table/hooks/useCellElement';
import useTooltip from 'common/hooks/useTooltip';

import {
  getDisabled,
} from 'containers/input-property/components/detailsTab/table/utils/renderFunctions';

import { Tooltip, Typography } from '@mui/material';

import { Cell, CellInput } from '../styled';

interface IProps {
  mainKey: string,
  style: React.CSSProperties,
  text: string | number,
  row: string,
  propertyName: string,
}

const CellElement = ({
  mainKey,
  style,
  text,
  propertyName,
  row,
}: IProps):JSX.Element => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();
  const { state: selectedState } = useSelectedProperties();
  const { value, onChangeHandler, onBlurHandler } = useCellElement(text, propertyName, row);

  const textWidth = useMemo(() => (value?.toString().length > 20 ? value : null), [value]);

  return (
    <Cell key={mainKey} style={style}>
      <Tooltip
        followCursor
        open={isOpen}
        title={(
          <Typography>
            {value}
          </Typography>
        )}
        arrow
      >
        <Typography
          noWrap
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
          component="div"
        >
          <CellInput
            fullWidth
            // disabled={getDisabled(propertyName, selectedState.selected, row)}
            value={value}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            name={row}
            sx={{ border: 'none' }}
          />
          {textWidth}
        </Typography>
      </Tooltip>
    </Cell>
  );
};

export default memo(CellElement);
