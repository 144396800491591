import { memo, useEffect } from 'react';

import Grid from '@mui/material/Grid';

import { useHistory } from 'react-router';

import { ROUTE } from 'common/components/routes/utils/constants';

import MySubscription from './components/my-subscription';
import NewSubscription from './components/new-subscription';
import PrintDataTable from './components/print-data';

import {
  ResearchContainerStyled,
  ResearchSectionStyled,
  ResearchTabsWrapper,
} from './utils/styled';

const Research = ():JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ResearchContainerStyled>
        <ResearchTabsWrapper container>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
          >
            <ResearchSectionStyled>
              <NewSubscription />
            </ResearchSectionStyled>
          </Grid>
          <Grid
            item
            xs={12}
            md
          >
            <ResearchSectionStyled>
              <MySubscription />
            </ResearchSectionStyled>
          </Grid>
          <Grid
            item
            xs={12}
            // sm={12}
            // md={7}
          >
            <ResearchSectionStyled>
              <PrintDataTable />
            </ResearchSectionStyled>
          </Grid>
        </ResearchTabsWrapper>
      </ResearchContainerStyled>
    </>
  );
};

export default memo(Research);
