import { memo } from 'react';
import phone from 'assets/phone-icon.png';
import mail from 'assets/email-icon.png';

import {
  ContactsTabStyled,
} from 'containers/main/app-bar/Contacts/styled';

const ContactsTab = ():JSX.Element => (
  <ContactsTabStyled>
    <section className="contact scroll-container">
      <div className="container">
        <h2 className="contact__headline">Contact Us</h2>
        <ul className="contact__contacts">
          <li className="contact__contact scroll-element js-scroll fade-in-bottom">
            <h3 className="contact__name">WH Advisory & Investments LLC</h3>
            <h4 className="contact__role">Webster Hughes, PhD, Managing Member</h4>
            <hr />
            <div className="contact__detail">
              <a href="mailto:whughes@whadvisoryllc.com"><img src={mail} alt="Mail Icon" /></a>
              <a href="mailto:whughes@whadvisoryllc.com">whughes@whadvisoryllc.com</a>
            </div>
            <div className="contact__detail">
              <a href="tel:+17042006305"><img src={phone} alt="Phone Icon" /></a>
              <a href="tel:+17042006305">704-200-6305 (m)</a>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </ContactsTabStyled>
);

export default memo(ContactsTab);
