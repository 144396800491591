import { memo } from 'react';
import { GridCellProps } from 'react-virtualized';
import { IPropertyResponseData } from 'common/components/BingMap/utils/types';
import useTooltip from 'common/hooks/useTooltip';
import { Tooltip, Typography } from '@mui/material';
import ButtonCellElement from './ButtonCellElement';
import CellElement from './CellElement';
import {
  getRowName,
  getFieldName,
  getPropertyName,
  getText,
} from './utils/renderFunctions';

import { FixedCell } from '../styled';

type ExtendedGridCellProps = GridCellProps & {
  mainKey: string,
  data: IPropertyResponseData[],
};

const isCellWithDollarValue = (rawName: string): boolean => {
  const rawNames: string[] = [
    'MEDIAN HOMEVAL',
    'MEDIAN HHINC',
    'MEDIAN RENT',
    'MEDIAN OWNER COSTS',
    'VALUATION AMOUNT',
    'VALUATION PERUNIT',
    'TRAILING NOI PERUNIT',
    'UW NOI PERUNIT',
    'REV PERUNIT',
    'EXP PERUNIT',
    'NOI PERUNIT',
    'NCF PERUNIT',
    'FF REV PERUNIT',
    'FF EXP PERUNIT',
    'FF NOI PERUNIT',
    'FF NCF PERUNIT',
    'GROSRNT PERUNIT',
    'VACANCY PERUNIT',
    'BASERNT PERUNIT',
    'LAUNDRY PERUNIT',
    'PARKING PERUNIT',
    'OTHERIN PERUNIT',
    'RETAXES PERUNIT',
    'PROPINS PERUNIT',
    'UTILITI PERUNIT',
    'REPAIRS PERUNIT',
    'MANAGEM PERUNIT',
    'PAYROLL PERUNIT',
    'MARKETI PERUNIT',
    'PROFESS PERUNIT',
    'GENERAL PERUNIT',
    'OTHEREX PERUNIT',
    'GROUNDR PERUNIT',
    'HC INC PVTPAY PERUNIT',
    'HC INC MEDCARE PERUNIT',
    'HC INC NURSING PERUNIT',
    'HC INC MEALS PERUNIT',
    'HC EXP ROOMS PERUNIT',
    'HC EXP MEALS PERUNIT',
  ];
  return rawNames.includes(rawName.toLocaleUpperCase());
};

const CellRenderer = ({
  columnIndex,
  rowIndex,
  style,
  mainKey,
  data,
}:ExtendedGridCellProps): JSX.Element | null => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  if (rowIndex === 0) {
    if (columnIndex === 0) {
      return <FixedCell key={mainKey} style={style} />;
    }
    return (
      <ButtonCellElement
        mainKey={mainKey}
        style={style}
        propertyName={getPropertyName(columnIndex, data)}
      />
    );
  }
  if (rowIndex === 17) {
    if (columnIndex >= 1) {
      return (
        <Tooltip
          followCursor
          open={isOpen}
          title={(
            <Typography>
              {data[columnIndex - 1].affordability + (data[columnIndex - 1].affordabilityDetails || '')}
            </Typography>
          )}
          arrow
        >
          <CellElement
            mainKey={mainKey}
            style={style}
            row={getFieldName(rowIndex)}
            text={data[columnIndex - 1].affordability + (data[columnIndex - 1].affordabilityDetails || '')}
            propertyName={getPropertyName(columnIndex, data)}
          />
        </Tooltip>
      );
    }
  }

  if (columnIndex === 0) {
    return (
      <Tooltip
        followCursor
        open={isOpen}
        title={(
          <Typography>
            {getRowName(rowIndex)}
          </Typography>
        )}
        arrow
      >
        <FixedCell onMouseMove={onShowTooltip} onMouseOut={onHideTooltip} key={mainKey} style={style}>
          {getRowName(rowIndex)}
        </FixedCell>
      </Tooltip>
    );
  }

  if (isCellWithDollarValue(getRowName(rowIndex))) {
    return (
      <CellElement
        mainKey={mainKey}
        style={style}
        row={getFieldName(rowIndex)}
        text={getText(rowIndex, columnIndex, data).toLocaleString()}
        propertyName={getPropertyName(columnIndex, data)}
      />
    );
  }

  return (
    <CellElement
      mainKey={mainKey}
      style={style}
      row={getFieldName(rowIndex)}
      text={getText(rowIndex, columnIndex, data)}
      propertyName={getPropertyName(columnIndex, data)}
    />
  );
};

export default memo(CellRenderer);
