export const ROUTE = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME_DEFAULT: '/home',
  HOME: {
    HELP: '/home/help',
    INSIGHTS: '/home/insights',
    PRODUCTS: '/home/products',
    DATABASE: '/home/database',
    CONTACT: '/home/contact',
    TEAM: '/home/team',
    PRIVACY: '/home/privacy',
  },
  ACCOUNT_DEFAULT: '/account',
  ACCOUNT: {
    CMBS: '/account/cmbs',
    BUY_AND_SELL_DEFAULT: '/account/buy-and-cell-credits',
    BUY_AND_SELL: {
      PAYMENT_AND_INVOICES: '/account/buy-and-cell-credits/pay-and-invoices',
      BILLING_HISTORY: '/account/buy-and-cell-credits/billing-history',
      PROFILE: '/account/buy-and-cell-credits/profile',
      ADMIN: '/account/buy-and-cell-credits/admin',
    },
    INPUT_PROPERTY_DEFAULT: '/account/input-property',
    INPUT_PROPERTY: {
      DETAIL: '/account/input-property/detail',
    },
    COMPSETS: {
      MAP: '/account/compsets/map',
      DETAIL: '/account/compsets/map/detail',
    },
    RESEARCH_DEFAULT: '/account/research',
    QURTILES_DEFAULT: '/account/quartiles',
    BENCHMARK_DEFAULT: '/account/benchmark',
  },
  STATVAL: '/statval',
  NOT_FOUND: '/404',
} as const;
