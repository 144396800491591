import { styled } from '@mui/material';

export const Root = styled('div')`
    display: flex;
    border-bottom: 1px solid gray;
    &:last-child {
      border-bottom: 0px solid gray;;
    },
`;

export const Header = styled('div')({
  fontFamily: '\'OpenSans-SemiBold\', sans-serif',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '44px',
  padding: '0 12px',
  borderBottom: '1px solid gray',
  width: '100%',
  '& > div': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
