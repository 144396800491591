import { memo, useMemo } from 'react';

import { AutoSizer, MultiGrid } from 'react-virtualized';

import CellRenderer from 'containers/benchmark/components/infoTable/table/CellRenderer';

import { TableStyled } from 'containers/input-property/components/detailsTab/styled';
import { InfoTableWrapper } from 'containers/benchmark/utils/styled';

import { BenchmarkAnalyticsInfoModel } from 'containers/benchmark/lib/models/BencmarkAnalyticsInfo.model';
import { TABLE_SETTINGS } from './utils/constants';

const InfoTable = ({ data }:{data: BenchmarkAnalyticsInfoModel[]}): JSX.Element => {
  const getColumnWidth = useMemo(() => () => TABLE_SETTINGS.COLUMN_WIDTH, []);
  const memoizedRowHeight = useMemo(() => () => TABLE_SETTINGS.ROW_HEIGHT, []);

  const columnCount = useMemo(() => data?.length || 0, [data]);

  return (
    <InfoTableWrapper>
      <TableStyled>
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              cellRenderer={({ key: mainKey, ...rest }) => (
                <CellRenderer
                  {...rest}
                  mainKey={mainKey}
                  key={mainKey}
                  data={data}
                />
              )}
              columnWidth={getColumnWidth}
              columnCount={columnCount}
              enableFixedColumnScroll
              enableFixedRowScroll
              fixedColumnCount={TABLE_SETTINGS.FIXED_COLUMN_COUNT}
              fixedRowCount={TABLE_SETTINGS.FIXED_ROW_COUNT}
              height={height}
              rowHeight={memoizedRowHeight}
              rowCount={TABLE_SETTINGS.ROW_COUNT}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
      </TableStyled>
    </InfoTableWrapper>
  );
};

export default memo(InfoTable);
