import { styled } from '@mui/material';

import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import SortIcon from 'common/components/icons/SortIcon';

import { ROW_HEIGHT } from '../table-data';

export const TableWrapperStyled = styled('div')(({ theme }) => ({
  height: `calc(100% - ${ROW_HEIGHT}px )`,

  [theme.breakpoints.down('md')]: {
    maxHeight: '75%',
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  padding: theme.spacing(3.5, 4),

  '&:nth-child(6n)': {
    bodrer: 0,
  },
}));

export const TableHeadCellStyled = styled(TableCellStyled)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',

  '&:last-child': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderRight: 0,
  },

  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
  },
}));

export const TableBodyCellStyled = styled(TableCellStyled)(
  ({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),

    '&::nth-last-child(4)': {
      borderBottomLeftRadius: theme.shape.borderRadius,
    },

    // [theme.breakpoints.up('md')]: {
    //   '&:nth-child(5n)': {
    //     borderRight: 0,
    //   },
    // },
  }),
);

export const ButtonStyled = styled(Button)({
  flexGrow: '0.3',
  width: '80px',
  height: '70%',
  fontSize: '10px',
});

export const SortIconStyled = styled(SortIcon)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
}));
