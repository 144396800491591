import { memo, useMemo } from 'react';

import {
  useGetInputValue,
} from 'containers/input-property/components/mapTab/propertyData/requiredTable/hooks/useGetInputValue';
import { FormRegister, RowName } from 'containers/input-property/components/mapTab/map/utils/constants';

import { RequiredFields, REQUIRED_TABLE } from 'containers/input-property/utils/constants';
import { TableInputStyled } from '../../styled';

const NumberInput = ({ row, index, register }: {
  row: string | RowName,
  index: number,
  register: FormRegister,
}):JSX.Element => {
  const rowName = row as RowName;

  const { value } = useGetInputValue(index);

  const randomKey = useMemo(() => () => Math.floor((Math.random() * 1000)), []);
  const required = useMemo(() => () => REQUIRED_TABLE.REQUIRED_FIELDS.includes(row as RequiredFields),
    [row]);

  return (
    <TableInputStyled
      type="number"
      id={row}
      fullWidth
      key={randomKey()}
      required={required()}
      defaultValue={value}
      {...register(rowName, { required: true })}
    />
  );
};

export default memo(NumberInput);
