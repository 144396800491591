import { memo } from 'react';
import { useHistory } from 'react-router';

import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { useCurrentCompsetsPropertyContext, useCurrentPropertyContext } from 'context/hooks';

import { ROUTE } from 'common/components/routes/utils/constants';
import { PROPERTY_DETAIL } from '../utils/constants';

import { ButtonCell, DisplayButtonStyled } from '../styled';
// !!!DONT DELETE!!! MAY BE NEEDED IN THE FUTURE
// import CheckboxElement from 'containers/input-property/components/detailsTab/table/CheckboxElement';

interface IProps{
  mainKey: string,
  style: React.CSSProperties,
  propertyName: string,
}

const ButtonCellElement = ({ mainKey, style, propertyName }: IProps):JSX.Element => {
  const history = useHistory();
  const { dispatch: idDispatch } = usePropertyId();
  const { dispatch: propertyDispatch } = useCurrentPropertyContext();
  const { dispatch } = useCurrentCompsetsPropertyContext();

  const clickHandler = ():void => {
    if (propertyName) {
      history.push({ pathname: ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT });
      idDispatch({ newValue: propertyName });
      propertyDispatch({ currentProperty: propertyName });
      dispatch({ currentCompsetsProperty: propertyName });
    }
  };

  return (
    <ButtonCell key={mainKey} style={style}>
      {/* <CheckboxElement propertyName={propertyName} />  !!!DONT DELETE!!! MAY BE NEEDED IN THE FUTURE */}
      <DisplayButtonStyled
        fullWidth
        variant="contained"
        onClick={clickHandler}
      >
        {PROPERTY_DETAIL.EDIT_PROPERTY}
      </DisplayButtonStyled>
    </ButtonCell>
  );
};

export default memo(ButtonCellElement);
