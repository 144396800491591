export const FINANCIAL_DATE_TABLE = {
  TITLE: 'FINANCIAL DATA: Used to Perform COMPSET COMPARISONS',
  ROWS: [
    {
      rowName: 'OPTION 1: INPUT TOP-LINE FINANCIALS',
      inputName: '',
      dataName: '',
      type: 'header',
      disabled: true,
      key: ' ',
      symbol: '',
    },
    {
      rowName: 'STATEMENT YEAR',
      inputName: 'statementYear',
      dataName: '',
      type: 'number',
      disabled: true,
      key: ' ',
      symbol: '',
    },
    {
      rowName: 'OCCUP',
      inputName: 'occup',
      dataName: 'occup',
      type: 'number',
      disabled: true,
      key: ' ',
      symbol: '%',
    },
    {
      rowName: 'REVENUE',
      inputName: 'rev',
      dataName: 'rev',
      type: 'number',
      disabled: true,
      key: 'grosRnt',
      symbol: '$',
    },
    {
      rowName: 'EXPENSES',
      inputName: 'exp',
      dataName: 'expense',
      type: 'number',
      disabled: true,
      key: 'grosRnt',
      symbol: '$',
    },
    {
      rowName: 'CAPEX',
      inputName: 'capex',
      dataName: 'capex',
      type: 'number',
      disabled: true,
      key: 'grosRnt',
      symbol: '$',
    },
    {
      rowName: 'OPTION 2: INPUT LINE-ITEM FINANCIALS',
      inputName: '',
      dataName: '',
      type: 'header',
      disabled: true,
      key: ' ',
      symbol: '',
    },
    {
      rowName: 'GROSRNT',
      inputName: 'grosrnt',
      dataName: 'grosrnt',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '$',
    },
    {
      rowName: 'VACANCY LOSS',
      inputName: 'vacancy',
      dataName: 'vacancy',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'BASERNT',
      inputName: 'baseRnt',
      dataName: 'basernt',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '$',
    },
    {
      rowName: 'LAUNDRY',
      inputName: 'laundry',
      dataName: 'laundry',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'PARKING',
      inputName: 'parking',
      dataName: 'parking',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'OTHERIN',
      inputName: 'otherin',
      dataName: 'otherin',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '$',
    },
    {
      rowName: 'RETAXES',
      inputName: 'retaxes',
      dataName: 'retaxes',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '$',
    },
    {
      rowName: 'PROPINS',
      inputName: 'propins',
      dataName: 'propins',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'UTILITI',
      inputName: 'utiliti',
      dataName: 'utiliti',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'REPAIRS',
      inputName: 'repairs',
      dataName: 'repairs',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'MANAGEM',
      inputName: 'managem',
      dataName: 'managem',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'PAYROLL',
      inputName: 'payroll',
      dataName: 'payroll',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'MARKETI',
      inputName: 'marketi',
      dataName: 'marketi',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'PROFESS',
      inputName: 'profess',
      dataName: 'profess',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'GENERAL',
      inputName: 'general',
      dataName: 'general',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'OTHEREX',
      inputName: 'otherex',
      dataName: 'otherex',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'GROUNDR',
      inputName: 'groundr',
      dataName: 'groundr',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
    {
      rowName: 'CAPEX',
      inputName: 'capex',
      dataName: 'capex',
      type: 'number',
      disabled: true,
      key: 'topLine',
      symbol: '$',
    },
  ],
  COLUMN_INDEX: 0,
  OVERSCAN_ROW_COUNT: 20,
  DEFAULT_HEIGHT: 28,
} as const;

export const MODIFY_FINANCIALS_BUTTON_TEXT = 'Modify financials save' as const;

export const FINANCIAL_LINE_DATA = [
  { name: 'INPUT TOP-LINE FINANCIALS', config: 'useTopLine' },
  { name: 'INPUT LINE-ITEMS (CALC TOP-LINE)', config: 'useLineItem' },
] as const;

export const FINANCIAL_RNT_DATA = [
  { name: 'USE GROSRNT/VACANCY', config: 'useGrosRnt' },
  { name: 'USE BASERNT', config: 'useBaseRnt' },
] as const;
