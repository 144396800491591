export const TABS = {
  MAP: 'MAP',
  DETAIL: 'DETAIL',
} as const;

export const ROW_HEIGHT = 28 as const;

export const COMPSET_MAP_TAB = {
  INSERT: 'insert property into client portfolio',
  CANCEL: 'cancel',
  MODIFY: 'Modify atributes',
  SUBSCRIBE: 'Subscribe',
  DISPLAY_PROPERTY_DATA: 'DISPLAY PROPERTY DATA',
  UINTS: 'Units',
  BUILDING_AGE: 'Building age',
  SUBTYPE: 'Subtype',
  AFFORDABLE_ONLY: 'Affordable only',
  MARKETTRATE_ONLY: 'Marketrate only',
  REQUIRE_STNT: 'Require 2020 + oper STMNT',
  REQUIRE_UNDERWRITING: 'Require 2020 + underwriting',
  PROPERTY_INFO: 'Property info',
  SELECT_COMPS_FROM_MAP: 'SELECT COMPS FROM MAP',
  REMOVE: 'REMOVE',
  SUBSCRIBE_COMPS: 'SUBSCRIBE COMPS(X)',
  REMOVE_COMPS: 'REMOVE COMPS(X) TO COMPSET',
  DISPLAY_ALL: 'DISPLAY ALL CLIENTCOMPSETS',
  DISPLAY_UNASSIGMED: 'DISPLAY UNASSIGMED CLIENTCOMPS',
  SELECT_OBJECT: 'Select Property from Client Portfolio',
  FILTER: 'DISPLAY COMPS USING FILTERS',
  FILTERS: 'FILTERS',
  TOTAL_PRICE: 'TOTAL PRICE:',
  DOLLAR_SIGN: '$',
  BUILT: 'Built',
  CREDIT: 'Comp Credit',
  UNITS: 'Units',
  YEAR: 'Year',
  PRICE: 'PRICE: ',
  INPUT_NEW_PROPERTY: 'INPUT NEW PROPERTY',
  ALGORITHM: 'DISPLAY RECOMMENDED COMPS',
  /* eslint-disable max-len */
  PRICE_INFO: 'One-time charge includes one year of monthly updates of all financial reports, census data (1-mile radius), and all related investment analytics. Data subscription is for your internal use only for multifamily investment analysis. Redistribution, selling, or sharing of data is prohibited.',
} as const;

export const SUB_TYPES = [
  'GARDEN', 'MIDRISE', 'HIRISE', 'SENIOR', 'HEALTHCARE', 'STUDENT', 'MILITARY', 'MANUF',
] as const;

export const NUMBER_INPUTS = [
  'Zip', 'Year Built', 'Year Renov', 'Num Units',
];

export const REQUIRED_TABLE = {
  TITLE: 'PROPERTY NAME',
  ROWS: [
    'Property name',
    'Address',
    'City',
    'State',
    'Zip',
    'County',
    'Year Built',
    'Year Renov',
    'Num Units',
    'SubType',
    'Affordability',
  ],
} as const;

export const defaultFilters = {
  filterUnits: false,
  filterYear: false,
  filterSameSubtype: false,
  filterAfforadable: false,
  filterSimilarSubtype: false,
  filter2020FF: false,
  filter2020UW: false,
} as const;
