import { memo, Suspense, lazy } from 'react';

import 'bingmaps';

import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';

import { MapWrapperStyled } from './styled';

const BingMap = lazy(() => import('./components/BingMap'));

const Map = ():JSX.Element => (
  <Suspense fallback={<SuspenseFallback />}>
    <MapWrapperStyled>
      <BingMap />
    </MapWrapperStyled>
  </Suspense>
);

export default memo(Map);
