import {
  InfoTablePopupAction,
  InfoTablePopupState,
} from 'containers/benchmark/local-state/InfoTablePopupContext';
import {
  BenchmarkAnalyticsCallbackAction,
  BenchmarkAnalyticsCallbackState,
} from './BenchmarkAnalyticsCallbackContext';
import { RadioBenchmarkAction, RadioBenchmarkState } from './RadioBenchmarkContext';
import { ClientPortfolioNameAction, ClientPortfolioNameState } from './SelectClientPortfolioNameContext';

function setInfoTablePopupReducer(
  state: InfoTablePopupState,
  action: InfoTablePopupAction,
): InfoTablePopupState {
  let copy = { ...state };

  copy = {
    open: action.type === 'open',
  };

  return copy;
}

function setPortfolioNameReducer(
  state: ClientPortfolioNameState,
  action: ClientPortfolioNameAction,
): ClientPortfolioNameState {
  let copy = { ...state };

  copy = {
    value: action.value,
  };

  return copy;
}

function setRadioSelectionReducer(
  state: RadioBenchmarkState,
  action: RadioBenchmarkAction,
): RadioBenchmarkState {
  const copy = { ...state };
  copy.value = action.type;

  return copy;
}

function setCallbackReducer(
  state: BenchmarkAnalyticsCallbackState,
  action: BenchmarkAnalyticsCallbackAction,
): BenchmarkAnalyticsCallbackState {
  const copy = { ...state };
  if (action.type === 'call') {
    copy.callback = () => null;
  } else {
    copy.callback = null;
  }
  return copy;
}

export {
  setInfoTablePopupReducer,
  setPortfolioNameReducer,
  setRadioSelectionReducer,
  setCallbackReducer,
};
