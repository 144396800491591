import { memo } from 'react';
import { useQueryClient } from 'react-query';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { QUERY_KEY } from 'common/query-keys';
import QueryLoadingProvider from 'common/components/QueryLoadingProvider';

import { RESEARCH } from 'containers/research/utils/constants';
import { useGetQuartiles } from 'containers/research/lib/query-hooks';

import { useCreateQuartilesTableData } from 'containers/research/lib/mapped-table-data';
import { SpinnerWrapper } from 'common/components/SuspenseFallback/styled';
import { Spinner } from 'common/components/spinner/Spinner';
import { TableQuartilesDataCell } from 'containers/research/lib/models/types';
import { TableQuartiles } from './table-quartiles';
import ExportDataMenu from './table-quartiles/ExportDataMenu';

import { HeaderStyled } from '../styled';
import { IconButtonStyled } from '../new-subscription/modal/table/styled';

const QuartilesDataTable = ({ close }: { close: VoidFunction}): JSX.Element => {
  const fetchedQuartilesData = useGetQuartiles();
  const queryClient = useQueryClient();

  const isTableDataQuartilesInCache = !!queryClient.getQueryData(QUERY_KEY.QUARTILES);

  const quartilesData = (useCreateQuartilesTableData() || null) as TableQuartilesDataCell[];
  const quartiles = useGetQuartiles();

  if (!quartilesData.length || quartiles.isFetching) {
    return (
      <SpinnerWrapper>
        <Spinner size="large" color="primary" />
      </SpinnerWrapper>
    );
  }

  return (
    <Box height="100%">
      <HeaderStyled>
        <Typography
          display="flex"
          variant="h5"
          paddingBottom={2}
        >
          { RESEARCH.TITLES.PRINT_QUARTILES }
          <Box marginLeft={8}>
            <ExportDataMenu />
          </Box>
        </Typography>
        <IconButtonStyled onClick={close}>
          <CloseIcon fontSize="large" />
        </IconButtonStyled>
      </HeaderStyled>
      <QueryLoadingProvider result={fetchedQuartilesData}>
        {isTableDataQuartilesInCache && <TableQuartiles />}
      </QueryLoadingProvider>
    </Box>
  );
};

export default memo(QuartilesDataTable);
