import React, { useMemo } from 'react';
import { FinancialContext } from './FinancialContext';
import { financialReducer } from './financialReducer';

export const FinancialContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [financialState, setFinancialState] = React.useReducer(financialReducer, {
    useTopLine: true,
    useLineItem: false,
    useGrosRnt: false,
    useBaseRnt: false,
  });

  const ValuationContextValue = useMemo(() => ({
    state: financialState,
    dispatch: setFinancialState,
  }), [financialState]);

  return (
    <FinancialContext.Provider value={ValuationContextValue}>
      {children}
    </FinancialContext.Provider>
  );
};
