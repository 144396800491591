import { memo } from 'react';

import { CellInput } from 'containers/benchmark/utils/styled';

const InputRenderer = ({ name, value }: {
  name: string,
  value: string | number | undefined
}):JSX.Element => (
  <CellInput
    fullWidth
    disabled
    name={name}
    key={value}
    value={value}
  />
);

export default memo(InputRenderer);
