import { ISavedCompsets } from 'containers/compsets/lib/models/SavedCompsets.interface';
import { PriceActionType, Action, State } from './CompsPricesContext';

export function priceReducer(state: State, action: Action): State {
  const copy = { ...state };

  switch (action.type) {
    case PriceActionType.Add:
      if (action.newPrice) {
        copy.prices.push(action.newPrice);
        copy.prices = copy.prices.filter((value, index) => {
          const priceValue = JSON.stringify(value);
          return index === copy.prices.findIndex((obj) => JSON.stringify(obj) === priceValue);
        });
        copy.totalPrice = copy.prices.reduce((acc, item): number => acc + item.price, 0);
      }

      return copy;
    case PriceActionType.Delete:
      if (action.newPrice) {
        const price = action.newPrice;
        copy.prices = copy.prices.filter((item) => item.propertyName !== price.propertyName);
        copy.totalPrice = copy.prices.reduce((acc, item): number => acc + item.price, 0);
      }
      return copy;
    case PriceActionType.Clear:
      copy.prices.length = 0;
      copy.totalPrice = 0;
      copy.savedProperties = [] as ISavedCompsets[];

      return copy;
    case PriceActionType.SavedProperties:
      copy.savedProperties = action.savedProperties as ISavedCompsets[];

      return copy;

    default:

      return copy;
  }
}
