import { memo, useMemo } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { ROUTE } from 'common/components/routes/utils/constants';
import BenchmarkProvider from 'containers/benchmark/local-state/BenchmarkProvider';
import BenchmarkList from 'containers/benchmark/components/list';
import BenchmarkTableSection from 'containers/benchmark/components/benchmarkTable';
import BenchmarkInfoTable from 'containers/benchmark/components/infoTable';
import {
  BuyAndSellDataCreditsProvider,
} from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/BuyAndSellDataCreditsProvider';

import {
  BenchmarkContainerStyled,
  BenchmarkTabsWrapper,
  SectionStyled,
} from 'containers/benchmark/utils/styled';

const Benchmark = memo((): JSX.Element => {
  const history = useHistory();

  const routerPath = history.location.pathname;

  const isWrongBenchmarkPath = useMemo(
    () => Object.values([ROUTE.ACCOUNT.BENCHMARK_DEFAULT])
      .every((path) => path !== routerPath),
    [routerPath],
  );

  return (

    <BenchmarkContainerStyled>
      <BenchmarkProvider>
        {isWrongBenchmarkPath
          ? <Redirect to={ROUTE.NOT_FOUND} />
          : (
            <BuyAndSellDataCreditsProvider>
              <BenchmarkTabsWrapper>
                <SectionStyled>
                  <BenchmarkList />
                </SectionStyled>
                <SectionStyled>
                  <BenchmarkTableSection />
                </SectionStyled>
                <BenchmarkInfoTable />
              </BenchmarkTabsWrapper>
            </BuyAndSellDataCreditsProvider>
          )}
      </BenchmarkProvider>
    </BenchmarkContainerStyled>
  );
});

export default Benchmark;
