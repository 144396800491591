import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const FoldersIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 30 24">
    {/* eslint-disable-next-line max-len */}
    <path d="M28.2301 5.49316H3.82907L0.413814 22.1084C0.301783 22.6536 0.71815 23.1643 1.27473 23.1643H26.6667C27.5689 23.1643 28.3267 22.4857 28.4259 21.589L29.9893 7.4577C30.1053 6.40951 29.2847 5.49316 28.2301 5.49316Z" />
    {/* eslint-disable-next-line max-len */}
    <path d="M24.65 2.18719H9.176C8.69664 2.18719 8.30805 1.79859 8.30805 1.31924V0.884942C8.30805 0.396212 7.91184 0 7.42311 0H2.76988C1.79242 0 1 0.792362 1 1.76988V16.6623L3.27092 5.61398C3.35389 5.21039 3.70908 4.92076 4.12111 4.92076H26.4198V3.95707C26.4198 2.97955 25.6274 2.18719 24.65 2.18719Z" />
  </SvgIcon>
);

export default React.memo(FoldersIcon);
