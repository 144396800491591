import { memo } from 'react';

import { useHistory } from 'react-router';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useAuth } from 'containers/login/lib/auth';

import { ROUTE } from 'common/components/routes/utils/constants';
import { TABS } from 'containers/buy-sell-credits/utils/constants';
import { BUTTON_TEXT } from './constants';
import { USER_ROLES } from '../constants';

import {
  FormControlWrapper, LogoutButtonWrapper, MenuItemStyled, SelectStyled,
} from './styled';

export const ProfileMenu = memo(({ path }:
 { path: string, }) => {
  const { logout, user } = useAuth();

  const history = useHistory();

  const handleRouteLink = (e: React.MouseEvent<HTMLLIElement>): void => {
    history.push({ pathname: e.currentTarget.dataset.value });
  };

  const onLogoutHandle = (): void => {
    history.push({ pathname: ROUTE.LOGOUT });
    logout();
  };

  return (
    <FormControlWrapper>
      <FormControl>
        <SelectStyled
          value={0}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value={0} sx={{ pr: 6 }}>
            <Typography
              variant="subtitle1"
              color="primary"
              component="div"
            >
              {user?.name}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItemStyled
            value={ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT}
            onClick={handleRouteLink}
            $isSelected={
              path === ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT
            }
          >
            <Typography
              variant="subtitle1"
              color="primary"
              component="div"
            >
              {TABS.BUY_AND_SELL}
            </Typography>
          </MenuItemStyled>
          {/** these features in progress, It will be added as soon as possible */}
          {/* <MenuItemStyled
            value={ROUTE.ACCOUNT.BUY_AND_SELL.PAYMENT_AND_INVOICES}
            $isSelected={
              path === ROUTE.ACCOUNT.BUY_AND_SELL.PAYMENT_AND_INVOICES
            }
            onClick={handleRouteLink}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              component="div"
            >
              {TABS.PAYMENT_AND_INVOICES}
            </Typography>
          </MenuItemStyled> */}
          {/* <MenuItemStyled
            value={ROUTE.ACCOUNT.BUY_AND_SELL.BILLING_HISTORY}
            $isSelected={
              path === ROUTE.ACCOUNT.BUY_AND_SELL.BILLING_HISTORY
            }
            onClick={handleRouteLink}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              component="div"
            >
              {TABS.BILLING_HISTORY}
            </Typography>
          </MenuItemStyled> */}
          <MenuItemStyled
            value={user?.role === USER_ROLES.USER
              ? ROUTE.ACCOUNT.BUY_AND_SELL.PROFILE
              : ROUTE.ACCOUNT.BUY_AND_SELL.ADMIN}
            $isSelected={
              path
              === ROUTE.ACCOUNT.BUY_AND_SELL.PROFILE
              || path === ROUTE.ACCOUNT.BUY_AND_SELL.ADMIN
            }
            onClick={handleRouteLink}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              component="div"
            >
              {user?.role === USER_ROLES.USER ? TABS.PROFILE : TABS.ADMIN}
            </Typography>
          </MenuItemStyled>
          <Divider />
          <LogoutButtonWrapper>
            <Button
              variant="contained"
              color="secondary"
              onClick={onLogoutHandle}
              fullWidth
            >
              {(BUTTON_TEXT.LOGOUT).toUpperCase()}
            </Button>
          </LogoutButtonWrapper>
        </SelectStyled>
      </FormControl>
    </FormControlWrapper>
  );
});
