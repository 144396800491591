import { memo, useMemo } from 'react';

import { Tooltip, Typography } from '@mui/material';
import { GridCellProps } from 'react-virtualized';

import HeaderCell from 'containers/benchmark/components/SHARED/HeaderCell';
import { BENCHMARK_TABLE } from 'containers/benchmark/components/benchmarkTable/table/utils/constants';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';
import useTooltip from 'common/hooks/useTooltip';

import {
  BlindCell,
  Cell,
  FixedCellTable,
  TypographyTable,
} from 'containers/input-property/components/detailsTab/styled';
import { formattedDate } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/helpers';

type ExtendedGridCellProps = GridCellProps & {
  mainKey: string;
  data: BenchmarkAnalyticsModel[];
};

const CellRenderer = ({
  columnIndex,
  rowIndex,
  style,
  mainKey,
  data,
}:ExtendedGridCellProps): JSX.Element | null => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  const cellName = BENCHMARK_TABLE.ROWS[rowIndex - 1]?.row;
  const dataName = BENCHMARK_TABLE.ROWS[rowIndex - 1]?.dataName;
  const symbol = BENCHMARK_TABLE.ROWS[rowIndex - 1]?.symbol;
  const positive = BENCHMARK_TABLE.ROWS[rowIndex - 1]?.positive;
  const cellData = data[columnIndex];

  const percent = useMemo(() => `${cellData
    ? (Math.round(cellData[dataName] * 100000 as number) / 1000).toFixed(1) : ''} 
    ${cellData ? symbol : ''}`,
  [cellData, dataName, symbol]);

  const dubPercent = useMemo(() => `${cellData
    ? (Math.round(cellData[dataName] * 100000 as number) / 1000).toFixed(2) : ''} 
    ${cellData ? '%' : ''}`,
  [cellData, dataName]);

  const int = useMemo(() => `${cellData
    ? (Math.round(cellData[dataName] as number)).toLocaleString('us') : ''}`,
  [cellData, dataName]);

  const price = useMemo(() => `${cellData ? symbol : ''}
   ${cellData ? (Math.round(cellData[dataName] as number)).toLocaleString('us') : ''}`,
  [cellData, dataName, symbol]);

  const entire = useMemo(() => {
    if (!cellData || !cellData[dataName]) return '';
    if (dataName === 'statementEndDate' && cellData[dataName] !== null) {
      return formattedDate(cellData[dataName] as unknown as Date);
    }
    if (dataName === 'valuationDate' && cellData[dataName] !== null) {
      return formattedDate(cellData[dataName] as unknown as Date);
    }
    if (dataName === 'statementDate' && cellData[dataName] !== null) {
      return formattedDate(cellData[dataName] as unknown as Date);
    }
    if (dataName === 'medianAge' && cellData[dataName] !== null) {
      return (Math.round(cellData[dataName] as unknown as number * 10) / 10).toFixed(1);
    }
    return typeof cellData[dataName] === 'number'
      ? Math.floor(cellData[dataName] as unknown as number) : cellData[dataName];
  },
  [cellData, dataName]);

  const displayData = useMemo((): number | string => {
    switch (symbol) {
      case '$':
        return price;
      case '%':
        return percent;
      case '2%':
        return dubPercent;
      case 'int':
        return int;
      default:
        return entire;
    }
  }, [dubPercent, entire, int, percent, price, symbol]);

  const indexColor = useMemo((): string => {
    if (cellData) {
      if (positive === 'green' && cellData[dataName] as number >= 0) return 'green';
      if (positive === 'green' && cellData[dataName] as number <= 0) return 'red';
      if (positive === 'red' && cellData[dataName] as number >= 0) return 'red';
      if (positive === 'red' && cellData[dataName] as number <= 0) return 'green';
      if (positive === 'black') return 'black';
    }
    return 'green';
  }, [cellData, dataName, positive]);

  if (rowIndex === 0) {
    return (
      <FixedCellTable key={mainKey} style={style}>
        <HeaderCell
          text={BENCHMARK_TABLE.COLUMNS[columnIndex]}
          columns={BENCHMARK_TABLE.COLUMNS as unknown as string[]}
        />
      </FixedCellTable>
    );
  }
  if (rowIndex === 16 && (columnIndex === 4)) {
    return (
      <BlindCell key={mainKey} style={style}>
        {cellData ? cellData[dataName] : null}
      </BlindCell>
    );
  }
  if (rowIndex === 16 && (columnIndex === 3)) {
    return (
      <BlindCell key={mainKey} style={style}>
        {cellData ? cellData[dataName] : null}
      </BlindCell>
    );
  }
  if (rowIndex === 23 && (columnIndex === 4)) {
    return (
      <BlindCell key={mainKey} style={style}>
        {cellData ? cellData[dataName] : null}
      </BlindCell>
    );
  }
  if (rowIndex === 23 && (columnIndex === 3)) {
    return (
      <BlindCell key={mainKey} style={style}>
        {cellData ? cellData[dataName] : null}
      </BlindCell>
    );
  }
  if (columnIndex === 0) {
    return (
      <FixedCellTable key={mainKey} style={style}>
        {cellName}
      </FixedCellTable>
    );
  }

  if (columnIndex === 2) {
    return (
      <Cell key={mainKey} style={style} color={indexColor}>
        <Tooltip
          open={isOpen}
          title={(
            <Typography noWrap variant="body2">
              {cellData ? cellData[dataName] : null}
            </Typography>
          )}
          arrow
        >
          <TypographyTable
            color={indexColor}
            noWrap
            variant="body2"
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            { displayData }
          </TypographyTable>
        </Tooltip>
      </Cell>
    );
  }

  return (
    <Cell key={mainKey} style={style}>
      <Tooltip
        open={isOpen}
        title={(
          <Typography noWrap variant="body2">
            {cellData ? cellData[dataName] : null}
          </Typography>
        )}
        arrow
      >
        <TypographyTable
          noWrap
          variant="body2"
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
        >
          { displayData }
        </TypographyTable>
      </Tooltip>
    </Cell>
  );
};

export default memo(CellRenderer);
