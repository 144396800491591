import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
);

const ChartTableOfRevenue = ({ state }: { state: BenchmarkAnalyticsModel[] }): JSX.Element | null => {
  const bgColor = {
    id: 'bgColor',
    beforeDraw: (chart: { ctx: any; width: any; height: any; }, options: any) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback(value: any) {
            return `${value}%`;
          },
        },
      },
    },
    plugins: {
      bgColor,
      datalabels: {
        anchor: 'end' as const,
        align: 'top' as const,
        font: {
          size: 9,
        },
        padding: {
          bottom: -9,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'TOP-LINE FINANCIALS (% OF REVENUE)',
      },
      subtitle: {
        display: true,
        text: 'VERSUS COMPSET MEDIANS AND QUARTILES',
      },
    },
  };

  const labels = ['EXP PCTREV', 'NOI PCTREV'];

  if (!state) return null;
  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.5,
        label: 'CLIENT PROPERTY',
        data: [+(state[0].expPctRev * 100).toFixed(0), (state[0].noiPctRev * 100).toFixed(0)],
        backgroundColor: 'rgb(68, 116 , 193)',
      },
      {
        barPercentage: 0.5,
        label: '1ST QUARTILE',
        data: [+(state[2].expPctRev * 100).toFixed(0), (state[2].noiPctRev * 100).toFixed(0)],
        backgroundColor: 'rgb(85, 129 , 59)',
      },
      {
        barPercentage: 0.5,
        label: 'MEDIAN',
        data: [(state[3].expPctRev * 100).toFixed(0), (state[3].noiPctRev * 100).toFixed(0)],
        backgroundColor: 'rgb(254, 190 , 48)',
      },
      {
        barPercentage: 0.5,
        label: '4TH QUARTILE',
        data: [(state[4].expPctRev * 100).toFixed(0), (state[4].noiPctRev * 100).toFixed(0)],
        backgroundColor: 'rgb(253, 0 , 19)',
      },
    ],
  };
  return (
    <Bar
      style={{ border: '2px solid rgb(68, 116 , 193)' }}
      width={50}
      height={25}
      options={options}
      data={data}
    />
  );
};
export default ChartTableOfRevenue;
