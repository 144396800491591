import { createContext, useReducer } from 'react';

type StrNumNull = string | number | null;

interface StatvalTable {
  expPctrev?: StrNumNull;
  expPerunit?: StrNumNull;
  noiPctrev?: StrNumNull;
  noiPerunit?: StrNumNull;
  occup?: StrNumNull;
  revPerunit?: StrNumNull;
  statementEndDateEstimate?: StrNumNull;
  statementEndDateMostrecent?: StrNumNull;
  uwCaprateComment?: StrNumNull;
  uwCaprateEstimate?: StrNumNull;
  uwCaprateEstimateVsMostrecent?: StrNumNull;
  uwCaprateHigh?: StrNumNull;
  uwCaprateLow?: StrNumNull;
  uwCaprateMostrecent?: StrNumNull;
  uwDateMostrecent?: StrNumNull;
  uwExpPctrevEstimate?: StrNumNull;
  uwExpPctrevEstimateVsMostrecent?: StrNumNull;
  uwExpPctrevEstimateVsTrailing?: StrNumNull;
  uwExpPctrevHigh?: StrNumNull;
  uwExpPctrevLow?: StrNumNull;
  uwExpPctrevMostrecent?: StrNumNull;
  uwExpPerunitEstimate?: StrNumNull;
  uwExpPerunitEstimateVsMostrecent?: StrNumNull;
  uwExpPerunitEstimateVsTrailing?: StrNumNull;
  uwExpPerunitHigh?: StrNumNull;
  uwExpPerunitLow?: StrNumNull;
  uwExpPerunitMostrecent?: StrNumNull;
  uwNoiPctrevEstimate?: StrNumNull;
  uwNoiPctrevEstimateVsMostrecent?: StrNumNull;
  uwNoiPctrevEstimateVsTrailing?: StrNumNull;
  uwNoiPctrevHigh?: StrNumNull;
  uwNoiPctrevLow?: StrNumNull;
  uwNoiPctrevMostrecent?: StrNumNull;
  uwNoiPerunitEstimate?: StrNumNull;
  uwNoiPerunitEstimateVsMostrecent?: StrNumNull;
  uwNoiPerunitEstimateVsTrailing?: StrNumNull;
  uwNoiPerunitHigh?: StrNumNull;
  uwNoiPerunitLow?: StrNumNull;
  uwNoiPerunitMostrecent?: StrNumNull;
  uwOccupEstimate?: StrNumNull;
  uwOccupEstimateVsMostrecent?: StrNumNull;
  uwOccupEstimateVsTrailing?: StrNumNull;
  uwOccupHigh?: StrNumNull;
  uwOccupLow?: StrNumNull;
  uwOccupMostrecent?: StrNumNull;
  uwRevPerunitEstimate?: StrNumNull;
  uwRevPerunitEstimateVsMostrecent?: StrNumNull;
  uwRevPerunitEstimateVsTrailing?: StrNumNull;
  uwRevPerunitHigh?: StrNumNull;
  uwRevPerunitLow?: StrNumNull;
  uwRevPerunitMostrecent?: StrNumNull;
  uwValPerunitMostrecent?: StrNumNull;
  uwValuationEstimate?: StrNumNull;
  uwValuationHigh?: StrNumNull;
  uwValuationLow?: StrNumNull;
  uwValuationMostrecent?: StrNumNull;
  valDateComment?: StrNumNull;
  valDateEstimate?: StrNumNull;
  valPerunitComment?: StrNumNull;
  valPerunitEstimate?: StrNumNull;
  valPerunitEstimateVsMostrecent?: StrNumNull;
  valPerunitHigh?: StrNumNull;
  valPerunitLow?: StrNumNull;
  valuationComment? : StrNumNull;
  valuationEstimateVsTrailing? : StrNumNull;
  histCapRate? : StrNumNull;
  histTRSY?: StrNumNull;
  currTRSYDate?: StrNumNull;
  currTRSY?: StrNumNull;
  capRateADJ?: StrNumNull;
  currCapRate?: StrNumNull;
  numUnits?: StrNumNull;
}

export type Action = { type: 'SET_PROP_NAME'; payload: string;} |
                     { type: 'SET_IS_PROP_NAME_SELECTED'; payload: boolean;} |
                     { type: 'SET_TABLE_DATA'; payload: StatvalTable | null;} |
                     { type: 'SET_OBSERVATIONS_SET_TABLE_DATA'; payload: {[key: string]: string | null}[];};
export type Dispatch = (action: Action) => void;

export type State = {
    propName: string;
    isPropNameSelected: boolean;
    tableData:StatvalTable | null;
    observationSets: {[key: string]: string | null}[];
};

type GenericStateContext = State;
type GenericDispatchContext = Dispatch;

export const TableStateContext = createContext<GenericStateContext>(
  {
    propName: '',
    isPropNameSelected: false,
    tableData: null,
    observationSets: [],
  },
);
export const TableDispatchContext = createContext<GenericDispatchContext>(() => undefined);

const initialState: State = {
  propName: '',
  isPropNameSelected: false,
  tableData: null,
  observationSets: [],
};

const reducer = (state: State, action: Action): State | never => {
  switch (action.type) {
    case 'SET_PROP_NAME':
      return {
        ...state,
        propName: action.payload,
      };
    case 'SET_IS_PROP_NAME_SELECTED':
      return {
        ...state,
        isPropNameSelected: action.payload,
      };
    case 'SET_TABLE_DATA': // TODO: rename into SET_COMPARISON_TABLE_DATA
      return {
        ...state,
        tableData: action.payload,
      };
    case 'SET_OBSERVATIONS_SET_TABLE_DATA':
      return {
        ...state,
        observationSets: action.payload,
      };
    default:
      throw new Error('No such action type in reducer!');
  }
};

export const TableContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch]: [State, Dispatch] = useReducer(
    reducer,
    initialState,
  );

  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        {children}
      </TableDispatchContext.Provider>
    </TableStateContext.Provider>
  );
};
