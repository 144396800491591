export const enum PrintedDataKeys {
  City = 'city',
  State = 'state',
  Zip = 'zip',
  County = 'county',
  Cmsa = 'cmsa',
  NcreifDivision = 'ncreifDivision',
  NcreifRegion = 'ncreifRegion',
  YearBuilt = 'yearBuilt',
  YearRenov = 'yearRenov',
  NumUnits = 'numUnits',
  SubType = 'subType',
  Affordability = 'affordability',
  PopulationPerSqMile = 'populationPerSqMile',
  MedianHomeVal = 'medianHomeVal',
  MedianHHInc = 'medianHHInc',
  MedianAge = 'medianAge',
  MedianRent = 'medianRent',
  MedianOwnerCosts = 'medianOwnerCosts',
  RenterPct = 'renterPct',
  rentOver30PctHHIncPCT = 'rentOver30PctHHIncPCT',
  BachelorsDegreePCT = 'bachelorsDegreePCT',
  UnemploymentPCT = 'unemploymentPCT',
  MgtFinanceJobPCT = 'mgtFinanceJobPCT',
  MovedInAfter2010PCT = 'movedInAfter2010PCT',
  UnderwritingUwYear = 'underwritingUwYear',
  TopLineRevYear = 'topLineRevYear',
  FullLineItemFfYear = 'fullLineItemFfYear',
  AppraisalOrStatVal = 'appraisalOrStatVal',
  ValuationDate = 'valuationDate',
  StatementEndDateRev = 'statementEndDateRev',
  ValuationPerunit = 'valuationPerunit',
  UwNoiPerunit = 'uwNoiPerunit',
  UwCaprate = 'uwCaprate',
  Occup = 'occup',
  RevPerunit = 'revPerunit',
  ExpPerunit = 'expPerunit',
  NoiPerunit = 'noiPerunit',
  NcfPerunit = 'ncfPerunit',
  CapexPerunit = 'capexPerunit',
  ExpPctrev = 'expPctrev',
  NoiPctrev = 'noiPctrev',
  NcfPctrev = 'ncfPctrev',
  CapexPctrev = 'capexPctrev',
  RevPct1Yrch = 'revPct1Yrch',
  ExpPct1Yrch = 'expPct1Yrch',
  NoiPct1Yrch = 'noiPct1Yrch',
  RevPerunit1Yrch = 'revPerunit1Yrch',
  ExpPerunit1Yrch = 'expPerunit1Yrch',
  NoiPerunit1Yrch = 'noiPerunit1Yrch',
  GrosrntPerunit = 'grosrntPerunit',
  VacancyPerunit = 'vacancyPerunit',
  BaserntPerunit = 'baserntPerunit',
  RetaxesPerunit = 'retaxesPerunit',
  PropinsPerunit = 'propinsPerunit',
  UtilitiPerunit = 'utilitiPerunit',
  RepairsPerunit = 'repairsPerunit',
  ManagemPerunit = 'managemPerunit',
  PayrollPerunit = 'payrollPerunit',
  MarketiPerunit = 'marketiPerunit',
  GrosrntPctrev = 'grosrntPctrev',
  VacancyPctrev = 'vacancyPctrev',
  BaserntPctrev = 'baserntPctrev',
  RetaxesPctrev = 'retaxesPctrev',
  PropinsPctrev = 'propinsPctrev',
  UtilitiPctrev = 'utilitiPctrev',
  RepairsPctrev = 'repairsPctrev',
  ManagemPctrev = 'managemPctrev',
  PayrollPctrev = 'payrollPctrev',
  MarketiPctrev = 'marketiPctrev',
  GrosrntPerunit1Yrch = 'grosrntPerunit1Yrch',
  VacancyPerunit1Yrch = 'vacancyPerunit1Yrch',
  BaserntPerunit1Yrch = 'baserntPerunit1Yrch',
  RetaxesPerunit1Yrch = 'retaxesPerunit1Yrch',
  PropinsPerunit1Yrch = 'propinsPerunit1Yrch',
  UtilitiPerunit1Yrch = 'utilitiPerunit1Yrch',
  RepairsPerunit1Yrch = 'repairsPerunit1Yrch',
  ManagemPerunit1Yrch = 'managemPerunit1Yrch',
  PayrollPerunit1Yrch = 'payrollPerunit1Yrch',
  MarketiPerunit1Yrch = 'marketiPerunit1Yrch',
  HcIncPvtpayPctrev = 'hcIncPvtpayPctrev',
  HcIncMedcarePctrev = 'hcIncMedcarePctrev',
  HcIncNursingPctrev = 'hcIncNursingPctrev',
  HcIncMealsPctrev = 'hcIncMealsPctrev',
  HcExpRoomsPctrev = 'hcExpRoomsPctrev',
  HcExpMealsPctrev = 'hcExpMealsPctrev',
  HcIncPvtpayPerunit = 'hcIncPvtpayPerunit',
  HcIncMedcarePerunit = 'hcIncMedcarePerunit',
  HcIncNursingPerunit = 'hcIncNursingPerunit',
  HcIncMealsPerunit = 'hcIncMealsPerunit',
  HcExpRoomsPerunit = 'hcExpRoomsPerunit',
  HcExpMealsPerunit = 'hcExpMealsPerunit',
}

export const enum QuartilesDataKeys {
  MedianQ1Q4 = 'medianQ1Q4',
  geography = 'geography',
  SubType = 'subType',
  props = 'props',
  YearBuilt = 'yearBuilt',
  YearRenov = 'yearRenov',
  NumUnits = 'numUnits',
  PopulationPerSqMile = 'populationPerSqMile',
  MedianAge = 'medianAge',
  MedianHHInc = 'medianHHInc',
  MedianHomeVal = 'medianHomeVal',
  MedianRent = 'medianRent',
  MedianOwnerCosts = 'medianOwnerCosts',
  RenterPCT = 'renterPCT',
  rentOver30PctHHIncPCT = 'rentOver30PctHHIncPCT',
  BachelorsDegreePCT = 'bachelorsDegreePCT',
  UnemploymentPCT = 'unemploymentPCT',
  MngtFinanceJobPCT = 'mngtFinanceJobPCT',
  MovedInAfter2010PCT = 'movedInAfter2010PCT',
  ValuationDate = 'valuationDate',
  StatementEndDateRev = 'statementEndDateRev',
  ValuationPerUnit = 'valuationPerUnit',
  UwNoiPerUnitBenchmark = 'uwNoiPerUnitBenchmark',
  UwCaprateBenchmark = 'uwCaprateBenchmark',
  Occup = 'occup',
  RevPerUnit = 'revPerUnit',
  ExpPerUnit = 'expPerUnit',
  NoiPerUnit = 'noiPerUnit',
  NcfPerUnit = 'ncfPerUnit',
  CapexPerUnit = 'capexPerUnit',
  ExpPctRev = 'expPctRev',
  NoiPctRev = 'noiPctRev',
  NcfPctRev = 'ncfPctRev',
  CapexPctRev = 'capexPctRev',
  RevPct1Yrch = 'revPct1Yrch',
  ExpPct1Yrch = 'expPct1Yrch',
  NoiPct1Yrch = 'noiPct1Yrch',
  RevPerUnit1Yrch = 'revPerUnit1Yrch',
  ExpPerUnit1Yrch = 'expPerUnit1Yrch',
  NoiPerUnit1Yrch = 'noiPerUnit1Yrch',
  GrosrntPerUnit = 'grosrntPerUnit',
  VacancyPerUnit = 'vacancyPerUnit',
  BaserntPerUnit = 'baserntPerUnit',
  RetaxesPerUnit = 'retaxesPerUnit',
  PropinsPerUnit = 'propinsPerUnit',
  UtilitiPerUnit = 'utilitiPerUnit',
  RepairsPerUnit = 'repairsPerUnit',
  ManagemPerUnit = 'managemPerUnit',
  PayrollPerUnit = 'payrollPerUnit',
  MarketiPerUnit = 'marketiPerUnit',
  GrosrntPctRev = 'grosrntPctRev',
  VacancyPctRev = 'vacancyPctRev',
  BaserntPctRev = 'baserntPctRev',
  RetaxesPctRev = 'retaxesPctRev',
  PropinsPctRev = 'propinsPctRev',
  UtilitiPctRev = 'utilitiPctRev',
  RepairsPctRev = 'repairsPctRev',
  ManagemPctRev = 'managemPctRev',
  PayrollPctRev = 'payrollPctRev',
  MarketiPctRev = 'marketiPctRev',
  GrosrntPerUnit1Yrch = 'grosrntPerUnit1Yrch',
  VacancyPerUnit1Yrch = 'vacancyPerUnit1Yrch',
  BaserntPerUnit1Yrch = 'baserntPerUnit1Yrch',
  RetaxesPerUnit1Yrch = 'retaxesPerUnit1Yrch',
  PropinsPerUnit1Yrch = 'propinsPerUnit1Yrch',
  UtilitiPerUnit1Yrch = 'utilitiPerUnit1Yrch',
  RepairsPerUnit1Yrch = 'repairsPerUnit1Yrch',
  ManagemPerUnit1Yrch = 'managemPerUnit1Yrch',
  PayrollPerUnit1Yrch = 'payrollPerUnit1Yrch',
  MarketiPerUnit1Yrch = 'marketiPerUnit1Yrch',
  HcIncPvtPayPctRev = 'hcIncPvtPayPctRev',
  HcIncMedcarePctRev = 'hcIncMedcarePctRev',
  HcIncNursingPctRev = 'hcIncNursingPctRev',
  HcIncMealsPctRev = 'hcIncMealsPctRev',
  HcExpRoomsPctRev = 'hcExpRoomsPctRev',
  HcExpMealsPctRev = 'hcExpMealsPctRev',
  HcIncPvtPayPerUnit = 'hcIncPvtPayPerUnit',
  HcIncMedcarePerUnit = 'hcIncMedcarePerUnit',
  HcIncNursingPerUnit = 'hcIncNursingPerUnit',
  HcIncMealsPerUnit = 'hcIncMealsPerUnit',
  HcExpRoomsPerUnit = 'hcExpRoomsPerUnit',
  HcExpMealsPerUnit = 'hcExpMealsPerUnit',
}

export type PrintedData = {
  city: string | null,
  state: string | null,
  zip: string | null,
  county: string | null,
  cmsa: string | null,
  ncreifDivision: string | null,
  ncreifRegion: string | null,
  yearBuilt: string | null,
  yearRenov: string | null,
  numUnits: string | null,
  subType: string | null,
  affordability: string | null,
  populationPerSqMile: string | null,
  medianHomeVal: string | null,
  medianHHInc: string | null,
  medianAge: string | null,
  medianRent: string | null,
  medianOwnerCosts: string | null,
  renterPct: string | null,
  rentOver30PctHHIncPCT: string | null,
  bachelorsDegreePCT: string | null,
  unemploymentPCT: string | null,
  mgtFinanceJobPCT: string | null,
  movedInAfter2010PCT: string | null,
  underwritingUwYear: string | null,
  topLineRevYear: string | null,
  fullLineItemFfYear: string | null,
  appraisalOrStatVal: string | null,
  valuationDate: string | null,
  statementEndDateRev: string | null,
  valuationPerunit: string | null,
  uwNoiPerunit: string | null,
  uwCaprate: string | null,
  occup: string | null,
  revPerunit: string | null,
  expPerunit: string | null,
  noiPerunit: string | null,
  ncfPerunit: string | null,
  capexPerunit: string | null,
  expPctrev: string | null,
  noiPctrev: string | null,
  ncfPctrev: string | null,
  capexPctrev: string | null,
  revPct1Yrch: string | null,
  expPct1Yrch: string | null,
  noiPct1Yrch: string | null,
  revPerunit1Yrch: string | null,
  expPerunit1Yrch: string | null,
  noiPerunit1Yrch: string | null,
  grosrntPerunit: string | null,
  vacancyPerunit: string | null,
  baserntPerunit: string | null,
  retaxesPerunit: string | null,
  propinsPerunit: string | null,
  utilitiPerunit: string | null,
  repairsPerunit: string | null,
  managemPerunit: string | null,
  payrollPerunit: string | null,
  marketiPerunit: string | null,
  grosrntPctrev: string | null,
  vacancyPctrev: string | null,
  baserntPctrev: string | null,
  retaxesPctrev: string | null,
  propinsPctrev: string | null,
  utilitiPctrev: string | null,
  repairsPctrev: string | null,
  managemPctrev: string | null,
  payrollPctrev: string | null,
  marketiPctrev: string | null,
  grosrntPerunit1Yrch: string | null,
  vacancyPerunit1Yrch: string | null,
  baserntPerunit1Yrch: string | null,
  retaxesPerunit1Yrch: string | null,
  propinsPerunit1Yrch: string | null,
  utilitiPerunit1Yrch: string | null,
  repairsPerunit1Yrch: string | null,
  managemPerunit1Yrch: string | null,
  payrollPerunit1Yrch: string | null,
  marketiPerunit1Yrch: string | null,
  hcIncPvtpayPctrev: string | null,
  hcIncMedcarePctrev: string | null,
  hcIncNursingPctrev: string | null,
  hcIncMealsPctrev: string | null,
  hcExpRoomsPctrev: string | null,
  hcExpMealsPctrev: string | null,
  hcIncPvtpayPerunit: string | null,
  hcIncMedcarePerunit: string | null,
  hcIncNursingPerunit: string | null,
  hcIncMealsPerunit: string | null,
  hcExpRoomsPerunit: string | null,
  hcExpMealsPerunit: string | null,
};

export type PrintedQuartilesData = {
  props: string | null,
  clientName: string | null,
  algoCompsetMetrostats: string | null,
  exidPlidPrid?: string | null,
  propertyName?: string | null,
  geography: string | null,
  subType: string | null,
  numOrig: string | null,
  numUw: string | null,
  numAvm: string | null,
  numRev: string | null,
  numFF: string | null,
  numRev1Yrch: string | null,
  numFF1Yrch: string | null,
  numHC: string | null,
  gardenPct: string | null,
  midrisePct: string | null,
  hirisePct: string | null,
  seniorPct: string | null,
  healthcarePct: string | null,
  studentPct: string | null,
  manufPct: string | null,
  militaryPct: string | null,
  affordablePct: string | null,
  hasuwPct: string | null,
  hasavmPct: string | null,
  useduwPct: string | null,
  usedavmPct: string | null,
  hasrevPct: string | null,
  hasffPct: string | null,
  hasorigPct: string | null,
  fixedPct: string | null,
  floatPct: string | null,
  acquisitionPct: string | null,
  refinancePct: string | null,
  supplementalPct: string | null,
  medianQ1Q4: string | null,
  yearBuilt: string | null,
  yearRenov: string | null,
  yearBuiltRenov: string | null,
  numUnits: string | null,
  revenuePctMedianIncome: string | null,
  demographicScore: string | null,
  populationPerSqMile: string | null,
  medianAge: string | null,
  medianHHInc: string | null,
  medianHomeVal: string | null,
  medianRent: string | null,
  medianOwnerCosts: string | null,
  renterPCT: string | null,
  cashRentOver30PCT: string | null,
  revPct1Yrch: string | null,
  expPct1Yrch: string | null,
  noiPct1Yrch: string | null,
  rentOver30PctHHIncPCT: string | null,
  bachelorsDegreePCT: string | null,
  unemploymentPCT: string | null,
  mngtFinanceJobPCT: string | null,
  movedInAfter2010PCT: string | null,
  valuationDate: string | null,
  statementDate: string | null,
  statementEndDateRev?: string | null,
  valuationPerUnit: string | null,
  bachelorsOrMorePCT: string | null,
  mngtFinancePCT: string | null,
  movedIn2010orLaterPCT: string | null,
  rateAvg3MorigTrsy1M: string | null,
  rateAvg3MorigTrsy10Y: string | null,
  hpi1Yrch: string | null,
  hpiMetro1Yrch: string | null,
  statementEndDate?: string | null,
  occup: string | null,
  revPerUnit: string | null,
  expPerUnit: string | null,
  noiPerUnit: string | null,
  ncfPerUnit: string | null,
  capex0PerUnit: string | null,
  expPctRev: string | null,
  noiPctRev: string | null,
  ncfPctRev: string | null,
  rev1YrPctCh: string | null,
  exp1YrPctCh: string | null,
  noi1YrPctCh: string | null,
  ncf1YrPctCh: string | null,
  occup1Yrch: string | null,
  revPerUnit1Yrch: string | null,
  expPerUnit1Yrch: string | null,
  noiPerUnit1Yrch: string | null,
  ncfPerUnit1Yrch: string | null,
  groundrPctRev: string | null,
  grosrntPctRev: string | null,
  vacancyPctRev: string | null,
  baserntPctRev: string | null,
  retaxesPctRev: string | null,
  propinsPctRev: string | null,
  utilitiPctRev: string | null,
  repairsPctRev: string | null,
  managemPctRev: string | null,
  payrollPctRev: string | null,
  marketiPctRev: string | null,
  capexPctRev: string | null,
  generalPctRev: string | null,
  grosrntPerUnit: string | null,
  vacancyPerUnit: string | null,
  baserntPerUnit: string | null,
  laundryPerUnit: string | null,
  parkingPerUnit: string | null,
  otherinPerUnit: string | null,
  retaxesPerUnit: string | null,
  propinsPerUnit: string | null,
  utilitiPerUnit: string | null,
  repairsPerUnit: string | null,
  managemPerUnit: string | null,
  payrollPerUnit: string | null,
  marketiPerUnit: string | null,
  professPerUnit: string | null,
  generalPerUnit: string | null,
  otherexPerUnit: string | null,
  groundrPerUnit: string | null,
  capexPerUnit: string | null,
  hcIncPvtPayPctRev: string | null,
  hcIncMedcarePctRev: string | null,
  hcIncNursingPctRev: string | null,
  hcIncMealsPctRev: string | null,
  hcExpRoomsPctRev: string | null,
  hcExpMealsPctRev: string | null,
  hcIncPvtPayPerUnit: string | null,
  hcIncMedcarePerUnit: string | null,
  hcIncNursingPerUnit: string | null,
  hcIncMealsPerUnit: string | null,
  hcExpRoomsPerUnit: string | null,
  hcExpMealsPerUnit: string | null,
  grosrntPerUnit1Yrch: string | null,
  vacancyPerUnit1Yrch: string | null,
  baserntPerUnit1Yrch: string | null,
  laundryPerUnit1Yrch: string | null,
  parkingPerUnit1Yrch: string | null,
  otherinPerUnit1Yrch: string | null,
  retaxesPerUnit1Yrch: string | null,
  propinsPerUnit1Yrch: string | null,
  utilitiPerUnit1Yrch: string | null,
  repairsPerUnit1Yrch: string | null,
  managemPerUnit1Yrch: string | null,
  payrollPerUnit1Yrch: string | null,
  marketiPerUnit1Yrch: string | null,
  professPerUnit1Yrch: string | null,
  generalPerUnit1Yrch: string | null,
  otherexPerUnit1Yrch: string | null,
  groundrPerUnit1Yrch: string | null,
  capexPerUnit1Yrch: string | null,
  hcIncPvtPayPerUnit1Yrch: string | null,
  hcIncMedcarePerUnit1Yrch: string | null,
  hcIncNursingPerUnit1Yrch: string | null,
  hcIncMealsPerUnit1Yrch: string | null,
  hcExpRoomsPerUnit1Yrch: string | null,
  hcExpMealsPerUnit1Yrch: string | null,
  uwDate: string | null,
  uwCapRate: string | null,
  valCapRate: string | null,
  uwValuation: string | null,
  uwValPerUnit: string | null,
  uwOccup: string | null,
  uwRevPerUnit: string | null,
  uwExpPerUnit: string | null,
  uwNoiPerUnit: string | null,
  uwNcfPerUnit: string | null,
  uwExpPctRev: string | null,
  uwNoiPctRev: string | null,
  uwNcfPctRev: string | null,
  uwRevActualRatio: string | null,
  uwExpActualRatio: string | null,
  uwNoiActualRatio: string | null,
  uwNcfActualRatio: string | null,
  uwDscrNcfIo: string | null,
  uwDscrNcfAmort: string | null,
  uwDscrNcfCap: string | null,
  loanOrigDate: string | null,
  loanOrigBalance: string | null,
  loanMaturityBalance: string | null,
  loanMaturityDate: string | null,
  loanOrigLtv: string | null,
  loanMaturityLtv: string | null,
  loanOrigTerm: string | null,
  loanAmortTerm: string | null,
  loanIoPeriod: string | null,
  loanRate: string | null,
  loanFullAmPct: string | null,
  loanRateCap: string | null,
  schedEndbal: string | null,
  schedIntPct: string | null,
  schedPrinPct: string | null,
  schedPaymentPct: string | null,
  advanceTotalPct: string | null,
  valDateEstimate: string | null,
  valPerUnitEstimate: string | null,
  uwCapRateEstimate: string | null,
  uwRevPerUnitEstimate: string | null,
  uwExpPerUnitEstimate: string | null,
  uwCapexPerUnitEstimate: string | null,
  revPerUnitEstimate: string | null,
  valTypeBenchmark?: string | null,
  valDatePerUnitBenchmark: string | null,
  valPerUnitBenchmark: string | null,
  uwRevPerUnitBenchmark: string | null,
  uwExpPerUnitBenchmark: string | null,
  uwNoiPerUnitBenchmark: string | null,
  uwCaprateBenchmark: string | null,
  baserntPctRevBenchmark: string | null,
  otherinPctRevBenchmark: string | null,
  otherexPctRevBenchmark: string | null,
  baserntPerUnitBenchmark: string | null,
  otherinPerUnitBenchmark: string | null,
  otherexPerUnitBenchmark: string | null,
  statementType?: string | null,
};
