import { PALETTE } from '../palette/palette';
import { BORDER_RADIUS } from '../shape';

export default {
  styleOverrides: {
    root: {
      minHeight: 36,
      '&.Mui-disabled': {
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.26) !important',
          outline: 'none',
        },
      },
    },
    input: {
      height: 10,
      borderRadius: BORDER_RADIUS,
      fontSize: 14,
      '&.Mui-disabled': {
        backgroundColor: PALETTE.LIGHTER_GRAY,
      },
    },
  },
};
