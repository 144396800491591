import { memo } from 'react';
import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { Stack } from '@mui/material';
import RequiredTable from './requiredTable/RequiredTable';
import { PropertyDataStyled } from './styled';
import ValTable from '../ValTable';
import FinDateTable from '../FinDateTable';
// !!!DELETE AFTER TESTING!!!
// import ValuationDateTable from './financialTable/ValuationDateTable';
// import HealthcareDateTable from './healthcareTable/HealthcareDateTable';
// import FinancialDateTable from './valuationTable/FinancialDateTable';
// !!!DELETE AFTER TESTING!!!

const PropertyData = ():JSX.Element => {
  const { state: idState } = usePropertyId();
  const isIdState = idState.id;

  return (
    <PropertyDataStyled>
      <Stack display="column" gap={5}>
        <RequiredTable />
        { isIdState && (
          <>
            <div style={{ borderTop: '1px dashed #bbb' }} />
            <ValTable />
            <div style={{ borderTop: '1px dashed #bbb' }} />
            <FinDateTable />
            {/** temporarily unavailable at the request of customer */}
            {/* <div style={{ borderTop: '1px dashed #bbb' }} />
            <HealthTable /> */}
            {/* !!!DELETE AFTER TESTING!!! */}
            {/* <ValuationDateTable /> */}
            {/* <FinancialDateTable /> */}
            {/* <HealthcareDateTable /> */}
            {/* !!!DELETE AFTER TESTING!!! */}
          </>
        ) }
      </Stack>
    </PropertyDataStyled>
  );
};

export default memo(PropertyData);
