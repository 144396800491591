import {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TextField } from '@mui/material';

import { useMappedDataPost } from 'common/components/BingMap/lib/dataMappers';
import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { TableDispatchContext } from '../../model';

import { BasicCell } from '../BasicCell/InputCell';

type PropsType = { index: number; disabled: boolean };

const CellDateRev: FC<PropsType> = ({ index, disabled }): ReactElement => {
  const rawTableData = useMappedDataPost();
  const { state: idState } = usePropertyId();
  const selectedIndex = rawTableData?.findIndex((i) => i.propertyName === idState.id);

  const date = useMemo(
    () => new Date(`${rawTableData[selectedIndex]?.statementEndDateRev}`),
    [rawTableData, selectedIndex],
  );

  const formattedDate = useMemo(
    () => `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`,
    [date],
  );

  const [initialDate, setInitialDate] = useState(`${formattedDate}`);

  useEffect(() => {
    if (idState) {
      setInitialDate(`${formattedDate}`);
    }
  }, [formattedDate, idState]);

  const dispatch = useContext(TableDispatchContext);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setInitialDate(event.target.value);

    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: { index, value: event.target.value },
    });
  };

  return (
    <BasicCell isColored={disabled}>
      <TextField
        sx={{
          fontFamily: '\'OpenSans-SemiBold\', sans-serif',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: 'normal',
          letterSpacing: 'normal',
        }}
        fullWidth
        onChange={onChangeHandler}
        type="date"
        value={initialDate}
      />
    </BasicCell>
  );
};

export default CellDateRev;
