import React, { useCallback } from 'react';
import { useFilterData } from 'containers/buy-sell-credits/admin/local-state/hooks';

const useSearch = (): {
  onSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
} => {
  const { state: filterDataState, dispatch: dispatchData } = useFilterData();

  const onSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatchData({ ...filterDataState, word: event.target.value });
  }, [dispatchData, filterDataState]);

  return {
    onSearchInputChange,
    text: filterDataState.word,
  };
};

export default useSearch;
