import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { LoginContext } from 'containers/login/components/index';
import { TEXT } from 'containers/login/utils/constants';

import { genPassword } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/helpers';

type ReturnType = [
  string,
  string,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  () => void
]

export const usePassword = (
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
):ReturnType => {
  const lockRegisterButton = useContext(LoginContext);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setCofirmedPassword] = useState<string>('');

  useEffect(() => {
    if (password) {
      onChange({
        target: {
          name: TEXT.REGISTRATION_FIELDS_NAMES.PW,
          value: password,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [onChange, password]);

  useEffect(() => {
    if (password && confirmPassword) {
      lockRegisterButton(password !== confirmPassword);
    }
    if (password === '' || confirmPassword === '') {
      lockRegisterButton(true);
    }
  }, [confirmPassword, lockRegisterButton, password]);

  const generatePassword = (): void => {
    const generatedPassword = genPassword();
    setPassword(generatedPassword);
    setCofirmedPassword(generatedPassword);
  };

  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === TEXT.REGISTRATION_FIELDS_NAMES.CONFIRM_PW) {
      setCofirmedPassword(e.target.value);
    }
    if (e.target.name === TEXT.REGISTRATION_FIELDS_NAMES.PW) {
      setPassword(e.target.value);
    }
  };

  return [password, confirmPassword, onChangeHandle, generatePassword];
};
