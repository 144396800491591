import { memo, useEffect } from 'react';

import { useMappedPropNames } from 'common/components/BingMap/lib/dataMappers';
import { useScreenDown } from 'common/hooks/screenSize';
import { useCurrentPropertyContext } from 'context/hooks';
import { useSelectProperty } from 'containers/input-property/components/mapTab/map/hooks/useSelectProperty';

import { HEADER_TEXT } from 'common/components/BingMap/utils/constants';
import CustomListMenu from 'containers/compsets/utils/shared/CustomMenu';

import { Typography } from '@mui/material';

import {
  FormControlWrapperStyled,
  MenuItemStyled,
  MenuStyled,
} from 'containers/compsets/utils/shared/styled';

const SelectPropertyList = ():JSX.Element => {
  const isDownMd = useScreenDown('md');
  const propNames = useMappedPropNames();
  const { state } = useCurrentPropertyContext();

  const {
    property,
    anchorEl,
    handleClickSelect,
    handleCloseSelect,
    handleChange,
  } = useSelectProperty();

  useEffect(() => {
    if (state.currentPropertyEvent) {
      handleChange(state.currentPropertyEvent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControlWrapperStyled $isDownMd={isDownMd}>
      <CustomListMenu
        handleClick={handleClickSelect}
        labelText={property || HEADER_TEXT.SELECT}
      >
        <MenuStyled
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseSelect}
          transformOrigin={{
            vertical: -10,
            horizontal: 'left',
          }}
        >
          {propNames.map((name) => (
            <MenuItemStyled key={name} data-value={name} onClick={handleChange}>
              <Typography variant="inherit" noWrap>
                {name}
              </Typography>
            </MenuItemStyled>
          ))}
        </MenuStyled>
      </CustomListMenu>
    </FormControlWrapperStyled>
  );
};

export default memo(SelectPropertyList);
