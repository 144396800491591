import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const FacebookIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    {/* eslint-disable-next-line max-len */}
    <path d="M12.0075 8.9995L12.452 6.104H9.6735V4.225C9.6735 3.433 10.0615 2.6605 11.306 2.6605H12.569V0.1955C12.569 0.1955 11.423 0 10.327 0C8.039 0 6.5435 1.387 6.5435 3.8975V6.1045H4V9H6.5435V16H9.6735V9L12.0075 8.9995Z" />
  </SvgIcon>
);

export default React.memo(FacebookIcon);
