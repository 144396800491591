import { styled } from '@mui/material';
import { setMainWidth } from './theme-helpers';

export const MainStyled = styled('main', {
  shouldForwardProp: (propName) => propName !== '$noMatchExistedRoutes' && propName !== '$isOpen',
})<{ $isOpen?: boolean; $noMatchExistedRoutes?: boolean; }>(({ theme, $isOpen, $noMatchExistedRoutes }) => ({
  height: '100%',
  width: '100%',
  margin: 'auto',
  flex: 1,
  alignItems: 'center',
  overflow: 'auto',
  backgroundColor: theme.palette.background.blue,
  ...setMainWidth(theme, $isOpen as boolean, $noMatchExistedRoutes as boolean),
}));
