import { Theme } from '@mui/material';
import memoizeOne from 'memoize-one';
import { DEFAULT_DRAWER_WIDTH, DRAWER_WIDTH } from '.';
import { AppBarType } from './theme-types';

export const setAppBar = memoizeOne((open: boolean, theme: Theme): AppBarType => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const setDrawer = ((open: boolean, theme: Theme): Record<string, unknown> => (open ? ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}) : ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${DEFAULT_DRAWER_WIDTH}px !important`,
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9) + 1,
  },
})));
