import { memo, useReducer } from 'react';
import { selectedPropertiesReducer } from './reducers';
import { SelectedPropertiesContext } from './SelectedPropertiesContext';

interface IProps {
  children: React.ReactNode;
}

const SelectedPropertiesProvider = memo(({ children }: IProps) => {
  const [state, dispatch] = useReducer(selectedPropertiesReducer, { selected: [] });

  return (
    <SelectedPropertiesContext.Provider value={{ state, dispatch }}>
      {children}
    </SelectedPropertiesContext.Provider>
  );
});

export default SelectedPropertiesProvider;
