import {
  memo, useCallback, useEffect,
} from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';

import { ApplicationBar } from 'common/components/ApplicationBar';

import { useAuth } from 'containers/login/lib/auth';
import { ROUTE } from 'common/components/routes/utils/constants';
import { useScreenDown } from 'common/hooks/screenSize';
import { BUTTON_TEXT } from './constants';

import { TabPanels } from './TabPanels';
import { AppBarTabList } from './AppBarTabList';
import { ProfileMenu } from './ProfileMenu';

import { ButtonStyled, RightMenuWrapperStyled } from './styled';
import { useMainPageTabContext } from './local-state/hooks';

const AppBar = (): JSX.Element => {
  const history = useHistory();
  const { user, isLoggingIn } = useAuth();
  const isScreenDownMd = useScreenDown('md');
  const { state: { isClicked }, dispatch } = useMainPageTabContext();

  const token = user;
  const routerPath = history.location.pathname;

  const onLoginOpenHandle = (): void => {
    dispatch({ setClicked: false });
    history.push({ pathname: ROUTE.LOGIN });
  };

  const handleGetPath = (path: string): void => {
    if (path === ROUTE.HOME_DEFAULT) {
      dispatch({ setClicked: false });
    }
  };

  const onTabListClick = (): void => {
    dispatch({ setClicked: true });
  };

  useEffect(() => {
    const haseSomethingAfterHome = routerPath.match(/home(.*)/);

    if (!routerPath.includes('home/')) dispatch({ setClicked: false });
    if (haseSomethingAfterHome && haseSomethingAfterHome[1]) dispatch({ setClicked: true });
  }, [dispatch, routerPath]);

  return (
    <TabContext value={isClicked ? routerPath : ROUTE.HOME.INSIGHTS}>
      <ApplicationBar getRoute={handleGetPath}>
        <Box display="inline-flex" height="100%">
          {/** MIGHT BE USEFUL IN THE FUTURE */}
          {/* {(!routerPath.includes('account'))
            && (
              <AppBarTabList
                onTabListClick={onTabListClick}
                clicked={isClicked}
                onChange={handleChange}
                value={routerPath}
              />
            ) } */}
          <AppBarTabList
            onTabListClick={onTabListClick}
            clicked={isClicked}
            value={routerPath}
          />
          <RightMenuWrapperStyled $isDownMd={isScreenDownMd}>
            {!token
              ? (
                <ButtonStyled
                  variant="outlined"
                  color="secondary"
                  disabled={isLoggingIn}
                  onClick={onLoginOpenHandle}
                >
                  {BUTTON_TEXT.LOGIN.toUpperCase()}
                </ButtonStyled>
              )
              : <ProfileMenu path={history.location.pathname} />}
          </RightMenuWrapperStyled>
        </Box>
      </ApplicationBar>
      {isClicked && <TabPanels />}
    </TabContext>
  );
};

export default withRouter(memo(AppBar));
