import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import { TableDispatchContext } from '../../model/index';
import { BasicCell } from '../BasicCell/InputCell';

type PropsType = {
  index: number;
  disabled: boolean;
  selectors: string[];
};

const CellSelect: FC<PropsType> = ({ disabled, index, selectors = [] }) => {
  const [selectedName, setSelectedName] = useState<string>(selectors[0]);
  const dispatch = useContext(TableDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: { index, value: selectors[0] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (event: SelectChangeEvent<string>): void => {
    const { value: inputValue } = event.target;
    setSelectedName(inputValue);
    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: { index, value: inputValue },
    });
  };

  return (
    <BasicCell isColored={disabled}>
      <FormControl fullWidth size="small">
        <Select onChange={onChangeHandler} value={selectedName}>
          {selectors.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </BasicCell>
  );
};

export default CellSelect;
