import { createContext } from 'react';

export type FixedColumnsAction = { type: 'increment'; } | { type: 'decrement'; };
export type FixedColumnsDispatch = (action: FixedColumnsAction) => void;
export type FixedColumnsState = { count: number; };
export type CountProviderProps = { children: React.ReactNode; };

export const FixedColumnsCountStateContext = createContext<
  { state: FixedColumnsState; dispatch: FixedColumnsDispatch; } | undefined
>(undefined);

createContext<(FixedColumnsState & FixedColumnsDispatch) | undefined>(undefined);
