import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SortIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 14 11">
    <path d="M3.28125 11L6.375 8.25H4.05469V6.91406e-07H2.50781V8.25H0.1875L3.28125 11Z" />
    <path d="M10.4688 0L7.375 2.75H9.69531V11H11.2422V2.75H13.5625L10.4688 0Z" />
  </SvgIcon>
);

export default React.memo(SortIcon);
