import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

const SubscriptionIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    { /* eslint-disable max-len */ }
    <path d="M20.4375 0C19.2743 0 18.3281 0.946219 18.3281 2.10938C18.3281 2.58117 18.4893 3.01275 18.7521 3.3645L15.3118 7.09744C15.1509 7.05802 14.9854 7.03125 14.8125 7.03125C14.3807 7.03125 13.9795 7.16259 13.6448 7.38609L11.1916 5.54672C11.2539 5.34802 11.2969 5.14083 11.2969 4.92188C11.2969 3.75872 10.3507 2.8125 9.1875 2.8125C8.02434 2.8125 7.07812 3.75872 7.07812 4.92188C7.07812 5.14083 7.12111 5.34802 7.18336 5.54672L4.73016 7.38609C4.39547 7.16259 3.99431 7.03125 3.5625 7.03125C2.39934 7.03125 1.45312 7.97747 1.45312 9.14062C1.45312 10.3038 2.39934 11.25 3.5625 11.25C4.72566 11.25 5.67188 10.3038 5.67188 9.14062C5.67188 8.92167 5.62889 8.71448 5.56664 8.51578L8.01984 6.67641C8.35448 6.89991 8.75569 7.03125 9.1875 7.03125C9.61931 7.03125 10.0205 6.89991 10.3552 6.67641L12.8084 8.51578C12.7461 8.71448 12.7031 8.92167 12.7031 9.14062C12.7031 10.3038 13.6493 11.25 14.8125 11.25C15.9757 11.25 16.9219 10.3038 16.9219 9.14062C16.9219 8.66883 16.7607 8.23725 16.4979 7.8855L19.9382 4.15256C20.0991 4.19198 20.2646 4.21875 20.4375 4.21875C21.6007 4.21875 22.5469 3.27253 22.5469 2.10938C22.5469 0.946219 21.6007 0 20.4375 0Z" />
    <path d="M10.5938 11.25H7.78125C7.39261 11.25 7.07812 11.5645 7.07812 11.9531V21.1875H11.2969V11.9531C11.2969 11.5645 10.9824 11.25 10.5938 11.25Z" />
    <path d="M16.2188 14.0625H13.4062C13.0176 14.0625 12.7031 14.377 12.7031 14.7656V21.1875H16.9219V14.7656C16.9219 14.377 16.6074 14.0625 16.2188 14.0625Z" />
    <path d="M21.8438 8.4375H19.0312C18.6426 8.4375 18.3281 8.75198 18.3281 9.14062V21.1875H22.5469V9.14062C22.5469 8.75198 22.2324 8.4375 21.8438 8.4375Z" />
    <path d="M4.96875 15.4688H2.15625C1.76761 15.4688 1.45312 15.7832 1.45312 16.1719V21.1875H5.67188V16.1719C5.67188 15.7832 5.35739 15.4688 4.96875 15.4688Z" />
    <path d="M23.2969 22.5938C20.157 22.5938 18.7158 22.5938 18.1467 22.5938C17.8865 22.5938 17.7936 22.5938 17.7943 22.5938C16.7582 22.5938 12.9018 22.5938 0.703125 22.5938C0.314484 22.5938 0 22.9082 0 23.2969C0 23.6855 0.314484 24 0.703125 24C9.21188 24 15.3483 24 23.2969 24C23.6855 24 24 23.6855 24 23.2969C24 22.9082 23.6855 22.5938 23.2969 22.5938Z" />
  </SvgIcon>

);

export default React.memo(SubscriptionIcon);
