import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';

export const FooterStyled = styled(Grid)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  minHeight: 60,
  padding: theme.spacing(4.5),
  backgroundColor: theme.palette.background.white,
  [theme.breakpoints.down('lg')]: {
    minHeight: 'auto',
    marginBottom: 0,
    padding: 0,
  },
}));

export const LinkWrapperStyled = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 3),
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 1.5),
}));
