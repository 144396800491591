import { createContext, useReducer, useMemo } from 'react';

export interface ICheckboxContext {state: CheckboxState, dispatch: CheckboxDispatch}

type Maybe<T> = T | null | undefined

export type CheckboxAction = {
    type: string,
    payload: {propertyName: Maybe<string>; exidPlidPrid: string}
}

export type CheckboxComp = {
  propertyName: Maybe<string>;
  exidPlidPrid: string;
}

export type CheckboxState = {
  checkComps: CheckboxComp[],
}

export type CheckboxDispatch = (action: CheckboxAction) => void

type genericType = { state: CheckboxState, dispatch: CheckboxDispatch} | undefined;

export const CheckboxContext = createContext<genericType>(undefined);

const reducer = (state: CheckboxState, action: CheckboxAction): CheckboxState => {
  let clonedIndices;
  let foundIndex;

  switch (action.type) {
    case 'ADD_CHECK_COMP':
      return {
        checkComps: [...state.checkComps, action.payload],
      };

    case 'REMOVE_CHECK_COMP':
      clonedIndices = [...state.checkComps];
      foundIndex = [...state.checkComps].findIndex(
        (n: CheckboxComp) => n.exidPlidPrid === action.payload.exidPlidPrid,
      );
      clonedIndices.splice(foundIndex, 1);

      return {
        ...state,
        checkComps: clonedIndices,
      };

    case 'CLEAN_STATE':
      return {
        checkComps: [],
      };

    default:
      throw new Error(`No such action type as ${action.type}!`);
  }
};

export const CheckboxContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const initState = {
    checkComps: [],
  };
  const [checkboxState, setCheckboxState] = useReducer(reducer, initState);
  const CheckboxContextValue = useMemo(() => ({
    state: checkboxState,
    dispatch: setCheckboxState,
  }), [checkboxState]);

  return (
    <CheckboxContext.Provider value={CheckboxContextValue}>
      {children}
    </CheckboxContext.Provider>
  );
};
