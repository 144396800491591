/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CartIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path d="M11.6361 29.2722C13.6443 29.2722 15.2722 27.6443 15.2722 25.6361C15.2722 23.6279 13.6443 22 11.6361 22C9.62794 22 8 23.6279 8 25.6361C8 27.6443 9.62794 29.2722 11.6361 29.2722Z" />
    <path d="M23.0312 22.0179C21.0133 21.8193 19.2165 23.2942 19.0179 25.3121C18.8193 27.33 20.2942 29.1268 22.3121 29.3254C22.5512 29.3489 22.7921 29.3489 23.0312 29.3254C25.0492 29.1268 26.524 27.33 26.3254 25.3121C26.1539 23.5687 24.7745 22.1895 23.0312 22.0179Z" />
    <path d="M29.7595 8.2259C29.5789 7.95541 29.2728 7.79572 28.9476 7.80227H24.9231V7.73167C24.9234 3.46186 21.4623 0.000275813 17.1925 1.64814e-08C12.9228 -0.00027578 9.46119 3.46083 9.46091 7.73064V7.80227H6.46025L5.43649 3.14242C5.3228 2.6684 4.90021 2.33324 4.41274 2.33048H0V4.44859H3.56549L6.84857 19.5225C6.93524 20.0181 7.36927 20.3773 7.87233 20.3698H26.3352C26.8227 20.3669 27.2452 20.0318 27.359 19.5578L29.9713 9.10845C30.0479 8.79728 29.969 8.46832 29.7595 8.2259ZM17.192 13.3447C14.0921 13.3447 11.579 10.8316 11.579 7.73167C11.579 4.63172 14.0921 2.11867 17.192 2.11867C20.292 2.11867 22.805 4.63172 22.805 7.73167C22.7858 10.8236 20.284 13.3254 17.192 13.3447Z" />
    <path d="M16.3443 8.83766L14.4028 6.86076L12.8848 8.34344L15.5677 11.0617C15.7632 11.2612 16.0297 11.3754 16.309 11.3794C16.5848 11.3929 16.8535 11.2905 17.0504 11.097L21.4984 6.89606L20.0511 5.34277L16.3443 8.83766Z" />
  </SvgIcon>
);

export default React.memo(CartIcon);
