export const TAB_LABEL = {
  HELP: 'Help',
  INSIGHTS: 'Insights',
  DATABASE: 'Database',
  PRODUCTS: 'Products',
  CONTACT: 'Contact Us',
  TEAM: 'Team',
} as const;

export const TAB_LABELS = Object.values(TAB_LABEL);

export const BUTTON_TEXT = {
  LOGOUT: 'Logout',
  LOGIN: 'Login',
} as const;
