import * as React from 'react';

import { useModalContext } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/hooks';
import { ActionType } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/ModalContext';
import { IConfirmModal } from './models/ConfirmModal.interface';
import { MODAL_BUTTONS } from './utils/modalConstants';

import {
  ModalBenchmarkWrapperStyled,
  BootstrapDialog,
  ActionsWrapperStyled,
  ConfirmButtonStyled,
  CancelButtonStyled,
  ModalTextStyled,
  ModalTitleStyled,
  ErrorTextStyled,
} from './utils/styled';

export const ModalWindow = React.memo((
  {
    children,
    onConfirm,
    title,
    text,
    disableButton = false,
    errorText,
    buttonText = {
      confirm: MODAL_BUTTONS.SUBSCRIPTION_APPLY,
      cancel: MODAL_BUTTONS.SUBSCRIPTION_CLOSE,
    },
  }:IConfirmModal,
): JSX.Element => {
  const { state: { toggle }, dispatch } = useModalContext();

  const closeModal = (): void => {
    dispatch({ type: ActionType.Close });
  };

  return (
    <BootstrapDialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={toggle}
    >
      <ModalBenchmarkWrapperStyled>
        <ModalTitleStyled>
          {title}
        </ModalTitleStyled>
        {children}
        {text && (
          <ModalTextStyled>
            {text}
          </ModalTextStyled>
        )}
        {disableButton && <ErrorTextStyled>{errorText}</ErrorTextStyled>}
        <ActionsWrapperStyled>
          <ConfirmButtonStyled
            color="secondary"
            variant="contained"
            onClick={onConfirm}
            disabled={disableButton}
          >
            {buttonText.confirm}
          </ConfirmButtonStyled>
          <CancelButtonStyled
            variant="outlined"
            onClick={closeModal}
          >
            {buttonText.cancel}
          </CancelButtonStyled>
        </ActionsWrapperStyled>
      </ModalBenchmarkWrapperStyled>
    </BootstrapDialog>
  );
});
