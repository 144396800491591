import { memo, useCallback, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { RESEARCH } from 'containers/research/utils/constants';
import { useGetResearchDataFile } from 'containers/research/lib/query-hooks';

import {
  MenuItemStyled,
  MenuStyled,
  ButtonStyled,
  CSVIconStyled,
  ExelIconStyled,
} from './utils/styled';

const ExportDataMenu = memo(({ isButtonDisabled }: {isButtonDisabled: boolean}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const mutation = useGetResearchDataFile();

  const handleClickSelect = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSelect = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const downoadCSV = (): void => {
    mutation.mutateAsync(RESEARCH.FILE.CSV);
  };
  const downoadXLS = (): void => {
    mutation.mutateAsync(RESEARCH.FILE.XLS);
  };
  const downoadXLSX = (): void => {
    mutation.mutateAsync(RESEARCH.FILE.XLSX);
  };

  return (
    <Box>
      <ButtonStyled
        color="secondary"
        disabled={isButtonDisabled}
        onClick={handleClickSelect}
        endIcon={
          anchorEl
            ? <KeyboardArrowUpIcon />
            : <KeyboardArrowDownIcon />
        }
        variant="contained"
      >
        {RESEARCH.BUTTON_TEXT.EXPORT_DATA}
      </ButtonStyled>
      <MenuStyled
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseSelect}
        onClick={handleCloseSelect}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left',
        }}
      >
        <MenuItemStyled
          disableGutters
          divider
          onClick={downoadCSV}
        >
          <CSVIconStyled />
          <Typography variant="subtitle2">
            {RESEARCH.BUTTON_TEXT.EXPORT_CSV}
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled
          disableGutters
          divider
          onClick={downoadXLS}
        >
          <ExelIconStyled />
          <Typography variant="subtitle2">
            {RESEARCH.BUTTON_TEXT.EXPORT_EXEL}
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled
          disableGutters
          onClick={downoadXLSX}
        >
          <ExelIconStyled />
          <Typography variant="subtitle2">
            {RESEARCH.BUTTON_TEXT.EXPORT_EXEL_XLSX}
          </Typography>
        </MenuItemStyled>
      </MenuStyled>
    </Box>
  );
});

export default ExportDataMenu;
