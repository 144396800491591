import { createContext } from 'react';

import { PropUpdateModelPartial } from 'common/components/BingMap/utils/types';

export interface IEditColumnContext {
  ColumnToEditId: string;
  setColumnToEdit: React.Dispatch<React.SetStateAction<string>>;
}
export type EditColumnAction =
  { type: 'update'; propName: string; fieldName: string, value: string; } |
  { type: 'clear'; };

export type EditColumnDispatch = (action: EditColumnAction) => void;
export type EditColumnState = { mapOfProps: Map<string, PropUpdateModelPartial>; };

export const EditColumnContext = createContext<
  { state: EditColumnState; dispatch: EditColumnDispatch; } | undefined
>(undefined);

createContext<(EditColumnState & EditColumnDispatch) | undefined>(undefined);
