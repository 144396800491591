import { memo } from 'react';

import { useSubscriptionModal } from 'containers/benchmark/utils/useSubscriptionModal';

import { MODAL_BUTTONS, MODAL_TITLES, MODAL_TEXT } from 'containers/benchmark/modal/utils/modalConstants';
import { ModalWindow } from 'containers/benchmark/modal/ModalWindow';

import { ModalTextInputStyled } from 'containers/benchmark/modal/utils/styled';

const SubscriptionModal = ():JSX.Element => {
  const { subscribeModal } = useSubscriptionModal();

  return (
    <ModalWindow
      buttonText={{ confirm: MODAL_BUTTONS.SUBSCRIPTION_APPLY, cancel: MODAL_BUTTONS.SUBSCRIPTION_CLOSE }}
      onConfirm={subscribeModal}
      title={MODAL_TITLES.REQURED_SUBSCRIPTION}
    >
      <ModalTextInputStyled>
        {MODAL_TEXT.SUBSCRIPTION}
      </ModalTextInputStyled>
    </ModalWindow>
  );
};

export default memo(SubscriptionModal);
