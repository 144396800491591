import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TwitterIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    {/* eslint-disable-next-line max-len */}
    <path d="M15.7621 3.29122C15.2054 3.53789 14.6074 3.70456 13.9788 3.77989C14.6274 3.39175 15.1127 2.78087 15.3441 2.06122C14.7347 2.42321 14.0677 2.67802 13.3721 2.81456C12.9043 2.31513 12.2848 1.98411 11.6096 1.87287C10.9345 1.76164 10.2415 1.87642 9.63825 2.19939C9.03501 2.52236 8.55527 3.03546 8.27353 3.65902C7.99178 4.28259 7.92378 4.98172 8.08009 5.64789C6.84521 5.58589 5.63717 5.26492 4.53437 4.70582C3.43157 4.14673 2.45865 3.362 1.67876 2.40256C1.41209 2.86256 1.25876 3.39589 1.25876 3.96389C1.25846 4.47522 1.38438 4.97872 1.62534 5.42971C1.86631 5.8807 2.21486 6.26525 2.64009 6.54922C2.14694 6.53353 1.66467 6.40028 1.23342 6.16055V6.20055C1.23337 6.91772 1.48144 7.61281 1.93554 8.16789C2.38964 8.72296 3.0218 9.10384 3.72476 9.24589C3.26728 9.3697 2.78765 9.38794 2.32209 9.29922C2.52042 9.9163 2.90675 10.4559 3.427 10.8425C3.94725 11.2291 4.57537 11.4433 5.22342 11.4552C4.12331 12.3188 2.76468 12.7873 1.36609 12.7852C1.11834 12.7853 0.870807 12.7708 0.624756 12.7419C2.04441 13.6547 3.69698 14.1391 5.38476 14.1372C11.0981 14.1372 14.2214 9.40522 14.2214 5.30122C14.2214 5.16789 14.2181 5.03322 14.2121 4.89989C14.8196 4.46054 15.344 3.91649 15.7608 3.29322L15.7621 3.29122Z" />
  </SvgIcon>
);

export default React.memo(TwitterIcon);
