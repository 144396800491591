import { AxiosResponse } from 'axios';

import axiosInstance from 'common/axios';

import { BUSINESS_INFO_URL, DATASET_URL } from 'common/globalConstants';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';

import { PrintedData, PrintedQuartilesData } from './models/printedDataTypes';
import {
  IMetrocounts,
  MetroDivisionRegion,
  CompsFetchData,
  CompsPriceData,
  PrintDataQueryParams,
} from './models/types';

const axios = axiosInstance;

export const fetchMetrocounts = async (): Promise<IMetrocounts[]> => {
  let result = null;

  try {
    result = await axios.get(`${DATASET_URL.METROCOUNTS}`).then((data) => data);

    return result.data as Promise<IMetrocounts[]>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchSubtypes = async (): Promise<string[]> => {
  let result = null;
  try {
    result = await axios.get(`${BUSINESS_INFO_URL.SUBTYPE}`).then((data) => data);

    return result.data as Promise<string[]>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchNeighborhood = async (): Promise<MetroDivisionRegion[]> => {
  let result = null;

  try {
    result = await axios.get(`${BUSINESS_INFO_URL.NEIGHBORHOOD}`).then((data) => data);

    return result.data as Promise<MetroDivisionRegion[]>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchResearch = async (
  { region, subtype }: { region: string, subtype: string; },
): Promise<PrintedData[]> => {
  let result: AxiosResponse<Promise<PrintedData[]>> = {} as AxiosResponse<Promise<PrintedData[]>>;

  try {
    if (!region || !subtype) { return null as never; }
    result = await axios.get(`${DATASET_URL.RESEARCH}?mdra=${region}&subtype=${subtype}`)
      .then((data) => data);

    return result.data as Promise<PrintedData[]>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchQuartiles = async (
  { region, subtype }: { region: string, subtype: string; },
): Promise<PrintedQuartilesData[]> => {
  // eslint-disable-next-line max-len
  let result: AxiosResponse<Promise<PrintedQuartilesData[]>> = {} as AxiosResponse<Promise<PrintedQuartilesData[]>>;
  const requestParams = `${DATASET_URL.QUARTILES}?mdra=${region}&subtype=${subtype}`;

  try {
    if (!region || !subtype) { return null as never; }

    result = await axios.get(`${requestParams}&firstStatementYear=2021&secondStatementYear=2020`);

    return result.data as Promise<PrintedQuartilesData[]>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchPrice = async <Type extends CompsPriceData>(
  metrostatsData: PrintDataQueryParams,
): Promise<AxiosResponse<Type>> => {
  let result: AxiosResponse<Type> = {} as AxiosResponse<Type>;

  const body = {
    propertyName: '',
    propCompAlgoDemoAvmStats: 'METROSTATS',
    compExidPlidPrid: '',
    metrostatsMdra: `${metrostatsData.region}`,
    metrostatsSubtype: `${metrostatsData.subtype}`,
  } as CompsFetchData;

  try {
    result = await axios.post(`${DATASET_URL.PRICE}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as Error)?.message}`);
  }

  return result;
};

export const subscribeData = async (metrostatsData: PrintDataQueryParams): Promise<AxiosResponse<never>> => {
  let result: AxiosResponse<never> = {} as AxiosResponse<never>;

  const body = {
    mdra: metrostatsData.region,
    subtype: metrostatsData.subtype,
  };

  try {
    result = await axios.put(`${DATASET_URL.SUBSCRIBE}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error('NO PROPERTIES FOR DATASET');
  }

  return result;
};

export const fetchResearchDataFile = async (
  { region, subtype, fileExtension }:
    { region: string, subtype: string, fileExtension: { extension: string, type: string; }; },
): Promise<never> => {
  const result: AxiosResponse<Promise<never>> = {} as AxiosResponse<Promise<never>>;

  try {
    if (!region || !subtype) { return null as never; }

    axios.get(`${DATASET_URL.RESEARCH}/export/${fileExtension.extension}?mdra=${region}&subtype=${subtype}`,
      {
        headers: { Accept: '*/*' },
        responseType: 'blob',
      })
      .then((response) => {
        const { data } = response;
        const link = document.createElement('a');
        link.download = `exportedData.${fileExtension.extension}`;
        link.href = URL.createObjectURL(data);
        link.click();
      });

    return result.data as Promise<never>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchQuartilesDataFile = async (
  { region, subtype, fileExtension }:
    { region: string, subtype: string, fileExtension: { extension: string, type: string; }; },
): Promise<never> => {
  const result: AxiosResponse<Promise<never>> = {} as AxiosResponse<Promise<never>>;
  const requestParams = `${fileExtension.extension}?mdra=${region}&subtype=${subtype}`;
  try {
    if (!region || !subtype) { return null as never; }

    axios.get(
      `${DATASET_URL.QUARTILES}/export/${requestParams}&firstStatementYear=2020&secondStatementYear=2021`,
      {
        headers: { Accept: '*/*' },
        responseType: 'blob',
      },
    )
      .then((response) => {
        const { data } = response;
        const link = document.createElement('a');
        link.download = `exportedData.${fileExtension.extension}`;
        link.href = URL.createObjectURL(data);
        link.click();
      });

    return result.data as Promise<never>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchInputPropertyDataFile = async (
  { fileExtension }:
    { fileExtension: { extension: string, type: string; }; },
): Promise<never> => {
  const result: AxiosResponse<Promise<never>> = {} as AxiosResponse<Promise<never>>;

  try {
    axios.get(`properties/export/${fileExtension.extension}`,
      {
        headers: { Accept: '*/*' },
        responseType: 'blob',
      })
      .then((response) => {
        const { data } = response;
        const link = document.createElement('a');
        link.download = `exportedData.${fileExtension.extension}`;
        link.href = URL.createObjectURL(data);
        link.click();
      });

    return result.data as Promise<never>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchCompsetsDataFile = async (
  { propertyName, fileExtension }:
    { propertyName: string, fileExtension: { extension: string, type: string; }; },
): Promise<never> => {
  const result: AxiosResponse<Promise<never>> = {} as AxiosResponse<Promise<never>>;

  try {
    axios.get(`compsets/${propertyName}/properties/export/${fileExtension.extension}`,
      {
        headers: { Accept: '*/*' },
        responseType: 'blob',
      })
      .then((response) => {
        const { data } = response;
        const link = document.createElement('a');
        link.download = `exportedData.${fileExtension.extension}`;
        link.href = URL.createObjectURL(data);
        link.click();
      });

    return result.data as Promise<never>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchBenchmarkDataFile = async (
  { radioSelection, propertyName, fileExtension }:
    { radioSelection: string, propertyName: string, fileExtension: { extension: string, type: string; }; },
): Promise<never> => {
  const result: AxiosResponse<Promise<never>> = {} as AxiosResponse<Promise<never>>;
  const requestParams = `${radioSelection}/${propertyName}/export/${fileExtension.extension}`;

  try {
    axios.get(`benchmark/analitics/${requestParams}`,
      {
        headers: { Accept: '*/*' },
        responseType: 'blob',
      })
      .then((response) => {
        const { data } = response;
        const link = document.createElement('a');
        link.download = `exportedData.${fileExtension.extension}`;
        link.href = URL.createObjectURL(data);
        link.click();
      });

    return result.data as Promise<never>;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
