import React, { useCallback } from 'react';

interface UseForms<T> {
  values: T;
  onChange: (e: React.ChangeEvent<HTMLInputElement | unknown>) => void;
  onReset: VoidFunction
}
export function useForm<T>(initialValues?: T): UseForms<T> {
  const [values, setValues] = React.useState<T>(initialValues as T);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === 'true' || e.target.value === 'false') {
      setValues((v) => (
        { ...v, [e.target.name]: e.target.value === 'false' }
      ));
    } else {
      setValues((v) => (
        { ...v, [e.target.name]: e.target.value.trim() }
      ));
    }
  }, []);

  const onReset = (): void => {
    setValues({} as T);
  };

  return {
    values,
    onChange,
    onReset,
  } as UseForms<T>;
}
