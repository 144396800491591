import { memo } from 'react';

import { Typography } from '@mui/material';

import { useSelectPortfolio } from 'containers/benchmark/utils/hooks';
import { useGetPropertyNames } from 'containers/benchmark/lib/query-hooks';

import {
  FormControlWrapperStyled,
  MenuItemStyled,
  MenuStyled,
} from 'containers/compsets/utils/shared/styled';
import CustomListMenuBenchmark from 'containers/compsets/utils/shared/CustomMenuBenchmark';

const SelectClientPortfolio = ():JSX.Element => {
  const {
    anchorEl,
    handleClickSelect,
    handleCloseSelect,
    handleChange,
    propertyName,
  } = useSelectPortfolio();

  const { data } = useGetPropertyNames();

  return (
    <FormControlWrapperStyled $isDownMd>
      <CustomListMenuBenchmark
        handleClick={handleClickSelect}
        labelText={propertyName}
        isWide
      >
        <MenuStyled
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseSelect}
          transformOrigin={{
            vertical: -10,
            horizontal: 'left',
          }}
        >
          {!!data?.length
          && data.map((name) => (
            <MenuItemStyled
              key={name}
              data-value={name}
              onClick={handleChange}
            >
              <Typography variant="inherit" noWrap>
                {name}
              </Typography>
            </MenuItemStyled>
          ))}
        </MenuStyled>
      </CustomListMenuBenchmark>
    </FormControlWrapperStyled>
  );
};

export default memo(SelectClientPortfolio);
