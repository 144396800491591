import { memo, useMemo } from 'react';

import { useQueryClient } from 'react-query';

import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import AutoSizer from 'react-virtualized-auto-sizer';

import { QUERY_KEY } from 'common/query-keys';

import { TableCell } from 'containers/research/lib/models/types';
import CellRenderer from './CellRenderer';
import {
  FIXED_COLUMN_COUNT,
  FIXED_ROW_COUNT,
  ROW_HEIGHT,
  COLUMN_TITLES,
} from './table-data';

import { TableWrapperStyled } from './styled';
import {
  STYLE,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
} from './styled/virtualized-styles';

export const Table = memo((): JSX.Element => {
  const useQuery = useQueryClient();
  const metrocountsCachedData = useQuery.getQueryState(QUERY_KEY.METROCOUNTS)?.data as TableCell[];
  const moodifiedDataArray = useMemo(
    () => [({} as TableCell), ...metrocountsCachedData], [metrocountsCachedData],
  );
  const rowCount = useMemo(() => moodifiedDataArray.length, [moodifiedDataArray]);

  return (
    <TableWrapperStyled>
      <AutoSizer>
        {({ height, width }) => (
          <MultiGrid
            fixedColumnCount={FIXED_COLUMN_COUNT}
            columnWidth={({ index }) => (COLUMN_TITLES[index].width)}
            columnCount={COLUMN_TITLES.length}
            height={height}
            rowHeight={ROW_HEIGHT}
            rowCount={rowCount}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
            style={STYLE}
            cellRenderer={({ key: mainKey, ...rest }) => (
              <CellRenderer
                {...rest}
                key={mainKey}
                data={moodifiedDataArray}
              />
            )}
            fixedRowCount={FIXED_ROW_COUNT}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
          />
        )}
      </AutoSizer>
    </TableWrapperStyled>
  );
});
