import { useState, useCallback } from 'react';

import { useModalContext } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/hooks';
import { ActionType } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/ModalContext';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { useGetPriceNumComps } from 'containers/compsets/lib/query-hooks';
import { useGetBenchmarkAnalytics, usePostSubscriptionPropertyBy } from '../lib/query-hooks';
import { useSetClientPortfolioName } from '../local-state/hooks';

import { useCreateBencmarkAnalyticsTableData } from '../lib/mapped-table-data';
import { ReturnSubscriptionModal } from '../lib/utils/types';

export const useSubscriptionModal = (): ReturnSubscriptionModal => {
  const [value, setValue] = useState('');

  const { dispatch } = useModalContext();
  const { state } = useSetClientPortfolioName();

  const { mutateAsync } = useGetPriceNumComps();
  const { propName, selection, callback } = useCreateBencmarkAnalyticsTableData();
  const subscribe = usePostSubscriptionPropertyBy(value);
  const savedCompsets = useGetBenchmarkAnalytics({ propName, selection, callback });

  const getPrice = useCallback((): void => {
    mutateAsync({ propertyName: state.value });
  }, [mutateAsync, state.value]);

  const subscribeModal = useCallback(async (): Promise<void> => {
    setValue(state.value);
    getPrice();
    await subscribe.refetch();
    await savedCompsets.refetch();

    SnackBarUtils.success(`${'SUBSCRIBED TO BENCHMARK ANALYTICS:'}${propName}`);
    dispatch({ type: ActionType.Close });
  }, [dispatch, getPrice, propName, savedCompsets, state.value, subscribe]);

  return {
    subscribeModal,
  };
};
