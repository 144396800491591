import { lazy, memo, Suspense } from 'react';

import { USER_ROLES } from 'containers/main/constants';
import { ROUTE } from 'common/components/routes/utils/constants';
import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';

import { useAuth } from 'containers/login/lib/auth';
import {
  BuyAndSellDataCreditsProvider,
} from './buy-sell-data-credits/local-state/BuyAndSellDataCreditsProvider';

import {
  TabPanelInnerContainerStyled,
  TabPanelInnerContainerVirtualizedStyled,
  TabPanelPaperStyled,
  TabPanelStyled,
} from './styled';

const Profile = lazy(() => import('./profile'));
const Admin = lazy(() => import('./admin'));
const DataCredits = lazy(() => import('./buy-sell-data-credits'));

export const TabPanelsComponent = memo(() => {
  const { user } = useAuth();

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <TabPanelPaperStyled variant="elevation" elevation={3}>
        <TabPanelStyled value={ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT}>
          <TabPanelInnerContainerStyled>
            <DataCredits />
          </TabPanelInnerContainerStyled>
        </TabPanelStyled>
        {/** these features in progress, It will be added as soon as possible */}
        {/* <TabPanelStyled value={ROUTE.ACCOUNT.BUY_AND_SELL.PAYMENT_AND_INVOICES}>
          <TabPanelInnerContainerStyled>
            Payment and invoices works!
          </TabPanelInnerContainerStyled>
        </TabPanelStyled> */}
        {/* <TabPanelStyled value={ROUTE.ACCOUNT.BUY_AND_SELL.BILLING_HISTORY}>
          <TabPanelInnerContainerStyled>
            Billing history works!
          </TabPanelInnerContainerStyled>
        </TabPanelStyled> */}
        {user?.role === USER_ROLES.USER && (
          <TabPanelStyled value={ROUTE.ACCOUNT.BUY_AND_SELL.PROFILE}>
            <TabPanelInnerContainerStyled>
              <Profile />
            </TabPanelInnerContainerStyled>
          </TabPanelStyled>
        )}
        {user?.role === USER_ROLES.ADMIN && (
          <TabPanelStyled value={ROUTE.ACCOUNT.BUY_AND_SELL.ADMIN}>
            <TabPanelInnerContainerVirtualizedStyled>
              <BuyAndSellDataCreditsProvider>
                <Admin />
              </BuyAndSellDataCreditsProvider>
            </TabPanelInnerContainerVirtualizedStyled>
          </TabPanelStyled>
        )}
      </TabPanelPaperStyled>
    </Suspense>
  );
});
