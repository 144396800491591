import { styled, InputLabel, Box } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',
  '& .MuiDialogActions-root': {
    padding: theme.spacing(7.5),
    alignSelf: 'left',
  },
}));

export const ModalWrapperStyled = styled('div')(({ theme }) => ({
  width: theme.spacing(80),
  minHeight: theme.spacing(50.25),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  padding: theme.spacing(7.5),
  positon: 'relative',
}));

export const ModalBenchmarkWrapperStyled = styled('div')(({ theme }) => ({
  width: theme.spacing(85),
  minHeight: theme.spacing(50.25),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  padding: theme.spacing(7.5),
  positon: 'relative',
}));

export const ActionsWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '244px',
});

export const CloseIconStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: theme.spacing(1.25),
  right: theme.spacing(2.5),
  cursor: 'pointer',
}));

export const ModalTitleStyled = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(6),
  marginBottom: theme.spacing(2.75),
}));

export const ModalTextStyled = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: theme.spacing(4),
  fontWeight: theme.spacing(25),
  fontFamily: theme.typography.fontFamily,
}));

export const ModalTextInputStyled = styled(ModalTextStyled)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-inputAdornedStart': {
    fontSize: theme.spacing(4),
  },

  '& .MuiTextField-root': {
    width: theme.spacing(60),
  },
}));

export const ConfirmButtonStyled = styled(Button)(({ theme }) => ({
  height: '48px',
  width: '114px',
  marginTop: theme.spacing(7.5),
}));

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  height: '48px',
  width: '114px',
  marginTop: theme.spacing(7.5),
  color: theme.palette.background.blue,
  borderColor: theme.palette.background.blue,
}));

export const InputTextStyled = styled(InputLabel)(() => ({
  color: '#000',
}));

export const ModalBodyWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  '.MuiButtonBase-root': {
    marginTop: theme.spacing(-15),
    marginRight: theme.spacing(-5),
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  right: theme.spacing(5),
  padding: 0,
}));

export const ErrorTextStyled = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: 'red',
}));
