import { styled } from '@mui/material';

import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { DATA_MENU_WIDTH } from './constants';

export const ButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: 14,
  padding: theme.spacing(2.5, 4),
  justifyContent: 'space-between',
  minHeight: theme.spacing(10.5),
  width: DATA_MENU_WIDTH,
}));

export const MenuStyled = styled(Menu)(({ theme }) => ({
  maxHeight: '70vh',

  '.MuiList-padding': {
    padding: 0,
  },

  '.MuiPopover-paper': {
    padding: theme.spacing(0, 3),
    width: `${DATA_MENU_WIDTH}px`,
  },

}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  padding: theme.spacing(2, 0),

  h6: { marginLeft: theme.spacing(2.5) },
}));
