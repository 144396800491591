import React, { useMemo } from 'react';
import { ValuationContext } from './ValuationContext';
import { valuationReducer } from './valuationReducer';

export const ValuationContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [valuationState, setValuationState] = React.useReducer(valuationReducer, {
    solveForCaprate: true,
    solveForVal: false,
    useTrailingFinancials: true,
  });

  const ValuationContextValue = useMemo(() => ({
    state: valuationState,
    dispatch: setValuationState,
  }), [valuationState]);

  return (
    <ValuationContext.Provider value={ValuationContextValue}>
      {children}
    </ValuationContext.Provider>
  );
};
