import { createContext } from 'react';
import { RadioSelectionValue } from '../components/list/utils/types';

export type RadioBenchmarkAction = { type: RadioSelectionValue; };

export type RadioBenchmarkDispatch = (action: RadioBenchmarkAction) => void;

export type RadioBenchmarkState = { value: RadioSelectionValue; };

export const RadioBenchmarkContext = createContext<
  { state: RadioBenchmarkState; dispatch: RadioBenchmarkDispatch; } | undefined
>(undefined);
