import { useContext } from 'react';

import { SetEditRowContext, SetEditRowDispatch, SetEditRowState } from './SetEditRowContext';

import {
  FixedColumnsCountStateContext, FixedColumnsDispatch, FixedColumnsState,
} from './FixedColumnsCountStateContext';

import {
  FilterDispatch, FilterState, TableColumnMenuContext,
} from './TableColumnMenuContext';
import { DeleteModalContext, DeleteModalDispatch, DeleteModalState } from './DeleteModalContext';
import { AddUserModalContext, AddUserModalDispatch, AddUserModalState } from './AddUserModalContext';
import { SortByNameContext, SortByNameDispatch, SortByNameState } from './SortByNameContext';
import { FilterContext, FilterAdminDispatch, FilterAdminState } from './FilterContext';
import { FilterDataContext, FilterDataDispatch, FilterDataState } from './FilterDataContext';

const useFilterByColumnName = (): {
  state: FilterState,
  dispatch: FilterDispatch,
} => {
  const context = useContext(TableColumnMenuContext);
  if (context === undefined) {
    throw new Error('useFilterByColumnName must be used within a TableColumnMenuProvider');
  }

  return context;
};

const useFilterData = (): {
  state: FilterDataState,
  dispatch: FilterDataDispatch,
} => {
  const context = useContext(FilterDataContext);
  if (context === undefined) {
    throw new Error('FilterContext must be used within a FilterContextProvider');
  }

  return context;
};

const useColumFilter = (): {
  state: FilterAdminState,
  dispatch: FilterAdminDispatch,
} => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('FilterContext must be used within a FilterContextProvider');
  }

  return context;
};

const useSetFixedColumnsCount = (): {
  state: FixedColumnsState,
  dispatch: FixedColumnsDispatch,
} => {
  const context = useContext(FixedColumnsCountStateContext);
  if (context === undefined) {
    throw new Error('useUpdateFixedColumnsCount must be used within a CountProvider');
  }

  return context;
};

const useSetToEditRow = (): {
  state: SetEditRowState,
  dispatch: SetEditRowDispatch,
} => {
  const context = useContext(SetEditRowContext);
  if (context === undefined) {
    throw new Error('useSetToEditRow must be used within a SetEditRowProvider');
  }

  return context;
};

const useDeleteRow = (): {
  state: DeleteModalState,
  dispatch: DeleteModalDispatch,
} => {
  const context = useContext(DeleteModalContext);
  if (context === undefined) {
    throw new Error('useDeleteRow must be used within a DeleteModalProvider');
  }

  return context;
};

const useOpenAddUserModal = (): {
  state: AddUserModalState,
  dispatch: AddUserModalDispatch,
} => {
  const context = useContext(AddUserModalContext);
  if (context === undefined) {
    throw new Error('useOpenAddUserModal must be used within a AddUserModalProvider');
  }

  return context;
};

const useSetSortByFieldNameTableData = (): {
  state: SortByNameState,
  dispatch: SortByNameDispatch,
} => {
  const context = useContext(SortByNameContext);
  if (context === undefined) {
    throw new Error('useSetSortByFieldNameTableData must be used within a SortByNameProvider');
  }

  return context;
};

export {
  useSetSortByFieldNameTableData,
  useSetFixedColumnsCount,
  useFilterByColumnName,
  useOpenAddUserModal,
  useSetToEditRow,
  useDeleteRow,
  useColumFilter,
  useFilterData,
};
