import { styled } from '@mui/material';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export const ContactsTabStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(3),
  overflow: 'auto',
}));

export const ContactsSectionStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ContainerStyled = styled(Container)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    gap: 0,
    gridTemplateColumns: '1fr',
  },
}));

export const TextContainerStyled = styled('div')(({ theme }) => ({
  margin: theme.spacing(7, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(6, 0, 2, 0),
  },
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.5,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  paddingTop: theme.spacing(2),
}));

export const LinkStyled = styled('a')(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.5,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  paddingTop: theme.spacing(2),
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:visited': {
    color: theme.palette.text.mediumGray,
  },
}));

export const InputsContainerStyled = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(7),
  display: 'grid',
  gridTemplateAreas: `'name email'
                       'message message'
                       '. button'`,
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    gridTemplateAreas: `'name'
                        'email'
                        'message'
                        'button'`,
  },
}));

export const InputStyled = styled(TextField)(({ theme }) => ({
  '&>.MuiInputLabel-formControl': {
    color: theme.palette.text.mediumGray,
  },
}));

export const NameInputStyled = styled(InputStyled)({
  gridArea: 'name',
});

export const EmailInputStyled = styled(InputStyled)({
  gridArea: 'email',
});

export const MessageInputStyled = styled(InputStyled)({
  gridArea: 'message',
  height: '100%',
  '&>.MuiOutlinedInput-multiline': {
    height: '100%',
  },
});

export const SendButtonStyled = styled(Button)({
  gridArea: 'button',
  justifySelf: 'flex-end',
});
