import { memo } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { BENCHMARK } from 'containers/benchmark/utils/constants';
import { useFilterByModal } from 'containers/benchmark/components/SHARED/utils/hooks';

import {
  FilterByMenuButtonStyled,
  FilterByMenuStyled,
} from 'containers/buy-sell-credits/admin/table/column-menu/utils/styled';

const FilterByMenu = ({ columns }:{columns: string[]}): JSX.Element => {
  const {
    anchorEl,
    open,
    handleClick,
    onClose,
    selected,
  } = useFilterByModal();

  return (
    <Grid>
      <FilterByMenuButtonStyled
        variant="outlined"
        disableElevation
        onClick={handleClick}
        fullWidth
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selected || BENCHMARK.FILTER_SELECT}
      </FilterByMenuButtonStyled>
      <FilterByMenuStyled
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {columns.map((column) => (
          <MenuItem
            key={column}
            disableRipple
            onClick={onClose}
          >
            {column}
          </MenuItem>
        )) }
      </FilterByMenuStyled>
    </Grid>
  );
};

export default memo(FilterByMenu);
