import { useContext } from 'react';

import { MainPageTabContext as Context } from './MainPageTabContext';
import type { MainPageTabContext } from './MainPageTabContext';

export function useMainPageTabContext(): MainPageTabContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('MainPageTabContext must be used within a AppContextProvider');
  }

  return context;
}
