import { memo } from 'react';

import { BENCHMARK } from 'containers/benchmark/utils/constants';
import { useInfoTablePopup } from 'containers/benchmark/local-state/hooks';

import {
  BenchmarkTableHeaderStyled,
  InfoButtonStyled,
  TableTitleStyled,
} from 'containers/benchmark/utils/styled';

import { Box } from '@mui/material';
import ExportDataMenu from './ExportDataMenu';

const Header = (): JSX.Element => {
  const { dispatch } = useInfoTablePopup();

  const openInfoTablePopup = (): void => dispatch({ type: 'open' });

  return (
    <BenchmarkTableHeaderStyled>
      <TableTitleStyled>{BENCHMARK.BENCHMARK_ANALYTICS.toUpperCase()}</TableTitleStyled>
      <Box marginLeft={40}>
        <ExportDataMenu />
      </Box>
      <InfoButtonStyled onClick={openInfoTablePopup}>
        {BENCHMARK.INFO_BUTTON}
      </InfoButtonStyled>
    </BenchmarkTableHeaderStyled>
  );
};

export default memo(Header);
