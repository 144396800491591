import { styled } from '@mui/material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FilledInput from '@mui/material/FilledInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

export const LoginPaperWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  overflow: 'auto',
  alignItems: 'center',
  margin: `${theme.spacing(3)} auto`,
}));

export const LoginPaperStyled = styled(Paper)<{ component?: string; }>(({ theme }) => ({
  maxWidth: 570,
  margin: 'auto',
  height: 'fit-content',
  padding: theme.spacing(25),
  overflow: 'auto',
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(12),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

export const HeaderStyled = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

export const InputLabelStyled = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export const FilledInputStyled = styled(FilledInput)(({ theme }) => ({
  height: 48,
  border: `1px solid ${theme.palette.primary.light}`,
  borderColor: theme.palette.primary.light,
  backgroundColor: theme.palette.secondary.light,
  fontSize: 14,
}));

export const PasswordSectionStyled = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const NameSectionStyled = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const FormControlLabelStyled = styled(FormControlLabel)({
  fontSize: 14,
});

export const ButtonWrapperStyled = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3.5, 0),
}));

export const PasswordIconButtonStyled = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(3.75),
}));
