export const VALUATION_DATE_TABLE = {
  TITLE: 'VALUATION DATA: Used to Perform COMPSET COMPARISONS',
  ROWS: [
    {
      rowName: 'INPUT VALUATION AND UNDERWRITTEN FINANCIALS',
      inputName: '',
      dataName: '',
      type: 'header',
      disabled: true,
      key: '',
      symbol: '',
    },
    {
      rowName: 'Valuation Date',
      inputName: 'valuationDate',
      dataName: 'valuationDate',
      type: 'date',
      disabled: true,
      key: '',
      symbol: '',
    },
    {
      rowName: 'Valuation Amount',
      inputName: 'valuationAmount',
      dataName: 'valuationAmount',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '$',
    },
    {
      rowName: 'UW REVENUE',
      inputName: 'uwRev',
      dataName: 'uwRev',
      type: 'number',
      disabled: true,
      key: 'trailing',
      symbol: '$',
    },
    {
      rowName: 'UW EXPENSES',
      inputName: 'uwExp',
      dataName: 'uwExp',
      type: 'number',
      disabled: true,
      key: 'trailing',
      symbol: '$',
    },
    {
      rowName: 'UW CAPEX',
      inputName: 'uwCapex',
      dataName: 'uwCapex',
      type: 'number',
      disabled: true,
      key: 'trailing',
      symbol: '$',
    },
    {
      rowName: 'UW CAPRATE',
      inputName: 'uwCaprate',
      dataName: 'uwCaprate',
      type: 'number',
      disabled: true,
      key: '',
      symbol: '%',
    },
  ],
  COLUMN_INDEX: 0,
  OVERSCAN_ROW_COUNT: 2,
  DEFAULT_HEIGHT: 28,
} as const;

export const MODIFY_VALUATION_BUTTON_TEXT = 'Modify valuation save' as const;

export const TRAILING_FINANCIALS = 'Use trailing financials' as const;

export const valuationData = [
  { name: 'SOLVE FOR CAPRATE', config: 'solveForCaprate' },
  { name: 'SOLVE FOR VALUATION', config: 'solveForVal' },
] as const;
