import {
  memo, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router';

import { Index } from 'react-virtualized';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ROUTE } from 'common/components/routes/utils/constants';
import QueryLoadingProvider from 'common/components/QueryLoadingProvider';

import { useGetMetrocounts, useGetQuartiles, useGetResearch } from 'containers/research/lib/query-hooks';
import { PrintDataQueryParams, TableCell } from 'containers/research/lib/models/types';
import { RESEARCH } from 'containers/research/utils/constants';
import CellRenderer from './CellRenderer';
import {
  FIXED_COLUMN_COUNT,
  FIXED_ROW_COUNT,
  COLUMN_TITLES, ROW_HEIGHT, COLUMN_WIDTH,
} from './table-data';

import { TableWrapperStyled } from './styled';
import {
  STYLE,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
} from './styled/virtualized-styles';

export const Table = memo(({ metrocountsData }: {metrocountsData: TableCell[] }): JSX.Element => {
  const [params, setParams] = useState({} as PrintDataQueryParams);
  const [paramsQuartiles, setParamsQuartiles] = useState({} as PrintDataQueryParams);

  const history = useHistory();

  const research = useGetResearch();
  const quartiles = useGetQuartiles();
  const fetchedMetrocounts = useGetMetrocounts();

  const rowCount = useMemo(() => metrocountsData.length, [metrocountsData.length]);
  const getRowSize = useMemo(() => ({ index }:Index) => (index === 0 ? COLUMN_WIDTH : ROW_HEIGHT), []);

  const calculatedColumnWidth = useCallback((width: number, index: Index): number => {
    const currentIndex = index.index;
    const columnWidth = (width / COLUMN_TITLES.length);
    const fixedColumnWidth = COLUMN_TITLES[currentIndex as number].width;

    return columnWidth > fixedColumnWidth ? columnWidth : fixedColumnWidth;
  }, []);

  const handlePrintData = ({ region, subtype }:PrintDataQueryParams): void => {
    history.push({
      pathname: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
      search: `?${RESEARCH.PRINT_DATA_PARAMS}=${JSON.stringify({ region, subtype })}`,
    });
    setParams({ region, subtype } as SetStateAction<PrintDataQueryParams>);
  };

  const handleQuartilesData = ({ region, subtype }:PrintDataQueryParams): void => {
    history.push({
      pathname: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
      search: `?${RESEARCH.PRINT_DATA_PARAMS}=${JSON.stringify({ region, subtype })}`,
    });
    setParamsQuartiles({ region, subtype } as SetStateAction<PrintDataQueryParams>);
  };

  useEffect(() => {
    history.push({
      pathname: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
      search: `?${RESEARCH.PRINT_DATA_PARAMS}=${JSON.stringify(params)}`,
    });
    research.refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [history, params]);

  useEffect(() => {
    history.push({
      pathname: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
      search: `?${RESEARCH.PRINT_DATA_PARAMS}=${JSON.stringify(paramsQuartiles)}`,
    });
    quartiles.refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [history, paramsQuartiles]);

  return (
    <TableWrapperStyled>
      <QueryLoadingProvider result={fetchedMetrocounts}>
        <AutoSizer>
          {({ height, width }) => (
            <MultiGrid
              fixedColumnCount={FIXED_COLUMN_COUNT}
              columnWidth={(index) => calculatedColumnWidth(width, index)}
              columnCount={COLUMN_TITLES.length}
              height={height}
              rowHeight={getRowSize}
              rowCount={rowCount}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
              style={STYLE}
              cellRenderer={({ key: mainKey, ...rest }) => (
                <CellRenderer
                  {...rest}
                  key={mainKey}
                  data={metrocountsData}
                  handlePrintData={handlePrintData}
                  handleQuartilesData={handleQuartilesData}
                />
              )}
              fixedRowCount={FIXED_ROW_COUNT}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            />
          )}
        </AutoSizer>
      </QueryLoadingProvider>
    </TableWrapperStyled>
  );
});
