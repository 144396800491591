import { useState } from 'react';

import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';

import { BENCHMARK } from 'containers/benchmark/utils/constants';
import { useSetRadioSelection } from 'containers/benchmark/local-state/hooks';

import { RadioSelectionValue } from './utils/types';

const RadioButtonGroup = (): JSX.Element => {
  const { dispatch } = useSetRadioSelection();

  const [value, setValue] = useState<RadioSelectionValue>('compsetByMfcAlgorithm');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value as RadioSelectionValue);
    dispatch({ type: (event.target as HTMLInputElement).value as RadioSelectionValue });
  };

  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="compsetByMfcAlgorithm"
          label={BENCHMARK.RADIO_2}
          control={<Radio color="default" />}
        />
        <FormControlLabel
          value="savedCompset"
          label={BENCHMARK.RADIO_1}
          control={<Radio color="default" />}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
