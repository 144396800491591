import React, {
  createContext, memo, useEffect,
} from 'react';

import logo from 'assets/logo.png';

import { useHistory } from 'react-router';
import { useScreenDown } from 'common/hooks/screenSize';
import { useSideBarContext } from 'context/hooks';

import MenuIcon from '@mui/icons-material/Menu';

import { SideBarActionType } from 'context/SideBarContext';
import SidebarDrawer from './SidebarDrawer';
import { MobileMenu } from './views/MobileMenu';
import { DesktopMenu } from './views/DesktopMenu';
import { ROUTE } from '../routes/utils/constants';

import {
  AppBarStyled,
  ContentWrapperStyled,
  GridItemStyled,
  LogoStyled,
  ToolbarIconButtonStyled,
  ToolbarStyled,
} from './styled';

interface ApplicationBarProps {
  children: React.ReactNode;
  getRoute: (name: string) => void;
}

export const ApplicationBarContext = createContext<boolean>(false);

export const ApplicationBar = memo(({ children, getRoute }: ApplicationBarProps): JSX.Element | null => {
  const { state: { sideBarOpen, shouldSideBarBeDisplayed }, dispatch } = useSideBarContext();

  useEffect(() => {
    dispatch({ type: SideBarActionType.SideBarOpen, sideBarOpen: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isScreenDownMd = useScreenDown('md');
  const history = useHistory();
  const routerPath = history.location.pathname;

  const isStartOrLoginPage = routerPath === ROUTE.LOGIN
    || routerPath === ROUTE.HOME_DEFAULT
    || routerPath === ROUTE.NOT_FOUND;
  const isHomeTabRoute = routerPath.includes('home');

  const onLogoClickHandle = (): void => {
    history.push({
      pathname: ROUTE.HOME_DEFAULT,
    });
    getRoute(ROUTE.HOME_DEFAULT);
    dispatch({ type: SideBarActionType.SideBarOpen, sideBarOpen: true });
  };

  const handleDrawerOpen = (): void => {
    dispatch({ type: SideBarActionType.SideBarOpen, sideBarOpen: true });
  };

  const handleDrawerClose = (): void => {
    dispatch({ type: SideBarActionType.SideBarOpen, sideBarOpen: true });
  };

  return (
    <ApplicationBarContext.Provider value={sideBarOpen}>
      <AppBarStyled
        color="default"
        position="relative"
        $isOpen={sideBarOpen}
      >
        <ContentWrapperStyled
          item
          container
          wrap="nowrap"
          direction="row"
          alignItems="flex-end"
        >
          {(!isStartOrLoginPage && !shouldSideBarBeDisplayed && !isHomeTabRoute)
          && (
            <ToolbarStyled $isOpen={sideBarOpen}>
              <ToolbarIconButtonStyled
                color="default"
                onClick={handleDrawerOpen}
                $isOpen={sideBarOpen}
              >
                <MenuIcon />
              </ToolbarIconButtonStyled>
            </ToolbarStyled>
          )}
          <GridItemStyled
            item
            container
            xs={8}
            justifyContent="flex-start"
          >
            <LogoStyled
              src={logo}
              alt="Logo"
              onClick={onLogoClickHandle}
            />
          </GridItemStyled>
          {isScreenDownMd
            ? !shouldSideBarBeDisplayed && (
              <MobileMenu>
                {children}
              </MobileMenu>
            )
            : !shouldSideBarBeDisplayed && (
              <DesktopMenu>
                {children}
              </DesktopMenu>
            )}
        </ContentWrapperStyled>
      </AppBarStyled>
      {(!isStartOrLoginPage && !shouldSideBarBeDisplayed && !isHomeTabRoute) && (
        <SidebarDrawer
          closeDrawer={handleDrawerClose}
          isOpen={sideBarOpen}
        />
      )}
    </ApplicationBarContext.Provider>
  );
});
