import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import palette from './palette';
import shape from './shape';
import typography from './typography';

import { components } from './components/index';
import defaultTheme from './defaultTheme';

export default createTheme({
  breakpoints,
  components,
  palette,
  shape,
  spacing: defaultTheme.spacing,
  typography,
});
