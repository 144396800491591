import { memo } from 'react';

import { TEXT } from 'containers/CMBS/utils/constants';

import {
  ImageSectionStyled,
  ImageTextStyled,
  ImageTextWrapperStyled,
  ImageStyled,
} from './styled';

const ImageSection = ():JSX.Element => (
  <ImageSectionStyled elevation={5}>
    <ImageStyled />
    <ImageTextWrapperStyled>
      <ImageTextStyled>{TEXT.IMAGE_TEXT}</ImageTextStyled>
    </ImageTextWrapperStyled>
  </ImageSectionStyled>
);

export default memo(ImageSection);
