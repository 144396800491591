import { styled } from '@mui/material';

export const H1Styled = styled('h1')({
  position: 'absolute',
  left: 0,
  top: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  padding: 0,
  border: 0,
  height: '1px',
  width: '1px',
  overflow: 'hidden',
  visibility: 'hidden',
});
