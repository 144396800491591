import {
  createContext, FC, ForwardedRef, useReducer,
} from 'react';
import { Data } from '../lib/types';

type RefType = {
  getData: () => Data[];
  disableEnableRows: (disabled: number[], enable: number[]) => void;
  setData: (data: Data[]) => void;
} | null;

export type Action =
  | {
      type: 'UPDATE_VALUE_BY_INDEX';
      payload: {
        index: number;
        value: string;
      }
    }
  | {
      type: 'SET_INITIAL_DATA';
      payload: {
        rowData: Data[];
        ref: ForwardedRef<RefType>;
      }
    }
  | {
      type: 'DISABLE_ENABLE_ROWS';
      payload: {
        enable: number[];
        disabled: number[];
      }
    }
  | {
      type: 'SET_DATA';
      payload: {
        rowData: Data[];
      };
    };

export type Dispatch = (action: Action) => void;

export type State = {
  rowData: Data[];
  ref: ForwardedRef<RefType>;
};

type GenericStateContext = State;
type GenericDispatchContext = Dispatch;

export const TableStateContext = createContext<GenericStateContext>({
  rowData: [],
  ref: null,
});

export const TableDispatchContext = createContext<GenericDispatchContext>(() => undefined);

const initialState: State = {
  rowData: [],
  ref: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_VALUE_BY_INDEX': {
      const clonedRowData: Data[] = [...state.rowData];
      const row: Data = clonedRowData[action.payload.index];
      row.value = action.payload.value;
      return { ...state, rowData: clonedRowData };
    }

    case 'SET_INITIAL_DATA': {
      const { rowData, ref } = action.payload;
      const transformedRowData = rowData.map((i) => {
        if (i.type === 'percent' && i.name === 'OCCUP') {
          return { ...i, value: `${(Number(i.value) * 100).toFixed(1)}` };
        }
        if (i.type === 'percent') {
          return { ...i, value: `${(Number(i.value) * 100).toFixed(2)}` };
        }
        return i;
      });
      return { rowData: transformedRowData, ref };
    }

    case 'SET_DATA': {
      const { rowData } = action.payload;
      const mapped = state.rowData.map((i: Data, index) => ({ ...i, value: rowData[index].value }));
      const transformedRowData = mapped.map((i) => {
        if (i.type === 'percent' && i.name === 'OCCUP') {
          return { ...i, value: `${(Number(i.value) * 100).toFixed(1)}` };
        }
        if (i.type === 'percent') {
          return { ...i, value: `${(Number(i.value) * 100).toFixed(2)}` };
        }
        return i;
      });

      return { ...state, rowData: transformedRowData };
    }

    case 'DISABLE_ENABLE_ROWS': {
      const { disabled, enable } = action.payload;
      const mapped = state.rowData.map((data: Data) => {
        if (data.id !== undefined && disabled.includes(data.id) && enable.includes(data.id)) {
          return { ...data, disabled: undefined };
        }
        if (data.id !== undefined && disabled.includes(data.id)) {
          return { ...data, disabled: true };
        }
        if (data.id !== undefined && enable.includes(data.id)) {
          return { ...data, disabled: false };
        }
        return data;
      });
      return { ...state, rowData: mapped };
    }

    default:
      throw new Error('No such action type in reducer!');
  }
};

export const TableContextProvider: FC<{children: React.ReactNode}> = ({ children }): JSX.Element => {
  const [state, dispatch]: [State, Dispatch] = useReducer(reducer, initialState);

  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        {children}
      </TableDispatchContext.Provider>
    </TableStateContext.Provider>
  );
};
