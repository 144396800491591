import { memo } from 'react';

import SelectedPropertiesProvider from 'common/components/BingMap/local-state/SelectedPropertiesProvider';
import DetailsPropertyTable from './table/DetailsPropertyTable';
import ExportDataMenu from './ExportDataMenu';
// !!!DONT DELETE!!! MAY BE NEEDED IN THE FUTURE
// import Header from 'containers/input-property/components/detailsTab/header/Header';
import {
  DetailsTabContainerStyled,
  TableWrapper,
} from './styled';

const DetailsTab = ():JSX.Element => (
  <DetailsTabContainerStyled>
    <SelectedPropertiesProvider>
      {/* <Header /> !!!DONT DELETE!!! MAY BE NEEDED IN THE FUTURE */}
      <TableWrapper>
        <ExportDataMenu />
        <DetailsPropertyTable />
      </TableWrapper>
    </SelectedPropertiesProvider>
  </DetailsTabContainerStyled>
);

export default memo(DetailsTab);
