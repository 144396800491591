import { PropUpdateModelPartial } from 'common/components/BingMap/utils/types';

export const TYPE = {
  date: 'date',
  dateRev: 'dateRev',
  text: 'text',
  dollar: 'dollar',
  percent: 'percent',
  select: 'select',
  header: 'header',
} as const;

export type Data = {
  id?: number;
  disabled?: boolean;
  name: string;
  value: string;
  type: string;
  selectors?: string[];
};

export const getFinTableData = (
  rawTableData: Partial<PropUpdateModelPartial>[],
  selectedIndex: number,
): Data[] => {
  const res: Data[] = [];
  res.push({
    name: 'OPTION 1: INPUT TOP-LINE FINANCIALS',
    value: '',
    type: TYPE.header,
  });
  res.push({
    name: 'STATEMENT YEAR',
    value: `${rawTableData[selectedIndex]?.statementEndDateRev}`,
    type: TYPE.dateRev,
  });
  res.push({
    name: 'OCCUP',
    value: `${rawTableData[selectedIndex]?.occup}`,
    type: TYPE.percent,
  });
  res.push({
    name: 'REVENUE',
    value: `${rawTableData[selectedIndex]?.rev}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'EXPENSES',
    value: `${rawTableData[selectedIndex]?.exp}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'CAPEX',
    value: `${rawTableData[selectedIndex]?.capex0}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'OPTION 2: INPUT LINE-ITEM FINANCIALS',
    value: '',
    type: TYPE.header,
  });
  res.push({
    name: 'GROSRNT',
    value: `${rawTableData[selectedIndex]?.grosRnt}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'VACANCY LOSS',
    value: `${rawTableData[selectedIndex]?.vacancy}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'BASERNT',
    value: `${rawTableData[selectedIndex]?.baseRnt}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'LAUNDRY',
    value: `${rawTableData[selectedIndex]?.laundry}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'PARKING',
    value: `${rawTableData[selectedIndex]?.parking}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'OTHERIN',
    value: `${rawTableData[selectedIndex]?.otherin}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'RETAXES',
    value: `${rawTableData[selectedIndex]?.retaxes}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'PROPINS',
    value: `${rawTableData[selectedIndex]?.propins}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'UTILITI',
    value: `${rawTableData[selectedIndex]?.utiliti}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'REPAIRS',
    value: `${rawTableData[selectedIndex]?.repairs}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'MANAGEM',
    value: `${rawTableData[selectedIndex]?.managem}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'PAYROLL',
    value: `${rawTableData[selectedIndex]?.payroll}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'MARKETI',
    value: `${rawTableData[selectedIndex]?.marketi}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'PROFESS',
    value: `${rawTableData[selectedIndex]?.profess}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'GENERAL',
    value: `${rawTableData[selectedIndex]?.general}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'OTHEREX',
    value: `${rawTableData[selectedIndex]?.otherex}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'GROUNDR',
    value: `${rawTableData[selectedIndex]?.groundr}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'CAPEX',
    value: `${rawTableData[selectedIndex]?.capex}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'OPTION 3: INC/EXP INPUT IS ADDED TO OTHERIN/OTHEREX CATEGORIES',
    value: '',
    type: TYPE.header,
  });
  res.push({
    name: 'HC_INC_PVTPAY',
    value: `${rawTableData[selectedIndex]?.hcIncPvtpay}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'HC_INC_MEDCARE',
    value: `${rawTableData[selectedIndex]?.hcIncMedcare}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'HC_INC_NURSING',
    value: `${rawTableData[selectedIndex]?.hcIncNursing}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'HC_INC_MEALS',
    value: `${rawTableData[selectedIndex]?.hcIncMeals}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'HC_EXP_ROOMS',
    value: `${rawTableData[selectedIndex]?.hcExpRooms}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'HC_EXP_MEALS',
    value: `${rawTableData[selectedIndex]?.hcExpMeals}`,
    type: TYPE.dollar,
  });
  return res;
};

export const TITLE_MODAL_TEXT = {
  TITLE: 'Notification',
};

export const CONFIRMATION_MODAL_TEXT = {
  FINANCIAL_TABLE: 'Do you want to modify financials save?',
  VALUATION_TABLE: 'Do you want to modify valuation save?',
  HEALTHCARE_TABLE: 'Do you want to modify healthcare save?',
};

export const BUTTONS_MODAL_TEXT = {
  AGREEMENT: 'YES',
  DISAGREEMENT: 'NO',
};
