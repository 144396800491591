import { styled } from '@mui/material';

import { Link } from 'react-router-dom';

export const NotFoundContainer = styled('div', {
  shouldForwardProp: (propName) => propName !== '$downMd',
})<{ $downMd?: boolean; }>(({ theme, $downMd }) => ({
  backgroundColor: theme.palette.background.blue,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.background.white,
  position: 'relative',
  padding: $downMd ? theme.spacing(5) : 0,
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: (propName) => propName !== '$downMd',
})<{ $downMd?: boolean; }>(({ theme, $downMd }) => ({
  width: $downMd ? 'auto' : theme.spacing(57.75),
  height: $downMd ? 'auto' : theme.spacing(12),
  marginTop: $downMd ? 'auto' : theme.spacing(8.25),
  backgroundColor: theme.palette.background.blue,
  border: `1px solid ${theme.palette.background.white}`,
  borderRadius: theme.shape.borderRadius,
  fontSize: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.background.white,
  ': hover': {
    backgroundColor: theme.palette.background.white,
    textDecoration: 'none',
    color: theme.palette.background.blue,
  },
}));

export const NotFoundTitleStyled = styled('div', {
  shouldForwardProp: (propName) => propName !== '$downMd',
})<{ $downMd?: boolean; }>(({ theme, $downMd }) => ({
  fontSize: $downMd ? theme.spacing(20) : theme.spacing(45),
}));

export const NotFoundTextStyled = styled('div', {
  shouldForwardProp: (propName) => propName !== '$downMd',
})<{ $downMd?: boolean; }>(({ theme, $downMd }) => ({
  fontSize: $downMd ? theme.spacing(4.5) : theme.spacing(8),
}));
