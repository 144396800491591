import React from 'react';
import { CheckboxContext, ICheckboxContext } from 'containers/compsets/local-state/CheckboxContext';
import { ISideBarContext, SideBarContext } from './SideBarContext';
import { ILocationContext, LocationContext } from './LocationContext';
import { CurrentPropertyContext, ICurrentPropertyContext } from './CurrentPropertyContext';

import {
  CurrentCompsetsPropertyContext,
  ICurrentCompsetsPropertyContext,
} from './CurrentCompsetsPropertyContext';

import {
  CompsPricesContext,
  ICompsPricesContext,
} from './CompsPricesContext';

export function useLocationContext(): ILocationContext {
  const context = React.useContext(LocationContext);

  if (context === undefined) {
    throw new Error('LocationContext must be used within a AppContextProvider');
  }

  return context;
}

export function useSideBarContext(): ISideBarContext {
  const context = React.useContext(SideBarContext);

  if (context === undefined) {
    throw new Error('SideBarContext must be used within a SideBarContextProvider');
  }

  return context;
}

export function useCurrentPropertyContext(): ICurrentPropertyContext {
  const context = React.useContext(CurrentPropertyContext);

  if (context === undefined) {
    throw new Error('CurrentPropertyContext must be used within a CurrentPropertyContextProvider');
  }

  return context;
}

export function useCurrentCompsetsPropertyContext(): ICurrentCompsetsPropertyContext {
  const context = React.useContext(CurrentCompsetsPropertyContext);

  if (context === undefined) {
    throw new Error('CurrentCompsetsPropertyContext must be used within a CurrentPropertyContextProvider');
  }

  return context;
}

export function useCompsPricesContext(): ICompsPricesContext {
  const context = React.useContext(CompsPricesContext);

  if (context === undefined) {
    throw new Error('CompsPricesContext must be used within a CurrentPropertyContextProvider');
  }

  return context;
}

export function useCheckboxCompsContext(): ICheckboxContext {
  const context = React.useContext(CheckboxContext);

  if (context === undefined) {
    throw new Error('CheckboxContext must be used within a CurrentPropertyContextProvider');
  }

  return context;
}
