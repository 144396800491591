import { MutableRefObject, useRef, useState } from 'react';

interface SetModalMobileMenuReturnParams {
  menuOpen: boolean;
  anchorRef: MutableRefObject<null>;
  handleDrawerOpen: VoidFunction;
  handleClose: VoidFunction;
  handleDrawerClose: (e: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => void;
}

export const useSetModalMobileMenu = (): SetModalMobileMenuReturnParams => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(anchorEl);

  const handleDrawerOpen = (): void => {
    setMenuOpen(true);
  };

  const handleDrawerClose = (e: React.MouseEvent<HTMLDivElement | Document, MouseEvent>): void => {
    e.stopPropagation();
    setMenuOpen(false);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return {
    menuOpen,
    anchorRef,
    handleClose,
    handleDrawerClose,
    handleDrawerOpen,
  };
};
