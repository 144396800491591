import { styled } from '@mui/material';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { BENCHMARK_TABLE_OFFSET } from 'common/theme/constants';

export const BenchmarkContainerStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.gray,
  height: '100%',
  width: '100%',
  padding: theme.spacing(3.5),
}));

export const BenchmarkTabsWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  padding: theme.spacing(5),
  overflow: 'auto',
  display: 'grid',
  gridTemplateColumns: '0.75fr 1.50fr',
  gap: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
  },
}));

export const SectionStyled = styled(Paper)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(1),
  overflow: 'auto',
}));

export const BenchmarkSectionStyled = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(3),
}));

export const BenchmarkTableWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  height: `calc(100%  - ${BENCHMARK_TABLE_OFFSET}px)`,
}));

export const TableTitleStyled = styled(Typography)({
  fontWeight: 700,
  width: 'inherit',
});

export const WrapperStyled = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
}));
export const WrapperStyledCharts = styled('div')(({ theme }) => ({
  minHeight: theme.spacing(100),
  padding: theme.spacing(8, 10),
  marginTop: theme.spacing(-5),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const TableStyled = styled('div')(() => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const PropWraper = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontSize: '20px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(-3),
}));
export const PropWraperLine = styled('div')(({ theme }) => ({
  textDecoration: 'underline',
  width: '100%',
  textAlign: 'center',
  fontSize: '20px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(-18),
}));
export const HorizontalText = styled('div')(({ theme }) => ({
  writingMode: 'vertical-rl',
  margin: theme.spacing(2),
  transform: 'rotate(180deg)',
}));

export const ChartWraper = styled('div')(() => ({
  width: '40%',
  flexDirection: 'column',
  flexWrap: 'wrap',
  display: 'flex',
  marginLeft: '8%',
  backgroundColor: 'white',
}));

export const TitleStyled = styled('div')(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: theme.spacing(4),
}));

export const CellInput = styled(TextField)(({ theme }) => ({
  fieldset: {
    border: 'none',
  },
  '& input': {
    color: theme.palette.text.primary,
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
}));

export const BenchmarkTableHeaderStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const ButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(1, 3),
}));

export const InfoButtonStyled = styled(ButtonStyled)(({ theme }) => ({
  color: theme.palette.blue.main,
  border: `1px solid ${theme.palette.blue.main}`,
  '&:hover': {
    color: theme.palette.blue.light,
    border: `1px solid ${theme.palette.blue.light}`,
  },
  '&:active': {
    color: theme.palette.blue.dark,
    border: `1px solid ${theme.palette.blue.dark}`,
  },
}));

export const InfoTableSectionStyled = styled(Paper)(({ theme }) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '85%',
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(5),
  outline: 'none',
}));

export const InfoTableWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  height: `calc(100%  - ${BENCHMARK_TABLE_OFFSET}px)`,
}));
