import { styled } from '@mui/material';

import TextField from '@mui/material/TextField';

import { MAP_HEIGHT_OFFSET, PINS_SIZE } from 'common/theme/constants';
import { PALETTE } from 'common/theme/palette/palette';

export const MapWrapperStyled = styled('div')(({ theme }) => ({
  height: `calc(100% - ${MAP_HEIGHT_OFFSET}px)`,
  [theme.breakpoints.down('sm')]: {
    minHeight: '400px',
  },
}));

export const FooterStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

export const FooterItemStyled = styled('div')(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(8),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  svg: {
    maxWidth: `${PINS_SIZE}px`,
    maxHeight: `${PINS_SIZE}px`,
    marginRight: theme.spacing(2),
  },
}));

// Due to  ReactDOMServer.renderToStaticMarkup() cannot use MUI
export const InfoBoxStyledStyled = styled('div')({
  width: '320px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: PALETTE.WHITE,
  borderRadius: '6px',
  border: `1px solid ${PALETTE.GRAY}`,
  svg: {
    maxWidth: '24px',
    maxHeight: '24px',
  },
});

export const InfoItemStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: PALETTE.WHITE,
});

export const InfoboxAdditionalInfoStyled = styled(InfoItemStyled)({
  alignItems: 'flex-start',
  paddingRight: '20px',
});

export const InfoTextStyled = styled('div')({
  marginLeft: '15px',
  marginBottom: '5px',
  fontStyle: 'normal',
  fontWeight: 'lighter',
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  flexDirection: 'column',
});

export const InfoButtonStyled = styled('button')({
  outline: 'none',
  border: 'none',
  backgroundColor: PALETTE.MEDIUM_BLUE,
  color: PALETTE.WHITE,
  lineHeight: '1.3',
  borderRadius: '6px',
  padding: '12px 27px',
  textTransform: 'uppercase',
  marginTop: '8px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: PALETTE.BLUE,
  },
  '&:active': {
    backgroundColor: PALETTE.DARK_BLUE,
  },
});

export const AddButtonStyled = styled(InfoButtonStyled)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'center',
  gap: '10px',
  fontSize: '16px',
  backgroundColor: PALETTE.SUCCESS,
  color: PALETTE.WHITE,
  '&:hover': {
    backgroundColor: PALETTE.PIN_GREEN,
  },
});

export const RemoveButtonStyled = styled(InfoButtonStyled)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'center',
  gap: '10px',
  backgroundColor: PALETTE.ERROR,
  color: PALETTE.WHITE,
  '&:hover': {
    backgroundColor: PALETTE.ERROR_LIGHT,
  },
});

export const SelectWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(50),
}));

export const InputStyled = styled(TextField)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  '&>.MuiInputLabel-outlined': {
    color: theme.palette.text.mediumGray,
    lineHeight: 0.5,
  },
}));

export const HeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

export const HeaderSectionStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
