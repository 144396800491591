import { memo } from 'react';

import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { TITLE_MAP } from 'common/components/Helmet/constants';

const HelmetComponent = ():JSX.Element => {
  const location = useLocation();

  let title = 'STATVAL™';

  if (TITLE_MAP.has(location.pathname)) {
    title = `${title} | ${TITLE_MAP.get(location.pathname)}`;
  }

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default memo(HelmetComponent);
