import { memo, useMemo } from 'react';

import { useIsFetching } from 'react-query';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import useTooltip from 'common/hooks/useTooltip';
import { QUERY_KEY } from 'common/query-keys';

import { ExtendedGridCellMetrocountProps } from 'containers/research/lib/models/types';
import { RESEARCH } from 'containers/research/utils/constants';
import { formattedDate } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/helpers';
import {
  useModalWindow,
} from 'containers/input-property/components/mapTab/propertyData/requiredTable/Buttons/hooks/useModalWindow';

import { COLUMN_TITLES } from './table-data';

import {
  TableHeadCellStyled,
  TableBodyCellStyled,
  ButtonStyled,
} from './styled';
import DialogWindowQuartiles from './DialogWindowQuartiles';

const CellRenderer = ({
  rowIndex, columnIndex, style, data, handlePrintData, handleQuartilesData,
}: ExtendedGridCellMetrocountProps): JSX.Element | null => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();
  const isFetching = useIsFetching([QUERY_KEY.RESEARCH]);
  const isQuartilesFetching = useIsFetching([QUERY_KEY.QUARTILES]);

  const {
    openWindow,
    handleOpen,
    handleClose,
  } = useModalWindow();

  const cellKey = useMemo(
    () => rowIndex + columnIndex + Math.floor(Math.random()),
    [rowIndex, columnIndex],
  );

  const printDataQueryParams = {
    region: data[rowIndex].metroDivisionRegion,
    subtype: data[rowIndex].subtype,
  };
  const printQuartilesQueryParams = {
    region: data[rowIndex].metroDivisionRegion,
    subtype: data[rowIndex].subtype,
  };
  const cellName = COLUMN_TITLES[columnIndex].cell;
  const cellData = data[rowIndex];
  const onHandlePrintData = (): void => {
    if (handlePrintData) { handlePrintData(printDataQueryParams); }
  };

  const onHandleQuartilesData = (): void => {
    if (handleQuartilesData) {
      handleOpen();
      handleQuartilesData(printQuartilesQueryParams);
    }
  };

  const entire = useMemo(() => (
    cellName === 'endDateMdraSubtype' && cellData[cellName] !== null
      ? formattedDate(cellData[cellName] as unknown as Date)
      : cellData[cellName]),
  [cellData, cellName]);

  if (rowIndex === 0) {
    return (
      <TableHeadCellStyled
        component="div"
        style={style}
        key={cellKey}
      >
        {COLUMN_TITLES[columnIndex].title}
        {columnIndex < COLUMN_TITLES.length - 1 && (
          <Box>
            {/** this part temporarily unavailable */}
            {/* <IconButton>
              <SortIconStyled />
            </IconButton>
            <IconButton>
              <FilterListIcon fontSize="small" />
            </IconButton> */}
          </Box>
        )}
      </TableHeadCellStyled>
    );
  }
  // MIGHT BE AVAILABLE SOON
  if (rowIndex > 0 && columnIndex === (COLUMN_TITLES.length - 4)) {
    return (
      <TableBodyCellStyled
        component="div"
        key={cellKey}
        style={style}
      >
        <DialogWindowQuartiles
          open={openWindow}
          close={handleClose}
        />
        <ButtonStyled
          disabled={!!isQuartilesFetching}
          variant="contained"
          color="secondary"
          onClick={onHandleQuartilesData}
        >
          {RESEARCH.BUTTON_TEXT.PRINT_QUARTILES}
        </ButtonStyled>
      </TableBodyCellStyled>
    );
  }

  if (rowIndex > 0 && columnIndex === (COLUMN_TITLES.length - 3)) {
    return (
      <TableBodyCellStyled
        component="div"
        key={cellKey}
        style={style}
      >
        <ButtonStyled
          disabled={!!isFetching}
          variant="contained"
          color="secondary"
          onClick={onHandlePrintData}
        >
          {RESEARCH.BUTTON_TEXT.PRINT_DATA}
        </ButtonStyled>
      </TableBodyCellStyled>
    );
  }

  if (cellName.length > 1) {
    return (
      <Tooltip open={isOpen} title={<>{cellData[cellName]}</>} arrow>
        <TableBodyCellStyled
          component="div"
          key={cellKey}
          style={style}
        >
          <Typography
            noWrap
            variant="body2"
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            {entire}
          </Typography>
        </TableBodyCellStyled>
      </Tooltip>
    );
  }

  return null;
};

export default memo(CellRenderer);
