import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from 'common/query-keys';
import { FilteredCompsets } from 'containers/compsets/lib/models/FilteredCompsets.interface';
import { CompsetsFromServer } from 'containers/compsets/lib/models/SubscribedCompsets.interface';
import { ISavedCompsets } from 'containers/compsets/lib/models/SavedCompsets.interface';
import { useGetSubscribedCompsets } from 'containers/compsets/lib/query-hooks';
import { useCompsPricesContext } from 'context/hooks';
import { EditCompContext } from 'containers/compsets/local-state/EditCompsetContextProvider';
import {
  PropretyUpdateModel,
  IMapData,
  IMappedData,
  IPropertyResponseData,
  PropUpdateModelPartial,
} from '../utils/types';
import { useGetPropertyData } from './query-hooks';
import { PINS, ViewPinType } from '../utils/constants';

export const useMappedMapPropertyData = (): IMapData[] => {
  const { data } = useGetPropertyData();

  const modifiedDataArray: IMapData[] = [];

  if (data) {
    data.forEach((itemData: IPropertyResponseData) => {
      const mappedPropertyData = {
        propertyName: itemData.propertyName,
        latitude: itemData.latitude.toString(),
        longitude: itemData.longitude.toString(),
        address: itemData.propertyAddress,
        state: itemData.propertyState,
        zip: itemData.propertyZipCode,
        county: itemData.propertyCounty,
        city: itemData.propertyCity,
        yearBuild: itemData.yearBuilt.toString(),
        yearRenov: itemData.yearRenov.toString(),
        numUnits: itemData.numUnits.toString(),
        subtype: itemData.subType,
        affordability: itemData.affordability,
        topLineRevYear: itemData.topLineFinancialsRevYear.toString(),
        fullLineItemFFYear: itemData.fullLineItemFinancialsFFYear.toString(),
        type: PINS.YOUR.TEXT,
        mfcCompId: itemData.mfcCompId,
        subjectCompOther: itemData.subjectCompOther,
      } as IMapData;
      modifiedDataArray.push(mappedPropertyData);
    });
  }

  return modifiedDataArray;
};

type PinsData = { [key: string]: IMapData[]; };

export const useFilteredPropertyData = (): PinsData => {
  const context = useContext(EditCompContext);
  const query = useQueryClient();

  const { state: { savedProperties: savedComps } } = useCompsPricesContext();
  const { data: subscribedCompsets } = useGetSubscribedCompsets();
  const filteredComps = query
    ?.getQueryData(QUERY_KEY.FILTERED_PROPERTIES) as { data: FilteredCompsets[]; };

  const savedCompsNames = savedComps?.map((item) => item.propertyName);
  const modifiedData: PinsData = { forPurchase: [], inCart: [], purchased: [] };

  // create flag about filterin on/off
  const filters = localStorage.getItem('filtersSet') || '';
  let filtersSet;
  let isFilteredComsSet = false;
  if (filters !== '') {
    filtersSet = JSON.parse(filters);
    isFilteredComsSet = filtersSet.filter2020FF
      || filtersSet.filter2020UW
      || filtersSet.filterAfforadable
      || filtersSet.filterSameSubtype
      || filtersSet.filterSimilarSubtype
      || filtersSet.filterUnits
      || filtersSet.filterYear;
  }

  const isAlgoritmsCompsOn = context?.state.isCompsetEdit as boolean;

  if (subscribedCompsets && Array.isArray(subscribedCompsets) && !isFilteredComsSet) {
    modifiedData.purchased = []; // with stroke and green circle pins
    modifiedData.forPurchase = []; // yellow pins
    modifiedData.inCart = []; // yellow pins with stroke
    (subscribedCompsets as CompsetsFromServer[]).forEach((itemData) => {
      if (+itemData.subjectCompOther === ViewPinType.YELLOW_STROKE
        || +itemData.subjectCompOther === ViewPinType.GREEN_STROKE) {
        const mappedPropertyData = {
          propertyName: itemData.propertyName,
          latitude: itemData.latitude.toString(),
          longitude: itemData.longitude.toString(),
          address: itemData.propertyAddress,
          state: itemData.propertyState,
          zip: itemData.propertyZipCode,
          county: itemData.propertyCounty,
          city: itemData.propertyCity,
          yearBuild: itemData.yearBuilt.toString(),
          yearRenov: itemData.yearRenov.toString(),
          numUnits: itemData.numUnits.toString(),
          subtype: itemData.subType,
          affordability: itemData.affordability,
          topLineRevYear: itemData.topLineFinancialsRevYear.toString(),
          fullLineItemFFYear: itemData.fullLineItemFinancialsFFYear.toString(),
          type: PINS.PURCHASED.TEXT,
          mfcCompId: itemData.mfcCompId,
          subjectCompOther: itemData.subjectCompOther,
        };
        modifiedData.purchased.push(mappedPropertyData);
      }
    });
  }

  if (savedComps && Array.isArray(savedComps) && !isFilteredComsSet) {
    modifiedData.inCart = [];
    (savedComps as ISavedCompsets[]).forEach((itemData: ISavedCompsets) => {
      const mappedPropertyData = {
        propertyName: itemData.propertyName,
        latitude: itemData.latitude.toString(),
        longitude: itemData.longitude.toString(),
        address: itemData.propertyAddress,
        state: itemData.propertyState,
        zip: itemData.propertyZipCode,
        county: itemData.propertyCounty,
        city: itemData.propertyCity,
        yearBuild: itemData.yearBuilt.toString(),
        yearRenov: itemData.yearRenov.toString(),
        numUnits: itemData.numUnits.toString(),
        subtype: itemData.subType,
        affordability: itemData.affordability,
        topLineRevYear: itemData.topLineFinancialsRevYear.toString(),
        fullLineItemFFYear: itemData.fullLineItemFinancialsFFYear.toString(),
        mfcCompId: itemData.mfcCompId,
        type: PINS.PROPERTY_IN_CART.TEXT,
        subjectCompOther: itemData.subjectCompOther,
      };
      modifiedData.inCart.push(mappedPropertyData);
    });
  }
  if (filteredComps && Array.isArray(filteredComps.data)) {
    modifiedData.forPurchase = [];
    (filteredComps.data as FilteredCompsets[]).forEach((itemData: FilteredCompsets) => {
      if (!savedCompsNames?.includes(itemData.propertyName)) {
        const mappedPropertyData: IMapData = {
          propertyName: itemData.propertyName,
          latitude: itemData.latitude.toString(),
          longitude: itemData.longitude.toString(),
          address: itemData.propertyAddress,
          state: itemData.propertyState,
          zip: itemData.propertyZipCode,
          county: itemData.propertyCounty,
          city: itemData.propertyCity,
          yearBuild: itemData.yearBuilt.toString(),
          yearRenov: itemData.yearRenov.toString(),
          numUnits: itemData.numUnits.toString(),
          subtype: itemData.subType,
          affordability: itemData.affordability,
          mfcCompId: itemData.mfcCompId,
          topLineRevYear: itemData.topLineFinancialsRevYear.toString(),
          fullLineItemFFYear: itemData.fullLineItemFinancialsFFYear.toString(),
          type: PINS.FOR_PURCHASE.TEXT,
          subjectCompOther: itemData.subjectCompOther,
        };

        if (!itemData.compStatus.includes('SUBSCRIBED')) {
          modifiedData.forPurchase.push(mappedPropertyData);
        }
      }
    });
  }
  if ((!isAlgoritmsCompsOn || isFilteredComsSet) && filteredComps && Array.isArray(filteredComps.data)) {
    modifiedData.purchased = []; // with stroke and green circle pins
    modifiedData.forPurchase = []; // yellow pins
    modifiedData.inCart = []; // yellow pins with stroke
    (filteredComps.data as FilteredCompsets[]).forEach((itemData: FilteredCompsets) => {
      const mappedPropertyData: IMapData = {
        propertyName: itemData.propertyName,
        latitude: itemData.latitude.toString(),
        longitude: itemData.longitude.toString(),
        address: itemData.propertyAddress,
        state: itemData.propertyState,
        zip: itemData.propertyZipCode,
        county: itemData.propertyCounty,
        city: itemData.propertyCity,
        yearBuild: itemData.yearBuilt.toString(),
        yearRenov: itemData.yearRenov.toString(),
        numUnits: itemData.numUnits.toString(),
        subtype: itemData.subType,
        affordability: itemData.affordability,
        mfcCompId: itemData.mfcCompId,
        topLineRevYear: itemData.topLineFinancialsRevYear.toString(),
        fullLineItemFFYear: itemData.fullLineItemFinancialsFFYear.toString(),
        type: PINS.FOR_PURCHASE.TEXT,
        subjectCompOther: itemData.subjectCompOther,
      };
      // Yellow/stroke/0
      if (+itemData.subjectCompOther === ViewPinType.YELLOW_STROKE) {
        modifiedData.forPurchase.push(mappedPropertyData);
        modifiedData.purchased.push(mappedPropertyData);
      }
      // Green/stroke/1
      if (+itemData.subjectCompOther === ViewPinType.GREEN_STROKE) {
        modifiedData.purchased.push(mappedPropertyData);
        modifiedData.inCart.push(mappedPropertyData);
      }
      // Yellow/2
      if (+itemData.subjectCompOther === ViewPinType.YELLOW) {
        modifiedData.forPurchase.push(mappedPropertyData);
      }
      // Green/3
      if (+itemData.subjectCompOther === ViewPinType.GREEN) {
        modifiedData.purchased.push(mappedPropertyData);
      }
    });
  }

  return modifiedData;
};

export const useMappedRequiredTableData = (propertyName: string | null): IMappedData | null => {
  const { data } = useGetPropertyData();
  const prop = data?.find((item: IPropertyResponseData) => item.propertyName === propertyName);

  let modifiedData: IMappedData | null = null;
  if (prop) {
    modifiedData = {
      propertyName: prop.propertyName,
      data: [
        prop.propertyName,
        prop.propertyAddress,
        prop.propertyCity,
        prop.propertyState,
        prop.propertyZipCode,
        prop.propertyCounty,
        prop.yearBuilt,
        prop.yearRenov,
        prop.numUnits,
        prop.subType,
        prop.affordability + (prop.affordabilityDetails || ''),
      ],
    };
  }

  return modifiedData;
};

export const useMappedPropNames = (): string[] => {
  const { data } = useGetPropertyData();

  const modifiedDataArray: string[] = [];

  if (data) {
    data.forEach((itemData: IPropertyResponseData) => {
      modifiedDataArray.push(itemData.propertyName);
    });
  }

  return modifiedDataArray;
};

export const usePureDataByID = (propertyName: string | null): IPropertyResponseData | undefined => {
  const { data } = useGetPropertyData();

  return data?.find((item: IPropertyResponseData) => item.propertyName === propertyName);
};

export const useDetailsTableData = (): IPropertyResponseData[] | string => {
  const { data, error } = useGetPropertyData();

  if (error) {
    return error.message;
  }
  return data as IPropertyResponseData[];
};

export const useMappedPostData = (): Partial<PropUpdateModelPartial>[] | null => {
  const { data } = useGetPropertyData();
  const modifiedDataArray: Partial<PropUpdateModelPartial>[] = [];

  if (data) {
    data.forEach((prop: PropretyUpdateModel) => {
      const modifiedData = {
        propertyName: prop.propertyName,
        propertyAddress: prop.propertyAddress,
        propertyCity: prop.propertyCity,
        propertyState: prop.propertyState,
        propertyZipCode: prop.propertyZipCode,
        propertyCounty: prop.propertyCounty,
        latitude: +prop.latitude,
        longitude: +prop.longitude,
        yearBuilt: +prop.yearBuilt,
        yearRenov: +prop.yearRenov,
        numUnits: +prop.numUnits,
        propertySubtypeAssigned: prop.subType,
        affordability: prop.affordability,
        valdate: prop.valuationDate as unknown as Date || new Date(),
        statementEndDateRev: prop.statementEndDateRev as unknown as Date || new Date(),
        occup: +prop.occup,
        rev: +prop.rev,
        exp: +prop.expense,
        capex0: +prop.capex,
        grosRnt: +prop.grosrnt,
        vacancy: +prop.vacancy,
        baseRnt: +prop.basernt,
        laundry: +prop.laundry,
        parking: +prop.parking,
        otherin: +prop.otherin,
        retaxes: +prop.retaxes,
        propins: +prop.propins,
        utiliti: +prop.utiliti,
        repairs: +prop.repairs,
        managem: +prop.managem,
        payroll: +prop.payroll,
        marketi: +prop.marketi,
        profess: +prop.profess,
        general: +prop.general,
        otherex: +prop.otherex,
        groundr: +prop.groundr,
        capex: +prop.capex,
        useTopLine: prop.useTopLine,
        useLineItem: prop.useLineItem,
        useBaseRnt: prop.useBaseRnt,
        useGrosRnt: prop.useGrosRnt,
        hcIncPvtray: +prop.hcIncPvtPay,
        hcIncMedcare: +prop.hcIncMedcare,
        hcIncNursing: +prop.hcIncNursing,
        hcIncMeals: +prop.hcIncMeals,
        hcExpRooms: +prop.hcExpRooms,
        hcExpMeals: +prop.hcExpMeals,
        uwValuation: +prop.valuationAmount,
        uwOccup: +prop.occup,
        uwRev: +prop.uwRev,
        uwExp: +prop.uwExp,
        uwCapex: +prop.uwCapex,
        uwCaprate: +prop.uwCaprate,
        solveForCaprate: prop.solveForCaprate,
        solveForVal: prop.solveForVal,
        useTrailingFinancials: prop.useTrailingFinancials,
      };
      modifiedDataArray.push(modifiedData);
    });
  }

  return modifiedDataArray;
};

export const useMappedDataPost = (): Partial<PropUpdateModelPartial>[] => {
  const { data } = useGetPropertyData();
  const modifiedDataArray: Partial<PropUpdateModelPartial>[] = [];

  if (data) {
    data.forEach((prop: IPropertyResponseData & PropretyUpdateModel) => {
      const modifiedData = {
        propertyName: prop.propertyName,
        propertyAddress: prop.propertyAddress,
        propertyCity: prop.propertyCity,
        propertyState: prop.propertyState,
        propertyZipCode: prop.propertyZipCode,
        propertyCounty: prop.propertyCounty,
        latitude: +prop.latitude,
        longitude: +prop.longitude,
        yearBuilt: +prop.yearBuilt,
        yearRenov: +prop.yearRenov,
        numUnits: +prop.numUnits,
        propertySubtypeAssigned: prop.subType,
        affordability: prop.affordability,
        valdate: prop.valuationDate as unknown as Date || new Date(),
        statementEndDateRev: prop.statementEndDateRev as unknown as Date || new Date(),
        occup: +prop.occup,
        rev: +prop.rev,
        exp: +prop.expense,
        capex0: +prop.capex,
        grosRnt: +prop.grosrnt,
        vacancy: +prop.vacancy,
        baseRnt: +prop.basernt,
        laundry: +prop.laundry,
        parking: +prop.parking,
        otherin: +prop.otherin,
        retaxes: +prop.retaxes,
        propins: +prop.propins,
        utiliti: +prop.utiliti,
        repairs: +prop.repairs,
        managem: +prop.managem,
        payroll: +prop.payroll,
        marketi: +prop.marketi,
        profess: +prop.profess,
        general: +prop.general,
        otherex: +prop.otherex,
        groundr: +prop.groundr,
        capex: +prop.capex,
        useTopLine: prop.useTopLine,
        useLineItem: prop.useLineItem,
        useBaseRnt: prop.useBaseRnt,
        useGrosRnt: prop.useGrosRnt,
        hcIncPvtpay: +prop.hcIncPvtPay,
        hcIncMedcare: +prop.hcIncMedcare,
        hcIncNursing: +prop.hcIncNursing,
        hcIncMeals: +prop.hcIncMeals,
        hcExpRooms: +prop.hcExpRooms,
        hcExpMeals: +prop.hcExpMeals,
        uwValuation: +prop.valuationAmount,
        uwOccup: +prop.occup,
        uwRev: +prop.uwRev,
        uwExp: +prop.uwExp,
        uwCapex: +prop.uwCapex,
        uwCaprate: +prop.uwCaprate,
        uwCaprateInput: +prop.uwCaprateInput,
        solveForCaprate: prop.solveForCaprate,
        solveForVal: prop.solveForVal,
        useTrailingFinancials: prop.useTrailingFinancials,
      };
      modifiedDataArray.push(modifiedData);
    });
  }

  return modifiedDataArray;
};
