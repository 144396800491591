import { useScreenDown } from 'common/hooks/screenSize';

import { useSideBarContext } from 'context/hooks';
import { SideBarActionType } from 'context/SideBarContext';

import { TEXT } from './utils/constants';

import {
  NotFoundContainer, LinkStyled, NotFoundTitleStyled, NotFoundTextStyled,
} from './utils/styled';

export const NotFound = ():JSX.Element => {
  const isDownMd = useScreenDown('md');
  const { dispatch } = useSideBarContext();

  const handleClickBackHome = (): void => {
    dispatch(
      {
        type: SideBarActionType.SideBarOpen,
        shouldSideBarBeDisplayed: false,
      },
    );
  };

  return (
    <NotFoundContainer $downMd={isDownMd}>
      <NotFoundTitleStyled>
        {TEXT.NOT_FOUND}
      </NotFoundTitleStyled>
      <NotFoundTextStyled>
        {TEXT.PAGE_TEXT}
      </NotFoundTextStyled>
      <LinkStyled to="/home" onClick={handleClickBackHome}>
        {TEXT.BACK_BUTTON}
      </LinkStyled>
    </NotFoundContainer>
  );
};
