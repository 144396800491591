import { PropUpdateModelPartial } from 'common/components/BingMap/utils/types';
import {
  EditColumnAction,
  EditColumnState,
} from './EditColumnContext';

export function editColumnReducer(state: EditColumnState, action: EditColumnAction): {
  mapOfProps: Map<string, PropUpdateModelPartial>;
} {
  const newState = { ...state };

  switch (action.type) {
    case 'update':
      if (newState.mapOfProps.has(action.propName)) {
        newState.mapOfProps?.set(
          action.propName,
          Object.assign(newState.mapOfProps.get(action.propName), { [action.fieldName]: action.value }),
        );
      } else if (action.fieldName && action.propName) {
        newState.mapOfProps?.set(action.propName, {
          propertyName: action.propName,
          [action.fieldName]: action.value,
        } as unknown as PropUpdateModelPartial);
      }

      return newState;
    case 'clear':
      newState.mapOfProps?.clear();
      return newState;
    default:
      return state;
  }
}
