import { memo } from 'react';

import Grid from '@mui/material/Grid';

import { FilterMenu } from 'containers/benchmark/components/SHARED/filterMenu/FilterMenu';
import { useHeaderCellModal } from 'containers/benchmark/components/SHARED/utils/hooks';

import { HeadLabelWrapperTableStyled } from 'containers/buy-sell-credits/admin/table/styled';

const HeaderCell = ({ text, columns }:{ text: string, columns: string[] }): JSX.Element => {
  const {
    anchorEl,
    open,
    onClose,
  } = useHeaderCellModal();

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        wrap="nowrap"
      >
        <Grid
          xs={10}
          item
          container
          direction="row"
          wrap="nowrap"
        >
          <HeadLabelWrapperTableStyled>
            {text}
          </HeadLabelWrapperTableStyled>
          {/** this part temporarily unavailable */}
          {/* <SortButtonStyled size="small">
            <SortIcon fontSize="small" />
          </SortButtonStyled> */}
        </Grid>
        {/* <Grid item>
          <IconButton
            size="small"
            onClick={handleClick}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <FilterListIcon />
          </IconButton>
        </Grid> */}
      </Grid>
      <FilterMenu
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        columns={columns}
      />
    </>
  );
};

export default memo(HeaderCell);
