//= ============= Details tab styles ===============

import { styled, Typography } from '@mui/material';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface DisplaySize {
  $isDownLg: boolean;
}
interface DisplayColor {
  color?: string;
}

export const DetailsTabContainerStyled = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(6),
}));

export const DisplayButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.secondary.main,
  margin: theme.spacing(2),
  maxWidth: theme.spacing(60),
  '&:hover': {
    backgroundColor: theme.palette.success.light,
  },
  '&:active': {
    backgroundColor: theme.palette.success.dark,
  },
}));

export const CheckboxCellStyled = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
}));

export const CheckboxStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '25px',
  display: 'flex',
  marginTop: theme.spacing(3.5),
  justifyContent: 'start',
}));

export const TableWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  height: '100%',
  paddingBottom: theme.spacing(12),
}));

export const TableStyled = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  height: '100%',
  overflow: 'hidden',
  marginTop: theme.spacing(4),
}));

export const ButtonCell = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
}));

export const FixedCell = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  backgroundColor: theme.palette.background.lightGray,
  color: theme.palette.text.mediumGray,
  textTransform: 'uppercase',
  height: '70px',
  alignItems: 'center',
  textOverflow: 'ellipsis !important',
  whiteSpace: 'nowrap',
  width: '100px',
  overflow: 'hidden',
  display: 'block',
}));

export const FixedInfoCell = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderRight: `3px solid ${theme.palette.background.blue}`,
  color: theme.palette.text.mediumGray,
  textTransform: 'uppercase',
  height: '70px',
  alignItems: 'center',
  textAlign: 'center',
  textOverflow: 'ellipsis !important',
  whiteSpace: 'nowrap',
  width: '100px',
  overflow: 'hidden',
  display: 'block',
}));

export const FixedFreezedCell = styled(FixedInfoCell)(({ theme }) => ({
  color: '#131212',
  borderRight: `3px solid ${theme.palette.background.blue}`,
}));

export const FixedItemCell = styled(FixedInfoCell)(({ theme }) => ({
  fontFamily: 'OpenSans-SemiBold',
  color: '#131212',
  borderBottom: `0px solid ${theme.palette.background.borderLight}`,
  paddingTop: '9px',
}));

export const FixedCellButton = styled(FixedCell)({
  textAlign: 'center',
});

export const FixedCellInfoButton = styled(FixedInfoCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  textAlign: 'center',
}));

export const FixedCellTable = styled(FixedCell)({
  fontSize: '11px',
});

export const BlindCell = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  textOverflow: 'ellipsis !important',
  fontSize: '10px',
}));

export const Cell = styled('div')<DisplayColor>(({ theme, color }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  display: 'flex',
  color: color === 'green' ? 'green' : 'black',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const TypographyTable = styled(Typography)<DisplayColor>(({ color }) => ({
  fontSize: '12px',
  color: color === 'red' ? 'red' : 'unset',
}));

export const CellInput = styled(TextField)({
  fieldset: {
    border: 'none',
    outline: 'none',
  },
  '& input': {
    fontSize: '16px',
    color: '#131212',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
});

export const ButtonStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== '$isDownLg',
})<DisplaySize>(({ theme, $isDownLg }) => ({
  fontSize: '12px',
  width: $isDownLg ? '100%' : '250px',
  marginRight: $isDownLg ? 0 : theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

export const ButtonStyledPDF = styled(Button, {
  shouldForwardProp: (propName) => propName !== '$isDownLg',
})<DisplaySize>(({ theme, $isDownLg }) => ({
  fontSize: '12px',
  width: '250px',
  marginRight: $isDownLg ? 0 : theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

export const ButtonChartStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== '$isDownLg',
})<DisplaySize>(({ theme, $isDownLg }) => ({
  fontSize: '12px',
  width: '95%',
  marginRight: $isDownLg ? 0 : theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));
