import React, { useMemo } from 'react';

import { ROUTE } from 'common/components/routes/utils/constants';

import { MainPageTabContext } from 'containers/main/app-bar/local-state/MainPageTabContext';
import { setMainPageTabClickedReducer } from 'containers/main/app-bar/local-state/reducers';
import { setPathReducer } from './modalReducers';
import { tabBarReducer } from './tabBarReducer';
import { SideBarContext } from './SideBarContext';
import { LocationContext } from './LocationContext';
import { setCurrentPropertyReducer } from './currentPropertyReducers';
import { CurrentPropertyContext } from './CurrentPropertyContext';

import { CurrentCompsetsPropertyContext } from './CurrentCompsetsPropertyContext';
import { setCurrentCompsetsPropertyReducer } from './currentCompsetsPropertyReducers';

import { priceReducer } from './priceReducer';
import { CompsPricesContext } from './CompsPricesContext';

export const AppContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [location, setLocation] = React.useReducer(setPathReducer, { path: ROUTE.HOME_DEFAULT });
  const [priceState, setPriceState] = React.useReducer(
    priceReducer, { prices: [], totalPrice: 0, savedProperties: [] },
  );
  const [currentProperty, setCurrentProperty] = React.useReducer(
    setCurrentPropertyReducer, { currentPropertyEvent: null, currentProperty: null },
  );
  const [isTabClicked, setTabClicked] = React.useReducer(
    setMainPageTabClickedReducer, { isClicked: false },
  );
  const [currentCompsetsProperty, setCurrentCompsetsProperty] = React.useReducer(
    setCurrentCompsetsPropertyReducer,
    {
      currentCompsetsPropertyEvent: null,
      currentCompsetsProperty: null,
      currentCompsetsId: null,
    },
  );
  const [tabState, tabDispatch] = React.useReducer(
    tabBarReducer,
    { sideBarOpen: false, shouldSideBarBeDisplayed: false },
  );

  const locationContextValue = useMemo(() => ({
    state: location,
    dispatch: setLocation,
  }), [location]);

  const currentPropertyContextValue = useMemo(() => ({
    state: currentProperty,
    dispatch: setCurrentProperty,
  }), [currentProperty]);

  const tabClickedContextValue = useMemo(() => ({
    state: isTabClicked,
    dispatch: setTabClicked,
  }), [isTabClicked]);

  const currentCompsetsPropertyContextValue = useMemo(() => ({
    state: currentCompsetsProperty,
    dispatch: setCurrentCompsetsProperty,
  }), [currentCompsetsProperty]);

  const sideBarContextValue = useMemo(() => ({
    state: tabState,
    dispatch: tabDispatch,
  }), [tabState]);

  const compsPricesContextValue = useMemo(() => ({
    state: priceState,
    dispatch: setPriceState,
  }), [priceState]);

  return (
    <LocationContext.Provider value={locationContextValue}>
      <CompsPricesContext.Provider value={compsPricesContextValue}>
        <SideBarContext.Provider value={sideBarContextValue}>
          <MainPageTabContext.Provider value={tabClickedContextValue}>
            <CurrentPropertyContext.Provider value={currentPropertyContextValue}>
              <CurrentCompsetsPropertyContext.Provider value={currentCompsetsPropertyContextValue}>
                {children}
              </CurrentCompsetsPropertyContext.Provider>
            </CurrentPropertyContext.Provider>
          </MainPageTabContext.Provider>
        </SideBarContext.Provider>
      </CompsPricesContext.Provider>
    </LocationContext.Provider>
  );
};
