import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    fontSizeSmall: {
      fontSize: 16,
    },
    fontSizeMedium: {
      fontSize: 16,
    },
    colorAction: {
      color: PALETTE.BLUE,
    },
    colorPrimary: {
      color: PALETTE.SUCCESS,
    },
    colorDisabled: {
      color: PALETTE.GRAY,
    },
    colorSecondary: {
      color: `${PALETTE.WHITE} !important`,
    },
  },
};
