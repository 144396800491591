export const TEXT = {
  LOGOUT_TITLE: 'Log-In from another computer will Log-Out your session',
  TITLE: 'STATVAL™',
  DESCRIPTION: 'Automated Underwriting',
  SEARCH_INPUT: 'Input property address or zip code',
  BUTTON: {
    CLIENT_PROP: 'Analyze properties and markets',
    BUY_AND_SELL: 'Buy comp credits',
    SEARCH: 'Search',
  },
} as const;

export const INPUT_SEARCH_TIMEOUT = 300 as const;

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
} as const;
