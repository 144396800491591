export const PALETTE = {
  BLUE: '#1D86E7',
  MEDIUM_BLUE: '#007CBE',
  DARK_BLUE: '#0B223C',
  GRAY: '#C4C4C4',
  DARKER_GRAY: '#D8D8D8',
  LIGHTER_GRAY: '#F5F5F5',
  LIGHT_GRAY: '#BFC9D1',
  BACKGROUND_GRAY: '#E5E5E5',
  BACKGROUND_LIGHT_GRAY: '#F6F7F9',
  GRAY_BLUE: '#51728E',
  DARK_GRAY: '#737984',
  MEDIUM_GRAY: '#535E67',
  WHITE: '#FFFFFF',
  BLACK: '#131212',
  TEXT_DISABLED: '#85939F',
  ERROR: '#EA001B',
  ERROR_LIGHT: '#ff001d',
  SUCCESS: '#0FA958',
  BACKDROP_COLOR: '#26323833',
  TRANSPARENT: 'transparent',
  PIN_YELLOW: '#F2CB41',
  PIN_YELLOW_LIGHT: '#F1EBB0',
  PIN_GREEN: '#3EBF7A',
  TURQUOISE: '#26A69A',
  DARKER_GREEN: ' #00733B',
};
