import { TableTitle } from 'containers/research/lib/models/types';

export const FIXED_COLUMN_COUNT = 0 as const;
export const FIXED_ROW_COUNT = 1 as const;
export const ROW_HEIGHT = 48 as const;

export const COLUMN_TITLES: TableTitle[] = [
  { title: 'METRO_DIVISION_REGION', width: 270, cell: 'metroDivisionRegion' },
  { title: 'SUBTYPE  ', width: 150, cell: 'subtype' },
  { title: 'NUM_PROPS', width: 172, cell: 'numPropsMdraSubtype' },
  { title: 'EXPIRATION', width: 170, cell: 'endDateMdraSubtype' },
];

export const WIDTH = COLUMN_TITLES.reduce(
  (acc, columnWidth) => acc + columnWidth.width, 0,
);

export const HEIGHT = ROW_HEIGHT * COLUMN_TITLES.length;
