export const TABS = {
  DISPLAY_INPUT_PROPERTY_MAP: 'DISPLAY INPUT PROPERTY MAP',
  DISPLAY_INPUT_PROPERTY_DETAIL: 'DISPLAY INPUT PROPERTY  DETAIL',
} as const;

export const BUTTONS_TEXT = {
  INSERT: 'insert property into client portfolio',
  CANCEL: 'cancel',
  MODIFY: 'Modify atributes and save',
  DELETE: 'Archive property',
  DISPLAY_PROPERTY_DATA: 'DISPLAY PROPERTY DATA',
} as const;

export const TITLE_MODAL_TEXT = {
  TITLE: 'Notification',
};

export const CONFIRMATION_MODAL_TEXT = {
  ARCHIVE: 'Do you want to archive this property?',
  MODIFY: 'Do you want to modify atributes and save?',
};

export const BUTTONS_MODAL_TEXT = {
  AGREEMENT: 'YES',
  DISAGREEMENT: 'NO',
};

export const SUB_TYPES = [
  'GARDEN',
  'MIDRISE',
  'HIRISE',
  'SENIOR',
  'HEALTHCARE',
  'STUDENT',
  'MILITARY',
  'MANUF',
] as const;

export const AFFORDABILITY = [
  'AFFORDABLE',
  'MARKETRATE',
] as const;

export const NUMBER_INPUTS = [
  'ZIP',
  'YEAR BUILT',
  'YEAR RENOV',
  'NUM UNITS',
];

export type PropertyDataTableFields = {
  ['PROPERTY NAME']: { value: string | undefined; };
  ADDRESS: { value: string | undefined; };
  CITY: { value: string | undefined; };
  ['STATE (abbrev)']: { value: string | undefined; };
  ZIP: { value: number | undefined; };
  COUNTY: { value: string | undefined; };
  ['YEAR BUILT']: { value: number | undefined; };
  ['YEAR RENOV']: { value: number | undefined; };
  ['NUM UNITS']: { value: number | undefined; };
  SUBTYPE: { value: string | undefined; };
  AFFORDABILITY: { value: string | undefined; };
};

export const REQUIRED_TABLE = {
  TITLE: 'REQUIRED: Name and Attributes',
  ROWS: [
    'PROPERTY NAME',
    'ADDRESS',
    'CITY',
    'STATE (abbrev)',
    'ZIP',
    'COUNTY',
    'YEAR BUILT',
    'YEAR RENOV',
    'NUM UNITS',
    'SUBTYPE',
    'AFFORDABILITY',
  ],
  REQUIRED_FIELDS: [
    'PROPERTY NAME',
    'ADDRESS',
    'CITY',
    'STATE (abbrev)',
    'YEAR BUILT',
    'YEAR RENOV',
    'NUM UNITS',
    'SUBTYPE',
  ],
} as const;

export type RequiredFields =

'PROPERTY NAME'|
    'ADDRESS'|
    'CITY'|
    'STATE (abbrev)'|
    'YEAR BUILT'|
    'YEAR RENOV'|
    'NUM UNITS'|
    'SUBTYPE';
