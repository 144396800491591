import { memo, useMemo, useReducer } from 'react';

import { InfoTablePopupContext } from 'containers/benchmark/local-state/InfoTablePopupContext';
import { SelectClientPortfolioNameContext } from './SelectClientPortfolioNameContext';
import {
  setCallbackReducer, setInfoTablePopupReducer, setPortfolioNameReducer, setRadioSelectionReducer,
} from './reducers';
import { RadioBenchmarkContext } from './RadioBenchmarkContext';
import { BenchmarkAnalyticsCallbackContext } from './BenchmarkAnalyticsCallbackContext';

interface ReactWindowTableProviderProps{
  children: React.ReactNode;
}

const BenchmarkProvider = memo(({ children }: ReactWindowTableProviderProps) => {
  const [popupState, popupDispatch] = useReducer(setInfoTablePopupReducer, { open: false });
  const [portfolioNameState, porfolioNameDispatch] = useReducer(setPortfolioNameReducer, { value: '' });
  const [radioState, radioDispatch] = useReducer(setRadioSelectionReducer,
    { value: 'compsetByMfcAlgorithm' });
  const [analyticsCallbackState, analyticsCallbackDispatch] = useReducer(setCallbackReducer,
    { callback: null });

  const popupContextValue = useMemo(() => ({
    state: popupState,
    dispatch: popupDispatch,
  }), [popupState]);

  const clientPortfolioNameContextValue = useMemo(() => ({
    state: portfolioNameState,
    dispatch: porfolioNameDispatch,
  }), [portfolioNameState]);

  const radioContextValue = useMemo(() => ({
    state: radioState,
    dispatch: radioDispatch,
  }), [radioState]);

  const callbackContextValue = useMemo(() => ({
    state: analyticsCallbackState,
    dispatch: analyticsCallbackDispatch,
  }), [analyticsCallbackState]);

  return (
    <BenchmarkAnalyticsCallbackContext.Provider value={callbackContextValue}>
      <InfoTablePopupContext.Provider value={popupContextValue}>
        <SelectClientPortfolioNameContext.Provider value={clientPortfolioNameContextValue}>
          <RadioBenchmarkContext.Provider value={radioContextValue}>
            {children}
          </RadioBenchmarkContext.Provider>
        </SelectClientPortfolioNameContext.Provider>
      </InfoTablePopupContext.Provider>
    </BenchmarkAnalyticsCallbackContext.Provider>
  );
});

export default BenchmarkProvider;
