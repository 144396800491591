import * as React from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useScreenDown } from 'common/hooks/screenSize';
import { Typography } from '@mui/material';
import { ButtonStyledBenchmark } from './styled';

interface CustomListMenuProps {
  labelText: string | JSX.Element;
  children?: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLElement>)=>void
  isWide?: boolean
}

const CustomListMenuBenchmark = React.memo((
  {
    labelText,
    children,
    handleClick,
    isWide,
  }:CustomListMenuProps,
): JSX.Element => {
  const isDownMd = useScreenDown('lg');

  return (
    <Box>
      <ButtonStyledBenchmark
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        $isDownMd={isWide ?? isDownMd}
      >
        <Typography variant="inherit" noWrap>
          {labelText}
        </Typography>
      </ButtonStyledBenchmark>
      {children}
    </Box>
  );
});

export default CustomListMenuBenchmark;
