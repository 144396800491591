import {
  createContext,
  memo,
  useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';

import { useForm } from 'common/hooks/useForm';
import { useLocationContext } from 'context/hooks';
import { useAuth } from '../lib/auth';

import { LoginPage } from './LoginPage';
import { SignInPage } from './SignInPage';

import { LoginType } from '../utils/types';
import { TEXT } from '../utils/constants';

import {
  ButtonWrapperStyled,
  HeaderStyled,
  LoginPaperStyled,
  LoginPaperWrapper,
} from './styled';

export const LoginContext = createContext<(unlock: boolean) => void>((unlock: boolean) => unlock);

export const AuthComponent = memo((): JSX.Element => {
  const history = useHistory();

  const [mode, setMode] = useState<LoginType>('login');
  const [disableRegistry, setDisableRegistry] = useState<boolean>(true);

  const { state } = useLocationContext();

  const { values, onChange } = useForm({});

  const {
    login,
    register,
    isLoggingIn,
    isRegistering,
  } = useAuth();

  const isLoginMode = mode === 'login';

  const onResetRegMode = (): void => {
    setMode(isLoginMode ? 'register' : 'login');
  };

  const onSubmitClickHandle = async (e: React.FormEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    try {
      if (isLoginMode) await login(values);
      else await register(values);
      history.push(state.path);
    } catch (err) {
      if (err instanceof Error) SnackBarUtils.error(err.message);
    }
  };

  const onEnableRegisterButton = useCallback((unlock: boolean): void => {
    setDisableRegistry(() => unlock);
  }, []);

  return (
    <LoginContext.Provider value={onEnableRegisterButton}>
      <LoginPaperWrapper>
        <LoginPaperStyled
          component="form"
          onSubmit={onSubmitClickHandle}
        >
          <HeaderStyled variant="h4" color="textPrimary">
            {isLoginMode ? TEXT.HEADER.LOGIN : TEXT.HEADER.CREATE}
          </HeaderStyled>
          <Grid container direction="column">
            {isLoginMode
              ? <LoginPage onChange={onChange} />
              : <SignInPage onChange={onChange} />}
            <ButtonWrapperStyled>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
                disabled={(!isLoginMode && disableRegistry) || isLoggingIn || isRegistering}
              >
                {isLoginMode
                  ? TEXT.LOGIN_NOW.toUpperCase()
                  : TEXT.REGISTER_NOW.toUpperCase()}

              </Button>
            </ButtonWrapperStyled>
            <Grid item container direction="row" justifyContent="center">
              <Typography variant="subtitle2">
                { isLoginMode ? TEXT.NO_ACCOUNT : TEXT.HAVE_ACCOUNT }
              </Typography>
              &nbsp;
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="body2"
                color="textSecondary"
                onClick={onResetRegMode}
              >
                {isLoginMode ? TEXT.CREATE_ONE : TEXT.LOGIN}
              </Typography>
            </Grid>
          </Grid>
        </LoginPaperStyled>
      </LoginPaperWrapper>
    </LoginContext.Provider>
  );
});
