import { defaultFilters } from '../utils/constants';
import { FiltersActionType, Action, State } from './FiltersContext';

export function filtersReducer(state: State, action: Action): State {
  switch (action.type) {
    case FiltersActionType.Change:
      if (action.newFilters) return action.newFilters;

      return state;
    case FiltersActionType.Reset:

      return defaultFilters;
    default:

      return state;
  }
}
