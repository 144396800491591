import { styled } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',

  '& .MuiDialogActions-root': {
    padding: theme.spacing(7.5),
    alignSelf: 'left',
  },
}));

export const ModalBodyStyled = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(5),

  '.MuiButtonBase-root': {
    marginTop: theme.spacing(-15),
    marginRight: theme.spacing(-5),
    backgroundColor: theme.palette.secondary.light,
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  right: theme.spacing(5),
  padding: 0,
}));
