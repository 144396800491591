import { styled } from '@mui/material';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const FormStyled = styled('form')({
  width: '100%',
  height: 560,
  flexDirection: 'column',
  overflow: 'auto',
});

export const ModalContentContainerStyled = styled('div')(({ theme }) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  maxWidth: 584,
  maxHeight: 840,
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(6),
  outline: 'none',
}));

export const SaveAndCancelButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
}));

export const GridItemStyled = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(6),
}));

export const GridItemCheckboxContainerStyled = styled(GridItemStyled)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  marginBottom: theme.spacing(-4),

  '.MuiIconButton-label': {
    height: '22px',
    width: '22px',
  },
}));

export const CheckboxCheckedIconWrapperStyled = styled('div')({
  paddingLeft: 'auto',
});

export const InputLabelStyled = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.75),
}));

export const InputLabelCheckBoxStyled = styled(InputLabelStyled)({
  paddingBottom: 0,
});

export const GeneratePwStyled = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const GeneratePwContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.spacing(1.75),
  cursor: 'pointer',
  '&>svg': {
    width: 12,
    height: 12,
    marginTop: theme.spacing(0.5),
  },
}));

export const InfoTextStyled = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));
