import { memo } from 'react';

import Typography from '@mui/material/Typography';

import MapIcon from 'common/components/icons/bing-map/MapIcon';
import { PINS } from 'common/components/BingMap/utils/constants';

import { FooterItemStyled, FooterStyled } from './styled';

const Footer = ():JSX.Element => (
  <FooterStyled>
    <FooterItemStyled>
      <MapIcon colors={PINS.YOUR.COLORS} />
      <Typography>{PINS.YOUR.TEXT}</Typography>
    </FooterItemStyled>
  </FooterStyled>
);

export default memo(Footer);
