import { FONTS } from '../fonts';
import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    root: {
      fontSize: 18,
      fontFamily: FONTS.OPEN_SANS_REG,
      fontWeight: 100,
      color: PALETTE.BLACK,
      minWidth: 'auto',
      padding: '0 14px',
      paddingBottom: '33px',
      lineHeight: 1,
      minHeight: 35,
      '&$selected': {
        color: PALETTE.MEDIUM_BLUE,
      },
    },
    textColorInherit: {
      color: PALETTE.BLACK,
      opacity: 1,
    },
    wrapper: {
      fontFamily: FONTS.OPEN_SANS_REG,
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    labelIcon: {
      minHeight: '48px',
    },
  },
};
