import { memo } from 'react';
import { GridItemStyled } from '../styled';

interface DesktopMenuProps {
  children: React.ReactNode;
}

export const DesktopMenu = memo(({ children }: DesktopMenuProps) => (
  <>
    <GridItemStyled
      item
      container
      xs={1}
      justifyContent="flex-start"
    />
    <GridItemStyled
      item
      container
      xs={9}
      justifyContent="flex-end"
    >
      {children}
    </GridItemStyled>
  </>
));
