import { TableTitle } from 'containers/research/lib/models/types';

export const FIXED_COLUMN_COUNT = 0 as const;
export const FIXED_ROW_COUNT = 0 as const;
export const ROW_HEIGHT = 28 as const;
export const COLUMN_WIDTH = 32 as const;
export const COLUMN_TITLE_WIDTH = 270 as const;

export const COLUMN_TITLES: TableTitle[] = [
  { title: 'METRO_DIVISION_REGION', width: 160, cell: 'metroDivisionRegion' },
  { title: 'SUBTYPE  ', width: 160, cell: 'subtype' },
  { title: '', width: 160, cell: '' },
  { title: '', width: 160, cell: '' },
  { title: 'NUM_PROPS', width: 160, cell: 'numPropsMdraSubtype' },
  { title: 'EXPIRATION', width: 160, cell: 'endDateMdraSubtype' },
];
