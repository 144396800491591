import { createContext } from 'react';

export interface IButtonContext {
  isDisabled: boolean;
  buttonDispatch: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ButtonAction = {newValue: boolean};
export type ButtonDispatch = (action: ButtonAction) => void;
export type ButtonState = { isDisabled: boolean };

export const ButtonContext = createContext<
  { state: ButtonState; dispatch: ButtonDispatch; } | undefined
  >(undefined);

createContext<(ButtonState & ButtonDispatch) | undefined>(undefined);
