import { memo, useMemo } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { ROUTE } from 'common/components/routes/utils/constants';

import TextSection from 'containers/CMBS/TextSection';
import ImageSection from 'containers/CMBS/ImageSection';

import { CMBSContainerStyled, CMBSSectionStyled, CMBSTabsWrapper } from 'containers/CMBS/styled';

const CMBS = ():JSX.Element => {
  const history = useHistory();

  const routerPath = history.location.pathname;

  const isWrongInputPropertyPath = useMemo(() => Object.values([
    ROUTE.ACCOUNT.CMBS,
    ROUTE.ACCOUNT.CMBS,
  ]).every((path) => path !== routerPath),
  [routerPath]);

  return (
    <>
      {isWrongInputPropertyPath
        ? <Redirect to={ROUTE.NOT_FOUND} />
        : (
          <CMBSContainerStyled>
            <CMBSTabsWrapper>
              <CMBSSectionStyled>
                <TextSection />
              </CMBSSectionStyled>
              <CMBSSectionStyled>
                <ImageSection />
              </CMBSSectionStyled>
            </CMBSTabsWrapper>
          </CMBSContainerStyled>
        )}
    </>
  );
};

export default memo(CMBS);
