import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from 'common/axios';
import { COMPSETS_URL } from 'common/globalConstants';
import { RadioSelectionValue } from '../components/list/utils/types';
import { BenchmarkAnalyticsModel } from './models/BencmarkAnalytics.model';
import { BenchmarkAnalyticsInfoModel } from './models/BencmarkAnalyticsInfo.model';
import { BenchmarkAnalyticsSubscriptionModel } from './models/BencmarkAnalyticsSubscription.model';
import { ClientProperty } from './models/ClientProperty.model';

import { Endpoint } from './utils/endpoint';

const axios = axiosInstance;

export const getPropertyNames = async ():
  Promise<string[]> => new Promise<string[]>(
    (resolve, reject) => {
      axios.get<string[]>(Endpoint.PropertyNames)
        .then((response: AxiosResponse<string[]>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const getClientPropertyBy = async (name: string):
  Promise<ClientProperty> => new Promise<ClientProperty>(
    (resolve, reject) => {
      axios.get<ClientProperty>(`${Endpoint.Property}/${name}`, { params: { name } })
        .then((response: AxiosResponse<ClientProperty>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const getClientCompsetsPropertyBy = async (name: string):
  Promise<BenchmarkAnalyticsInfoModel> => new Promise<BenchmarkAnalyticsInfoModel>(
    (resolve, reject) => {
      axios.get<BenchmarkAnalyticsInfoModel>(`${Endpoint.Compsets}/${name}/properties`)
        .then((response: AxiosResponse<BenchmarkAnalyticsInfoModel>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const getClientCompsetsPropertyByAlgo = async (name: string):
  Promise<BenchmarkAnalyticsInfoModel> => new Promise<BenchmarkAnalyticsInfoModel>(
    (resolve, reject) => {
      axios.get<BenchmarkAnalyticsInfoModel>(`${Endpoint.Compsets}/${COMPSETS_URL.ALGORITHM}${name}`)
        .then((response: AxiosResponse<BenchmarkAnalyticsInfoModel>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const getbenchmarkAnalytics = async (
  { selection, propName }: { selection: RadioSelectionValue, propName: string; },
):
  Promise<BenchmarkAnalyticsModel[]> => new Promise<BenchmarkAnalyticsModel[]>(
    (resolve, reject) => {
      axios.get<BenchmarkAnalyticsModel[]>(`${Endpoint.BenchmarkAnalytics}/${selection}/${propName}`)
        .then((response: AxiosResponse<BenchmarkAnalyticsModel[]>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const postbenchmarkAnalyticsSubscription = async (name: string):
  Promise<BenchmarkAnalyticsSubscriptionModel> => new Promise<BenchmarkAnalyticsSubscriptionModel>(
    (resolve, reject) => {
      axios.post<BenchmarkAnalyticsSubscriptionModel>(`${Endpoint.Subscription}/?propertyName=${name}`)
        .then((response: AxiosResponse<BenchmarkAnalyticsSubscriptionModel>) => resolve(response.data))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );
