import defaultTheme from '../defaultTheme';
import { BORDER_RADIUS } from '../shape';

export default {
  styleOverrides: {
    input: {
      padding: defaultTheme.spacing(3, 4),
    },
    root: {
      '&:hover': {
        backgroundColor: defaultTheme.palette.background.white,
      },
      borderRadius: BORDER_RADIUS,
      height: '36px',
      backgroundColor: defaultTheme.palette.background.white,
      '&:hover:before': {
        borderBottom: 'none !important',
      },
      '&.Mui-focused': {
        backgroundColor: defaultTheme.palette.background.white,
      },
      ':before': {
        borderBottom: 'none',
      },
      ':after': {
        borderBottom: 'none',
      },
    },
  },
};
