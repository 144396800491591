import { memo } from 'react';

import { PIN_DEFAULT_SIZE } from 'common/components/BingMap/utils/constants';

interface IMapIconProps{
  colors: {
    MAIN: string,
    CIRCLE: string,
    STROKE: string,
  },
  size?: number,
  stroke?: number
}

const MapIcon = ({ colors, size = PIN_DEFAULT_SIZE, stroke = 0 }: IMapIconProps): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 26">
    <path
      stroke="black"
      strokeWidth={stroke.toString()}
      /* eslint-disable-next-line max-len */
      d="M11.9999 1.14746C16.9707 1.14746 21 5.17676 21 10.1476C21 13.9508 18.3277 18.0955 13.0522 22.626C12.7588 22.878 12.3848 23.0164 11.9981 23.016C11.6114 23.0157 11.2377 22.8766 10.9448 22.6241L10.5958 22.3214C5.5539 17.909 2.9997 13.8658 2.9997 10.1476C2.9997 5.17676 7.029 1.14746 11.9999 1.14746ZM11.9999 6.68602C11.0818 6.68602 10.2013 7.05072 9.55214 7.6999C8.90296 8.34907 8.53826 9.22955 8.53826 10.1476C8.53826 11.0657 8.90296 11.9462 9.55214 12.5953C10.2013 13.2445 11.0818 13.6092 11.9999 13.6092C12.9179 13.6092 13.7984 13.2445 14.4476 12.5953C15.0968 11.9462 15.4615 11.0657 15.4615 10.1476C15.4615 9.22955 15.0968 8.34907 14.4476 7.6999C13.7984 7.05072 12.9179 6.68602 11.9999 6.68602Z"
      fill={colors.MAIN}
    />
    <circle
      cx="12"
      cy="9.75"
      r="4"
      fill={colors.CIRCLE}
      stroke={colors.STROKE}
    />
  </svg>
);

export default memo(MapIcon);
