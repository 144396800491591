import { memo } from 'react';

import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import { DividerWrapper } from 'common/components/HorizontalDivider/styled';
import { SearchControl } from 'common/components/SearchControl';
import { BENCHMARK } from 'containers/benchmark/utils/constants';
import FilterByMenu from 'containers/benchmark/components/SHARED/filterMenu/FilterByMenu';
import { SelectList } from 'containers/benchmark/components/SHARED/filterMenu/SelectList';

import {
  DividerStyled,
  MenuGridContainerWrapper,
  MenuStyled,
  TitleRowWrapper,
} from 'containers/buy-sell-credits/admin/table/column-menu/utils/styled';

interface TableColumnMenuProps{
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  columns: string[]
}

export const FilterMenu = memo(({
  open: mainMenuOpen,
  anchorEl: mainMenuAnchorEl,
  onClose,
  columns,
}: TableColumnMenuProps): JSX.Element => {
  function Divider(): JSX.Element {
    return (
      <DividerWrapper
        $spacingTop={3.25}
        $spacingBottom={3.25}
      >
        <DividerStyled variant="fullWidth" />
      </DividerWrapper>
    );
  }

  return (
    <MenuStyled
      open={mainMenuOpen}
      anchorEl={mainMenuAnchorEl}
      MenuListProps={{
        disablePadding: true,
      }}
    >
      <MenuGridContainerWrapper item container direction="column">
        <TitleRowWrapper
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="div" variant="body1">
            {BENCHMARK.FILTER}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close color="disabled" fontSize="small" />
          </IconButton>
        </TitleRowWrapper>
        <Divider />
        <SearchControl />
        <Divider />
        <FilterByMenu columns={columns} />
        <Divider />
        <SelectList />
        <Divider />
        <Button
          color="secondary"
          variant="contained"
        >
          { BENCHMARK.FILTER_BUTTON }
        </Button>
      </MenuGridContainerWrapper>
    </MenuStyled>
  );
});
