import { memo } from 'react';
import Grid from '@mui/material/Grid';
import { usePasswordDisplayer } from 'containers/login/components/utils/hooks/usePasswordDisplayer';
import { AUTOCOMPLETE, TEXT } from '../utils/constants';
import {
  FilledInputStyled,
  InputLabelStyled,
  PasswordSectionStyled,
} from './styled';
// import Link from '@mui/material/Link';

interface LoginPageProps{
onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const LoginPage = memo(({ onChange }: LoginPageProps): JSX.Element => {
  const [passwVisible, MemoizedPasswordDisplayer] = usePasswordDisplayer();

  return (
    <>
      <Grid item container>
        <InputLabelStyled variant="body1" color="textPrimary">{TEXT.NAME}</InputLabelStyled>
        <FilledInputStyled
          fullWidth
          placeholder={TEXT.NAME_PLACEHOLDER}
          type="text"
          name={TEXT.REGISTRATION_FIELDS_NAMES.NAME}
          autoComplete={TEXT.REGISTRATION_FIELDS_NAMES.NAME}
          onChange={onChange}
        />
      </Grid>
      <PasswordSectionStyled>
        <InputLabelStyled variant="body1" color="textPrimary">{TEXT.PASSWORD}</InputLabelStyled>
        <FilledInputStyled
          fullWidth
          placeholder={TEXT.PASSWORD_PLACEHOLDER}
          type={passwVisible ? 'text' : 'password'}
          name={TEXT.REGISTRATION_FIELDS_NAMES.PW}
          autoComplete={AUTOCOMPLETE.CURRENT_PASSWORD}
          onChange={onChange}
          endAdornment={MemoizedPasswordDisplayer}
        />
      </PasswordSectionStyled>
      <Grid item container justifyContent="space-between" alignItems="center">
        { /*
        Removed remember me  components, because it is not required for now
        <RememberMe />
         */ }
        {/* <Link
          color="textSecondary"
          underline="hover"
          href="https://github.com/"
          variant="body2"
        >
          {TEXT.FORGOT_PASSWORD}
        </Link> */}
        <div style={{ height: '20px' }} />
      </Grid>
    </>
  );
});
