import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { TEXT } from 'containers/main/constants';
import { ROUTE } from 'common/components/routes/utils/constants';
import { useLocationContext } from 'context/hooks';
import { SloganPart } from './SloganPart';

import { BodyContentWrapper, ButtonsWrapper } from './styled';

export const StartPage = memo(():JSX.Element => {
  const history = useHistory();

  const { dispatch } = useLocationContext();

  const onHandleClick = (e: React.MouseEvent<HTMLElement>): void => {
    history.push(e.currentTarget.id as string);
    dispatch({ path: e.currentTarget.id as string });
  };

  return (
    <BodyContentWrapper
      container
      direction="column"
      justifyContent="center"
      wrap="nowrap"
    >
      <SloganPart />
      <ButtonsWrapper
        container
        justifyContent="center"
        direction="column"
        wrap="nowrap"
        alignItems="center"
      >
        <Box mb={6} width="100%">
          <Button
            id={ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT}
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={onHandleClick}
          >
            {(TEXT.BUTTON.BUY_AND_SELL).toUpperCase()}
          </Button>
        </Box>
        <Box width="100%">
          <Button
            id={ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT}
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={onHandleClick}
          >
            {(TEXT.BUTTON.CLIENT_PROP).toUpperCase()}
          </Button>
        </Box>
      </ButtonsWrapper>
    </BodyContentWrapper>
  );
});
