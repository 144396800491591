import { MainPageTabContextAction, MainPageTabContextState } from './MainPageTabContext';

export function setMainPageTabClickedReducer(
  state: MainPageTabContextState,
  action: MainPageTabContextAction,
): MainPageTabContextState {
  const copy = { ...state };

  copy.isClicked = action.setClicked;

  return copy;
}
