import { TextField } from '@mui/material';
import { ChangeEvent, FC, useContext } from 'react';
import { TableDispatchContext, TableStateContext } from '../../model/index';
import { BasicCell } from '../BasicCell/InputCell';

type PropsType = { index: number; disabled: boolean };

const CellText: FC<PropsType> = ({ disabled, index }) => {
  const { rowData } = useContext(TableStateContext);
  const dispatch = useContext(TableDispatchContext);

  const onTypeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: { index, value },
    });
  };

  return (
    <BasicCell isColored={disabled}>
      <TextField
        disabled={disabled}
        value={rowData[index].value}
        onChange={onTypeHandler}
        fullWidth
        size="small"
      />
    </BasicCell>
  );
};

export default CellText;
