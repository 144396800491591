import { memo } from 'react';

import { useCreateBencmarkAnalyticsTableData } from 'containers/benchmark/lib/mapped-table-data';

import Header from './Header';
import BenchmarkTable from './table/BenchmarkTable';

import { BenchmarkSectionStyled, BenchmarkTableWrapper } from '../../utils/styled';

const BenchmarkTableSection = (): JSX.Element => {
  const { bencmarkAnalytics } = useCreateBencmarkAnalyticsTableData();

  return (
    <BenchmarkSectionStyled>
      <Header />
      <BenchmarkTableWrapper>
        <BenchmarkTable data={bencmarkAnalytics} />
      </BenchmarkTableWrapper>
    </BenchmarkSectionStyled>
  );
};

export default memo(BenchmarkTableSection);
