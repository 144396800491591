import { useContext } from 'react';

import {
  EditColumnContext,
  EditColumnDispatch,
  EditColumnState,
} from './EditColumnContext';

export const useEditColumn = (): {
  state: EditColumnState,
  dispatch: EditColumnDispatch,
} => {
  const context = useContext(EditColumnContext);
  if (context === undefined) {
    throw new Error('useEditColumn must be used within a EditColumnProvider');
  }

  return context;
};
