import { styled } from '@mui/material';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const HeaderStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
}));

export const MenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  width: 'auto',
  flexWrap: 'wrap',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 7.5),
  whiteSpace: 'nowrap',
  minWidth: theme.spacing(34),
  color: theme.palette.blue.main,
  borderColor: theme.palette.blue.main,
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 3, 0, 0),
}));
