import { styled } from '@mui/material';

import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

export const MenuStyled = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginLeft: theme.spacing(-32),
}));

export const MenuGridContainerWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  minWidth: 282,
  minHeight: 405,
}));

export const DividerStyled = styled(Divider)({
  position: 'absolute',
  width: '100%',
  left: 0,
});

export const FilterByMenuStyled = styled(Menu)(({ theme }) => ({
  maxHeight: '384px',
  marginTop: theme.spacing(11),
  '.MuiMenu-paper': {
    minWidth: '248px',
  },
}));

export const FilterByMenuButtonStyled = styled(Button)({
  justifyContent: 'space-between',
});

export const TitleRowWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(-1.25),
  marginBottom: theme.spacing(-2),
}));

export const SelectListWrapperStyled = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.gray}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(2),
}));

export const RowStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: theme.palette.background.lightGray,
  },
}));

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(-1.75),
}));

export const RowLabelStyled = styled(Typography)({
  maxWidth: 186,
});

export const ButtonsStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(5),
  justifyContent: 'flex-end',
}));

export const ButtonsClearStyled = styled(Button)(() => ({
  minWidth: '96px',
}));
