import { styled } from '@mui/material';

import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import CSVIcon from 'common/components/icons/CsvIcon';
import ExelIcon from 'common/components/icons/ExelIcon';

import { DATA_MENU_WIDTH } from './constants';

export const ButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: theme.spacing(3),
  padding: theme.spacing(3, 5),
  justifyContent: 'center',
  minHeight: theme.spacing(10.5),
  width: DATA_MENU_WIDTH,
}));

export const MenuStyled = styled(Menu)(({ theme }) => ({
  '.MuiList-padding': {
    padding: 0,
  },

  '.MuiPopover-paper': {
    padding: theme.spacing(0, 3),
    width: `${DATA_MENU_WIDTH}px`,
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  width: `${DATA_MENU_WIDTH}px`,
  display: 'flex',
  justifyContent: 'left',
  padding: theme.spacing(2, 0),

  h6: { marginLeft: theme.spacing(2.5) },
}));

export const CSVIconStyled = styled(CSVIcon)(({ theme }) => ({
  color: theme.palette.background.turquoiseGreen,
}));

export const ExelIconStyled = styled(ExelIcon)(({ theme }) => ({
  color: theme.palette.background.darkerGreen,
}));
