import MuiAppBar from './MuiAppBar';
import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiCheckbox from './MuiCheckbox';
import MuiCircularProgress from './MuiCircularProgress';
import MuiFilledInput from './MuiFilledInput';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiTouchRipple from './MuiTouchRipple';
import MuiTypography from './MuiTypography';

export const components = {
  MuiAppBar,
  MuiBackdrop,
  MuiButton,
  MuiButtonBase,
  MuiCheckbox,
  MuiCircularProgress,
  MuiFilledInput,
  MuiInputBase,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiSelect,
  MuiSvgIcon,
  MuiTab,
  MuiTabs,
  MuiTouchRipple,
  MuiTypography,
};
