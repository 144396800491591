import { useCallback, useEffect, useMemo } from 'react';

import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import {
  useCreateProperty,
  useUpdateProperty,
} from 'common/components/BingMap/lib/query-hooks';

import { IPropertyData } from 'common/components/BingMap/utils/types';
import { FormReset } from 'containers/input-property/components/mapTab/map/utils/constants';
import { useEditColumn } from 'common/components/providers/EditTableProvider/hooks';
import { postPropertyDataAll } from 'common/components/BingMap/lib/api';

type RequiredTableFields = {
  ['PROPERTY NAME']: { value: string};
  ADDRESS: { value: string};
  CITY: { value: string};
  ['STATE (abbrev)']: { value: string};
  ZIP: { value: number};
  COUNTY: { value: string};
  ['YEAR BUILT']: { value: number};
  ['YEAR RENOV']: { value: number};
  ['NUM UNITS']: { value: number};
  SUBTYPE: { value: string};
  // lowIncomeStatus: {value:string};
  AFFORDABILITY: { value: string};
  submitBtn: {
    dataset: {
      type: 'add' | 'modify';
    }
  }
}

type ReturnType = {
  submitHandler: (event: React.SyntheticEvent<Element, Event>) => void,
  isLoading: boolean
}

export const useRequireTableFunctions = (reset: FormReset): ReturnType => {
  const { state: idState, dispatch: idDispatch } = usePropertyId();
  const { state: editState, dispatch: editDispatch } = useEditColumn();

  const mutationCreate = useCreateProperty();
  const mutationUpdate = useUpdateProperty();

  const memoizedSelectedProperty = useMemo(() => idState.id, [idState.id]);

  useEffect(() => {
    reset({}, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
  }, [memoizedSelectedProperty, reset]);

  const submitHandler = useCallback((event: React.SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as typeof event.target & RequiredTableFields;
    const newProperty: IPropertyData = {
      statementYear: 0,
      propertyName: target['PROPERTY NAME']?.value.trim() || memoizedSelectedProperty as string,
      propertyAddress: target.ADDRESS?.value.trim(),
      propertyCity: target.CITY?.value.trim(),
      propertyState: target['STATE (abbrev)']?.value.trim(),
      propertyZipCode: target.ZIP?.value,
      propertyCounty: target.COUNTY?.value.trim(),
      yearBuilt: target['YEAR BUILT']?.value,
      yearRenov: target['YEAR RENOV']?.value,
      numUnits: target['NUM UNITS']?.value,
      propertySubtypeAssigned: target.SUBTYPE?.value,
      // lowIncomeStatus: target.Affordability.value,
      affordability: target.AFFORDABILITY?.value,
    };

    const { type } = target.submitBtn.dataset;

    switch (type) {
      case 'add':
        if (editState.mapOfProps.has('create')) {
          const vals = editState.mapOfProps.get('create');
          const postAllData = { ...vals, ...newProperty };
          postPropertyDataAll(postAllData);
        } else {
          mutationCreate.mutateAsync(newProperty);
        }
        editDispatch({ type: 'clear' });
        idDispatch({ newValue: null });
        break;
      case 'modify':
        mutationUpdate.mutateAsync(newProperty);
        break;
      default:
        break;
    }
  }, [
    editDispatch,
    editState.mapOfProps,
    idDispatch,
    memoizedSelectedProperty,
    mutationCreate,
    mutationUpdate,
  ]);

  return { submitHandler, isLoading: mutationUpdate.isLoading };
};
