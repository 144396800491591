import { styled } from '@mui/material';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const IconButtonWrapperStyled = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.blue}`,
  padding: theme.spacing(2, 6.75),
  marginLeft: theme.spacing(4),
  '&:hover': {
    color: theme.palette.background.blue,
    border: `1px solid ${theme.palette.background.blue}`,
  },
}));

export const ModalTitleStyled = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const ModalContentContainerStyled = styled('div')(({ theme }) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 514,
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(6),
  outline: 'none',
}));
