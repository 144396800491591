import {
  memo,
  useCallback,
  useMemo,
} from 'react';

import TabContext from '@mui/lab/TabContext';

import { Redirect, useHistory } from 'react-router-dom';

import MapProvider from 'common/components/BingMap/local-state/MapProvider';
import {
  BuyAndSellDataCreditsProvider,
} from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/BuyAndSellDataCreditsProvider';
import { ROUTE } from 'common/components/routes/utils/constants';

import TabListComponent from 'containers/input-property/TabListComponent';
import TabPanelsComponent from 'containers/input-property/TabPanelsComponent';

import {
  InputPropertyContainerStyled,
  InputPropertyTabsWrapper,
  TabListWrapperStyled,
  TabPanelWrapperStyled,
} from 'containers/input-property/utils/styled';

const InputProperty = memo((): JSX.Element => {
  const history = useHistory();

  const routerPath = history.location.pathname;

  const isWrongInputPropertyPath = useMemo(() => Object.values([
    ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT,
    ROUTE.ACCOUNT.INPUT_PROPERTY.DETAIL,
  ]).every((path) => path !== routerPath),
  [routerPath]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: string): void => {
      history.push(newValue);
    }, [history],
  );

  return (
    <BuyAndSellDataCreditsProvider>
      <MapProvider>
        <InputPropertyContainerStyled>
          <InputPropertyTabsWrapper item container wrap="nowrap" direction="column">
            <TabContext value={routerPath}>
              {isWrongInputPropertyPath
                ? <Redirect to={ROUTE.NOT_FOUND} />
                : (
                  <>
                    <TabListWrapperStyled item>
                      <TabListComponent
                        onChange={handleChange}
                        value={routerPath}
                      />
                    </TabListWrapperStyled>
                    <TabPanelWrapperStyled item>
                      <TabPanelsComponent />
                    </TabPanelWrapperStyled>
                  </>
                )}
            </TabContext>
          </InputPropertyTabsWrapper>
        </InputPropertyContainerStyled>
      </MapProvider>
    </BuyAndSellDataCreditsProvider>
  );
});

export default InputProperty;
