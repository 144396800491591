import { createContext } from 'react';

export type IdAction = {newValue: string | null};
export type IdDispatch = (action: IdAction) => void;
export type IdState = { id: string | null };

export const PropertyIdContext = createContext<
  { state: IdState; dispatch: IdDispatch; } | undefined
  >(undefined);

createContext<(IdState & IdDispatch) | undefined>(undefined);
