import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

import {
  BootstrapDialog,
  IconButtonStyled,
  ModalBodyStyled,
} from './utils/styled';
import { TEXT, VideoType } from './utils/constants';

export const Modal = React.memo((
  { openedVideo, close }: {openedVideo: VideoType, close: VoidFunction},
): JSX.Element => {
  const renderIframe = (): JSX.Element => {
    if (openedVideo === TEXT.COMPS_VIDEO) {
      return (
        <iframe
          title={openedVideo}
          src="https://player.vimeo.com/video/846698368?h=c71e56ac03"
          width="640"
          height="321"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    }
    return (
      <iframe
        title={openedVideo}
        src="https://player.vimeo.com/video/846699974?h=d7c5c8251d"
        width="640"
        height="321"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    );
  };
  return (
    <BootstrapDialog
      open={!!openedVideo}
      onClose={close}
    >
      <DialogTitle>
        {openedVideo}
        <IconButtonStyled onClick={close}>
          <CloseIcon fontSize="medium" />
        </IconButtonStyled>
      </DialogTitle>
      <ModalBodyStyled>
        {renderIframe()}
      </ModalBodyStyled>
    </BootstrapDialog>
  );
});
