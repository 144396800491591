import { memo } from 'react';

import { FixedSizeList as List } from 'react-window';

import { FILTER_LIST } from 'containers/benchmark/utils/constants';
import Row from 'containers/benchmark/components/SHARED/filterMenu/Row';

import { SelectListWrapperStyled } from 'containers/buy-sell-credits/admin/table/column-menu/utils/styled';

export const SelectList = memo(() => (
  <SelectListWrapperStyled>
    <List
      height={FILTER_LIST.HEIGHT}
      // FIXME: add count of items to select from endpoint data
      itemCount={8}
      itemSize={FILTER_LIST.ROW_HEIGHT}
      width={FILTER_LIST.LIST_WIDTH}
    >
      {Row}
    </List>
  </SelectListWrapperStyled>
));
