type AnyObject = Record<string, unknown>;

const flatObject = (obj: AnyObject): AnyObject => {
  const result: AnyObject = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      const childObj = flatObject((obj as never)[key]);

      Object.keys(childObj).forEach((childObjKey) => {
        result[`${key}.${childObjKey}`] = childObj[childObjKey];
      });
    } else {
      result[key] = obj[key];
    }
  });

  return result;
};

export const getValuesOfFlattenObjectFrom = (obj: AnyObject): never[] => {
  const array: never[] = [];
  const flatteredObject = flatObject(obj);
  Object.keys(flatteredObject).forEach((key) => {
    array.push((flatteredObject as never)[key]);
  });

  return array;
};

export const isRouteValid = (
  routeSet: never[], input: string,
): boolean => Object
  .values(routeSet)
  .every((pathname) => input !== pathname);
