import React from 'react';

export interface IFinancialContext {state: FinancialState, dispatch: FinancialDispatch}

export type FinancialAction = {type: FinancialActionType}
export type FinancialState = {
  useTopLine: boolean, useLineItem: boolean, useGrosRnt: boolean, useBaseRnt: boolean
}
export type FinancialDispatch = (action: FinancialAction) => void

export enum FinancialActionType {
  TopLine = 'topLine',
  LineItem = 'lineItem',
  GrosRnt = 'grosRnt',
  BaseRnt = 'baseRnt',
}

export const FinancialContext = React.createContext<{
  state: FinancialState,
  dispatch: FinancialDispatch
} | undefined>(undefined);
