import { styled } from '@mui/material';

export const GridCellStyled = styled('div')(({ theme }) => ({
  width: 'auto',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.background.lightGray,
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  minWidth: theme.spacing(35),

  '&:nth-child(even)': {
    borderRight: 0,
  },

  '&:nth-child(7), &:nth-child(8)': {
    borderBottom: 0,
  },
}));

export const TableContainerStyled = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  display: 'grid',
  gridTemplateColumns: '3fr 4fr',
  margin: theme.spacing(2, 0),
  width: '100%',
  overflow: 'auto',
}));
