import { memo } from 'react';

import InputRenderer from 'containers/benchmark/components/list/table/InputRenderer';

import {
  StyledTableCell,
  StyledTableCellName,
  StyledTableRow,
} from 'containers/input-property/components/mapTab/propertyData/styled';

type RowRendererProps = {
clientPropertyData: Map<string, string | number | undefined>,
}
const RowRenderer = ({ clientPropertyData }: RowRendererProps):JSX.Element => {
  const rowNames = Array.from(clientPropertyData.keys());
  const rowValues = Array.from(clientPropertyData.values());

  return (
    <>
      {rowNames.map((row, index) => (
        <StyledTableRow key={rowNames[index]}>
          <StyledTableCellName component="th" scope="row">
            {row.toUpperCase()}
          </StyledTableCellName>
          <StyledTableCell>
            <InputRenderer
              name={rowNames[index]}
              value={rowValues[index] as string | number | undefined ?? undefined}
            />
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export default memo(RowRenderer);
