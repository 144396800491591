import { RESEARCH } from 'containers/research/utils/constants';

import * as React from 'react';
import {
  TableContainerStyled,
  GridCellStyled,
} from './styles';
import { TableStateContext } from '../../model';

const pesentage = (num: number):string => {
  if (num === undefined) return '';
  return `${(num * 100).toFixed(2)}%`;
};

export const CaprateTable = ():JSX.Element => {
  const { tableData } = React.useContext(TableStateContext);

  return (
    <TableContainerStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.TITLE}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.HISTORICAL}
      </GridCellStyled>
      <GridCellStyled>
        {pesentage(tableData?.histCapRate as number)}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.HISTORICAL_10YR}
      </GridCellStyled>
      <GridCellStyled>
        {pesentage(tableData?.histTRSY as number)}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.CURRENT_10YR}
      </GridCellStyled>
      <GridCellStyled>
        {pesentage(tableData?.currTRSY as number)}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.TRSY_YIELD}
      </GridCellStyled>
      <GridCellStyled>
        {(tableData?.currTRSY && tableData?.currCapRate)
          ? pesentage(
            (tableData?.currTRSY as number) - (tableData?.histTRSY as number),
          )
          : ''}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.CAPRATE_ADJUSTMENT}
      </GridCellStyled>
      <GridCellStyled>
        {pesentage(tableData?.capRateADJ as number)}
      </GridCellStyled>
      <GridCellStyled>
        {RESEARCH.CAPRATE.VALUATION}
      </GridCellStyled>
      <GridCellStyled>
        {pesentage(tableData?.currCapRate as number)}
      </GridCellStyled>
    </TableContainerStyled>
  );
};
