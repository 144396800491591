import { memo } from 'react';

import Box from '@mui/material/Box';

import ResetIcon from 'common/components/icons/ResetIcon';

import { GENERATE_PW } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/constants';

import { AUTOCOMPLETE, TEXT } from 'containers/login/utils/constants';

import { usePassword } from 'containers/login/components/utils/hooks/usePassword';
import { usePasswordDisplayer } from 'containers/login/components/utils/hooks/usePasswordDisplayer';

import {
  GeneratePwContainerStyled,
  GeneratePwStyled,
} from 'containers/buy-sell-credits/admin/table/add-new-user/styled';

import {
  FilledInputStyled,
  InputLabelStyled,
  PasswordSectionStyled,
} from 'containers/login/components/styled';

interface SignInProps{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RegistrationPassword = ({ onChange }: SignInProps):JSX.Element => {
  const [passwVisible, MemoizedPasswordDisplayer] = usePasswordDisplayer();
  const [password, confirmPassword, onChangeHandle, generatePassword] = usePassword(onChange);

  return (
    <>
      <PasswordSectionStyled>
        <InputLabelStyled variant="body1" color="textPrimary">{TEXT.PASSWORD}</InputLabelStyled>
        <Box display="flex" justifyContent="space-between">
          <GeneratePwContainerStyled onClick={generatePassword}>
            <ResetIcon color="action" />
            <GeneratePwStyled variant="body2" color="textSecondary">{GENERATE_PW}</GeneratePwStyled>
          </GeneratePwContainerStyled>
        </Box>
        <FilledInputStyled
          multiline={false}
          fullWidth
          placeholder={TEXT.PASSWORD_PLACEHOLDER}
          name={TEXT.REGISTRATION_FIELDS_NAMES.PW}
          autoComplete={AUTOCOMPLETE.NEW_PASSWORD}
          value={password}
          onChange={onChangeHandle}
          type={passwVisible ? 'text' : 'password'}
          endAdornment={MemoizedPasswordDisplayer}
          inputProps={{
            title: TEXT.VALIDATION_MESSAGE,
            pattern: TEXT.PASSWORD_PATTERN,
          }}
          required
        />
      </PasswordSectionStyled>
      <PasswordSectionStyled>
        <InputLabelStyled variant="body1" color="textPrimary">{TEXT.CONFIRM_PASSWORD}</InputLabelStyled>
        <FilledInputStyled
          multiline={false}
          fullWidth
          placeholder={TEXT.PASSWORD_PLACEHOLDER}
          name={TEXT.REGISTRATION_FIELDS_NAMES.CONFIRM_PW}
          autoComplete={AUTOCOMPLETE.CONFIRM_PASSWORD}
          value={confirmPassword}
          onChange={onChangeHandle}
          type={passwVisible ? 'text' : 'password'}
          endAdornment={MemoizedPasswordDisplayer}
          required
        />
      </PasswordSectionStyled>
    </>
  );
};

export default memo(RegistrationPassword);
