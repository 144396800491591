import { memo } from 'react';
import Grid from '@mui/material/Grid';
import RegistrationPassword from 'containers/login/components/RegistrationPassword';
import { TEXT } from '../utils/constants';
import {
  FilledInputStyled,
  InputLabelStyled,
  NameSectionStyled,
} from './styled';
// import Link from '@mui/material/Link';

interface SignInProps{
onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SignInPage = memo(({ onChange }: SignInProps): JSX.Element => (
  <>
    <Grid item container>
      <InputLabelStyled variant="body1" color="textPrimary">{TEXT.EMAIL}</InputLabelStyled>
      <FilledInputStyled
        fullWidth
        placeholder={TEXT.EMAIL_PLACEHOLDER}
        type="email"
        name={TEXT.REGISTRATION_FIELDS_NAMES.EMAIL}
        inputProps={{
          pattern: TEXT.EMAIL_PATTERN,
        }}
        autoComplete={TEXT.EMAIL}
        onChange={onChange}
        required
      />
    </Grid>
    <NameSectionStyled item container>
      <InputLabelStyled variant="body1" color="textPrimary">{TEXT.NAME}</InputLabelStyled>
      <FilledInputStyled
        fullWidth
        placeholder={TEXT.NAME_PLACEHOLDER}
        type="text"
        name={TEXT.REGISTRATION_FIELDS_NAMES.NAME}
        autoComplete={TEXT.NAME}
        onChange={onChange}
        required
      />
    </NameSectionStyled>
    <RegistrationPassword onChange={onChange} />
    <Grid item container justifyContent="space-between" alignItems="center">
      { /*
        Removed remember me  components, because it is not required for now
        <RememberMe />
         */ }
      {/* <Link
        color="textSecondary"
        underline="hover"
        href="https://github.com/"
        variant="body2"
      >
        {TEXT.FORGOT_PASSWORD}
      </Link> */}
      <div style={{ height: '20px' }} />
    </Grid>
  </>
));
