import { styled } from '@mui/material';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';

export const ProductTabStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(3),
  overflow: 'auto',
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.5,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  marginBottom: theme.spacing(7),
}));

export const ListWrapperStyled = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    gap: 0,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
  },
}));

export const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
  },
}));

export const ListItemStyled = styled('li')(({ theme }) => ({
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  listStyleType: 'disc',
  marginBottom: theme.spacing(2),
}));

export const TextContainerStyled = styled(Container)(({ theme }) => ({
  maxWidth: '75ch',
  fontSize: theme.typography.htmlFontSize,
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(7),
  textAlign: 'justify',
  h3: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
}));
