import { memo } from 'react';

import { TEXT } from 'containers/CMBS/utils/constants';

import CallIcon from 'common/components/icons/CallIcon';
import EmailIcon from 'common/components/icons/EmailIcon';

import HorizontalDivider from 'common/components/HorizontalDivider/HorizontalDivider';

import {
  ContactsSectionStyled,
  LinkStyled,
  TextBolderStyled,
  TextSectionStyled,
  TextStyled,
  TitleStyled,
} from 'containers/CMBS/styled';

const TextSection = ():JSX.Element => (
  <TextSectionStyled elevation={5}>
    <TitleStyled>{TEXT.CMBS_SURVEILLANCE}</TitleStyled>
    <TitleStyled $maxWidth="40ch">{TEXT.PROPERTY_LEVEL}</TitleStyled>
    <TitleStyled $maxWidth="50ch">{TEXT.FREDDIE_MAC}</TitleStyled>
    <TextStyled>{TEXT.PARAGRAPH_1}</TextStyled>
    <TextStyled>{TEXT.PARAGRAPH_2}</TextStyled>
    <TextBolderStyled $maxWidth="44ch">{TEXT.FURTHER_INFORMATION}</TextBolderStyled>
    <HorizontalDivider spacingBottom={4} />
    <ContactsSectionStyled>
      <EmailIcon color="action" />
      <LinkStyled href={TEXT.EMAIL_HREF} target="_blank">{TEXT.EMAIL}</LinkStyled>
    </ContactsSectionStyled>
    <ContactsSectionStyled>
      <CallIcon color="action" />
      <TextStyled>{TEXT.TELEPHONE}</TextStyled>
    </ContactsSectionStyled>
  </TextSectionStyled>
);

export default memo(TextSection);
