import { initReactQueryAuth } from 'react-query-auth';

import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { TEXT } from 'containers/login/utils/constants';

import jwtDecode from 'jwt-decode';
import { trimServerMessage } from 'common/utils/trimServerMessage';
import {
  getUserToken,
  loginWithEmailAndPassword,
  logOut,
  registerWithEmailAndPassword,
} from '../api';

import { storage } from '../utils/local-storage';

import { IUser } from './models/IUser.interface';
import { IUserData } from './models/ResponseData.interface';
import { AuthResponseType } from '../utils/TokenType';
import { refreshTokenSetup } from '../helpers';

interface Errors {
  response: {
    data: {
      detail: string;
    }
  },
}

export function handleUserResponse(data: AuthResponseType): string {
  if (data.authorization || data['refresh-token']) {
    const decodedJWT: IUserData = jwtDecode(data.authorization);

    storage.setAccessToken(`${data.authorization}`);
    storage.setRefreshToken(`${data['refresh-token']}`);

    storage.setExpiresAt(+decodedJWT.exp);
    storage.setExpiresIat(+decodedJWT.iat);
  }

  return data.authorization;
}

async function loadUser(): Promise<IUserData> {
  let user = null;
  const accessToken = storage.getAccessToken();

  if (accessToken) {
    await refreshTokenSetup(accessToken);
    const data = await getUserToken();
    user = data;
  }

  return user as IUserData;
}

async function loginFn(data: IUser): Promise<IUserData> {
  let user = null;
  try {
    const response = await loginWithEmailAndPassword(data as IUser);
    const token = handleUserResponse(response);
    user = await getUserToken(token);
    if (token) {
      await refreshTokenSetup(token);
    }

    SnackBarUtils.success('Credentials verified');
  } catch (error) {
    SnackBarUtils.error(trimServerMessage((error as Errors).response.data.detail));
  }

  return user as IUserData;
}

async function logoutFn(): Promise<void> {
  try {
    const response = await logOut();
    SnackBarUtils.success(trimServerMessage(response.message));
    storage.clearAll();
  } catch (error) {
    storage.clearAll();
  }
}

async function registerFn(data: IUser): Promise<IUserData> {
  let user = null;
  try {
    // TODO: refactor login
    await registerWithEmailAndPassword(data);
    const response = await loginWithEmailAndPassword(data as IUser);
    const token = handleUserResponse(response);
    user = await getUserToken(token);

    SnackBarUtils.success(TEXT.REGISTRATION_SUCCESS);
  } catch (error) {
    SnackBarUtils.error(trimServerMessage((error as Error).message));
  }

  return user as IUserData;
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
};

const { AuthProvider, useAuth } = initReactQueryAuth<IUserData, Error>(authConfig);

export { AuthProvider, useAuth };
