import { Box, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export const BodyContentWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.blue,
  flex: 1,
  height: '100%',
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(25),
  right: theme.spacing(4),
  padding: theme.spacing(1.2),
  border: '1pt solid #393939',
}));

export const TitleStyled = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(3.5),
}));

export const TextWrapper = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(12),
}));

export const ButtonsWrapper = styled(Grid)({
  maxWidth: 365,
  alignSelf: 'center',
});
