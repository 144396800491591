import FacebookIcon from 'common/components/icons/FacebookIcon';
import InstagramIcon from 'common/components/icons/InstagramIcon';
import LinkedInIcon from 'common/components/icons/LinkedInIcon';
import NewsIcon from 'common/components/icons/NewsIcon';
import TwitterIcon from 'common/components/icons/TwitterIcon';
import { useScreenDown } from 'common/hooks/screenSize';
import { SOCIALS } from '../constants';

import { IconButtonStyled } from './styled';

export const Socials = (): JSX.Element => {
  const isScreenDownMd = useScreenDown('md');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    window.open(event.currentTarget.name, '_blank');
  };

  return (
    <>
      <IconButtonStyled
        name={SOCIALS.TWITTER}
        onClick={handleClick}
        size={isScreenDownMd ? 'medium' : 'small'}
      >
        <TwitterIcon fontSize={isScreenDownMd ? 'medium' : 'small'} />
      </IconButtonStyled>
      <IconButtonStyled
        name={SOCIALS.FACEBOOK}
        onClick={handleClick}
        size={isScreenDownMd ? 'medium' : 'small'}
      >
        <FacebookIcon fontSize={isScreenDownMd ? 'medium' : 'small'} />
      </IconButtonStyled>
      <IconButtonStyled
        name={SOCIALS.BLOCKSPOT}
        onClick={handleClick}
        size={isScreenDownMd ? 'medium' : 'small'}
      >
        <NewsIcon fontSize={isScreenDownMd ? 'medium' : 'small'} />
      </IconButtonStyled>
      <IconButtonStyled
        name={SOCIALS.LINKEDIN}
        onClick={handleClick}
        size={isScreenDownMd ? 'medium' : 'small'}
      >
        <LinkedInIcon fontSize={isScreenDownMd ? 'medium' : 'small'} />
      </IconButtonStyled>
      <IconButtonStyled
        name={SOCIALS.INSTAGRAM}
        onClick={handleClick}
        size={isScreenDownMd ? 'medium' : 'small'}
      >
        <InstagramIcon fontSize={isScreenDownMd ? 'medium' : 'small'} />
      </IconButtonStyled>
    </>
  );
};
