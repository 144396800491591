import { memo } from 'react';

import SelectPropertyList from 'containers/input-property/components/mapTab/map/SelectPropertyList';

import { HeaderStyled } from 'common/components/BingMap/styled';

const Header = (): JSX.Element => (
  <HeaderStyled>
    <SelectPropertyList />
    {/** this feature temporarily locked accordingly customer's desire */}
    {/* <HeaderSectionStyled>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label={HEADER_TEXT.CHECKBOX.toUpperCase()}
        />
      </FormGroup>
      <Button
        color="secondary"
        variant="contained"
      >
        { HEADER_TEXT.BUTTON.toUpperCase() }
      </Button>
    </HeaderSectionStyled> */}
  </HeaderStyled>
);

export default memo(Header);
