import { Action, State } from './CurrentCompsetsPropertyContext';

export function setCurrentCompsetsPropertyReducer(state: State, action: Action): State {
  const copy = { ...state };

  copy.currentCompsetsProperty = action.currentCompsetsProperty;
  copy.currentCompsetsId = action.currentCompsetsId;
  copy.currentCompsetsPropertyEvent = action.currentCompsetsPropertyEvent as React.MouseEvent<HTMLElement>;

  return copy;
}
