import React from 'react';

export interface IValuationContext {state: ValState, dispatch: ValDispatch}

export type ValAction = {type: ValActionType}
export type ValState = { solveForCaprate: boolean, solveForVal: boolean, useTrailingFinancials:boolean }
export type ValDispatch = (action: ValAction) => void

export enum ValActionType {
  Caprate = 'caprate',
  Val = 'val',
  TrailingFinancialsChecked = 'trailingFinancialsChecked',
  TrailingFinancialsUnchecked = 'trailingFinancialsUnchecked',
}

export const ValuationContext = React.createContext<{
  state: ValState,
  dispatch: ValDispatch
} | undefined>(undefined);
