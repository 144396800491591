import React, { memo } from 'react';

import Tab from '@mui/material/Tab';
import { useScreenDown } from 'common/hooks/screenSize';
import { ROUTE } from 'common/components/routes/utils/constants';
import { Link } from 'react-router-dom';
import { TAB_LABEL } from './constants';

import { TabListStyled } from './styled';

interface AppBarTabListProps {
  onTabListClick:() => void;
  clicked: boolean;
  value: string
}

export const AppBarTabList = memo(({
  onTabListClick,
  clicked,
  value,
}: AppBarTabListProps) => {
  const isScreenDownMd = useScreenDown('md');

  return (
    <TabListStyled
      orientation={isScreenDownMd ? 'vertical' : 'horizontal'}
      $isSelectedTab={clicked}
      $isDownMd={isScreenDownMd}
      textColor="inherit"
      onClick={onTabListClick}
      value={value}
    >
      <Tab
        component={Link}
        label={TAB_LABEL.HELP}
        value={ROUTE.HOME.HELP}
        to={ROUTE.HOME.HELP}
      />
      {/* <Tab
        component={Link}
        label={TAB_LABEL.INSIGHTS}
        value={ROUTE.HOME.INSIGHTS}
        to={ROUTE.HOME.INSIGHTS}
      />
      <Tab
        component={Link}
        label={TAB_LABEL.PRODUCTS}
        value={ROUTE.HOME.PRODUCTS}
        to={ROUTE.HOME.PRODUCTS}
      /> */}
      <Tab
        component={Link}
        label={TAB_LABEL.DATABASE}
        value={ROUTE.HOME.DATABASE}
        to={ROUTE.HOME.DATABASE}
      />
      <Tab
        component={Link}
        label={TAB_LABEL.TEAM}
        value={ROUTE.HOME.TEAM}
        to={ROUTE.HOME.TEAM}
      />
      <Tab
        component={Link}
        label={TAB_LABEL.CONTACT}
        value={ROUTE.HOME.CONTACT}
        to={ROUTE.HOME.CONTACT}
      />
    </TabListStyled>
  );
});
