import { useContext } from 'react';

import {
  InfoTablePopupContext,
  InfoTablePopupDispatch,
  InfoTablePopupState,
} from 'containers/benchmark/local-state/InfoTablePopupContext';
import {
  SelectClientPortfolioNameContext,
  ClientPortfolioNameDispatch,
  ClientPortfolioNameState,
} from 'containers/benchmark/local-state/SelectClientPortfolioNameContext';
import { RadioBenchmarkContext, RadioBenchmarkDispatch, RadioBenchmarkState } from './RadioBenchmarkContext';
import {
  BenchmarkAnalyticsCallbackContext,
  BenchmarkAnalyticsCallbackDispatch,
  BenchmarkAnalyticsCallbackState,
} from './BenchmarkAnalyticsCallbackContext';

const useInfoTablePopup = (): {
  state: InfoTablePopupState,
  dispatch: InfoTablePopupDispatch,
} => {
  const context = useContext(InfoTablePopupContext);
  if (context === undefined) {
    throw new Error('useInfoTablePopup must be used within a InfoTablePopupProvider');
  }

  return context;
};

const useSetClientPortfolioName = (): {
  state: ClientPortfolioNameState,
  dispatch: ClientPortfolioNameDispatch,
} => {
  const context = useContext(SelectClientPortfolioNameContext);
  if (context === undefined) {
    throw new Error('useSetClientPortfolioName must be used within a InfoTablePopupProvider');
  }

  return context;
};

const useSetRadioSelection = (): {
  state: RadioBenchmarkState,
  dispatch: RadioBenchmarkDispatch,
} => {
  const context = useContext(RadioBenchmarkContext);
  if (context === undefined) {
    throw new Error('useSetRadioSelection must be used within a InfoTablePopupProvider');
  }

  return context;
};

const useCallBenchmarkAnalytics = (): {
  state: BenchmarkAnalyticsCallbackState,
  dispatch: BenchmarkAnalyticsCallbackDispatch,
} => {
  const context = useContext(BenchmarkAnalyticsCallbackContext);
  if (context === undefined) {
    throw new Error('useCallBenchmarkAnalytics must be used within a InfoTablePopupProvider');
  }

  return context;
};

export {
  useInfoTablePopup,
  useSetClientPortfolioName,
  useSetRadioSelection,
  useCallBenchmarkAnalytics,
};
