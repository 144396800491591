import { memo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { RESEARCH } from 'containers/research/utils/constants';
import { useCreateMetrocountsData } from 'containers/research/lib/mapped-table-data';
import { Table } from './table';

import { HeaderStyled } from '../styled';

const MySubscription = (): JSX.Element => {
  const metrocountsData = useCreateMetrocountsData()
    .filter((item) => item.endDateMdraSubtype !== '');

  return (
    <Box height="100%">
      <HeaderStyled>
        <Typography variant="h5">
          {RESEARCH.TITLES.MY_SUBSCRIPTION}
        </Typography>
      </HeaderStyled>
      <Table metrocountsData={metrocountsData} />
    </Box>
  );
};

export default memo(MySubscription);
