import { memo } from 'react';

import {
  Dialog, Grid,
} from '@mui/material';

import {
  ChartWraper,
  HorizontalText,
  TableStyled,
  WrapperStyledCharts,
  PropWraper, PropWraperLine,
} from 'containers/benchmark/utils/styled';
import ChartTablePerUnit from 'containers/benchmark/components/list/ChartTablePerUnit';
import CloseIcon from '@mui/icons-material/Close';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';
import { jsPDF as JsPDF } from 'jspdf';
import { FONTS } from 'common/theme/fonts';
import ChartTableOfRevenue from './ChartTableOfRevenue';
import ChartTablePerUnitExp from './ChartTablePerUnitExp';
import ChartTableOfRevenueExp from './ChartTableOfRevenueExp';
import { HeaderStyled } from '../../../research/components/styled';
import { IconButtonStyled } from '../../../research/components/new-subscription/modal/table/styled';
import { ButtonStyledPDF } from '../../../input-property/components/detailsTab/styled';

interface IModalParams {
  open: boolean;
  close: VoidFunction;
  state: BenchmarkAnalyticsModel[];
  clientPropertyData: Map<string, string | number | undefined>,
}

const DialogWindowCharts = ({
  open,
  close,
  state,
  clientPropertyData,
}: IModalParams): JSX.Element => {
  const rowValues = Array.from(clientPropertyData.values());

  const generatePDF = (): void => {
    const docs = document.querySelectorAll('#content>canvas');
    const docsHorizontal = document.getElementById('contentHorizontal')?.innerText || '';
    const docsBottomHorizontal = document.getElementById('contentBottomHorizontal')?.innerText || '';
    const docsLeft = document.querySelectorAll('#contentLeft>canvas');
    const docsTop = document.getElementById('contentTop')?.innerText || '';
    const docsMiddle = document.getElementById('contentMiddle')?.innerText || '';
    const docsDown = document.getElementById('contentDown')?.innerText || '';

    const canvasImages: string[] = [];
    docs.forEach((doc) => {
      const c = doc as HTMLCanvasElement;
      canvasImages.push(c.toDataURL('imagine/png', 10));
    });

    const canvasLefImages: string[] = [];
    docsLeft.forEach((doc) => {
      const c = doc as HTMLCanvasElement;
      canvasLefImages.push(c.toDataURL('imagine/png', 10));
    });

    const pdf = new JsPDF('l', 'pt', 'a4');
    const font = FONTS.OPEN_SANS_REG;
    pdf.setFontSize(8).setFont(font, 'bold');
    pdf.text(docsTop, 430, 60, { align: 'center' });
    pdf.line(343, 62, 517, 62);
    pdf.text(docsMiddle, 430, 80, { align: 'center' });
    pdf.text(docsDown, 430, 100, { align: 'center' });
    pdf.text(docsHorizontal, 30, 260, undefined, 90);
    pdf.text(docsBottomHorizontal, 30, 460, undefined, 90);
    pdf.addImage(canvasImages[0], 'PNG', 41, 115, 350, 175);
    pdf.addImage(canvasImages[1], 'PNG', 460, 115, 350, 175);
    pdf.text(docsHorizontal, 450, 260, undefined, 90);
    pdf.text(docsBottomHorizontal, 450, 460, undefined, 90);
    pdf.addImage(canvasLefImages[0], 'PNG', 30, 340, 350, 175);
    pdf.addImage(canvasLefImages[1], 'PNG', 440, 340, 350, 175);
    pdf.save('charts.pdf');
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth="xxl"
      >
        <Grid
          item
          xs
        >
          <WrapperStyledCharts>
            <HeaderStyled>
              <ButtonStyledPDF
                color="secondary"
                variant="contained"
                $isDownLg
                onClick={generatePDF}
              >
                DOWNLOAD PDF
              </ButtonStyledPDF>
              <IconButtonStyled onClick={close}>
                <CloseIcon fontSize="large" />
              </IconButtonStyled>
            </HeaderStyled>
            <PropWraperLine id="contentTop">
              Operating Statement Benchmark Comparison for:
            </PropWraperLine>
            <PropWraper id="contentMiddle">
              {' '}
              {rowValues[0]}
              ,
              {' '}
              {rowValues[1]}
              ,
              {' '}
              {rowValues[2]}
              ,
              {' '}
              {rowValues[3]}
              ,
              {' '}
              {rowValues[4]}
              ,
            </PropWraper>
            <PropWraper id="contentDown">
              {rowValues[9]}
              {' '}
              Subtype,
              {rowValues[8]}
              {' '}
              Units,
              {rowValues[6]}
              {' '}
              Year
            </PropWraper>
            <ChartWraper>
              <TableStyled id="content">
                <HorizontalText id="contentHorizontal">
                  $ PER UNIT PER MONTH
                </HorizontalText>
                <ChartTablePerUnit state={state} />
                <HorizontalText id="contentBottomHorizontal">
                  % OF REVENUE
                </HorizontalText>
                <ChartTableOfRevenue state={state} />
              </TableStyled>
              <TableStyled id="contentLeft">
                <HorizontalText>
                  $ PER UNIT PER MONTH
                </HorizontalText>
                <ChartTablePerUnitExp state={state} />
                <HorizontalText>
                  % OF REVENUE
                </HorizontalText>
                <ChartTableOfRevenueExp state={state} />
              </TableStyled>
            </ChartWraper>
          </WrapperStyledCharts>
        </Grid>
      </Dialog>
    </>
  );
};
export default memo(DialogWindowCharts);
