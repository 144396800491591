import { memo, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import useTooltip from 'common/hooks/useTooltip';

import { ExtendedGridCellMetrocountProps } from 'containers/research/lib/models/types';
import { COLUMN_TITLES } from './table-data';

import {
  TableHeadCellStyled,
  TableBodyCellStyled,
} from './styled';

const CellRenderer = ({
  rowIndex, columnIndex, style, data,
}: ExtendedGridCellMetrocountProps): JSX.Element | null => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  const cellKey = useMemo(
    () => rowIndex + columnIndex + Math.floor(Math.random()),
    [rowIndex, columnIndex],
  );

  const cellName = COLUMN_TITLES[columnIndex].cell;
  const cellData = data[rowIndex];

  if (rowIndex === 0) {
    return (
      <TableHeadCellStyled
        component="div"
        style={style}
        key={cellKey}
      >
        <Typography variant="body2">
          {COLUMN_TITLES[columnIndex].title}
        </Typography>
        {/** this part temporarily unavailable */}
        {/* <IconButtonStyled>
          <SortIconStyled />
        </IconButtonStyled>
        <IconButtonStyled>
          <FilterListIcon fontSize="small" />
        </IconButtonStyled> */}
      </TableHeadCellStyled>
    );
  }

  if (cellName.length > 1) {
    return (
      <Tooltip
        open={isOpen}
        title={<>{cellData[cellName]}</>}
        arrow
      >
        <TableBodyCellStyled
          component="div"
          key={cellKey}
          style={style}
        >
          <Typography
            noWrap
            variant="body2"
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            {cellData[cellName]}
          </Typography>
        </TableBodyCellStyled>
      </Tooltip>
    );
  }

  return null;
};

export default memo(CellRenderer);
