import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

const CsvIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 22 28">
    { /* eslint-disable max-len */ }
    <path d="M0.5 1.68C0.5 0.75206 1.25206 0 2.18 0H14.5L21.5 5.87999V26.32C21.5 27.2479 20.748 28 19.82 28H2.18C1.25206 28 0.5 27.2479 0.5 26.32V1.68Z" />
    <path d="M7.50114 15.0582C7.16866 14.787 6.81864 14.6203 6.3549 14.6203C5.52364 14.6203 4.92823 15.242 4.92823 16.7212C4.92823 18.1737 5.48866 18.7949 6.32038 18.7949C6.91492 18.7949 7.29162 18.5494 7.63287 18.2874L8.28911 19.1274C7.84286 19.5561 7.18615 19.9153 6.2237 19.9153C4.55246 19.9153 3.41406 18.8036 3.41406 16.7211C3.41406 14.6902 4.63073 13.5439 6.24996 13.5439C7.12497 13.5439 7.70248 13.8065 8.20997 14.2177L7.50114 15.0582Z" fill="white" />
    <path d="M10.8968 19.9153C9.79386 19.9153 8.97184 19.5307 8.47266 19.0403L9.20763 18.227C9.65434 18.6207 10.1968 18.8395 10.8443 18.8395C11.4393 18.8395 11.8418 18.5677 11.8418 18.1036C11.8418 17.6661 11.5881 17.474 10.7481 17.2106C9.35638 16.7732 8.82262 16.2219 8.82262 15.3119C8.82262 14.191 9.8551 13.5439 11.1156 13.5439C12.0347 13.5439 12.7518 13.8327 13.2773 14.3315L12.5944 15.1198C12.1831 14.7782 11.7014 14.6115 11.2289 14.6115C10.6865 14.6115 10.3268 14.8131 10.3268 15.1986C10.3268 15.5836 10.5985 15.7327 11.526 16.0298C12.6814 16.4056 13.3551 16.8782 13.3551 17.9898C13.356 19.1273 12.4289 19.9153 10.8968 19.9153Z" fill="white" />
    <path d="M16.2519 18.5587H16.2956L17.4073 13.7012H18.8948L17.1535 19.7667H15.3506L13.6172 13.7012H15.1576L16.2519 18.5587Z" fill="white" />
  </SvgIcon>
);

export default React.memo(CsvIcon);
