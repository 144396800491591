import { memo, useReducer } from 'react';

import { EditColumnContext } from './EditColumnContext';
import { editColumnReducer } from './reducer';

const EditTableProvider = memo(({ children }: {children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(editColumnReducer, { mapOfProps: new Map() });

  return (
    <EditColumnContext.Provider value={{ state, dispatch }}>
      {children}
    </EditColumnContext.Provider>
  );
});

export default EditTableProvider;
