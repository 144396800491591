import { memo } from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';

import { TypographyTabStyled } from './styled';

import { SideBarTab } from './sidebar-tabs';

interface TabItemProps{
isExpandedMenu: boolean;
item: SideBarTab
index: number
}
export const TabItem = memo(({ isExpandedMenu, item, index }: TabItemProps) => (
  isExpandedMenu
    ? (
      <div
        role="tab"
        tabIndex={index}
      >
        <div>{item.icon}</div>
        <TypographyTabStyled
          variant="subtitle2"
          color="secondary"
          component="div"
        >
          {item.text}
        </TypographyTabStyled>
      </div>
    )
    : (
      <ListItemIcon>
        {item.icon}
      </ListItemIcon>
    )
));
