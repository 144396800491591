import { memo } from 'react';

import { Dialog, Grid } from '@mui/material';

import { ResearchQuartilesSectionStyled } from 'containers/research/utils/styled';

import QuartilesDataTable from '../../print-data/quartiles';

interface IModalParams {
  open: boolean;
  close: VoidFunction;
}

const DialogWindowQuartiles = ({
  open,
  close,
}: IModalParams):JSX.Element => (
  <>
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="xxl"
    >
      <Grid
        item
        xs
      >
        <ResearchQuartilesSectionStyled>
          <QuartilesDataTable close={close} />
        </ResearchQuartilesSectionStyled>
      </Grid>
    </Dialog>
  </>
);
export default memo(DialogWindowQuartiles);
