import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    root: {
      background: PALETTE.TRANSPARENT,
      color: PALETTE.DARK_BLUE,
      minHeight: 35,
    },
    flexContainer: {
      '&:after': {
        content: '"before"',
        fontSize: 0,
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 2,
        bottom: 0,
        left: 0,
        borderRadius: 4,
        background: PALETTE.TRANSPARENT,
      },
    },
    indicator: {
      borderRadius: 4,
      background: PALETTE.MEDIUM_BLUE,
    },
  },
};
