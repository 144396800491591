import {
  memo, useCallback, useMemo, useState,
} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';

import { useCreateSubtypeData } from 'containers/research/lib/mapped-table-data';

import {
  MenuItemStyled,
  MenuStyled,
  ButtonStyled,
} from './utils/styled';

const SubtypeMenu = memo(({ selectedSubtype, setSubtype }:{selectedSubtype: string,
setSubtype: (selectedSubtype: string)=>void
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const subtypeData = useCreateSubtypeData();
  const subtypes = useMemo(() => subtypeData, [subtypeData]);

  const handleCloseSelect = useCallback((): void => {
    setAnchorEl(null);
  }, []);
  const handleClickSelect = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const subtypeSelection = ({ currentTarget }: React.MouseEvent<HTMLLIElement>): void => {
    if (currentTarget.dataset.value) {
      setSubtype(currentTarget.dataset.value as string);
    }
  };

  return (
    <>
      <ButtonStyled
        disableElevation
        onClick={handleClickSelect}
        endIcon={anchorEl
          ? <KeyboardArrowUpIcon />
          : <KeyboardArrowDownIcon />}
        color="primary"
        variant="outlined"
      >
        {selectedSubtype}
      </ButtonStyled>
      <MenuStyled
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseSelect}
        onClick={handleCloseSelect}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left',
        }}
      >
        {subtypes.map((subtype) => (
          <MenuItemStyled
            divider
            key={subtype}
            data-value={subtype}
            onClick={subtypeSelection}
          >
            <Typography variant="subtitle2">
              {subtype}
            </Typography>
          </MenuItemStyled>
        ))}
      </MenuStyled>
    </>
  );
});

export default SubtypeMenu;
