import axiosInstance from 'common/axios';

import { GEO_URL, PROPERTY_URL } from 'common/globalConstants';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';

import {
  ErrorPropertyPostReturnData,
  IPropertyData,
  LocationPointType,
  PropertyFetchDataReturnType,
  PropertyPostDataReturnType,
  PropUpdateModelPartial,
} from 'common/components/BingMap/utils/types';

import {
  PROPERTY_ARCHIVE_MESSAGE,
  PROPERTY_FINANCIAL_MESSAGE,
  PROPERTY_HEALTHCARE_MESSAGE,
  PROPERTY_POST_MESSAGE,
  PROPERTY_PUT_MESSAGE,
  PROPERTY_RESTORE_MESSAGE,
  PROPERTY_VALUATION_MESSAGE,
} from 'common/components/BingMap/utils/constants';

import { IServerError } from 'containers/login/lib/models/IServerError.interface';

const axios = axiosInstance;

export const fetchPropertyData = async (): Promise<PropertyFetchDataReturnType> => {
  let result = null;

  try {
    result = await axios.get(`${PROPERTY_URL.PROPERTIES}`);

    return result.data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getPropertyCoordinates = async (propertyData: IPropertyData)
  : Promise<LocationPointType> => {
  let result: LocationPointType = {} as LocationPointType;

  const {
    propertyAddress,
    propertyZipCode,
    propertyCounty,
    propertyCity,
    propertyState,
  } = propertyData;

  const queryParams = {
    AddressLine: propertyAddress,
    PostalCode: propertyZipCode,
    CountryRegion: propertyCounty,
    Locality: propertyCity,
    AdminDistrict: propertyState,
  };

  try {
    const res = await axios.get(`${GEO_URL}`, {
      params: queryParams,
      headers: { mapKey: process.env.REACT_APP_BING_MAP_KEY as string },
    });

    if (res.status === 204) {
      throw new Error('Please enter valid address');
    }

    if (res.data) {
      result = res.data as unknown as LocationPointType;
    }
  } catch (error) {
    const e = error as IServerError;
    const message = e.response?.data.detail
      ? `${e.response.data.title} ${e.response.data.detail}`
      : e.message;
    SnackBarUtils.error(message);
  }

  return result;
};

export const postPropertyData = async (propertyData: IPropertyData)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    const point = await getPropertyCoordinates(propertyData);
    const data = {
      ...propertyData,
      latitude: point?.latitude,
      longitude: point?.longitude,
    };

    result = await axios.post(`${PROPERTY_URL.PROPERTIES}`, data);

    SnackBarUtils.success(PROPERTY_POST_MESSAGE);
  } catch (error) {
    const e = error as IServerError;
    const message = e.response?.data.detail
      ? `${e.response.data.title} ${e.response.data.detail}`
      : e.message;
    SnackBarUtils.error(message);
  }

  return result;
};

export const postPropertyDataAll = async (propertyData: IPropertyData)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    const point = await getPropertyCoordinates(propertyData);
    const data = {
      ...propertyData,
      latitude: point?.latitude,
      longitude: point?.longitude,
    };
    const updateData = { statementYear: new Date().getFullYear(), ...data };

    result = await axios.post(`${PROPERTY_URL.PROPERTIES}`, data);
    SnackBarUtils.success(PROPERTY_POST_MESSAGE);
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/financials`, updateData);
    SnackBarUtils.success(PROPERTY_FINANCIAL_MESSAGE);
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/healthcare`, updateData);
    SnackBarUtils.success(PROPERTY_HEALTHCARE_MESSAGE);
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/valuation`, updateData);
    SnackBarUtils.success(PROPERTY_VALUATION_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const putPropertyData = async (propertyData: IPropertyData)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    const point = await getPropertyCoordinates(propertyData);
    const data = {
      ...propertyData,
      latitude: point?.latitude,
      longitude: point?.longitude,
    };

    const requestAddress = `${PROPERTY_URL.PROPERTIES}/${propertyData.propertyName}/address`;
    const requestSubtype = `${PROPERTY_URL.PROPERTIES}/${propertyData.propertyName}/attributes`;

    result = await axios.put(requestAddress, data);
    result = await axios.put(requestSubtype, propertyData);

    SnackBarUtils.success(PROPERTY_PUT_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const putPropertyDataFinancials = async (data: PropUpdateModelPartial)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/financials`, data);
    SnackBarUtils.success(PROPERTY_FINANCIAL_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const putPropertyDataValuation = async (data: PropUpdateModelPartial)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/valuation`, data);
    SnackBarUtils.success(PROPERTY_VALUATION_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const putPropertyDataHealthcare = async (data: PropUpdateModelPartial)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/${data.propertyName}/healthcare`, data);
    SnackBarUtils.success(PROPERTY_HEALTHCARE_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const archivePropertyData = async (propertyName: string)
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    result = await axios.delete(`${PROPERTY_URL.PROPERTIES}/${propertyName}/archive`);

    SnackBarUtils.success(PROPERTY_ARCHIVE_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const restorePropertyData = async ()
  : Promise<PropertyPostDataReturnType> => {
  let result: PropertyPostDataReturnType = {} as PropertyPostDataReturnType;

  try {
    result = await axios.put(`${PROPERTY_URL.PROPERTIES}/restore/all`);

    SnackBarUtils.success(PROPERTY_RESTORE_MESSAGE);
  } catch (error) {
    const err = error as ErrorPropertyPostReturnData;
    SnackBarUtils.error(
      `${err.response.data.title} ${err.response.data.detail}`,
    );
  }

  return result;
};

export const checkIsArchivedProperty = async (): Promise<{ data: boolean }> => {
  let result = {} as { data: boolean };

  try {
    result = await axios.get(`${PROPERTY_URL.PROPERTIES}/archived`);
  } catch (error) {
    SnackBarUtils.error('Failed to check is property archived');
  }

  return result;
};
