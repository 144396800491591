import Typography from '@mui/material/Typography';

import { RELOAD_BUTTON } from '../constants';

import { ReloadPanelStyled, ButtonStyled } from '../styled';

export const ReloadPanel = ({ error, refetch } : {
error : Error | null, refetch: ()=> void}): JSX.Element => {
  const handleRefetch = (): void => {
    refetch();
  };

  return (
    <ReloadPanelStyled>
      <Typography variant="h6">
        {error?.message}
      </Typography>
      <ButtonStyled
        variant="contained"
        color="secondary"
        onClick={handleRefetch}
      >
        {RELOAD_BUTTON}
      </ButtonStyled>
    </ReloadPanelStyled>
  );
};
