import { memo } from 'react';

import { FinancialContextProvider } from 'containers/input-property/local-state/FinancialContextProvider';
import { ValuationContextProvider } from 'containers/input-property/local-state/ValuationContextProvider';
import { useScreenDown } from 'common/hooks/screenSize';

import Map from './map/Map';
import PropertyData from './propertyData/PropertyData';

import {
  ItemWrapperStyled,
  MapTabContainerStyled,
  MapTabItemStyled,
} from './styled';

const MapTab = ():JSX.Element => {
  const isDowmSm = useScreenDown('sm');

  return (
    <ValuationContextProvider>
      <FinancialContextProvider>
        <MapTabContainerStyled
          container
          direction={isDowmSm ? 'column-reverse' : 'row'}
          wrap="nowrap"
        >
          <MapTabItemStyled item md={4} xl={3}>
            <ItemWrapperStyled>
              <PropertyData />
            </ItemWrapperStyled>
          </MapTabItemStyled>
          <MapTabItemStyled item md={9} xl={9}>
            <ItemWrapperStyled>
              <Map />
            </ItemWrapperStyled>
          </MapTabItemStyled>
        </MapTabContainerStyled>
      </FinancialContextProvider>
    </ValuationContextProvider>
  );
};
export default memo(MapTab);
