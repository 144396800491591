import { InfoTableTitle } from 'containers/benchmark/lib/utils/types';

export const INFO_TABLE = {
  ROWS: [
    { row: 'COMP STATUS', dataName: 'compStatus', symbol: '' },
    { row: 'DATABASE ID', dataName: 'mfcCompId', symbol: '' },
    { row: 'Property Name', dataName: 'propertyName', symbol: '' },
    { row: 'Address', dataName: 'propertyAddress', symbol: '' },
    { row: 'City', dataName: 'propertyCity', symbol: '' },
    { row: 'State', dataName: 'propertyState', symbol: '' },
    { row: 'ZIP', dataName: 'propertyZipCode', symbol: '' },
    { row: 'County', dataName: 'propertyCounty', symbol: '' },
    { row: 'CMSA', dataName: 'propertyCMSA', symbol: '' },
    { row: 'NCREIF Division', dataName: 'propertyDivision', symbol: '' },
    { row: 'NCREIF Region', dataName: 'propertyRegion', symbol: '' },
    { row: 'Latitude', dataName: 'latitude', symbol: '' },
    { row: 'Longitude', dataName: 'longitude', symbol: '' },
    { row: 'Miles From Subject', dataName: 'distanceToSubject', symbol: '' },
    { row: 'Year Built', dataName: 'yearBuilt', symbol: '' },
    { row: 'Year Renov', dataName: 'yearRenov', symbol: '' },
    { row: 'Num Units', dataName: 'numUnits', symbol: '' },
    { row: 'SubType', dataName: 'subType', symbol: '' },
    { row: 'Affordability', dataName: 'affordability', symbol: '' },
    { row: 'Population PerSqMile', dataName: 'populationPerSqMile', symbol: '' },
    { row: 'Median HomeVal', dataName: 'medianHomeVal', symbol: '$' },
    { row: 'Median HHInc', dataName: 'medianHHInc', symbol: '$' },
    { row: 'Median Age', dataName: 'medianAge', symbol: '' },
    { row: 'Median Rent', dataName: 'medianRent', symbol: '$' },
    { row: 'Median Owner Costs', dataName: 'medianOwnerCosts', symbol: '$' },
    { row: 'Renter PCT', dataName: 'renterPCT', symbol: '%' },
    { row: 'Rent Over30pct HHInc PCT', dataName: 'rentOver30PctHHIncPCT', symbol: '%' },
    { row: 'Bachelors Degree PCT', dataName: 'bachelorsDegreePCT', symbol: '%' },
    { row: 'Unemployment PCT', dataName: 'unemploymentPCT', symbol: '%' },
    { row: 'Mgt Finance Job PCT', dataName: 'mgtFinanceJobPCT', symbol: '%' },
    { row: 'MovedIn After2010 PCT', dataName: 'movedInAfter2010PCT', symbol: '%' },
  ] as InfoTableTitle[],
} as const;

export const TABLE_SETTINGS = {
  COLUMN_WIDTH: 300,
  COLUMN_COUNT: INFO_TABLE.ROWS.length - 1,
  FIXED_COLUMN_COUNT: 1,
  FIXED_ROW_COUNT: 0,
  ROW_HEIGHT: 45,
  ROW_COUNT: INFO_TABLE.ROWS.length,
} as const;
