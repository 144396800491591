import React, { useMemo } from 'react';

import { filtersReducer } from './filtersReducer';
import { FiltersContext } from './FiltersContext';
import { defaultFilters } from '../utils/constants';

export const FiltersContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [filtersState, setFiltersState] = React.useReducer(filtersReducer, defaultFilters);

  const FiltersContextValue = useMemo(() => ({
    state: filtersState,
    dispatch: setFiltersState,
  }), [filtersState]);

  return (
    <FiltersContext.Provider value={FiltersContextValue}>
      {children}
    </FiltersContext.Provider>
  );
};
