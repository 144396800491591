import '../Insights/styles.css';
import cycle from 'assets/dvc.png';

import { ProductTabStyled } from 'containers/main/app-bar/Insights/styled';

const DatabaseTab = ():JSX.Element => (
  <ProductTabStyled>
    <section className="dvc">
      <div className="container">
        <div className="dvc__content">
          <div>
            <h2 className="dvc__headline">
              Database, Verification, Confidentiality
            </h2>
            <p className="dvc__text">
              A core component of the Statistical
              Valuation System (STATVAL) is proprietary software that extracts
              and repurposes raw CMBS files for Advanced Statistics and
              Automated Property-Level Analytics.
            </p>
          </div>
          <img
            src={cycle}
            alt="Database Graphic"
            className="dvc__image show-for-d-only scroll-element js-scroll slide-right"
          />
        </div>
        <hr />
        <ul className="dvc__value-props">
          <li className="dvc__value-prop">
            45,000+ Appraised Values and Underwritten Financials and 150,000+
            serialized Operating Statements extracted from CMBS Offering
            Circulars and Trustee Reports
          </li>
          <li className="dvc__value-prop">
            Primary concentration is Garden style apartments serving middle
            income households. Also includes significant groupings of
            Mid-Rise, High-Rise, Senior, Healthcare, Student, and Manufactured
            Housing.
          </li>
          <li className="dvc__value-prop">
            Integrated with Tract-Level Census Data used to calculate
            Demographic Profiles for each Database and Client Property.
            Includes range of Affordability Categories consistent with FHFA
            and ESG mandates.
          </li>
          <li className="dvc__value-prop">
            All Client Property Data is saved on segregated data tables for
            the exclusive usage by the respective Client and specifically NOT
            co-mingled with the broader Database or made available to
            other Clients.
          </li>
        </ul>
      </div>
    </section>
  </ProductTabStyled>
);

export default DatabaseTab;
