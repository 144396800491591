export const TEXT = {
  LOGIN: 'Login',
  HEADER: {
    LOGIN: 'Login to your account',
    CREATE: 'Create new account',
  },
  EMAIL_PLACEHOLDER: 'John.snow@gmail.com',
  NAME_PLACEHOLDER: 'John Doe',
  PASSWORD_PLACEHOLDER: '•••••••••',
  EMAIL: 'Email',
  NAME: 'Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password?',
  LOGIN_NOW: 'Login Now',
  REGISTER_NOW: 'Register Now',
  NO_ACCOUNT: 'No account?',
  HAVE_ACCOUNT: 'Already have an account?',
  CREATE_ONE: 'Create one!',
  REGISTRATION_SUCCESS: 'Registration successfully!',
  REGISTRATION_FIELDS_NAMES: {
    EMAIL: 'personEmail',
    NAME: 'clientName',
    PW: 'clientPw',
    CONFIRM_PW: 'confirmPw',
  },
  PASSWORD_PATTERN: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9!@#$%^&*(),.;]{8,128}$',
  EMAIL_PATTERN: '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.'
    + '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  VALIDATION_MESSAGE:
    'Must be at least 8 characters, contain at least one number and one uppercase and lowercase letter.'
    + ' Can contain only latin letters, numbers and symbols: @#$%^&*(),.; ',
} as const;

export const AUTOCOMPLETE = {
  CURRENT_PASSWORD: 'current-password',
  NEW_PASSWORD: 'new-password',
  CONFIRM_PASSWORD: 'confirm-password',
} as const;
