import { memo } from 'react';

import { REQUIRED_TABLE } from 'containers/input-property/utils/constants';
import { FormRegister } from 'containers/input-property/components/mapTab/map/utils/constants';
import { Tooltip, Typography } from '@mui/material';
import useTooltip from 'common/hooks/useTooltip';
import InputRenderer from './InputRenderer';

import {
  StyledTableCell,
  StyledTableCellName,
  StyledTableRow,
} from '../styled';

const RowRenderer = ({ register }: {register: FormRegister, }):JSX.Element => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  return (

    <>
      {REQUIRED_TABLE.ROWS.map((row, index) => (
        <StyledTableRow key={row}>
          <StyledTableCellName component="th" scope="row">
            <Tooltip
              open={isOpen}
              title={(
                <Typography variant="body2">
                  {row.toUpperCase()}
                </Typography>
              )}
              arrow
            >
              <Typography
                noWrap
                variant="body2"
                onMouseMove={onShowTooltip}
                onMouseOut={onHideTooltip}
                component="span"
              >
                {row}
              </Typography>
            </Tooltip>
          </StyledTableCellName>
          <StyledTableCell>
            <InputRenderer row={row} index={index} register={register} />
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export default memo(RowRenderer);
