import { AxiosResponse } from 'axios';

import jwtDecode from 'jwt-decode';

import axiosInstance from 'common/axios';
import { ACCOUNT_URL } from 'common/globalConstants';

import { IServerError } from 'containers/login/lib/models/IServerError.interface';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { IUser } from './lib/models/IUser.interface';
import { storage } from './utils/local-storage';
import { LogoutResponseType, AuthResponseType } from './utils/TokenType';
import { IUserData } from './lib/models/ResponseData.interface';
import { AccountData } from './utils/types';

const axios = axiosInstance;

export function handleApiResponse(response: AxiosResponse<IUser | string>): AuthResponseType {
  if (response.status === 200) {
    return response.headers as AuthResponseType;
  }
  return response.headers as AuthResponseType;
}

export async function getUserToken(inputJWT?: string): Promise<IUserData> {
  let user = null;

  if (!inputJWT) {
    const token = storage.getAccessToken() as string;
    const decodedJWT: IUserData = jwtDecode(token);
    user = decodedJWT;

    return user;
  }
  const decodedJWT: IUserData = jwtDecode(inputJWT);
  user = decodedJWT;

  return user;
}

export async function logOut(): Promise<LogoutResponseType> {
  let result = null;

  try {
    const token = storage.getAccessToken();
    result = await axios.post(
      '/account/logout',
      {} as never,
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return result.data;
  } catch (error) {
    storage.clearAll();
  }
  return result?.data as unknown as LogoutResponseType;
}

export async function logIn(): Promise<AccountData> {
  let result = null;

  try {
    result = await axios.get('/account');
  } catch (error) {
    SnackBarUtils.error('SESSION IS INVALID');
  }

  return result?.data as unknown as AccountData;
}

export async function relogin(): Promise<AuthResponseType> {
  let result: AxiosResponse<AuthResponseType> | null = null;

  const refreshToken = storage.getRefreshToken();
  if (refreshToken) {
    try {
      result = await axios.post('/account/relogin', {}, {
        headers: {
          'Refresh-Token': refreshToken,
        },
      });
    } catch (error) {
      storage.clearAll();
      await logOut();
    }

    if (result?.status === 200) {
      const { headers } = result;
      const decodedJWT: IUserData = jwtDecode(headers.authorization);

      storage.setAccessToken(headers.authorization);
      storage.setRefreshToken(headers['refresh-token']);

      storage.setExpiresAt(+decodedJWT.exp);
      storage.setExpiresIat(+decodedJWT.iat);
    }

    return result as unknown as AuthResponseType;
  }

  return result as unknown as AuthResponseType;
}

export async function loginWithEmailAndPassword(data: IUser): Promise<AuthResponseType> {
  return axios.post('/account/login', { ...data, clientType: 'WEB' })
    .then(handleApiResponse);
}

export async function registerWithEmailAndPassword(
  data: IUser,
): Promise<AuthResponseType> {
  let result = null;

  try {
    const res = await axios.post(`${ACCOUNT_URL.ACCOUNT}/register`, { ...data });

    result = handleApiResponse(res as AxiosResponse<IUser>);
  } catch (error) {
    const e = error as IServerError;
    const message = e.response.data.detail ? `${e.response.data.title} ${e.response.data.detail}` : e.message;

    throw new Error(message.toString());
  }

  return result as AuthResponseType;
}
