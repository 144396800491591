import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
);

const ChartTableOfRevenueExp = ({ state }: { state: BenchmarkAnalyticsModel[] }): JSX.Element | null => {
  const bgColor = {
    id: 'bgColor',
    beforeDraw: (chart: { ctx: any; width: any; height: any; }, options: any) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };
  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback(value: any) {
            return `${value}%`;
          },
        },
      },
    },
    plugins: {
      bgColor,
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label(context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US').format(context.parsed.y);
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'top' as const,
        font: {
          size: 9,
        },
        padding: {
          bottom: -9,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'EXPENSE LINE-ITEMS (% OF REVENUE)',
      },
      subtitle: {
        display: true,
        text: 'VERSUS COMPSET MEDIANS AND QUARTILES',
      },
    },
  };

  const labels = ['RETAXES PCTREV', 'PROPINS PCTREV', 'UTILITI PCTREV', 'REPAIRS PCTREV',
    'MANAGEM PCTREV', 'PAYROLL PCTREV', 'MARKETI PCTREV', 'GENERAL PCTREV'];

  if (!state) return null;
  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.5,
        label: 'CLIENT PROPERTY',
        data: [(state[0].retaxesPctRev * 100).toFixed(1), (state[0].propinsPctRev * 100).toFixed(1),
          (state[0].utilitiPctRev * 100).toFixed(1), (state[0].repairsPctRev * 100).toFixed(1),
          (state[0].managemPctRev * 100).toFixed(1), (state[0].payrollPctRev * 100).toFixed(1),
          (state[0].marketiPctRev * 100).toFixed(1), (state[0].generalPctRev * 100).toFixed(1)],
        backgroundColor: 'rgb(68, 116 , 193)',
      },
      {
        barPercentage: 0.5,
        label: '1ST QUARTILE',
        data: [(state[2].retaxesPctRev * 100).toFixed(1), (state[2].propinsPctRev * 100).toFixed(1),
          (state[2].utilitiPctRev * 100).toFixed(1), (state[2].repairsPctRev * 100).toFixed(1),
          (state[2].managemPctRev * 100).toFixed(1), (state[2].payrollPctRev * 100).toFixed(1),
          (state[2].marketiPctRev * 100).toFixed(1), (state[2].generalPctRev * 100).toFixed(1)],
        backgroundColor: 'rgb(85, 129 , 59)',
      },
      {
        barPercentage: 0.5,
        label: 'MEDIAN',
        data: [(state[3].retaxesPctRev * 100).toFixed(1), (state[3].propinsPctRev * 100).toFixed(1),
          (state[3].utilitiPctRev * 100).toFixed(1), (state[3].repairsPctRev * 100).toFixed(1),
          (state[3].managemPctRev * 100).toFixed(1), (state[3].payrollPctRev * 100).toFixed(1),
          (state[3].marketiPctRev * 100).toFixed(1), (state[3].generalPctRev * 100).toFixed(1)],
        backgroundColor: 'rgb(254, 190 , 48)',
      },
      {
        barPercentage: 0.5,
        label: '4TH QUARTILE',
        data: [(state[4].retaxesPctRev * 100).toFixed(1), (state[4].propinsPctRev * 100).toFixed(1),
          (state[4].utilitiPctRev * 100).toFixed(1), (state[4].repairsPctRev * 100).toFixed(1),
          (state[4].managemPctRev * 100).toFixed(1), (state[4].payrollPctRev * 100).toFixed(1),
          (state[4].marketiPctRev * 100).toFixed(1), (state[4].generalPctRev * 100).toFixed(1)],
        backgroundColor: 'rgb(253, 0 , 19)',
      },
    ],
  };
  return (
    <Bar
      style={{ border: '2px solid rgb(68, 116 , 193)' }}
      width={50}
      height={25}
      options={options}
      data={data}
    />
  );
};
export default ChartTableOfRevenueExp;
