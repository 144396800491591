import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  SubTitle,
  Tooltip,
  Legend,
);

const ChartTablePerUnitExp = ({ state }:{ state:BenchmarkAnalyticsModel[] }):JSX.Element | null => {
  const bgColor = {
    id: 'bgColor',
    beforeDraw: (chart: { ctx: any; width: any; height: any; }, options: any) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };
  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback(value: any) {
            return `$${value}`;
          },
        },
      },
    },
    plugins: {
      bgColor,
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label(context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'top' as const,
        font: {
          size: 9,
        },
        padding: {
          bottom: -9,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'EXPENSE LINE-ITEMS ($ PER UNIT PER MONTH)',
      },
      subtitle: {
        display: true,
        text: 'VERSUS COMPSET MEDIANS AND QUARTILES',
      },
    },
  };

  const labels = ['RETAXES PERUNIT', 'PROPINS PERUNIT', 'UTILITI PERUNIT',
    'REPAIRS PERUNIT', 'MANAGEM PERUNIT', 'PAYROLL PERUNIT', 'MARKETI PERUNIT', 'GENERAL PERUNIT'];

  if (!state) return null;
  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.5,
        label: 'CLIENT PROPERTY',
        data: [state[0].retaxesPerUnit.toFixed(0),
          state[0].propinsPerUnit.toFixed(0),
          state[0].utilitiPerUnit.toFixed(0),
          state[0].repairsPerUnit.toFixed(0),
          state[0].managemPerUnit.toFixed(0),
          state[0].payrollPerUnit.toFixed(0),
          state[0].marketiPerUnit.toFixed(0),
          +state[0].generalPerUnit.toFixed(0)],
        backgroundColor: 'rgb(68, 116 , 193)',
      },
      {
        barPercentage: 0.5,
        label: '1ST QUARTILE',
        data: [state[2].retaxesPerUnit.toFixed(0),
          state[2].propinsPerUnit.toFixed(0),
          state[2].utilitiPerUnit.toFixed(0),
          state[2].repairsPerUnit.toFixed(0),
          state[2].managemPerUnit.toFixed(0),
          state[2].payrollPerUnit.toFixed(0),
          state[2].marketiPerUnit.toFixed(0),
          state[2].generalPerUnit.toFixed(0)],
        backgroundColor: 'rgb(85, 129 , 59)',
      },
      {
        barPercentage: 0.5,
        label: 'MEDIAN',
        data: [state[3].retaxesPerUnit.toFixed(0),
          state[3].propinsPerUnit.toFixed(0),
          state[3].utilitiPerUnit.toFixed(0),
          state[3].repairsPerUnit.toFixed(0),
          state[3].managemPerUnit.toFixed(0),
          state[3].payrollPerUnit.toFixed(0),
          state[3].marketiPerUnit.toFixed(0),
          state[3].generalPerUnit.toFixed(0)],
        backgroundColor: 'rgb(254, 190 , 48)',
      },
      {
        barPercentage: 0.5,
        label: '4TH QUARTILE',
        data: [state[4].retaxesPerUnit.toFixed(0),
          state[4].propinsPerUnit.toFixed(0),
          state[4].utilitiPerUnit.toFixed(0),
          state[4].repairsPerUnit.toFixed(0),
          state[4].managemPerUnit.toFixed(0),
          state[4].payrollPerUnit.toFixed(0),
          state[4].marketiPerUnit.toFixed(0),
          state[4].generalPerUnit.toFixed(0)],
        backgroundColor: 'rgb(253, 0 , 19)',
      },
    ],
  };
  return (
    <Bar
      style={{ border: '2px solid rgb(68, 116 , 193)' }}
      width={50}
      height={25}
      options={options}
      data={data}
    />
  );
};
export default ChartTablePerUnitExp;
