import { createContext } from 'react';

export type BenchmarkAnalyticsCallbackAction = { type: 'call' | 'cancel'; };

export type BenchmarkAnalyticsCallbackDispatch = (action: BenchmarkAnalyticsCallbackAction) => void;

export type BenchmarkAnalyticsCallbackState = { callback: () => void; } | { callback: null; };

export const BenchmarkAnalyticsCallbackContext = createContext<
  { state: BenchmarkAnalyticsCallbackState; dispatch: BenchmarkAnalyticsCallbackDispatch; } | undefined
>(undefined);
