import BuildingIcon from '../icons/BuildingIcon';
import CallIcon from '../icons/CallIcon';
import CartIcon from '../icons/bing-map/CartIcon';
import FoldersIcon from '../icons/FoldersIcon';
import GiveMeMoneyIcon from '../icons/GiveMeMoneyIcon';
import SubscriptionIcon from '../icons/SubscriptionIcon';
import { ROUTE } from '../routes/utils/constants';
import StatisticsFile from '../icons/StatisticsFile';

export type SideBarTab = {
  text: string;
  icon: JSX.Element;
  route: string;
}

const SIDEBAR_TABS: SideBarTab[] = [
  {
    text: 'CLIENT PORTFOLIO',
    icon: <BuildingIcon color="secondary" />,
    route: ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT,
  },
  {
    text: 'COMPSETS',
    icon: <FoldersIcon color="secondary" />,
    route: ROUTE.ACCOUNT.COMPSETS.MAP,
  },
  {
    text: 'BENCHMARKS',
    icon: <SubscriptionIcon color="secondary" />,
    route: ROUTE.ACCOUNT.BENCHMARK_DEFAULT,
  },
  {
    text: 'VALUATION',
    icon: <StatisticsFile color="secondary" />,
    route: ROUTE.STATVAL,
  },
  {
    text: 'RESEARCH',
    icon: <GiveMeMoneyIcon color="secondary" />,
    route: ROUTE.ACCOUNT.RESEARCH_DEFAULT,
  },
  // this route temporarily unavailable
  // {
  //   text: 'CMBS',
  //   icon: <RocketIcon color="secondary" />,
  //   route: ROUTE.ACCOUNT.CMBS,
  // },
  {
    text: 'DATA CREDITS',
    icon: <CartIcon color="secondary" />,
    route: ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT,
  },
  {
    text: 'CONTACT',
    icon: <CallIcon color="secondary" />,
    route: ROUTE.HOME.CONTACT,
  },
];

export default SIDEBAR_TABS;
