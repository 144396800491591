import {
  memo,
  useMemo,
  useState,
} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { RESEARCH } from 'containers/research/utils/constants';
import { useMetrocounts } from 'containers/research/utils/hooks';
import { ModalWindow } from './modal/ModalWindow';
import RegionMenu from './region-menu/RegionMenu';
import SubtypeMenu from './subtype-menu/SubtypeMenu';
import Table from './table';

import {
  HeaderStyled,
  MenuWrapper,
  BoxStyled,
} from '../styled';

const NewSubscription = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const {
    selectedProperty: { region, subtype },
    queryData,
    handleSetRegion,
    handleSetSubtype,
    handleSubscribeDataset,
  } = useMetrocounts();

  const regionTitle = useMemo(() => region || RESEARCH.BUTTON_TEXT.REION, [region]);
  const subtypeTitle = RESEARCH.BUTTON_TEXT.SUBTYPE;

  // const handleClickOpen = (): void => {
  //   setOpen(true);
  // };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <BoxStyled>
      <HeaderStyled>
        <Typography
          variant="h5"
          component="div"
        >
          {RESEARCH.TITLES.NEW_SUBSCRIPTION}
        </Typography>
        {/** THIS BUTTON UNAVAILABLE IN THE CURRENT MOMENT */}
        {/* <ButtonStyled
          variant="outlined"
          onClick={handleClickOpen}
        >
          {RESEARCH.BUTTON_TEXT.VIEW_DATASET}
        </ButtonStyled> */}
      </HeaderStyled>
      <MenuWrapper
        justifyContent="space-between"
      >
        <RegionMenu
          selectedRegion={regionTitle}
          setRegion={handleSetRegion}
        />
        <SubtypeMenu
          selectedSubtype={subtype || subtypeTitle}
          setSubtype={handleSetSubtype}
        />
      </MenuWrapper>
      <Table
        data={(region && subtype) ? queryData?.data : undefined}
        props={{ region, subtype }}
      />
      <Button
        color="secondary"
        variant="contained"
        style={{ marginRight: 'auto' }}
        onClick={handleSubscribeDataset}
        disabled={!region || !subtype || queryData === undefined}
      >
        {RESEARCH.BUTTON_TEXT.SUBSCRIBE_TO_DATASET}
      </Button>
      <ModalWindow
        open={open}
        close={handleClose}
      />
    </BoxStyled>
  );
};

export default memo(NewSubscription);
