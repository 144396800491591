import { styled } from '@mui/material';

import Button from '@mui/material/Button';

export const ReloadPanelStyled = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ButtonStyled = styled(Button)(({ theme }) => ({
  width: 'auto',
  backgroundColor: theme.palette.background.blue,
  color: theme.palette.background.white,
  padding: theme.spacing(3, 7.5),
  bottom: theme.spacing(6),
  fontSize: theme.spacing(3.5),
  marginTop: theme.spacing(10),
}));
