import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';

interface BenchmarkAnalytics {
  row: string,
  dataName: keyof BenchmarkAnalyticsModel,
  symbol: string;
  positive: string,
}

export const BENCHMARK_TABLE = {
  COLUMNS: [
    'BENCHMARK COMPARISONS',
    'CLIENT PROPERTY',
    'CLIENT VS MEDIAN',
    '1ST QUARTILE',
    'MEDIAN',
    '4TH QUARTILE',
  ],
  // TODO: Fill the values
  ROWS: [
    {
      row: 'Year Built', dataName: 'yearBuilt', symbol: '', positive: 'green',
    },
    {
      row: 'Year Built-Renov', dataName: 'yearBuiltRenov', symbol: '', positive: 'green',
    },
    {
      row: 'Num Units', dataName: 'numUnits', symbol: '', positive: 'green',
    },
    {
      row: 'Population PerSqMile', dataName: 'populationPerSqMile', symbol: 'int', positive: 'green',
    },
    {
      row: 'Median HomeVal', dataName: 'medianHomeVal', symbol: '$', positive: 'green',
    },
    {
      row: 'Median HHInc', dataName: 'medianHHInc', symbol: '$', positive: 'green',
    },
    {
      row: 'Median Age', dataName: 'medianAge', symbol: '', positive: 'green',
    },
    {
      row: 'Median Rent', dataName: 'medianRent', symbol: '$', positive: 'green',
    },
    {
      row: 'Median Owner Costs', dataName: 'medianOwnerCosts', symbol: '$', positive: 'green',
    },
    {
      row: 'Renter PCT', dataName: 'renterPCT', symbol: '%', positive: 'green',
    },
    {
      row: 'Rent Over30pct HHInc PCT', dataName: 'rentOver30PctHHIncPCT', symbol: '%', positive: 'green',
    },
    {
      row: 'Bachelors Degree PCT', dataName: 'bachelorsDegreePCT', symbol: '%', positive: 'green',
    },
    {
      row: 'Unemployment PCT', dataName: 'unemploymentPCT', symbol: '%', positive: 'green',
    },
    {
      row: 'Mgt Finance Job PCT', dataName: 'mngtFinanceJobPCT', symbol: '%', positive: 'green',
    },
    {
      row: 'MovedIn After2010 PCT', dataName: 'movedInAfter2010PCT', symbol: '%', positive: 'green',
    },
    {
      row: 'VALUATION TYPE', dataName: 'valTypeBenchmark', symbol: '', positive: 'black',
    },
    {
      row: 'VALUATION DATE', dataName: 'valuationDate', symbol: 'date', positive: '',
    },
    {
      row: 'VALUATION_PERUNIT', dataName: 'valuationPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'UW_REV_PERUNIT', dataName: 'uwRevPerUnitBenchmark', symbol: '$', positive: 'green',
    },
    {
      row: 'UW_EXP_PERUNIT', dataName: 'uwExpPerUnitBenchmark', symbol: '$', positive: 'red',
    },
    {
      row: 'UW_NOI_PERUNIT', dataName: 'uwNoiPerUnitBenchmark', symbol: '$', positive: 'green',
    },
    {
      row: 'UW_CAPRATE', dataName: 'uwCaprateBenchmark', symbol: '2%', positive: 'red',
    },
    {
      row: 'STATEMENT TYPE', dataName: 'statementType', symbol: '', positive: 'black',
    },
    {
      row: 'STATEMENT DATE', dataName: 'statementDate', symbol: 'date', positive: '',
    },
    {
      row: 'OCCUP', dataName: 'occup', symbol: '%', positive: 'green',
    },
    {
      row: 'REV PERUNIT', dataName: 'revPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'EXP PERUNIT', dataName: 'expPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'NOI PERUNIT', dataName: 'noiPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'CAPEX PERUNIT', dataName: 'capexPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'EXP PCTREV', dataName: 'expPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'NOI PCTREV', dataName: 'noiPctRev', symbol: '%', positive: 'green',
    },
    {
      row: 'CAPEX PCTREV', dataName: 'capexPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'BASERNT PCTREV', dataName: 'baserntPctRevBenchmark', symbol: '%', positive: 'green',
    },
    {
      row: 'OTHERIN PCTREV', dataName: 'otherinPctRevBenchmark', symbol: '%', positive: 'green',
    },
    {
      row: 'RETAXES PCTREV', dataName: 'retaxesPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'PROPINS PCTREV', dataName: 'propinsPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'UTILITI PCTREV', dataName: 'utilitiPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'REPAIRS PCTREV', dataName: 'repairsPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'MANAGEM PCTREV', dataName: 'managemPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'PAYROLL PCTREV', dataName: 'payrollPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'MARKETI PCTREV', dataName: 'marketiPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'GENERAL PCTREV', dataName: 'generalPctRev', symbol: '%', positive: 'red',
    },
    {
      row: 'OTHEREX PCTREV', dataName: 'otherexPctRevBenchmark', symbol: '%', positive: 'red',
    },
    {
      row: 'BASERNT PERUNIT', dataName: 'baserntPerUnitBenchmark', symbol: '$', positive: 'green',
    },
    {
      row: 'OTHERIN PERUNIT', dataName: 'otherinPerUnitBenchmark', symbol: '$', positive: 'green',
    },
    {
      row: 'RETAXES PERUNIT', dataName: 'retaxesPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'PROPINS PERUNIT', dataName: 'propinsPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'UTILITI PERUNIT', dataName: 'utilitiPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'REPAIRS PERUNIT', dataName: 'repairsPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'MANAGEM PERUNIT', dataName: 'managemPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'PAYROLL PERUNIT', dataName: 'payrollPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'MARKETI PERUNIT', dataName: 'marketiPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'GENERAL PERUNIT', dataName: 'generalPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'OTHEREX PERUNIT', dataName: 'otherexPerUnitBenchmark', symbol: '$', positive: 'red',
    },
    {
      row: 'HC INC PVTPAY PCTREV', dataName: 'hcIncPvtPayPctRev', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC MEDCARE PCTREV', dataName: 'hcIncMedcarePctRev', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC NURSING PCTREV', dataName: 'hcIncNursingPctRev', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC MEALS PCTREV', dataName: 'hcIncMealsPctRev', symbol: '$', positive: 'green',
    },
    {
      row: 'HC EXP ROOMS PCTREV', dataName: 'hcExpRoomsPctRev', symbol: '$', positive: 'red',
    },
    {
      row: 'HC EXP MEALS PCTREV', dataName: 'hcExpMealsPctRev', symbol: '$', positive: 'red',
    },
    {
      row: 'HC INC PVTPAY PERUNIT', dataName: 'hcIncPvtPayPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC MEDCARE PERUNIT', dataName: 'hcIncMedcarePerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC NURSING PERUNIT', dataName: 'hcIncNursingPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'HC INC MEALS PERUNIT', dataName: 'hcIncMealsPerUnit', symbol: '$', positive: 'green',
    },
    {
      row: 'HC EXP ROOMS PERUNIT', dataName: 'hcExpRoomsPerUnit', symbol: '$', positive: 'red',
    },
    {
      row: 'HC EXP MEALS PERUNIT', dataName: 'hcExpMealsPerUnit', symbol: '$', positive: 'red',
    },
  ] as Readonly<BenchmarkAnalytics>[],
} as const;

export const TABLE_SETTINGS = {
  COLUMN_WIDTH: 185,
  COLUMN_COUNT: BENCHMARK_TABLE.COLUMNS.length,
  FIXED_COLUMN_COUNT: 1,
  FIXED_ROW_COUNT: 1,
  ROW_HEIGHT: 28,
  ROW_COUNT: BENCHMARK_TABLE.ROWS.length + 1,
} as const;
