import { PALETTE } from 'common/theme/palette/palette';

export const DEFAULT_MAP_ZOOM = 4 as const;
export const PIN_MAP_ZOOM = 8 as const;
export const MAP_CENTER_LATITUDE = 42 as const;
export const MAP_CENTER_LONGITUDE = -95.41544 as const;

export const INFOBOX_OFFSET_X = -160 as const;
export const INFOBOX_OFFSET_Y = 40 as const;

export const PIN_DEFAULT_SIZE = 45 as const;

export const HEADER_TEXT = {
  SELECT: 'Select Property from Client Portfolio',
  CHECKBOX: 'Include trial comps',
  BUTTON: 'display Compset for clientproperty',
};

export const INFOBOX_TEXT = {
  COUNRTY: 'Country prop: ',
  YEAR_BUILD: 'Year built: ',
  YEAR_RENOV: 'Year renov: ',
  NUM_UNITS: 'Num units: ',
  SUBTYPE: 'Subtype: ',
  AFFORDABILITY: 'Affordability: ',
  TOP_LINE_REVYEAR: 'Top-line REV year: ',
  FULL_LINE_ITEM_FFYEAR: 'Full-line item FF year: ',
};

export const BUTTONS_TEXT = {
  DISPLAY_PROPERTY_DATA: 'DISPLAY PROPERTY DATA',
  REMOVE: 'REMOVE',
  ADD: 'ADD',
  IN_CART: 'In cart',
} as const;

export const PINS = {
  YOUR: {
    TEXT: 'Client Property',
    COLORS: {
      MAIN: PALETTE.BLACK,
      CIRCLE: PALETTE.BLACK,
      STROKE: PALETTE.WHITE,
    },
  },
  PURCHASED: {
    TEXT: 'Subscribed comp',
    COLORS: {
      MAIN: PALETTE.PIN_YELLOW,
      CIRCLE: PALETTE.PIN_GREEN,
      STROKE: PALETTE.PIN_GREEN,
    },
  },
  FOR_PURCHASE: {
    TEXT: 'Available Comp',
    COLORS: {
      MAIN: PALETTE.PIN_YELLOW,
      CIRCLE: PALETTE.PIN_YELLOW_LIGHT,
      STROKE: PALETTE.PIN_YELLOW_LIGHT,
    },
  },
  PROPERTY_IN_CART: {
    TEXT: 'Available Comp (Saved to Client Property)',
    COLORS: {
      MAIN: PALETTE.ERROR,
      CIRCLE: PALETTE.PIN_YELLOW_LIGHT,
      STROKE: PALETTE.BLACK,
      TYPE: 'Save compset',
    },
  },
  PURCHASED_IN_CART: {
    TEXT: 'Subscribed Comp (Saved to Client Property)',
    COLORS: {
      MAIN: PALETTE.PIN_YELLOW,
      CIRCLE: PALETTE.PIN_GREEN,
      STROKE: PALETTE.BLACK,
      TYPE: 'Save compset',
    },
  },
} as const;

export type PinsType = typeof PINS;

export const PROPERTY_REFRESH_MESSAGE = 'Property successfully loaded!' as const;
export const PROPERTY_POST_MESSAGE = 'Property successfully added!' as const;
export const PROPERTY_PUT_MESSAGE = 'Property successfully updated!' as const;
export const PROPERTY_ARCHIVE_MESSAGE = 'Property successfully archived!' as const;
export const PROPERTY_RESTORE_MESSAGE = 'Property successfully restored!' as const;

export const PROPERTY_ADDRESS_MESSAGE = 'Address data updated' as const;
export const PROPERTY_ATTRIBUTES_MESSAGE = 'Attributes data updated' as const;
export const PROPERTY_FINANCIAL_MESSAGE = 'Financial data updated' as const;
export const PROPERTY_HEALTHCARE_MESSAGE = 'Healthcare data updated' as const;
export const PROPERTY_VALUATION_MESSAGE = 'Valuation data updated' as const;

export enum ViewPinType {
  BLACK = 10,
  YELLOW_STROKE = 0,
  GREEN_STROKE = 1,
  YELLOW = 2,
  GREEN = 3,
}
