import { useEffect, useState } from 'react';

import { useEditColumn } from 'common/components/providers/EditTableProvider/hooks';

type InputType = string | number | Date | boolean;

type ReturnType = {
  value: InputType,
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void,
}

export const useCellElement = (
  text: string | number,
  propertyName: string,
  row: string,
): ReturnType => {
  const { state, dispatch } = useEditColumn();
  const [value, setValue] = useState<InputType>(text);

  useEffect(() => {
    const val = state.mapOfProps.get(propertyName) as { [key: string]: InputType };
    if (val) {
      setValue(val[row]);
    } else {
      setValue(text);
    }
  }, [propertyName, row, state, text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'update',
      value: event.target.value,
      propName: propertyName,
      fieldName: row,
    });
  };

  return { value, onChangeHandler, onBlurHandler };
};
