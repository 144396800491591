import { AxiosResponse } from 'axios';
import {
  QueryFunctionContext,
  QueryObserver,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { QUERY_KEY } from 'common/query-keys';
import { useCurrentPropertyContext, useCompsPricesContext } from 'context/hooks';
import { CompsPriceData } from 'containers/research/lib/models/types';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { PriceActionType } from 'context/CompsPricesContext';
import { FilterBodyRequest, FilteredCompsets } from './models/FilteredCompsets.interface';
import { CompsetsFromServer } from './models/SubscribedCompsets.interface';
import { SubscribeProps } from './models/types';
import { CompsetsProperties } from './models/CompsetsProperties.interface';
import { ISavedCompsets } from './models/SavedCompsets.interface';
import * as compsetsApi from './api';

export function useGetFilteredCompsets():
  UseMutationResult<AxiosResponse<FilteredCompsets[]>, Error, unknown, unknown> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: FilterBodyRequest | unknown) => compsetsApi.filterCompsets(params as FilterBodyRequest),
    {
      onSuccess: async (data, params) => {
        if (data.data) {
          queryClient.setQueryData([QUERY_KEY.FILTERED_PROPERTIES], data);
          const observer = new QueryObserver(queryClient, {
            queryKey: QUERY_KEY.FILTERED_PROPERTIES,
            queryFn: () => compsetsApi.filterCompsets(params as FilterBodyRequest),
          });
          observer.subscribe();
        }
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<FilteredCompsets[]>, Error, unknown, unknown>;
}

export function useCompsetsByAlgorithm(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (currentProperty: string | unknown) => compsetsApi.compsetsByAlgorithm(currentProperty as string),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData([QUERY_KEY.FILTERED_PROPERTIES], data);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useGetSubscribedCompsets(): UseQueryResult<CompsetsFromServer[], Error> {
  const { state } = useCurrentPropertyContext();
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.SUBSCRIBED_COMPSETS,
    () => compsetsApi.subscribedCompsets(state.currentProperty || ''),
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.SUBSCRIBED_COMPSETS),
      enabled: true,
      retry: 0,
    },
  );
}

export function useGetSavedCompsets(): UseQueryResult<ISavedCompsets[], Error> {
  const { state } = useCurrentPropertyContext();
  const { dispatch: editPriceState } = useCompsPricesContext();
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.SAVED_COMPSETS,
    () => compsetsApi.savedCompsets(state.currentProperty || ''),
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.SAVED_COMPSETS),
      enabled: true,
      retry: 0,
      onSuccess: (data) => editPriceState(({ savedProperties: data, type: PriceActionType.SavedProperties })),
    },
  );
}

export function useGetCompPrice():
  UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: { compId: string, propertyName: string; } | unknown) => compsetsApi
      .fetchCompPrice(params as { compId: string, propertyName: string; }),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(QUERY_KEY.DATASET_PRICE, data);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown>;
}

export function useGetPriceNumComps():
  UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: { propertyName: string; } | unknown) => compsetsApi
      .postCompPrice(params as { propertyName: string; }),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(QUERY_KEY.DATASET_PRICE, data);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown>;
}

export function useSubscribeListComps(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: SubscribeProps | unknown) => compsetsApi.subscribeListCompsets(params as SubscribeProps[]),
    {
      onSuccess: (data: unknown) => {
        queryClient.invalidateQueries(QUERY_KEY.SUBSCRIBED_COMPSETS);
        SnackBarUtils.success((data as { data: { error: string, message: string; }; }).data.message);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useAddComp(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: SubscribeProps | unknown) => compsetsApi.addCompset(params as SubscribeProps),
    {
      onSuccess: (data: unknown) => {
        SnackBarUtils.success((data as { data: { error: string, message: string; }; }).data.message);
        queryClient.invalidateQueries(QUERY_KEY.SAVED_COMPSETS);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useDeleteComp(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const mutation = useMutation(
    (params: SubscribeProps | unknown) => compsetsApi.deleteCompset(params as SubscribeProps),
    {
      onSuccess: async (data: unknown) => {
        SnackBarUtils.success((data as { data: { error: string, message: string; }; }).data.message);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useDeleteCompList(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const mutation = useMutation(
    (params: SubscribeProps[] | unknown) => compsetsApi.deleteCompsetList(params as SubscribeProps[]),
    {
      onSuccess: async (data: unknown) => {
        SnackBarUtils.success((data as { data: { error: string, message: string; }; }).data.message);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useSubscribeComp(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const mutation = useMutation(
    (params: SubscribeProps | unknown) => compsetsApi.putSubscribeCompset(params as SubscribeProps),
    {
      onSuccess: async (data: unknown) => {
        SnackBarUtils.success((data as { data: { error: string, message: string; }; }).data.message);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useDeleteAllCompsets(): UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (propertyName: string | unknown) => compsetsApi.deleteAllsavedCompsets(propertyName as string),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(QUERY_KEY.SAVED_COMPSETS);
        await queryClient.invalidateQueries(QUERY_KEY.FILTERED_PROPERTIES);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useCompsetsPropertiesDataList(): UseQueryResult<CompsetsProperties[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.COMPSETS_PROPERTIES,
    () => compsetsApi.getCompsetsPropertiesDataList(),
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.COMPSETS_PROPERTIES),
      enabled: true,
      retry: 0,
    },
  );
}

export function useGetCompsetsPropertyBy(name: string): UseQueryResult<CompsetsProperties[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    [QUERY_KEY.COMPSETS_PROPERTIES, name],
    (
      input: QueryFunctionContext<string[], CompsetsProperties>,
    ) => compsetsApi.getCompsetsPropertyBy(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QUERY_KEY.COMPSETS_PROPERTIES, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetInfoPropertyBy(name: string): UseQueryResult<CompsetsProperties, Error> {
  const queryClient = useQueryClient();

  return useQuery(
    [QUERY_KEY.PROPERTIES, name],
    (
      input: QueryFunctionContext<string[], CompsetsProperties>,
    ) => compsetsApi.getInfoPropertyBy(input.queryKey[1]),
    {
      enabled: !!name,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QUERY_KEY.PROPERTIES, name]),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}
