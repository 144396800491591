import { memo } from 'react';
import { TabPanel } from '@mui/lab';

import { ROUTE } from 'common/components/routes/utils/constants';
import InsightsTab from 'containers/main/app-bar/Insights/InsightsTab';
import ContactsTab from 'containers/main/app-bar/Contacts/ContactsTab';
import HelpTab from 'containers/main/app-bar/Help/HelpTab';
import TeamTab from 'containers/main/app-bar/Team/TeamTab';
import ProductsTab from 'containers/main/app-bar/Products/ProductsTab';
import DatabaseTab from 'containers/main/app-bar/Database/DatabaseTab';

import { MainWrapperStyled } from '../styled';
import { BodyStyled, TabPanelStyled } from './styled';

export const TabPanels = memo((): JSX.Element => (
  <MainWrapperStyled
    container
    wrap="nowrap"
    direction="column"
  >
    <BodyStyled
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <TabPanelStyled value={ROUTE.HOME.HELP}>
        <HelpTab />
      </TabPanelStyled>
      {/* <TabPanelStyled value={ROUTE.HOME.INSIGHTS}>
        <InsightsTab />
      </TabPanelStyled>
      <TabPanelStyled value={ROUTE.HOME.PRODUCTS}>
        <ProductsTab />
      </TabPanelStyled> */}
      <TabPanelStyled value={ROUTE.HOME.DATABASE}>
        <DatabaseTab />
      </TabPanelStyled>
      <TabPanelStyled value={ROUTE.HOME.TEAM}>
        <TeamTab />
      </TabPanelStyled>
      <TabPanelStyled value={ROUTE.HOME.CONTACT}>
        <ContactsTab />
      </TabPanelStyled>
      <TabPanel value={ROUTE.HOME.PRIVACY}>Privacy works!</TabPanel>
    </BodyStyled>
  </MainWrapperStyled>
));
