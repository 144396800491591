import { ValAction, ValState, ValActionType } from './ValuationContext';

export function valuationReducer(state: ValState, action: ValAction): ValState {
  const copy = { ...state };

  switch (action.type) {
    case ValActionType.Caprate:
      copy.solveForCaprate = true;
      copy.solveForVal = false;

      return copy;

    case ValActionType.Val:
      copy.solveForCaprate = false;
      copy.solveForVal = true;

      return copy;

    case ValActionType.TrailingFinancialsChecked:
      copy.useTrailingFinancials = true;

      return copy;

    case ValActionType.TrailingFinancialsUnchecked:
      copy.useTrailingFinancials = false;

      return copy;

    default:
      return copy;
  }

  return copy;
}
