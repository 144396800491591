import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { H1Styled } from 'common/components/H1Styled/H1Styled';
import { TEXT } from '../../constants';

import { BoxStyled, TextWrapper, TitleStyled } from './styled';

export const SloganPart = memo((): JSX.Element => (
  <>
    <BoxStyled>
      <Typography
        width={290}
        variant="h6"
        color="secondary"
        align="center"
      >
        {TEXT.LOGOUT_TITLE}
      </Typography>
    </BoxStyled>
    <TextWrapper
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      wrap="nowrap"
    >
      <H1Styled>{TEXT.TITLE.toUpperCase()}</H1Styled>
      <TitleStyled
        variant="h2"
        color="secondary"
        align="center"
      >
        {TEXT.TITLE.toUpperCase()}
      </TitleStyled>
      <Typography
        variant="caption"
        color="secondary"
        align="center"
      >
        {TEXT.DESCRIPTION.toUpperCase()}
      </Typography>
    </TextWrapper>
  </>
));
