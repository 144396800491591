import { memo, useState } from 'react';

import { Table, TableBody } from '@mui/material';

import { ActionType } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/ModalContext';
import { useBenchmarkList } from 'containers/benchmark/utils/hooks';
import { BENCHMARK } from 'containers/benchmark/utils/constants';
import {
  BenchmarkSpinnerWrapper,
  StyledTableWrapper,
} from 'containers/input-property/components/mapTab/propertyData/styled';
import { Spinner } from 'common/components/spinner/Spinner';

import SelectClientPortfolio from 'containers/benchmark/components/list/SelectClientPortfolio';
import RowRenderer from 'containers/benchmark/components/list/table/RowRenderer';

import {
  WrapperStyled,
  TableTitleStyled,
  BenchmarkSectionStyled,
  TitleStyled,
} from 'containers/benchmark/utils/styled';

import {
  ButtonChartStyled,
  ButtonStyled,
} from 'containers/input-property/components/detailsTab/styled';

import {
  useModalWindow,
} from 'containers/input-property/components/mapTab/propertyData/requiredTable/Buttons/hooks/useModalWindow';
import { useGetBenchmarkAnalytics } from 'containers/benchmark/lib/query-hooks';
import { useCreateBencmarkAnalyticsTableData } from 'containers/benchmark/lib/mapped-table-data';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';
import DialogWindowCharts from './DialogWindowCharts';
import SubscriptionModal from './SubscriptionModal';
import RadioButtonGroup from './RadioButtonGroup';

const BenchmarkList = (): JSX.Element => {
  const {
    isDownLg,
    title,
    // disabledSubscribe,
    clientPortfolioMap,
    isSuccess,
    isLoading,
    isLoadingAnalytics,
    isNoClientPortfolioData,
    onBenchmarkAnalyticsCallHandle,
    onBenchmarkAnalyticsSubscribe,
  } = useBenchmarkList();

  const {
    openWindow,
    handleOpen,
    handleClose,
  } = useModalWindow();
  const { propName, selection, callback } = useCreateBencmarkAnalyticsTableData();
  const savedCompsets = useGetBenchmarkAnalytics({ propName, selection, callback });

  const [state, setState] = useState<BenchmarkAnalyticsModel[]>([]);

  const onBenchmarkChart = async (): Promise<void> => {
    const result = await savedCompsets.refetch();
    if (!result.isSuccess) {
      SnackBarUtils.error(`${result?.error?.response?.data.detail}`);
    }

    if (result.isSuccess) {
      setState(result.data as BenchmarkAnalyticsModel[]);
      handleOpen();
    }
  };
  return (
    <BenchmarkSectionStyled>
      <TitleStyled>
        <TableTitleStyled>
          {BENCHMARK.LIST_TITLE.toUpperCase()}
        </TableTitleStyled>
          &nbsp;&nbsp;
        {(isLoading || isLoadingAnalytics) && (
          <BenchmarkSpinnerWrapper>
            <Spinner size="small" color="primary" />
          </BenchmarkSpinnerWrapper>
        )}
        <SelectClientPortfolio />
      </TitleStyled>
      <ButtonStyled
        fullWidth
        disabled={isNoClientPortfolioData}
        color="secondary"
        variant="contained"
        $isDownLg={isDownLg}
        onClick={onBenchmarkAnalyticsCallHandle}
      >
        { BENCHMARK.DISPLAY_BENCHMARK_BUTTON }
      </ButtonStyled>
      <ButtonStyled
        fullWidth
        disabled={isNoClientPortfolioData}
        color="secondary"
        variant="contained"
        $isDownLg={isDownLg}
        onClick={onBenchmarkChart}
      >
        {BENCHMARK.CHART}
      </ButtonStyled>
      <ButtonChartStyled
        fullWidth
        disabled={isNoClientPortfolioData}
        color="secondary"
        variant="contained"
        $isDownLg={isDownLg}
        onClick={onBenchmarkAnalyticsSubscribe}
      >
        {BENCHMARK.SUBSCRIPTION}
      </ButtonChartStyled>
      <DialogWindowCharts
        state={state}
        open={openWindow}
        close={handleClose}
        clientPropertyData={clientPortfolioMap}
      />
      <WrapperStyled>
        <RadioButtonGroup />
      </WrapperStyled>
      { title === ActionType.Subscription && !isSuccess && <SubscriptionModal /> }
      <WrapperStyled>
        <StyledTableWrapper $isLoading={isLoading}>
          <Table>
            <TableBody>
              <RowRenderer clientPropertyData={clientPortfolioMap} />
            </TableBody>
          </Table>
        </StyledTableWrapper>
      </WrapperStyled>
    </BenchmarkSectionStyled>
  );
};

export default memo(BenchmarkList);
