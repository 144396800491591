import { memo, useState } from 'react';
import benchmarksThumbnail from 'assets/benchmarks-overview-video-thumbnail.png';
import compsThumbnail from 'assets/comps-overview-video-thumbnail.png';

import { Modal } from './Modal';
import { TEXT, VideoType } from './utils/constants';

export const HelpTab = ():JSX.Element => {
  const [openedVideo, setOpenedVideo] = useState<VideoType>('');

  const handleOpen = (video: VideoType): void => {
    setOpenedVideo(video);
  };
  const handleClose = (): void => {
    setOpenedVideo('');
  };

  return (
    <div className="help">
      <div className="help__header">
        <div className="help__item">
          <button type="button" className="video-button" onClick={() => handleOpen(TEXT.COMPS_VIDEO)}>
            <img
              className="video-thumbnail"
              src={compsThumbnail}
              alt={TEXT.COMPS_VIDEO}
            />
          </button>
          <p className="statval__text help__text">{TEXT.COMPS_VIDEO}</p>
        </div>
        <div className="help__item">
          <button type="button" className="video-button" onClick={() => handleOpen(TEXT.BENCHMARKS_VIDEO)}>
            <img
              className="video-thumbnail"
              src={benchmarksThumbnail}
              alt={TEXT.BENCHMARKS_VIDEO}
            />
          </button>
          <p className="statval__text help__text">{TEXT.BENCHMARKS_VIDEO}</p>
        </div>
      </div>
      <section className="statval">
        <div className="container">
          <h3 className="statval__subheadline2">What does the application do?</h3>
          <p className="statval__text">
            The STATVAL™ Application enables Clients to perform both property-level and market-level
            analyses to support Acquisition Underwriting,
            Asset Management, and Strategy Research. Property-level analyses include Comp Selection,
            Operating Statement Benchmarking, and Automated
            Statistical Valuation. Market-level analyses include property-level data downloads and
            calculations of market medians and quartiles across
            selected CMSA’s and Multifamily Subtypes.
          </p>
          <h3 className="statval__subheadline2">How is the application priced?</h3>
          <p className="statval__text">
            STATVAL™ charges for data and analyses on a pay-per-use basis. Clients pay for only
            the data and analyses they need when they need it, and all data and analyses are
            updated monthly for 1-year after purchase data. A data purchase may be as little
            as one Comp, or a Benchmark and Valuation Analysis for a Client Property, or a data
            download for a large CMSA. To simplify accounting, pre-purchased Comp Credits are used
            as the currency for subscribing to STATVAL™ data and analyses. an as-needed basis or at
            substantial volume discounts with payment proceeds added to a monthly invoice.
          </p>
          <h3 className="statval__subheadline2">
            How many dollars does a Comp Credit cost and how many Comp Credits
            do the various data and analyses cost?
            {' '}
          </h3>
          <p className="statval__text">
            Pre-purchased Comp Credits are used as the currency for subscribing
            to STATVAL™ data and analyses.
            The retail price of 1 Comp Credit is $50. With volume discounts
            the price drops to $20 per Comp Credit.
            The average price for Comp data is 1 Comp Credit. Subscribing to an
            Operating Statement Benchmark Report
            or Automated Statistical Valuation costs 5 Comp Credits.
            Subscribing to bulk datasets are priced depending
            on the number of properties in the datasets.
          </p>
          <h3 className="statval__subheadline2">
            How do I get started?
          </h3>
          <p className="statval__text">
            Use the Log-In tab to create an account. Once your account is created,
            you can browse the website and input a Client Property into your Portfolio.
            To perform analyses on a Client Property or to download data, you must go to
            the Credits tab, sign the license agreement, and purchase Comp Credits.
          </p>
          <h3 className="statval__subheadline2">
            How do I input a Client Property into my Portfolio?
          </h3>
          <p className="statval__text">
            Use the Portfolio tab to input a Client Property. Start with Address and Attributes.
            The system then geo-maps the Client Property to a unique Census Tract and calculates
            a demographic profile. Next input the Client Property Operating Statement and Valuation
            Data. The system then calculates all financial ratios on a per unit and percent of revenue
            basis.
          </p>
          <h3 className="statval__subheadline2">
            Who has access to the Client Property data that I input?
          </h3>
          <p className="statval__text">
            The data is available exclusively to the Client who input the data
            and never co-mingled with the broader database.
          </p>
          <h3 className="statval__subheadline2">
            How much does it cost to input a Client Property into my Portfolio?
          </h3>
          <p className="statval__text">
            STATVAL™ does not charge to input a Client Property. Our theory is that
            allowing Clients to input properties free of charge will promote usage
            of the Comp Selection, Operating Statement Benchmarking,
            and Automated Statistical Valuation.
          </p>
          <h3 className="statval__subheadline2">
            What is a COMPSET?
          </h3>
          <p className="statval__text">
            A COMPSET is a set of properties (Comps) selected from the STATVAL™
            database which are comparable to a Client Property. A COMPSET
            is defined in relation to a specific Client Property. The criteria
            for selecting a COMPSET is typically based on proximity to the Client
            Property and similarity to Client Property regarding attributes
            (subtype, age, number of units), demographics, and rents.
          </p>
          <h3 className="statval__subheadline2">
            How do I select a COMPSET for a Client Property?
          </h3>
          <p className="statval__text">
            STATVAL™ provides 2 options for selectin the COMPSET for a Client Property.
            The first option is to use STATVAL™ Algorithms to select Comps which
            are similar to the Client Property with regard to location, attributes,
            and demographics. The second option is for the User to select filters
            to display a set of candidate Comps. Once a set of candidate properties
            is displayed on the Map, the user may point-and-click to add and remove
            candidate Comps from the COMPSET. Using the STATVAL™
            Algorithm is automatic and available immediately.
          </p>
          <h3 className="statval__subheadline2">
            What does it mean to subscribe to a Comp or COMPSET?
          </h3>
          <p className="statval__text">
            STATVAL™ updates all Comp data monthly. A 1-year subscription
            means that you can view and download updated data any time for
            the next year after the subscription start date. The average
            subscription cost for 1 Comp is one Comp Credit, but may plus
            or minus a fraction of a Comp Credit depending on how recent
            data is for the given Comp. Data availability is shown
            on the template when you select a Comp.
          </p>
          <h3 className="statval__subheadline2">
            What is an Operating Statement Benchmark Report?
          </h3>
          <p className="statval__text">
            The STATVAL™ BENCHMARK report provides a line-item comparison between a Client Property
            Operating Statement versus the Median and Quartiles of each line for a COMPSET
            selected for the Client Property. The User may choose to use the COMPSET
            select by STATVAL™ Algorithms or the COMPSET selected and saved by the Client.
            Most Users use the Algorithm because it does not require hand-selecting a COMPSET.
          </p>
          <h3 className="statval__subheadline2">
            How much does a BENCHMARK Report Cost?
          </h3>
          <p className="statval__text">
            The subscription price for a STATVAL™ BENCHMARK Report
            is 5 Comp Credits. The User may re-run the report
            with updated Comp Data for 1-year after subscription start date.
          </p>
          <h3 className="statval__subheadline2">
            What is an Automated Statistical Valuation?
          </h3>
          <p className="statval__text">
            The STATVAL™ VALUATION report provides a statistically estimated benchmark
            range for the Appraised Value, CAP Rate, and Underwritten Revenue
            and Expenses for a Client Property. The statistical estimate
            is generated by the STATVAL™ Machine-Learning Algorithms.
            The Algorithms consider property location, demographics,
            and financials and seek to estimate the Valuation that would be
            determined by an Appraise or Loan Underwriter.
            It uses the most CMBS Loan Origination data
            as the observation set to perform this statistical estimate.
          </p>
          <h3 className="statval__subheadline2">
            How much does a VALUATION Report Cost?
          </h3>
          <p className="statval__text">
            The subscription price for a STATVAL™ VALUATION Report is 5 Comp Credits.
            The User may re-run the report with updated
            Comp Data for 1-year after subscription start date.
          </p>
          <h3 className="statval__subheadline2">
            What is a Research Dataset?
          </h3>
          <p className="statval__text">
            STATVAL™ RESEARCH report provides Medians, Quartiles, and Property-Level
            Detail for all Comps in a CMSA (Census Metropolitan Survey Area)
            and Multifamily Subtype. The Detail includes a full set
            of property-level attributes, demographics,
            operating statement line-items, valuations,
            CAP Rates, year-or-year changes for
            each property and the rolled up into Medians and Quartiles across the full dataset.
            This Report provides Clients with granular detail for characteristics, performance,
            and trends of properties within a given market.
          </p>
          <h3 className="statval__subheadline2">
            How much does a RESEARCH Report Cost?
          </h3>
          <p className="statval__text">
            The subscription price for a STATVAL™ RESEARCH Report is calculated from the number
            of properties in the dataset. Technically, the calculated price is 0.2 Comp Credits
            per property in the dataset. For example, a CMSA and Subtype
            (e.g., Raleigh, NC Garden Apartments) with 300 properties would cost 60 Comp Credits.
            The User may re-run the report with
            all data updated and recalculated for 1-year after subscription start date.
          </p>
        </div>
      </section>
      <Modal
        openedVideo={openedVideo}
        close={handleClose}
      />
    </div>
  );
};

export default memo(HelpTab);
