import { Theme } from '@mui/material';
import { DEFAULT_DRAWER_WIDTH, DRAWER_WIDTH } from 'common/components/ApplicationBar/styled';
import { MainWidthType } from './theme-types';

const expandedDrawerWidth = DRAWER_WIDTH;
const collapsedDrawerWidth = DEFAULT_DRAWER_WIDTH;

export const setMainWidth = (theme: Theme, open: boolean, noMatchExistedRoutes: boolean): MainWidthType => {
  let padInNums = 0;

  if (noMatchExistedRoutes) {
    padInNums = 0;
  } else if (open) {
    padInNums = expandedDrawerWidth;
  } else {
    padInNums = collapsedDrawerWidth;
  }

  return {
    paddingLeft: padInNums,
    transition: theme.transitions.create('padding', {
      easing: noMatchExistedRoutes ? 'unset' : theme.transitions.easing.sharp,
      duration: noMatchExistedRoutes ? 0 : theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('padding', {
        easing: noMatchExistedRoutes ? 'unset' : theme.transitions.easing.sharp,
        duration: noMatchExistedRoutes ? 0 : theme.transitions.duration.enteringScreen,
      }),
    }),
  };
};
