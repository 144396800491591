import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axiosInstance from 'common/axios';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { TableStateContext } from 'containers/statval/model';
import { Stack } from '@mui/material';
import { useState } from 'react';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  direction: 'flex',
  minHeight: 200,
  flexWrap: 'nowrap',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4,
};

const title = {
  fontSize: '24px',
  marginBottom: '11px',
  fontFamily: 'Open Sans, sans-serif',
  color: 'black',
};

const text = {
  fontSize: '16px',
  fontWeight: '100px',
  marginBottom: '30px',
  fontFamily: 'OpenSans-Regular, sans-serif',
  color: 'black',
};

type PropType = {isOpen: boolean, setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>};

export default function SubscriptionModal({ isOpen, setIsModalOpen }:PropType):JSX.Element {
  const [price, setPrice] = useState(5);
  const { propName } = React.useContext(TableStateContext);
  const onSubscribeHandler = async (): Promise<void> => {
    try {
      const encoded = encodeURIComponent(propName);
      const { data: dataA } = await axiosInstance.post(`statval/subscribe?propertyName=${encoded}`);
      SnackBarUtils.success((dataA as {error: boolean; message: string;}).message);
      setPrice(5);
    } catch (error) {
      SnackBarUtils.error('Network error!!!');
    }
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Modal
        open={isOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={style}>
          <Typography style={title} variant="h6" component="h2">
            Required Subscription
          </Typography>
          <Typography style={text} variant="h6" component="h2">
            Subscription required for benchmark analytics cost=
            {price}
            {' '}
            comps
          </Typography>
          <Stack gap={2} direction="row" justifyContent="flex-start">
            <Button
              style={{ backgroundColor: '#1167b6' }}
              variant="contained"
              onClick={onSubscribeHandler}
            >
              SUBSCRIBE
            </Button>
            <Button
              style={{
                color: '#0d589c', borderColor: '#0d589c', minWidth: '114px', minHeight: '48px',
              }}
              variant="outlined"
              onClick={() => setIsModalOpen(false)}
            >
              CLOSE
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
