import jwtDecode from 'jwt-decode';
import { clearTimeout } from 'timers';
import { relogin } from './api';
import { IUserData } from './lib/models/ResponseData.interface';

const ONE_SEC = 1000 as const;

const convertTo = (unit: 'milliseconds' | 'seconds', timing: number): number => {
  if (unit === 'milliseconds') return timing * 1000;

  return timing * 100;
};

export const refreshTokenSetup = async (accessToken: string): Promise<void> => {
  const decodedJWT: IUserData = jwtDecode(accessToken);
  let refreshTimeout = null;

  const end = decodedJWT.iat;
  const start = decodedJWT.exp;
  const timeoutInSec = start - end;
  const timeout = convertTo('milliseconds', timeoutInSec);

  const refreshToken = async (): Promise<void> => {
    refreshTimeout = setTimeout(refreshToken, timeout - ONE_SEC);

    await relogin();
  };

  refreshTimeout = setTimeout(refreshToken, timeout - ONE_SEC);

  if (!accessToken) clearTimeout(refreshTimeout);
};
