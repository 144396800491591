import { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QUERY_KEY } from 'common/query-keys';

import { useGetPrice, useSubscribeDataset } from 'containers/research/lib/query-hooks';
import { CompsPriceData, UseMetrocounts } from 'containers/research/lib/models/types';

export function useMetrocounts(): UseMetrocounts {
  const [region, setRegion] = useState('');
  const [subtype, setSubtype] = useState('');

  const query = useQueryClient();
  const mutation = useSubscribeDataset();
  const mutationPrice = useGetPrice();

  const getPrice = useCallback(async (): Promise<void> => {
    await mutationPrice.mutateAsync({ region, subtype });
  }, [mutationPrice, region, subtype]);

  const queryData = query.getQueryData<{ data: CompsPriceData | undefined; }>(QUERY_KEY.DATASET_PRICE);

  const handleSetRegion = (selectedRegion: string): void => {
    setRegion(selectedRegion);
  };
  const handleSetSubtype = (selectedSubtype: string): void => {
    setSubtype(selectedSubtype);
  };
  const handleSubscribeDataset = (): void => {
    mutation.mutateAsync({ region, subtype });
    setRegion('');
    setSubtype('');
    query.setQueryData(QUERY_KEY.DATASET_PRICE, null);
  };

  useEffect(() => {
    if (region && subtype) getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, subtype]);

  return {
    selectedProperty: { region, subtype },
    queryData,
    handleSetRegion,
    handleSetSubtype,
    handleSubscribeDataset,
  };
}
