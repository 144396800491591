import { createContext } from 'react';

export type InfoTablePopupAction = { type: 'open'; } | { type: 'close'; };

export type InfoTablePopupDispatch = (action: InfoTablePopupAction) => void;

export type InfoTablePopupState = { open: boolean; };

export const InfoTablePopupContext = createContext<
  { state: InfoTablePopupState; dispatch: InfoTablePopupDispatch; } | undefined
  >(undefined);

createContext<(InfoTablePopupState & InfoTablePopupDispatch) | undefined>(undefined);
