import { memo } from 'react';
import phone from 'assets/phone-icon.png';
import linkedin from 'assets/linkedin.svg';
import mail from 'assets/email-icon.png';

import { AboutTabStyled } from 'containers/main/app-bar/Team/styled';

const TeamTab = ():JSX.Element => (
  <AboutTabStyled>
    <section className="team scroll-container">
      <div className="container">
        <h2 className="team__headline scroll-element js-scroll fade-in-bottom-1">Our Team</h2>
        <div className="team__member member member--exec scroll-element js-scroll fade-in-bottom-2">
          <h3 className="member__name">WH Advisory & Investments LLC</h3>
          <h4 className="member__role">Webster Hughes, PhD, Managing Member</h4>
          <hr />
          <div
            className="member__creds-container js-member-content"
            data-member="webster"
          >
            <p>
              <b>Webster Hughes, PhD</b>
              has decades of experience in sales, trading, research,
              and advanced software development related to fixed income, structured products,
              and multifamily real estate investment.
              Hughes started his career with a PhD in Mathematical Physics from Princeton University
              and two major scientific publications while in his early twenties. In 1986,
              Dr. Hughes left academia and joined Salomon Brothers to develop the first generation of valuation
              and risk models for Mortgage-Backed Securities. Subsequent positions include Senior Managing Director
              at Bear Stearns, Managing Director of First Union Capital Markets, and Proprietary Consultant to Bank
              of America. For the past decade, Hughes’ work has been focused extensively on Freddie Mac Multifamily
              CMBS Investments. This work led to consulting projects with institutional real estate investors and
              development of the
              &nbsp;
              <a href="https://statval.com" target="_blank" rel="noreferrer">statval.com</a>
              &nbsp;
              multifamily database application.
            </p>
          </div>
          <hr />
          <div className="member__interactives">
            <div className="member__contact">
              <div className="member__phone-flex">
                <a className="member__phone" href="tel:+17042006305">
                  <img
                    src={phone}
                    alt="Phone icon"
                  />
                </a>
                <a href="tel:+17042006305" className="show-for-d-only">(704) 200-6305</a>
              </div>
              <div>
                <a className="member__email" href="mailto:whughes@whadvisoryllc.com">
                  <img src={mail} alt="Mail Icon" />
                </a>
                <a className="member__email" href="mailto:whughes@whadvisoryllc.com">
                  whughes@whadvisoryllc.com
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/webster-hughes-phd/"
                  rel="noreferrer"
                >
                  <img
                    className="member__linked-in"
                    src={linkedin}
                    alt="LinkedIn Icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </AboutTabStyled>
);
export default memo(TeamTab);
