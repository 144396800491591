/* eslint-disable max-len */
export const BUTTON_TEXT = {
  CREATE: 'Create',
  CREATE_AND_SEND: 'Create  and send',
  CANCEL: 'Cancel',
} as const;

export const INPUT_TEXT = {
  ADMIN_NAME: 'Admin Name',
  IS_ADMIN: 'Is Admin',
  CLIENT_NAME: 'Client Name',
  CLIENT_PW: 'Client Password',
  PERSON_EMAIL: 'Person Email',
  PERSON_FIRST_NAME: 'Person First Name',
  PERSON_LAST_NAME: 'Person Last Name',
  COMPANY_NAME: 'Company Name',
  COMPANY_WEBSITE: 'Company Website',
  ADMIN_SEES_PASSWORD: 'Admin Sees Password',
} as const;

export const INPUT_NAME = {
  ADMIN_NAME: 'adminName',
  IS_ADMIN: 'isAdmin',
  CLIENT_NAME: 'clientName',
  CLIENT_PW: 'clientPw',
  PERSON_EMAIL: 'personEmail',
  PERSON_FIRST_NAME: 'personFirstName',
  PERSON_LAST_NAME: 'personLastName',
  COMPANY_NAME: 'companyName',
  COMPANY_WEBSITE: 'companyWebsite',
  ADMIN_SEES_PASSWORD: 'adminSeesPassword',
} as const;

export const PLACEHOLDER = {
  EMAIL: 'John.snow@gmail.com',
  NAME: 'John Doe',
  PASSWORD: '•••••••••',
} as const;

export const MODAL_TITLE = 'Manage Members' as const;
export const BACKDROP_TIMEOUT = { timeout: 300 } as const;
export const GENERATE_PW = 'Generete random password' as const;
export const DEBOUNCE_TIMEOUT = 300 as const;
export const INFO_TEXT = 'If a new email address is added or changed the user will receive an invintation to the website' as const;
