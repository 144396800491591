import defaultTheme from '../defaultTheme';

export default {
  styleOverrides: {
    h2: {
      [defaultTheme.breakpoints.down('xl')]: {
        fontSize: 24,
      },
    },
    h4: {
      [defaultTheme.breakpoints.down('xl')]: {
        fontSize: 24,
      },
    },
    body1: {
      [defaultTheme.breakpoints.down('xl')]: {
        fontSize: 14,
      },
    },
    caption: {
      [defaultTheme.breakpoints.down('xl')]: {
        fontSize: 16,
      },
    },
  },
};
