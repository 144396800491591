export const ACCOUNT_URL = {
  ACCOUNT: 'account',
} as const;

export const CREDITS_URL = {
  PAYMENT: 'payment-gateway/money-transfer',
  TRANSFER: 'credits/transfer-credits',
  CREDITS: 'credits',
  INVOICE: 'invoice',
  PRICES: 'prices',
  LIMIT: 'limit',
  PURCHASE_CREDITS: 'purchase-credits',
} as const;

export const PROPERTY_URL = {
  PROPERTIES: 'properties',
} as const;

export const GEO_URL = 'geo/coordinates';

export const EMAIL_URL = {
  EMAIL: 'email',
} as const;

export const LICENSE_TERMS_URL = {
  LICENSE_TERMS: 'license-terms?hasReadAndAgree=',
} as const;

export const DATASET_URL = {
  METROCOUNTS: 'dataset/metrocounts',
  PRICE: 'dataset/price',
  SUBSCRIBE: 'dataset/subscribe',
  QUARTILES: 'dataset/quartiles',
  RESEARCH: 'dataset/research',
} as const;

export const BUSINESS_INFO_URL = {
  CHECKBOX: 'business-info/check-box/all',
  NEIGHBORHOOD: 'business-info/neighborhood/all',
  BUSINESS_INFO: 'business-info/state/all',
  SUBTYPE: 'business-info/sub-type/all',
} as const;

export const COMPSETS_URL = {
  COMPSETS: 'compsets',
  COMPSETS_LIST: 'compsets/list',
  PROPERTIES: 'properties',
  PORTFOLIO: 'portfolio',
  SUBSCRIBE: 'compsets/subscribe',
  SUBSCRIBE_LIST: 'subscribe-list',
  ALGORITHM: 'algorithm?propertyName=',
  FILTER_PARAMS: {
    FILTER: 'filter?propertyName=',
    UNITS: 'NumUnits=',
    YEAR: 'BuildRenov=',
    SUBTYPE: 'SameSubtype=',
    SIMILAR_SUBTYPE: 'SimilarSubtype=',
    AFFORDABLE: 'Affordability=',
    F2020F: 'OperatingStmnt2020=',
    U2020W: 'Underwriting2020=',
  },
  SAVED_PROPERTIES: 'saved-properties',
  ALL: 'all',
} as const;
