import * as React from 'react';
import {
  Box, Button, Checkbox, FormControlLabel, FormGroup, Stack,
} from '@mui/material';
import { useState } from 'react';
import axiosInstance from 'common/axios';
import { TableDispatchContext, TableStateContext } from 'containers/statval/model';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import TooltipWrap from 'common/components/DuplexlTable/ui/TooltipWrap';
import { TextAlign } from 'chart.js';
import { AxiosError } from 'axios';
import { queryClient } from '../../../../common/components/providers/ReactQueryProvider';
import { QueryKey } from '../../../benchmark/lib/utils/query-keys';

const COL_WIDTH_1 = 40;
const COL_WIDTH_2 = 80;

const SUB_COL_WIDTH_1 = COL_WIDTH_2 / 3;
const SUB_COL_WIDTH_2 = COL_WIDTH_2 / 3;
const SUB_COL_WIDTH_3 = COL_WIDTH_2 / 3;

const fromISOtoStringData = (date: string | undefined | null): string => {
  if (date === undefined || date === null) return '';
  const dateObj = new Date(date);
  return `${
    `0${dateObj.getMonth() + 1}`.slice(-2)}/${`0${dateObj.getDate()}`.slice(-2)}/${dateObj.getFullYear()
  }`;
};

const pesentage = (num: number):string => {
  if (num === undefined) return '';
  return `${(num * 100).toFixed(2)}%`;
};

const oneSignPesentage = (num: number):string => {
  if (num === undefined) return '';
  return `${(num * 100).toFixed(1)}%`;
};

const dollar = (num: number):string => {
  if (num === undefined) return '';
  return `$${Math.round(num).toLocaleString('us')}`;
};

type StrNumNull = string | number | null;

interface StatvalTab {
  expPctrev?: StrNumNull;
  expPerunit?: StrNumNull;
  noiPctrev?: StrNumNull;
  noiPerunit?: StrNumNull;
  occup?: StrNumNull;
  revPerunit?: StrNumNull;
  statementEndDateEstimate?: StrNumNull;
  statementEndDateMostrecent?: StrNumNull;
  uwCaprateComment?: StrNumNull;
  uwCaprateEstimate?: StrNumNull;
  uwCaprateEstimateVsMostrecent?: StrNumNull;
  uwCaprateHigh?: StrNumNull;
  uwCaprateLow?: StrNumNull;
  uwCaprateMostrecent?: StrNumNull;
  uwDateMostrecent?: StrNumNull;
  uwExpPctrevEstimate?: StrNumNull;
  uwExpPctrevEstimateVsMostrecent?: StrNumNull;
  uwExpPctrevEstimateVsTrailing?: StrNumNull;
  uwExpPctrevHigh?: StrNumNull;
  uwExpPctrevLow?: StrNumNull;
  uwExpPctrevMostrecent?: StrNumNull;
  uwExpPerunitEstimate?: StrNumNull;
  uwExpPerunitEstimateVsMostrecent?: StrNumNull;
  uwExpPerunitEstimateVsTrailing?: StrNumNull;
  uwExpPerunitHigh?: StrNumNull;
  uwExpPerunitLow?: StrNumNull;
  uwExpPerunitMostrecent?: StrNumNull;
  uwNoiPctrevEstimate?: StrNumNull;
  uwNoiPctrevEstimateVsMostrecent?: StrNumNull;
  uwNoiPctrevEstimateVsTrailing?: StrNumNull;
  uwNoiPctrevHigh?: StrNumNull;
  uwNoiPctrevLow?: StrNumNull;
  uwNoiPctrevMostrecent?: StrNumNull;
  uwNoiPerunitEstimate?: StrNumNull;
  uwNoiPerunitEstimateVsMostrecent?: StrNumNull;
  uwNoiPerunitEstimateVsTrailing?: StrNumNull;
  uwNoiPerunitHigh?: StrNumNull;
  uwNoiPerunitLow?: StrNumNull;
  uwNoiPerunitMostrecent?: StrNumNull;
  uwOccupEstimate?: StrNumNull;
  uwOccupEstimateVsMostrecent?: StrNumNull;
  uwOccupEstimateVsTrailing?: StrNumNull;
  uwOccupHigh?: StrNumNull;
  uwOccupLow?: StrNumNull;
  uwOccupMostrecent?: StrNumNull;
  uwRevPerunitEstimate?: StrNumNull;
  uwRevPerunitEstimateVsMostrecent?: StrNumNull;
  uwRevPerunitEstimateVsTrailing?: StrNumNull;
  uwRevPerunitHigh?: StrNumNull;
  uwRevPerunitLow?: StrNumNull;
  uwRevPerunitMostrecent?: StrNumNull;
  uwValPerunitMostrecent?: StrNumNull;
  uwValuationEstimate?: StrNumNull;
  uwValuationHigh?: StrNumNull;
  uwValuationLow?: StrNumNull;
  uwValuationMostrecent?: StrNumNull;
  valDateComment?: StrNumNull;
  valDateEstimate?: StrNumNull;
  valPerunitComment?: StrNumNull;
  valPerunitEstimate?: StrNumNull;
  valPerunitEstimateVsMostrecent?: StrNumNull;
  valPerunitHigh?: StrNumNull;
  valPerunitLow?: StrNumNull;
  valuationComment? : StrNumNull;
  valuationEstimateVsTrailing? : StrNumNull;
  HistCapRate? : StrNumNull;
  histTRSY?: StrNumNull;
  currTRSYDate?: StrNumNull;
  currTRSY?: StrNumNull;
  capRateADJ?: StrNumNull;
  currCapRate?: StrNumNull;
}

// #region Cell
interface CellProps {
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  radiusTopLeft?: boolean;
  radiusTopRight?: boolean;
  radiusBottomRight?: boolean;
  radiusBottomLeft?: boolean;
  borderTopBold?: boolean;
  borderRightBold?: boolean;
  borderBottomBold?: boolean;
  borderLeftBold?: boolean;
  textAlign?: string;
  isWeight?: boolean;
  isBackgrounded?: boolean;
  width?: string;
  text: string | number | undefined;
  isTitle?: boolean;
}

const Cell = ({
  borderTop = true,
  borderRight = false,
  borderBottom = false,
  borderLeft = true,
  borderTopBold = false,
  borderRightBold = false,
  borderBottomBold = false,
  borderLeftBold = false,
  radiusTopLeft = false,
  radiusTopRight = false,
  radiusBottomRight = false,
  radiusBottomLeft = false,
  textAlign = 'left',
  isWeight = true,
  width = '100',
  isBackgrounded = false,
  text,
  isTitle = false,

}: CellProps):JSX.Element => (
  <TooltipWrap text={text === null || text === undefined ? '' : String(text)}>
    <div style={{
      color: isTitle ? 'rgb(83, 94, 103)' : '#85939F',
      backgroundColor: isBackgrounded ? '#F6F7F9' : 'white',
      fontWeight: isWeight ? '900' : undefined,
      textAlign: textAlign as TextAlign,
      width: `${width}%`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: '5px',
      fontSize: '14px',
      fontFamily: 'OpenSans-SemiBold, sans-serif',
      /* eslint no-nested-ternary: "off" */
      borderTop: borderTop ? borderTopBold ? '2px solid grey' : '1px solid #BFC9D1' : undefined,
      borderRight: borderRight ? borderRightBold ? '2px solid grey' : '1px solid #BFC9D1' : undefined,
      borderBottom: borderBottom ? borderBottomBold ? '2px solid grey' : '1px solid #BFC9D1' : undefined,
      borderLeft: borderLeft ? borderLeftBold ? '2px solid grey' : '1px solid #BFC9D1' : undefined,
      borderRadius: `
        ${radiusTopLeft ? '6px' : '0'} 
        ${radiusTopRight ? '6px' : '0'} 
        ${radiusBottomRight ? '6px' : '0'} 
        ${radiusBottomLeft ? '6px' : '0'}
      `,
    }}
    >
      {text}
    </div>
  </TooltipWrap>
);

const Row = ({ children }:{children: JSX.Element[] | JSX.Element}):JSX.Element => (
  <div style={{ display: 'flex' }}>{children}</div>
);

interface StatvalTabInterface {
  data: {
    statvalComparison: StatvalTab,
    capRateADJModel: StatvalTab,
    observationSets: Record<string, unknown>
  }
}

export const StatvalTable = ():JSX.Element => {
  const dispatch = React.useContext(TableDispatchContext);
  const [isCalculateStatvalLoading, setIsCalculateStatvalLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { propName, tableData, isPropNameSelected } = React.useContext(TableStateContext);
  const avmYear = process.env.AVM_YEAR || 2022;

  const onClickCalculateStatvalHandler = async (): Promise<void> => {
    setIsCalculateStatvalLoading(true);
    try {
      const {
        data: { statvalComparison, capRateADJModel, observationSets },
      } = await axiosInstance.get(
        `statval/${propName}?avmYear=${avmYear}&avmOutlier=1.5&calculate=${isChecked}`,
      ) as StatvalTabInterface;
      const cache = queryClient.getQueryData([QueryKey.ClientProperty, propName]) as Record<string, unknown>;

      if (statvalComparison?.valPerunitEstimate !== undefined
        && statvalComparison?.valPerunitEstimate === 0) {
        SnackBarUtils.error('VALUATION DID NOT CALCULATE');
      }

      dispatch({
        type: 'SET_TABLE_DATA',
        payload:
          {
            ...(statvalComparison),
            ...(capRateADJModel),
            ...({ numUnits: cache?.numUnits }),
          } as StatvalTab,
      });
      dispatch({
        type: 'SET_OBSERVATIONS_SET_TABLE_DATA',
        payload: observationSets as unknown as { [key: string]: string | null; }[],
      });
    } catch (error) {
      const err = error as AxiosError;
      const responseData = err.response?.data as unknown as {detail: string};
      SnackBarUtils.error(responseData.detail);
    }
    setIsCalculateStatvalLoading(false);
  };

  const onCheckhandler = (): void => {
    setIsChecked((prev) => !prev);
  };

  return (
    <Stack
      style={{
        backgroundColor: 'white',
        padding: '16px',
        height: '100%',
        borderRadius: '6px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 2.1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      <Stack
        style={{ marginBottom: '8px' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>STATVAL</div>
        <Stack direction="row" gap={2}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={onCheckhandler} defaultChecked />}
              label="Use saved value (no recalc)"
            />
          </FormGroup>
          <Button
            onClick={onClickCalculateStatvalHandler}
            disabled={!isPropNameSelected}
            style={{ backgroundColor: isPropNameSelected ? '#1060AA' : 'rgba(0, 0, 0, 0.12)' }}
            variant="contained"
          >
            {isCalculateStatvalLoading ? 'CALCULATING...' : 'CALCULATE STATVAL'}
          </Button>
        </Stack>
      </Stack>
      <div style={{ overflowY: 'auto', height: '490px', position: 'relative' }}>
        <Box>
          <Row>
            <Cell
              isTitle
              text="STATVAL"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              radiusTopLeft
              isBackgrounded
              borderTopBold
              borderRightBold
              borderBottomBold
              borderLeftBold
              borderBottom
              isWeight
            />
            <Cell
              isTitle
              borderTopBold
              borderRightBold
              borderBottomBold
              text="STATVAL BENCHMARK RANGE"
              width={String(COL_WIDTH_2)}
              textAlign="center"
              borderBottom
              isWeight
              radiusTopRight
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="VALUATION TYPE"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              isTitle
              text="LOW"
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              isTitle
              text="STATVAL"
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              isTitle
              text="HIGH"
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="VALUATION DATE"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell text="" width={String(SUB_COL_WIDTH_1)} textAlign="center" />
            <Cell
              text={fromISOtoStringData(tableData?.currTRSYDate as string)}
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="VALUATION"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text={(tableData?.numUnits && tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.numUnits as number) * (tableData?.noiPerunit as number))
                    / ((tableData?.currCapRate as number) + 0.0025)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.numUnits && tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.numUnits as number) * (tableData?.noiPerunit as number))
                / (tableData?.currCapRate as number)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.numUnits && tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.numUnits as number) * (tableData?.noiPerunit as number))
                / ((tableData?.currCapRate as number) - 0.0025)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="VALUATION_PERUNIT"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text={(tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.noiPerunit as number))
                    / ((tableData?.currCapRate as number) + 0.0025)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.noiPerunit as number))
                    / ((tableData?.currCapRate as number))),
                )
                : ''}
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.numUnits && tableData?.noiPerunit && tableData?.currCapRate)
                ? dollar(
                  ((12 * (tableData?.noiPerunit as number))
                    / ((tableData?.currCapRate as number) - 0.0025)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="CAPRATE"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text={(tableData?.currCapRate)
                ? pesentage(
                  ((tableData?.currCapRate as number) + 0.0025),
                )
                : ''}
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.currCapRate)
                ? pesentage(
                  ((tableData?.currCapRate as number)),
                )
                : ''}
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text={(tableData?.currCapRate)
                ? pesentage(
                  ((tableData?.currCapRate as number) - 0.0025),
                )
                : ''}
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              borderTopBold
              borderRightBold
              borderBottomBold
              borderLeftBold
              text="STATEMENTTYPE"
              width="40"
              textAlign="center"
              isBackgrounded
              borderBottom
            />
            <Cell
              isTitle
              borderTopBold
              borderRightBold
              borderBottomBold
              text="MOST RECENT TRAILING FINANCIALS"
              width="80"
              textAlign="center"
              borderBottom
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="STATEMENTENDDATE"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={fromISOtoStringData(tableData?.statementEndDateMostrecent as string)}
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="UW OCCUP"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={
                tableData?.occup ? oneSignPesentage(tableData?.occup as number) : ''
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="REV PERUNIT"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={
                (tableData?.revPerunit ? dollar(tableData?.revPerunit as number) : '')
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="EXP PERUNIT"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={
                tableData?.expPerunit ? dollar(tableData?.expPerunit as number) : ''
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="NOI PERUNIT"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={
                tableData?.noiPerunit ? dollar(tableData?.noiPerunit as number) : ''
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="EXP PCTREV"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              isBackgrounded
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
            />
            <Cell
              text={
                tableData?.expPctrev ? oneSignPesentage(tableData?.expPctrev as number) : ''
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderRight
            />
          </Row>
          <Row>
            <Cell
              isTitle
              text="NOI PCTREV"
              width={String(COL_WIDTH_1)}
              textAlign="center"
              radiusBottomLeft
              isBackgrounded
              borderBottom
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_1)}
              textAlign="center"
              borderBottom
            />
            <Cell
              text={
                tableData?.noiPctrev ? oneSignPesentage(tableData?.noiPctrev as number) : ''
              }
              width={String(SUB_COL_WIDTH_2)}
              textAlign="center"
              borderBottom
            />
            <Cell
              text=""
              width={String(SUB_COL_WIDTH_3)}
              textAlign="center"
              borderBottom
              radiusBottomRight
              borderRight
            />
          </Row>
        </Box>
      </div>
    </Stack>
  );
};
