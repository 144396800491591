import { styled } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';

export const FilledInputStyled = styled(FilledInput)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: `${theme.shape.borderRadius}px`,
  border: `1px solid ${theme.palette.background.border}`,
  '&.MuiFilledInput-adornedStart': {
    paddingLeft: theme.spacing(0.25),
  },
  '&>.MuiFilledInput-inputAdornedStart': {
    paddingLeft: `${theme.spacing(1.5)} !important`,
    paddingRight: `${theme.spacing(1.5)} !important`,
  },
}));
