import { Box, Grid } from '@mui/material';
import { withRouter } from 'react-router';
import { TableContextProvider } from './model';
import { StatvalTable } from './ui/StatvalTable';
import { StepsSign } from './ui/StepsSign';
import { PropertyDetail } from './ui/PropertyDetail';

const styles = {
  main: {
    backgroundColor: '#E5E5E5',
    overflow: 'auto',
    padding: '30px',
  },
  observationSet: { backgroundColor: 'none' },
  dataTableWrapper: {
    backgroundColor: 'none',
    height: '100%',
    width: '100%',
  },
};

export const Statval = ():JSX.Element => (
  <TableContextProvider>
    <Box style={styles.main}>
      <Grid spacing={6} container style={{ minHeight: '60%', marginBottom: '20px' }}>
        <Grid item container sm={3}>
          <Box style={{ height: '100%', width: '100%' }}>
            <PropertyDetail />
          </Box>
        </Grid>
        <Grid item container sm={9}>
          <Box style={styles.dataTableWrapper}>
            <StatvalTable />
          </Box>
        </Grid>
      </Grid>
      <Box style={styles.observationSet}>
        <StepsSign />
      </Box>
    </Box>
  </TableContextProvider>
);

export default withRouter(Statval);
