export const PROPERTY_DETAIL = {
  REFRESH_BUTTON_TEXT: 'REFRESH',
  REFRESH_BUTTON_TEXT_REFRESHING: 'REFRESHING',
  EDIT_PROPERTY: 'EDIT PROPERTY',
  DISPLAY_PROPERTY_DATA: 'DISPLAY PROPERTY ON MAP',
  MODIFY_BUTTON_TEXT: 'Modify attributes and save',
  RESTORE_BUTTON_TEXT: 'Restore property',
  ARCHIVE_BUTTON_TEXT: 'Archive property',
  ROWS: [
    {
      row: 'Property Name',
      name: 'propertyName',
      dataName: 'propertyName',
      symbol: '',
    },
    {
      row: 'Address',
      name: 'propertyAddress',
      dataName: 'propertyAddress',
      symbol: '',
    },
    {
      row: 'City',
      name: 'propertyCity',
      dataName: 'propertyCity',
      symbol: '',
    },
    {
      row: 'State',
      name: 'propertyState',
      dataName: 'propertyState',
      symbol: '',
    },
    {
      row: 'Zip',
      name: 'propertyZipCode',
      dataName: 'propertyZipCode',
      symbol: '',
    },
    {
      row: 'County',
      name: 'propertyCounty',
      dataName: 'propertyCounty',
      symbol: '',
    },
    {
      row: 'CMSA',
      name: 'CMSA',
      dataName: 'propertyCmsa',
      symbol: '',
    },
    {
      row: 'NCREIF Division',
      name: 'Division',
      dataName: 'propertyDivision',
      symbol: '',
    },
    {
      row: 'NCREIF Region',
      name: 'Region',
      dataName: 'propertyRegion',
      symbol: '',
    },
    {
      row: 'Latitude',
      name: 'latitude',
      dataName: 'latitude',
      symbol: '',
    },
    {
      row: 'Longitude',
      name: 'longitude',
      dataName: 'longitude',
      symbol: '',
    },
    {
      row: 'Miles From Subject Property',
      name: 'distanceToSubject',
      dataName: 'distanceToSubject',
      symbol: '',
    },
    {
      row: 'Year Built',
      name: 'yearBuilt',
      dataName: 'yearBuilt',
      symbol: '',
    },
    {
      row: 'Year Renov',
      name: 'yearRenov',
      dataName: 'yearRenov',
      symbol: '',
    },
    {
      row: 'Num Units',
      name: 'numUnits',
      dataName: 'numUnits',
      symbol: '',
    },
    {
      row: 'SubType',
      name: 'propertySubtypeAssigned',
      dataName: 'subType',
      symbol: '',
    },
    {
      row: 'Affordability',
      name: 'affordability',
      dataName: 'affordability',
      symbol: '',
    },
    {
      row: 'Population PerSqMile',
      name: 'populationPerSqMile',
      dataName: 'populationPerSqMile',
      symbol: 'int',
    },
    {
      row: 'Median HomeVal',
      name: 'medianHomeVal',
      dataName: 'medianHomeVal',
      symbol: '$',
    },
    {
      row: 'Median HHInc',
      name: 'medianHHInc',
      dataName: 'medianHHInc',
      symbol: '$',
    },
    {
      row: 'Median Age',
      name: 'medianAge',
      dataName: 'medianAge',
      symbol: '',
    },
    {
      row: 'Median Rent',
      name: 'medianRent',
      dataName: 'medianRent',
      symbol: '$',
    },
    {
      row: 'Median Owner Costs',
      name: 'medianOwnerCosts',
      dataName: 'medianOwnerCosts',
      symbol: '$',
    },
    {
      row: 'Renter PCT',
      name: 'renterPct',
      dataName: 'renterPct',
      symbol: '%',
    },
    {
      row: 'Rent Over30Pct HHInc PCT',
      name: 'rentOver30PctHHIncPCT',
      dataName: 'rentOver30PctHHIncPCT',
      symbol: '%',
    },
    {
      row: 'Bachelors Degree PCT',
      name: 'bachelorsDegreePct',
      dataName: 'bachelorsDegreePct',
      symbol: '%',
    },
    {
      row: 'Unemployment PCT',
      name: 'unemploymentPct',
      dataName: 'unemploymentPct',
      symbol: '%',
    },
    {
      row: 'Mgt Finance Job PCT',
      name: 'mgtFinanceJobPct',
      dataName: 'mgtFinanceJobPct',
      symbol: '%',
    },
    {
      row: 'MovedIn After2010 PCT',
      name: 'movedInAfter2010Pct',
      dataName: 'movedInAfter2010Pct',
      symbol: '%',
    },
    {
      row: 'UnderWriting UWYEAR',
      name: 'underWritingUwYear',
      dataName: 'underWritingUwYear',
      symbol: '',
    },
    {
      row: 'Top-Line REVYEAR',
      name: 'topLineFinancialsRevYear',
      dataName: 'topLineFinancialsRevYear',
      symbol: '',
    },
    {
      row: 'Full Line-Item FFYEAR',
      name: 'fullLineItemFinancialsFFYear',
      dataName: 'fullLineItemFinancialsFFYear',
      symbol: '',
    },
    {
      row: 'APPRAISAL OR STATVAL',
      name: 'appraisalOrStatVal',
      dataName: 'appraisalOrStatVal',
      symbol: '',
    },
    {
      row: 'VALUATION DATE',
      name: 'valuationDate',
      dataName: 'valuationDate',
      symbol: '',
    },
    {
      row: 'VALUATION AMOUNT',
      name: 'valuationAmount',
      dataName: 'valuationAmount',
      symbol: '$',
    },
    {
      row: 'VALUATION PERUNIT',
      name: 'valuationPerunit',
      dataName: 'valuationPerunit',
      symbol: '$',
    },
    {
      row: 'UW_REV_PERUNIT',
      name: 'uwRevPerunit',
      dataName: 'uwRevPerunit',
      symbol: '$',
    },
    {
      row: 'UW_EXP_PERUNIT',
      name: 'uwExpPerunit',
      dataName: 'uwExpPerunit',
      symbol: '$',
    },
    {
      row: 'UW CAPRATE',
      name: 'uwCaprate',
      dataName: 'uwCaprate',
      symbol: '%',
    },
    {
      row: 'LOAN CALCULATION DATE',
      name: 'distributionDateMostrecent',
      dataName: 'distributionDateMostrecent',
      symbol: '',
    },
    {
      row: 'LOAN CURRENT BALANCE',
      name: 'currBalance',
      dataName: 'currBalance',
      symbol: '$',
    },
    {
      row: 'CURRENT RATE',
      name: 'currRate',
      dataName: 'currRate',
      symbol: '%',
    },
    {
      row: 'CURRENT LTV',
      name: 'currLtv',
      dataName: 'currLtv',
      symbol: '%',
    },
    {
      row: 'DSCR (NCF/PAYMENT)',
      name: 'currDscrNcF',
      dataName: 'currDscrNcF',
      symbol: '',
    },
    {
      row: 'LEVERED NOI',
      name: 'currLeveredNoi',
      dataName: 'currLeveredNoi',
      symbol: '%',
    },
    {
      row: 'REV STATEMENTENDDATE',
      name: 'statementEndDateRev',
      dataName: 'statementEndDateRev',
      symbol: '',
    },
    {
      row: 'OCCUP',
      name: 'occup',
      dataName: 'occup',
      symbol: '%',
    },
    {
      row: 'REV PERUNIT',
      name: 'revPerUnit',
      dataName: 'revPerUnit',
      symbol: '$',
    },
    {
      row: 'EXP PERUNIT',
      name: 'expPerUnit',
      dataName: 'expPerUnit',
      symbol: '$',
    },
    {
      row: 'NOI PERUNIT',
      name: 'noiPerUnit',
      dataName: 'noiPerUnit',
      symbol: '$',
    },
    {
      row: 'NCF PERUNIT',
      name: 'ncfPerUnit',
      dataName: 'ncfPerUnit',
      symbol: '$',
    },
    {
      row: 'FF STATEMENTENDDATE',
      name: 'statementEndDateFF',
      dataName: 'statementEndDateFF',
      symbol: '',
    },
    {
      row: 'FF REV PERUNIT',
      name: 'revPerUnitFF',
      dataName: 'revPerUnitFF',
      symbol: '$',
    },
    {
      row: 'FF EXP PERUNIT',
      name: 'expPerUnitFF',
      dataName: 'expPerUnitFF',
      symbol: '$',
    },
    {
      row: 'FF NOI PERUNIT',
      name: 'noiPerUnitFF',
      dataName: 'noiPerUnitFF',
      symbol: '$',
    },
    {
      row: 'FF NCF PERUNIT',
      name: 'ncfPerUnitFF',
      dataName: 'ncfPerUnitFF',
      symbol: '$',
    },
    {
      row: 'EXP PCTREV',
      name: 'expPctRev',
      dataName: 'expPctRev',
      symbol: '%',
    },
    {
      row: 'NOI PCTREV',
      name: 'noiPctRev',
      dataName: 'noiPctRev',
      symbol: '%',
    },
    {
      row: 'NCF PCTREV',
      name: 'ncfPctRev',
      dataName: 'ncfPctRev',
      symbol: '%',
    },
    {
      row: 'CAPEX PCTREV',
      name: 'capexPctRev',
      dataName: 'capexPctRev',
      symbol: '%',
    },
    {
      row: 'GROSRNT PCTREV',
      name: 'grosrntPctRev',
      dataName: 'grosrntPctRev',
      symbol: '%',
    },
    {
      row: 'VACANCY PCTREV',
      name: 'vacancyPctRev',
      dataName: 'vacancyPctRev',
      symbol: '%',
    },
    {
      row: 'BASERNT PCTREV',
      name: 'baserntPctRev',
      dataName: 'baserntPctRev',
      symbol: '%',
    },
    {
      row: 'LAUNDRY PCTREV',
      name: 'laundryPctRev',
      dataName: 'laundryPctRev',
      symbol: '%',
    },
    {
      row: 'PARKING PCTREV',
      name: 'parkingPctRev',
      dataName: 'parkingPctRev',
      symbol: '%',
    },
    {
      row: 'OTHERIN PCTREV',
      name: 'otherinPctRev',
      dataName: 'otherinPctRev',
      symbol: '%',
    },
    {
      row: 'RETAXES PCTREV',
      name: 'retaxesPctRev',
      dataName: 'retaxesPctRev',
      symbol: '%',
    },
    {
      row: 'PROPINS PCTREV',
      name: 'propinsPctRev',
      dataName: 'propinsPctRev',
      symbol: '%',
    },
    {
      row: 'UTILITI PCTREV',
      name: 'utilitiPctRev',
      dataName: 'utilitiPctRev',
      symbol: '%',
    },
    {
      row: 'REPAIRS PCTREV',
      name: 'repairsPctRev',
      dataName: 'repairsPctRev',
      symbol: '%',
    },
    {
      row: 'MANAGEM PCTREV',
      name: 'managemPctRev',
      dataName: 'managemPctRev',
      symbol: '%',
    },
    {
      row: 'PAYROLL PCTREV',
      name: 'payrollPctRev',
      dataName: 'payrollPctRev',
      symbol: '%',
    },
    {
      row: 'MARKETI PCTREV',
      name: 'marketiPctRev',
      dataName: 'marketiPctRev',
      symbol: '%',
    },
    {
      row: 'PROFESS PCTREV',
      name: 'professPctRev',
      dataName: 'professPctRev',
      symbol: '%',
    },
    {
      row: 'GENERAL PCTREV',
      name: 'generalPctRev',
      dataName: 'generalPctRev',
      symbol: '%',
    },
    {
      row: 'OTHEREX PCTREV',
      name: 'otherexPctRev',
      dataName: 'otherexPctRev',
      symbol: '%',
    },
    {
      row: 'GROUNDR PCTREV',
      name: 'groundrPctRev',
      dataName: 'groundrPctRev',
      symbol: '%',
    },
    {
      row: 'GROSRNT PERUNIT',
      name: 'grosrntPerUnit',
      dataName: 'grosrntPerUnit',
      symbol: '$',
    },
    {
      row: 'VACANCY PERUNIT',
      name: 'vacancyPerUnit',
      dataName: 'vacancyPerUnit',
      symbol: '$',
    },
    {
      row: 'BASERNT PERUNIT',
      name: 'baserntPerUnit',
      dataName: 'baserntPerUnit',
      symbol: '$',
    },
    {
      row: 'LAUNDRY PERUNIT',
      name: 'laundryPerUnit',
      dataName: 'laundryPerUnit',
      symbol: '$',
    },
    {
      row: 'PARKING PERUNIT',
      name: 'parkingPerUnit',
      dataName: 'parkingPerUnit',
      symbol: '$',
    },
    {
      row: 'OTHERIN PERUNIT',
      name: 'otherinPerUnit',
      dataName: 'otherinPerUnit',
      symbol: '$',
    },
    {
      row: 'RETAXES PERUNIT',
      name: 'retaxesPerUnit',
      dataName: 'retaxesPerUnit',
      symbol: '$',
    },
    {
      row: 'PROPINS PERUNIT',
      name: 'propinsPerUnit',
      dataName: 'propinsPerUnit',
      symbol: '$',
    },
    {
      row: 'UTILITI PERUNIT',
      name: 'utilitiPerUnit',
      dataName: 'utilitiPerUnit',
      symbol: '$',
    },
    {
      row: 'REPAIRS PERUNIT',
      name: 'repairsPerUnit',
      dataName: 'repairsPerUnit',
      symbol: '$',
    },
    {
      row: 'MANAGEM PERUNIT',
      name: 'managemPerUnit',
      dataName: 'managemPerUnit',
      symbol: '$',
    },
    {
      row: 'PAYROLL PERUNIT',
      name: 'payrollPerUnit',
      dataName: 'payrollPerUnit',
      symbol: '$',
    },
    {
      row: 'MARKETI PERUNIT',
      name: 'marketiPerUnit',
      dataName: 'marketiPerUnit',
      symbol: '$',
    },
    {
      row: 'PROFESS PERUNIT',
      name: 'professPerUnit',
      dataName: 'professPerUnit',
      symbol: '$',
    },
    {
      row: 'GENERAL PERUNIT',
      name: 'generalPerUnit',
      dataName: 'generalPerUnit',
      symbol: '$',
    },
    {
      row: 'OTHEREX PERUNIT',
      name: 'otherexPerUnit',
      dataName: 'otherexPerUnit',
      symbol: '$',
    },
    {
      row: 'GROUNDR PERUNIT',
      name: 'groundrPerUnit',
      dataName: 'groundrPerUnit',
      symbol: '$',
    },
    {
      row: 'HC INC PVTPAY PCTREV',
      name: 'hcIncPvtPayPctRev',
      dataName: 'hcIncPvtPayPctRev',
      symbol: '%',
    },
    {
      row: 'HC INC MEDCARE PCTREV',
      name: 'hcIncMedcarePctRev',
      dataName: 'hcIncMedcarePctRev',
      symbol: '%',
    },
    {
      row: 'HC INC NURSING PCTREV',
      name: 'hcIncNursingPctRev',
      dataName: 'hcIncNursingPctRev',
      symbol: '%',
    },
    {
      row: 'HC INC MEALS PCTREV',
      name: 'hcIncMealsPctRev',
      dataName: 'hcIncMealsPctRev',
      symbol: '%',
    },
    {
      row: 'HC EXP ROOMS PCTREV',
      name: 'hcExpRoomsPctRev',
      dataName: 'hcExpRoomsPctRev',
      symbol: '%',
    },
    {
      row: 'HC EXP MEALS PCTREV',
      name: 'hcExpMealsPctRev',
      dataName: 'hcExpMealsPctRev',
      symbol: '%',
    },
    {
      row: 'HC INC PVTPAY PERUNIT',
      name: 'hcIncPvtPayPerUnit',
      dataName: 'hcIncPvtPayPerUnit',
      symbol: '$',
    },
    {
      row: 'HC INC MEDCARE PERUNIT',
      name: 'hcIncMedcarePerUnit',
      dataName: 'hcIncMedcarePerUnit',
      symbol: '$',
    },
    {
      row: 'HC INC NURSING PERUNIT',
      name: 'hcIncNursingPerUnit',
      dataName: 'hcIncNursingPerUnit',
      symbol: '$',
    },
    {
      row: 'HC INC MEALS PERUNIT',
      name: 'hcIncMealsPerUnit',
      dataName: 'hcIncMealsPerUnit',
      symbol: '$',
    },
    {
      row: 'HC EXP ROOMS PERUNIT',
      name: 'hcExpRoomsPerUnit',
      dataName: 'hcExpRoomsPerUnit',
      symbol: '$',
    },
    {
      row: 'HC EXP MEALS PERUNIT',
      name: 'hcExpMealsPerUnit',
      dataName: 'hcExpMealsPerUnit',
      symbol: '$',
    },
  ],
} as const;

export const NUMBER_INPUTS = [
  'propertyZipCode',
  'latitude',
  'longitude',
  'yearBuilt',
  'yearRenov',
  'numUnits',
  'occup',
  'rev',
  'exp',
  'capex0',
  'grosRnt',
  'vacancy',
  'baseRnt',
  'laundry',
  'parking',
  'otherin',
  'retaxes',
  'propins',
  'utiliti',
  'repairs',
  'managem',
  'payroll',
  'marketi',
  'profess',
  'general',
  'otherex',
  'groundr',
  'capex',
  'hcIncPvtPayPctRev',
  'hcIncMedcarePctRev',
  'hcIncNursingPctRev',
  'hcIncMealsPctRev',
  'hcExpRoomsPctRev',
  'hcExpMealsPctRev',
  'hcIncPvtPayPerUnit',
  'hcIncMedcarePerUnit',
  'hcIncNursingPerUnit',
  'hcIncMealsPerUnit',
  'hcExpRoomsPerUnit',
  'hcExpMealsPerUnit',
  'uwRevPerunit',
  'distributionDateMostrecent',
  'currBalance',
  'currRate',
  'currLtv',
  'currDscrNcF',
  'currLeveredNoi',
  'statementEndDateFF',
  'otherexPerUnit',
  'uwExpPerunit',
] as const;

export const TABLE_SETTINGS = {
  COLUMN_WIDTH: 300,
  COLUMN_COUNT: 8,
  FIXED_COLUMN_COUNT: 1,
  FIXED_ROW_COUNT: 1,
  ROW_HEIGHT: 26,
  ROW_HEIGHT_HEADER: 100,
  ROW_COUNT: PROPERTY_DETAIL.ROWS.length + 1,
} as const;
