import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { QUERY_KEY } from 'common/query-keys';

import { RESEARCH } from 'containers/research/utils/constants';

import { PrintedData, PrintedQuartilesData } from './models/printedDataTypes';
import {
  IMetrocounts,
  MetroDivisionRegion,
  CompsPriceData,
  PrintDataQueryParams,
  FileExtensionType,
  PrintDataPropertyName,
  PrintBenchmarkDataPropertyName,
} from './models/types';
import * as datasetApi from './api';

export function useGetMetrocounts(): UseQueryResult<IMetrocounts[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.METROCOUNTS,
    datasetApi.fetchMetrocounts,
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.METROCOUNTS),
      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetSubtype(): UseQueryResult<string[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.SYBTYPE,
    datasetApi.fetchSubtypes,
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.SYBTYPE),
      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetNeighborhood(): UseQueryResult<MetroDivisionRegion[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.NEIGHBORHOOD,
    datasetApi.fetchNeighborhood,
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.NEIGHBORHOOD),
      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetResearch(): UseQueryResult<PrintedData[], Error> {
  const queryClient = useQueryClient();
  const params = useHistory();

  const searchParams = new URLSearchParams(params.location.search)
    .get(`${RESEARCH.PRINT_DATA_PARAMS}`);
  const fakaSearcPrarams = { region: '', subtype: '' } as PrintDataQueryParams;
  const searchParamsParsed: PrintDataQueryParams = !!searchParams && JSON.parse(
    searchParams.replace(`?${RESEARCH.PRINT_DATA_PARAMS}=` as string, ''),
  );
  const queryParams = searchParams ? searchParamsParsed : fakaSearcPrarams;

  return useQuery(
    QUERY_KEY.RESEARCH,
    () => datasetApi.fetchResearch(queryParams),
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.RESEARCH),
      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetQuartiles(): UseQueryResult<PrintedQuartilesData[], Error> {
  const queryClient = useQueryClient();
  const params = useHistory();

  const searchParams = new URLSearchParams(params.location.search)
    .get(`${RESEARCH.PRINT_DATA_PARAMS}`);
  const fakaSearcPrarams = { region: '', subtype: '' } as PrintDataQueryParams;
  const searchParamsParsed: PrintDataQueryParams = !!searchParams && JSON.parse(
    searchParams.replace(`?${RESEARCH.PRINT_DATA_PARAMS}=` as string, ''),
  );
  const queryParams = searchParams ? searchParamsParsed : fakaSearcPrarams;

  return useQuery(
    QUERY_KEY.QUARTILES,
    () => datasetApi.fetchQuartiles(queryParams),
    {
      initialData: () => queryClient.getQueryData(QUERY_KEY.QUARTILES),
      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useGetPrice():
  UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: PrintDataQueryParams | unknown) => datasetApi.fetchPrice(params as PrintDataQueryParams),
    {
      onSuccess: async (data) => queryClient.setQueryData(QUERY_KEY.DATASET_PRICE, data),
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<CompsPriceData>, Error, unknown, unknown>;
}

export function useSubscribeDataset():
  UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: PrintDataQueryParams | unknown) => datasetApi.subscribeData(params as PrintDataQueryParams),
    {
      onSuccess: async (data: AxiosResponse<{ error: string, message: string; }>) => {
        queryClient.refetchQueries([QUERY_KEY.METROCOUNTS]);

        if (data.data?.message) {
          SnackBarUtils.success(data.data?.message);
        }
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<unknown>, Error, unknown, unknown>;
}

export function useGetResearchDataFile(): UseMutationResult<AxiosResponse<string>, Error, unknown, unknown> {
  const params = useHistory();

  const searchParams = new URLSearchParams(params.location.search)
    .get(`${RESEARCH.PRINT_DATA_PARAMS}`);
  const fakaSearcPrarams = { region: '', subtype: '' } as PrintDataQueryParams;
  const searchParamsParsed: PrintDataQueryParams = !!searchParams && JSON.parse(
    searchParams.replace(`?${RESEARCH.PRINT_DATA_PARAMS}=` as string, ''),
  );
  const queryParams = searchParams ? searchParamsParsed : fakaSearcPrarams;

  const mutation = useMutation(
    (fileExtension: FileExtensionType | unknown) => {
      const paramsforFileRequest: PrintDataQueryParams &
      { fileExtension: FileExtensionType; } = {
        fileExtension: fileExtension as FileExtensionType,
        region: queryParams.region,
        subtype: queryParams.subtype,
      };
      return datasetApi.fetchResearchDataFile(paramsforFileRequest);
    },
    {
      onSuccess: async () => {
        SnackBarUtils.success(RESEARCH.DOWNLOAD);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<string>, Error, unknown, unknown>;
}

export function useGetQuartilesDataFile(): UseMutationResult<AxiosResponse<string>, Error, unknown, unknown> {
  const params = useHistory();

  const searchParams = new URLSearchParams(params.location.search)
    .get(`${RESEARCH.PRINT_DATA_PARAMS}`);
  const fakaSearcPrarams = { region: '', subtype: '' } as PrintDataQueryParams;
  const searchParamsParsed: PrintDataQueryParams = !!searchParams && JSON.parse(
    searchParams.replace(`?${RESEARCH.PRINT_DATA_PARAMS}=` as string, ''),
  );
  const queryParams = searchParams ? searchParamsParsed : fakaSearcPrarams;

  const mutation = useMutation(
    (fileExtension: FileExtensionType | unknown) => {
      const paramsforFileRequest: PrintDataQueryParams &
      { fileExtension: FileExtensionType; } = {
        fileExtension: fileExtension as FileExtensionType,
        region: queryParams.region,
        subtype: queryParams.subtype,
      };
      return datasetApi.fetchQuartilesDataFile(paramsforFileRequest);
    },
    {
      onSuccess: async () => {
        SnackBarUtils.success(RESEARCH.DOWNLOAD);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<string>, Error, unknown, unknown>;
}

export function useGetInputPropertyDataFile():
  UseMutationResult<AxiosResponse<string>, Error, unknown, unknown> {
  const mutation = useMutation(
    (fileExtension: FileExtensionType | unknown) => {
      const paramsforFileRequest:
        { fileExtension: FileExtensionType; } = { fileExtension: fileExtension as FileExtensionType };
      return datasetApi.fetchInputPropertyDataFile(paramsforFileRequest);
    },
    {
      onSuccess: async () => {
        SnackBarUtils.success(RESEARCH.DOWNLOAD);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<string>, Error, unknown, unknown>;
}

export function useGetCompsetsDataFile(property: string):
  UseMutationResult<AxiosResponse<string>, Error, unknown, unknown> {
  const mutation = useMutation(
    (fileExtension: FileExtensionType | unknown) => {
      const paramsforFileRequest: PrintDataPropertyName &
      { fileExtension: FileExtensionType; } = {
        fileExtension: fileExtension as FileExtensionType,
        propertyName: property as string,
      };
      return datasetApi.fetchCompsetsDataFile(paramsforFileRequest);
    },
    {
      onSuccess: async () => {
        SnackBarUtils.success(RESEARCH.DOWNLOAD);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<string>, Error, unknown, unknown>;
}

export function useGetBenchmarkDataFile(radioSelection: string, property: string):
  UseMutationResult<AxiosResponse<string>, Error, unknown, unknown> {
  const mutation = useMutation(
    (fileExtension: FileExtensionType | unknown) => {
      const paramsforFileRequest: PrintBenchmarkDataPropertyName &
      { fileExtension: FileExtensionType; } = {
        fileExtension: fileExtension as FileExtensionType,
        propertyName: property as string,
        radioSelection: radioSelection as string,
      };
      return datasetApi.fetchBenchmarkDataFile(paramsforFileRequest);
    },
    {
      onSuccess: async () => {
        SnackBarUtils.success(RESEARCH.DOWNLOAD);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as UseMutationResult<AxiosResponse<string>, Error, unknown, unknown>;
}
