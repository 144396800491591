import { QuartilesDataKeys } from 'containers/research/lib/models/printedDataTypes';
import { TableQuartilesDataTitle } from 'containers/research/lib/models/types';

export const FIXED_COLUMN_COUNT = 0 as const;
export const FIXED_ROW_COUNT = 1 as const;
export const ROW_HEIGHT = 28 as const;
export const COLUMN_WIDTH = 230 as const;
export const COLUMN_TITLE_WIDTH = 220 as const;

export const COLUMN_TITLES: TableQuartilesDataTitle[] = [
  { title: '', cell: QuartilesDataKeys.geography, symbol: '' },
  { title: '', cell: QuartilesDataKeys.SubType, symbol: '' },
  { title: '', cell: QuartilesDataKeys.props, symbol: '' },
  { title: 'Year Built', cell: QuartilesDataKeys.YearBuilt, symbol: '' },
  { title: 'Year Renov', cell: QuartilesDataKeys.YearRenov, symbol: '' },
  { title: 'Num Units', cell: QuartilesDataKeys.NumUnits, symbol: '' },
  { title: 'Population PerSqMile', cell: QuartilesDataKeys.PopulationPerSqMile, symbol: '' },
  { title: 'Median HomeVal', cell: QuartilesDataKeys.MedianHomeVal, symbol: '$' },
  { title: 'Median HHInc', cell: QuartilesDataKeys.MedianHHInc, symbol: '$' },
  { title: 'Median Age', cell: QuartilesDataKeys.MedianAge, symbol: '' },
  { title: 'Median Rent', cell: QuartilesDataKeys.MedianRent, symbol: '$' },
  { title: 'Median Owner Costs', cell: QuartilesDataKeys.MedianOwnerCosts, symbol: '$' },
  { title: 'Renter PCT', cell: QuartilesDataKeys.RenterPCT, symbol: '%' },
  { title: 'Rent Over30pct HHInc PCT', cell: QuartilesDataKeys.rentOver30PctHHIncPCT, symbol: '%' },
  { title: 'Bachelors Degree PCT', cell: QuartilesDataKeys.BachelorsDegreePCT, symbol: '%' },
  { title: 'Unemployment PCT', cell: QuartilesDataKeys.UnemploymentPCT, symbol: '%' },
  { title: 'Mgt Finance Job PCT', cell: QuartilesDataKeys.MngtFinanceJobPCT, symbol: '%' },
  { title: 'MovedIn After2010 PCT', cell: QuartilesDataKeys.MovedInAfter2010PCT, symbol: '%' },
  { title: 'VALUATION DATE', cell: QuartilesDataKeys.ValuationDate, symbol: '' },
  { title: 'VALUATION PERUNIT', cell: QuartilesDataKeys.ValuationPerUnit, symbol: '$' },
  { title: 'UW NOI PERUNIT', cell: QuartilesDataKeys.UwNoiPerUnitBenchmark, symbol: '$' },
  { title: 'UW CAPRATE', cell: QuartilesDataKeys.UwCaprateBenchmark, symbol: '2%' },
  { title: 'OCCUP', cell: QuartilesDataKeys.Occup, symbol: '%' },
  { title: 'REV PERUNIT', cell: QuartilesDataKeys.RevPerUnit, symbol: '$' },
  { title: 'EXP PERUNIT', cell: QuartilesDataKeys.ExpPerUnit, symbol: '$' },
  { title: 'NOI PERUNIT', cell: QuartilesDataKeys.NoiPerUnit, symbol: '$' },
  { title: 'NCF PERUNIT', cell: QuartilesDataKeys.NcfPerUnit, symbol: '$' },
  { title: 'CAPEX PERUNIT', cell: QuartilesDataKeys.CapexPerUnit, symbol: '$' },
  { title: 'EXP PCTREV', cell: QuartilesDataKeys.ExpPctRev, symbol: '%' },
  { title: 'NOI PCTREV', cell: QuartilesDataKeys.NoiPctRev, symbol: '%' },
  { title: 'NCF PCTREV', cell: QuartilesDataKeys.NcfPctRev, symbol: '%' },
  { title: 'CAPEX PCTREV', cell: QuartilesDataKeys.CapexPctRev, symbol: '%' },
  { title: 'REV  PCT 1YRCH', cell: QuartilesDataKeys.RevPct1Yrch, symbol: '%' },
  { title: 'EXP PCT 1YRCH', cell: QuartilesDataKeys.ExpPct1Yrch, symbol: '%' },
  { title: 'NOI PCT 1YRCH', cell: QuartilesDataKeys.NoiPct1Yrch, symbol: '%' },
  { title: 'REV PERUNIT 1YRCH', cell: QuartilesDataKeys.RevPerUnit1Yrch, symbol: '$' },
  { title: 'EXP PERUNIT 1YRCH', cell: QuartilesDataKeys.ExpPerUnit1Yrch, symbol: '$' },
  { title: 'NOI PERUNIT 1YRCH', cell: QuartilesDataKeys.NoiPerUnit1Yrch, symbol: '$' },
  { title: 'GROSRNT PERUNIT', cell: QuartilesDataKeys.GrosrntPerUnit, symbol: '$' },
  { title: 'VACANCY PERUNIT', cell: QuartilesDataKeys.VacancyPerUnit, symbol: '$' },
  { title: 'BASERNT PERUNIT', cell: QuartilesDataKeys.BaserntPerUnit, symbol: '$' },
  { title: 'RETAXES PERUNIT', cell: QuartilesDataKeys.RetaxesPerUnit, symbol: '$' },
  { title: 'PROPINS PERUNIT', cell: QuartilesDataKeys.PropinsPerUnit, symbol: '$' },
  { title: 'UTILITI PERUNIT', cell: QuartilesDataKeys.UtilitiPerUnit, symbol: '$' },
  { title: 'REPAIRS PERUNIT', cell: QuartilesDataKeys.RepairsPerUnit, symbol: '$' },
  { title: 'MANAGEM PERUNIT', cell: QuartilesDataKeys.ManagemPerUnit, symbol: '$' },
  { title: 'PAYROLL PERUNIT', cell: QuartilesDataKeys.PayrollPerUnit, symbol: '$' },
  { title: 'MARKETI PERUNIT', cell: QuartilesDataKeys.MarketiPerUnit, symbol: '$' },
  { title: 'GROSRNT PCTREV', cell: QuartilesDataKeys.GrosrntPctRev, symbol: '%' },
  { title: 'VACANCY PCTREV', cell: QuartilesDataKeys.VacancyPctRev, symbol: '%' },
  { title: 'BASERNT PCTREV', cell: QuartilesDataKeys.BaserntPctRev, symbol: '%' },
  { title: 'RETAXES PCTREV', cell: QuartilesDataKeys.RetaxesPctRev, symbol: '%' },
  { title: 'PROPINS PCTREV', cell: QuartilesDataKeys.PropinsPctRev, symbol: '%' },
  { title: 'UTILITI PCTREV', cell: QuartilesDataKeys.UtilitiPctRev, symbol: '%' },
  { title: 'REPAIRS PCTREV', cell: QuartilesDataKeys.RepairsPctRev, symbol: '%' },
  { title: 'MANAGEM PCTREV', cell: QuartilesDataKeys.ManagemPctRev, symbol: '%' },
  { title: 'PAYROLL PCTREV', cell: QuartilesDataKeys.PayrollPctRev, symbol: '%' },
  { title: 'MARKETI PCTREV', cell: QuartilesDataKeys.MarketiPctRev, symbol: '%' },
  { title: 'GROSRNT PERUNIT 1YRCH', cell: QuartilesDataKeys.GrosrntPerUnit1Yrch, symbol: '$' },
  { title: 'VACANCY PERUNIT 1YRCH', cell: QuartilesDataKeys.VacancyPerUnit1Yrch, symbol: '$' },
  { title: 'BASERNT PERUNIT 1YRCH', cell: QuartilesDataKeys.BaserntPerUnit1Yrch, symbol: '$' },
  { title: 'RETAXES PERUNIT 1YRCH', cell: QuartilesDataKeys.RetaxesPerUnit1Yrch, symbol: '$' },
  { title: 'PROPINS PERUNIT 1YRCH', cell: QuartilesDataKeys.PropinsPerUnit1Yrch, symbol: '$' },
  { title: 'UTILITI PERUNIT 1YRCH', cell: QuartilesDataKeys.UtilitiPerUnit1Yrch, symbol: '$' },
  { title: 'REPAIRS PERUNIT 1YRCH', cell: QuartilesDataKeys.RepairsPerUnit1Yrch, symbol: '$' },
  { title: 'MANAGEM PERUNIT 1YRCH', cell: QuartilesDataKeys.ManagemPerUnit1Yrch, symbol: '$' },
  { title: 'PAYROLL PERUNIT 1YRCH', cell: QuartilesDataKeys.PayrollPerUnit1Yrch, symbol: '$' },
  { title: 'MARKETI PERUNIT 1YRCH', cell: QuartilesDataKeys.MarketiPerUnit1Yrch, symbol: '$' },
  { title: 'HC INC PVTPAY PCTREV', cell: QuartilesDataKeys.HcIncPvtPayPctRev, symbol: '%' },
  { title: 'HC INC MEDCARE PCTREV', cell: QuartilesDataKeys.HcIncMedcarePctRev, symbol: '' },
  { title: 'HC INC NURSING PCTREV', cell: QuartilesDataKeys.HcIncNursingPctRev, symbol: '%' },
  { title: 'HC INC MEALS PCTREV', cell: QuartilesDataKeys.HcIncMealsPctRev, symbol: '%' },
  { title: 'HC EXP ROOMS PCTREV', cell: QuartilesDataKeys.HcExpRoomsPctRev, symbol: '%' },
  { title: 'HC EXP MEALS PCTREV', cell: QuartilesDataKeys.HcExpMealsPctRev, symbol: '%' },
  { title: 'HC INC PVTPAY PERUNIT', cell: QuartilesDataKeys.HcIncPvtPayPerUnit, symbol: '$' },
  { title: 'HC INC MEDCARE PERUNIT', cell: QuartilesDataKeys.HcIncMedcarePerUnit, symbol: '' },
  { title: 'HC INC NURSING PERUNIT', cell: QuartilesDataKeys.HcIncNursingPerUnit, symbol: '$' },
  { title: 'HC INC MEALS PERUNIT', cell: QuartilesDataKeys.HcIncMealsPerUnit, symbol: '$' },
  { title: 'HC EXP ROOMS PERUNIT', cell: QuartilesDataKeys.HcExpRoomsPerUnit, symbol: '$' },
  { title: 'HC EXP MEALS PERUNIT', cell: QuartilesDataKeys.HcExpMealsPerUnit, symbol: '$' },
];

export const ROW_TITLES = [
  { title: '4TH_QUARTILE_2021' },
  { title: 'MEDIAN_2021' },
  { title: '1ST_QUARTILE_2021' },
  { title: '4TH_QUARTILE_2020' },
  { title: 'MEDIAN_2020' },
  { title: '1ST_QUARTILE_2020' },
];

export const ROW_TITLES_PROPS: TableQuartilesDataTitle[] = [
  { title: 'GEOGRAPHY:', cell: QuartilesDataKeys.geography, symbol: '' },
  { title: 'SUBTYPE:', cell: QuartilesDataKeys.SubType, symbol: '' },
  { title: '#PROPS(2021)', cell: QuartilesDataKeys.props, symbol: '' },
  { title: 'GEOGRAPHY:', cell: QuartilesDataKeys.geography, symbol: '' },
  { title: 'SUBTYPE:', cell: QuartilesDataKeys.SubType, symbol: '' },
  { title: '#PROPS(2020)', cell: QuartilesDataKeys.props, symbol: '' },
];

export const ROUND_TITLES = [
  'grosrntPerUnit1Yrch',
  'vacancyPerUnit1Yrch',
  'baserntPerUnit1Yrch',
  'retaxesPerUnit1Yrch',
  'propinsPerUnit1Yrch',
  'utilitiPerUnit1Yrch',
  'repairsPerUnit1Yrch',
  'managemPerUnit1Yrch',
  'payrollPerUnit1Yrch',
  'marketiPerUnit1Yrch',
] as const;
