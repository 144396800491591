/* eslint-disable max-len */
import { memo } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const EmailIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M21.5531 4.00019C21.4622 3.99082 21.3706 3.99082 21.2798 4.00019H2.6131C2.49346 4.00203 2.37462 4.01997 2.25977 4.05352L11.8931 13.6469L21.5531 4.00019Z" />
    <path d="M22.5404 4.92676L12.8338 14.5934C12.584 14.8418 12.246 14.9811 11.8938 14.9811C11.5415 14.9811 11.2036 14.8418 10.9538 14.5934L1.33378 5.00009C1.3042 5.10878 1.28852 5.22079 1.28711 5.33342V18.6668C1.28711 19.0204 1.42759 19.3595 1.67763 19.6096C1.92768 19.8596 2.26682 20.0001 2.62044 20.0001H21.2871C21.6407 20.0001 21.9799 19.8596 22.2299 19.6096C22.48 19.3595 22.6204 19.0204 22.6204 18.6668V5.33342C22.6151 5.19453 22.5881 5.05731 22.5404 4.92676V4.92676ZM3.53378 18.6668H2.60711V17.7134L7.45378 12.9068L8.39378 13.8468L3.53378 18.6668ZM21.2738 18.6668H20.3404L15.4804 13.8468L16.4204 12.9068L21.2671 17.7134L21.2738 18.6668Z" />
  </SvgIcon>
);

export default memo(EmailIcon);
