import {
  FocusEvent, MouseEvent, useCallback, useState,
} from 'react';

type TooltipType = {
  isOpen: boolean;
  onShowTooltip: (event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>) => void;
  onHideTooltip: () => void;
}

const useTooltip = (): TooltipType => {
  const [isOpen, setIsOpen] = useState(false);

  const onShowTooltip = useCallback(
    (event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>): void => {
      if (event.currentTarget.offsetWidth < event.currentTarget.scrollWidth) {
        setIsOpen(true);
        return;
      }
      setIsOpen(false);
    }, [],
  );

  const onHideTooltip = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    onShowTooltip,
    onHideTooltip,
  };
};

export default useTooltip;
