import { memo, useMemo } from 'react';

import { MenuItem } from '@mui/material';

import { SUB_TYPES } from 'containers/input-property/utils/constants';
import {
  useGetInputValue,
} from 'containers/input-property/components/mapTab/propertyData/requiredTable/hooks/useGetInputValue';
import { FormRegister, RowName } from 'containers/input-property/components/mapTab/map/utils/constants';

import { TableInputStyled } from '../../styled';

const SelectInput = ({ row, index, register }: {
  row: string,
  index: number,
  register: FormRegister,
}):JSX.Element => {
  const { value } = useGetInputValue(index);
  const randomKey = useMemo(() => () => Math.floor((Math.random() * 1000)), []);

  return (
    <TableInputStyled
      select
      id={row}
      fullWidth
      key={randomKey()}
      required
      defaultValue={value}
      {...register(row as RowName)}
    >
      {SUB_TYPES.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TableInputStyled>
  );
};

export default memo(SelectInput);
