/* eslint-disable max-len */
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    '*::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: theme.palette.background.gray,
    },
    '*::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      backgroundColor: theme.palette.background.gray,
    },

    '*::-webkit-scrollbar-thumb': {
      borderRadius: '50px',
      backgroundColor: theme.palette.background.blue,
    },

    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
      height: 0,
    },
    'html, body, #root': {
      height: '100vh',
      letterSpacing: '0.5px',
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.background.gray,
      justifyContent: 'space-between',

    },
    body: {
      margin: 0,
      paddingRight: '0 !important', // more info https://github.com/mui-org/material-ui/issues/10000,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100vh',
    },
  },
}));

const GlobalStyles = (): null => {
  useStyles();

  return null;
};

export default GlobalStyles;
