import React, {
  memo, useContext, useEffect, useImperativeHandle,
} from 'react';
import { styled } from '@mui/material';
import {
  TableContextProvider,
  TableDispatchContext,
  TableStateContext,
} from './model';
import Row from './ui/Row';
import { Data } from './lib/types';

type PropsType = {
  rowData: Data[];
  disabled: number[];
};

type RefType = {
  getData: () => Data[];
  disableEnableRows: (disabled: number[], enable: number[]) => void;
  setData: (data: Data[]) => void;
} | null;

const Root = styled('div')`
  width: 100%;
  border: 1px solid gray;
  border-radius: 6px;
  overflow: hidden;
`;

const Content = React.forwardRef<RefType, PropsType>((props, ref) => {
  const dispatch = useContext(TableDispatchContext);
  const { rowData: rowDataArray } = useContext(TableStateContext);
  useEffect(() => {
    dispatch({
      type: 'SET_INITIAL_DATA',
      payload: {
        rowData: props.rowData.map<Data>((data, index) => ({
          ...data,
          id: index,
          disabled: props.disabled.includes(index),
        })),
        ref,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    getData: () => rowDataArray.filter((data) => data.type !== 'header'),
    disableEnableRows: (disabled: number[], enable: number[]) => {
      dispatch({
        type: 'DISABLE_ENABLE_ROWS',
        payload: {
          disabled,
          enable,
        },
      });
    },
    setData: (data: Data[]): void => {
      dispatch({
        type: 'SET_DATA',
        payload: {
          rowData: data,
        },
      });
    },
  }));

  return (
    <Root>
      {rowDataArray.map((data: Data) => (<Row key={data.id} data={data} />))}
    </Root>
  );
});

const DuplexlTable = ({ rowData, disabled }: PropsType, ref: React.ForwardedRef<RefType>): JSX.Element => (
  <TableContextProvider>
    <Content ref={ref} rowData={rowData} disabled={disabled} />
  </TableContextProvider>
);

export default memo(React.forwardRef<RefType, PropsType>(DuplexlTable));
