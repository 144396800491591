import { useEffect, useState } from 'react';

import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { useMappedRequiredTableData } from 'common/components/BingMap/lib/dataMappers';

interface IReturn {
  value: number | string,
}

export const useGetInputValue = (index: number):IReturn => {
  const { state: idState } = usePropertyId();

  const tableData = useMappedRequiredTableData(idState.id);

  const [value, setValue] = useState<string | number>('');

  useEffect(() => {
    if (idState.id && tableData) {
      const { data } = tableData;
      if (data[index]) {
        setValue(data[index] || '');
      }
    } else {
      setValue('');
    }
  }, [idState.id, tableData, index]);

  return { value };
};
