import memoizeOne from 'memoize-one';

import { IPropertyResponseData } from 'common/components/BingMap/utils/types';

import { formattedDate } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/helpers';

import { NUMBER_INPUTS, PROPERTY_DETAIL } from '../../utils/constants';

export const getRowName = memoizeOne(
  (rowIndex: number): string => PROPERTY_DETAIL.ROWS[rowIndex - 1].row,
);

export const getFieldName = memoizeOne(
  (rowIndex: number): string => PROPERTY_DETAIL.ROWS[rowIndex - 1].name,
);

export const getRowSymbol = memoizeOne(
  (rowIndex: number): string => PROPERTY_DETAIL.ROWS[rowIndex - 1].symbol,
);

export const getPropertyName = memoizeOne(
  (columnIndex: number, data: IPropertyResponseData[]): string => data[columnIndex - 1].propertyName,
);

export const getText = memoizeOne(
  (rowIndex: number, columnIndex: number, data: IPropertyResponseData[]): string | number => {
    const property = data[columnIndex - 1];
    const { dataName } = PROPERTY_DETAIL.ROWS[rowIndex - 1];

    const price = getRowSymbol(rowIndex) === '$'
      ? Math.round(property[dataName] as number)
      : property[dataName];

    const convertedProperty = getRowSymbol(rowIndex) === '%'
      ? Math.round(typeof property[dataName] === 'number' ? property[dataName] as number * 10000 : 0) / 100
      : (dataName.includes('populationPerSqMile') && Math.round(property[dataName] as number))
      || price;

    if (getRowSymbol(rowIndex) === '$') {
      const int = typeof convertedProperty === 'number'
        ? `${getRowSymbol(rowIndex)} ${convertedProperty.toLocaleString('us')}`
        : property[dataName];
      return int as number;
    }

    if (getRowSymbol(rowIndex) === '%' && dataName === 'uwCaprate') {
      const int = typeof convertedProperty === 'number'
        ? `${((+(convertedProperty) * 100) / 100).toFixed(2)} ${getRowSymbol(rowIndex)}`
        : property[dataName];
      return int as number;
    }
    if (getRowSymbol(rowIndex) === '%') {
      const int = typeof convertedProperty === 'number'
        ? `${(Math.round(+(convertedProperty.toFixed(2)) * 10) / 10)
          .toFixed(1)} ${getRowSymbol(rowIndex)}`
        : property[dataName];
      return int as number;
    }

    if (getRowSymbol(rowIndex) === 'int') {
      const int = typeof convertedProperty === 'number'
        ? `${convertedProperty.toLocaleString()}`
        : property[dataName];
      return int as number;
    }

    if ((dataName === 'valuationDate'
      || dataName === 'distributionDateMostrecent'
      || dataName === 'statementEndDateFF'
      || dataName === 'statementEndDateRev') && property[dataName] !== null) {
      return formattedDate(property[dataName]);
    }

    return property[dataName] as string;
  },
);

export const getDisabled = memoizeOne(
  (propName: string, selectedProps: string[], row: string): boolean => (
    !(selectedProps.includes(propName) && !(row === 'propertyName') && !!row)
  ),
);

export const getInputType = memoizeOne(
  (row: string): string => (
    (NUMBER_INPUTS as ReadonlyArray<string>).includes(row) ? 'number' : 'text'
  ),
);
