import { createContext } from 'react';

export type AddUserModalAction = { type: 'open'; } |
{ type: 'close'; };

export type AddUserModalDispatch = (action: AddUserModalAction) => void;

export type AddUserModalState = { open: boolean; };

export const AddUserModalContext = createContext<
  { state: AddUserModalState; dispatch: AddUserModalDispatch; } | undefined
>(undefined);

createContext<(AddUserModalState & AddUserModalDispatch) | undefined>(undefined);
