import { memo } from 'react';

import { Dialog } from '@mui/material';

import {
  ActionsWrapperStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalTextStyled,
  ModalTitleStyled,
  ModalWrapperStyled,
} from 'containers/benchmark/modal/utils/styled';

interface IModalParams {
  open: boolean;
  datatype?: string;
  close: VoidFunction;
  onConfirm: VoidFunction;
  title: string;
  modalText: string;
  textConfirm: string;
  textClose: string;
}

const DialogWindow = ({
  open,
  close,
  onConfirm,
  datatype,
  title,
  modalText,
  textConfirm,
  textClose,
}: IModalParams):JSX.Element => (
  <>
    <Dialog open={open} onClose={close}>
      <ModalWrapperStyled>
        <ModalTitleStyled>
          {title}
        </ModalTitleStyled>
        <ModalTextStyled>
          {modalText}
        </ModalTextStyled>
        <ActionsWrapperStyled>
          <ConfirmButtonStyled
            color="secondary"
            variant="contained"
            type="submit"
            name="submitBtn"
            form="form-modify"
            data-type={datatype}
            onClick={onConfirm}
          >
            {textConfirm}
          </ConfirmButtonStyled>
          <CancelButtonStyled
            variant="outlined"
            onClick={close}
          >
            {textClose}
          </CancelButtonStyled>
        </ActionsWrapperStyled>
      </ModalWrapperStyled>
    </Dialog>
  </>
);
export default memo(DialogWindow);
