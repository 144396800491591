import { Box, InputAdornment, TextField } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useEffect, useState,
} from 'react';
import { TableDispatchContext } from '../../model/index';
import { BasicCell } from '../BasicCell/InputCell';

type PropsType = { value: string; index: number; disabled: boolean };

const CellPercent: FC<PropsType> = ({ value, index, disabled }) => {
  const [input, setInput] = useState<string>('');
  const dispatch = useContext(TableDispatchContext);

  useEffect(() => {
    setInput(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onBlur = (): void => {
    dispatch({
      type: 'UPDATE_VALUE_BY_INDEX',
      payload: { index, value: input },
    });
  };

  const onTypeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: inputValue } = event.target;
    const reg = /^-?\d*\.?\d*$/;

    if (!reg.test(inputValue)) {
      return;
    }
    setInput(inputValue);
  };

  return (
    <BasicCell isColored={disabled}>
      <Box width={80}>
        <TextField
          style={{ width: '87px' }}
          disabled={disabled}
          value={input}
          // value={input}
          onBlur={onBlur}
          onChange={onTypeHandler}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Box>
    </BasicCell>
  );
};

export default CellPercent;
