import { memo } from 'react';

import { Button } from '@mui/material';

import { useFormState } from 'react-hook-form';
import { useEditColumn } from 'common/components/providers/EditTableProvider/hooks';
import { FormControl } from 'containers/input-property/components/mapTab/map/utils/constants';
import { BUTTONS_TEXT } from 'containers/input-property/utils/constants';

import { ButtonsContainerStyled } from './styled';

const InsertButton = ({ control }:{control: FormControl}):JSX.Element => {
  const { isDirty } = useFormState({ control });
  const { state: editState } = useEditColumn();
  const isDisabled = !(isDirty || !!editState.mapOfProps.size);

  return (
    <ButtonsContainerStyled>
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        disabled={isDisabled}
        type="submit"
        name="submitBtn"
        data-type="add"
      >
        {BUTTONS_TEXT.INSERT.toUpperCase()}
      </Button>
    </ButtonsContainerStyled>
  );
};

export default memo(InsertButton);
