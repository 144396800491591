import { styled } from '@mui/material';

export const StepsSignStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
  padding: theme.spacing(5),
  height: '100%',
  borderRadius: '6px',
  // eslint-disable-next-line max-len
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
}));

export const StepsSignTextStyled = styled('div')(({ theme }) => ({
  border: '1px solid #ccc',
  borderRadius: '6px',
  width: theme.spacing(230),
  padding: theme.spacing(5),
  marginRight: theme.spacing(5),
}));

export const StepsSignTextTitleStyled = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const StepsSignTextBoxStyled = styled('div')(({ theme }) => ({
  fontWeight: 'normal',
  marginBottom: theme.spacing(3),
}));

export const TableContainerStyled = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto repeat(6, auto)',
  width: '100%',
  overflow: 'auto',
}));

export const GridCellStyled = styled('div')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.background.white,
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  minWidth: theme.spacing(35),
  textAlign: 'center',

  // eslint-disable-next-line max-len
  '&:nth-child(13), &:nth-child(11), &:nth-child(9), &:nth-child(7), &:nth-child(5), &:nth-child(3), &:nth-child(1)': {
    borderRight: 0,
  },

  '&:nth-child(1)': {
    gridColumn: 'span 2',
    backgroundColor: theme.palette.background.lightGray,
  },

  '&:nth-child(12), &:nth-child(13)': {
    borderBottom: 0,
  },
}));
