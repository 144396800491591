import { useCallback, useEffect, useState } from 'react';

import { useButtonState, usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { useCurrentCompsetsPropertyContext, useCurrentPropertyContext } from 'context/hooks';

type ReturnType = {
  property: string,
  anchorEl: HTMLElement | null,
  handleClickSelect: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  handleCloseSelect: () => void,
  handleChange: (event: React.MouseEvent<HTMLElement>) => void;
};

export const useSelectProperty = (): ReturnType => {
  const { state: propId, dispatch: idDispatch } = usePropertyId();

  const { dispatch: buttonDispatch } = useButtonState();
  const { dispatch: propertyDispatch } = useCurrentPropertyContext();
  const { dispatch } = useCurrentCompsetsPropertyContext();

  const [property, setProperty] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setProperty(propId.id || '');
  }, [propId]);

  const handleClickSelect = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleChange = (event: React.MouseEvent<HTMLElement>): void => {
    handleCloseSelect();
    const prop = event.currentTarget.dataset.value as string;
    const currentState = { ...event };

    if (prop) {
      setProperty(prop as string);
      idDispatch({ newValue: prop as string });
      buttonDispatch({ newValue: true });
      propertyDispatch({ currentProperty: prop, currentPropertyEvent: currentState });
      dispatch({ currentCompsetsProperty: prop });
    }
  };

  return {
    property,
    anchorEl,
    handleClickSelect,
    handleCloseSelect,
    handleChange,
  };
};
