import {
  memo, useEffect, useMemo, useRef,
} from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';

import { TableStyled } from 'containers/input-property/components/detailsTab/styled';
import { BenchmarkAnalyticsModel } from 'containers/benchmark/lib/models/BencmarkAnalytics.model';

import CellRenderer from './CellRenderer';
import { TABLE_SETTINGS } from './utils/constants';

const BenchmarkTable = ({ data }:{data: BenchmarkAnalyticsModel[] }):JSX.Element => {
  const getColumnWidth = useMemo(() => () => TABLE_SETTINGS.COLUMN_WIDTH, []);
  const tableRef = useRef<MultiGrid>(null);

  useEffect(() => {
    const grid = tableRef.current;
    if (grid) {
      grid.forceUpdateGrids();
    }
  }, [data]);

  return (
    <TableStyled>
      <AutoSizer>
        {({ width, height }) => (
          <MultiGrid
            ref={tableRef}
            cellRenderer={({ key: mainKey, ...rest }) => (
              <CellRenderer
                {...rest}
                mainKey={mainKey}
                key={mainKey}
                data={data}
              />
            )}
            columnWidth={getColumnWidth}
            columnCount={TABLE_SETTINGS.COLUMN_COUNT}
            enableFixedColumnScroll
            enableFixedRowScroll
            fixedColumnCount={TABLE_SETTINGS.FIXED_COLUMN_COUNT}
            fixedRowCount={TABLE_SETTINGS.FIXED_ROW_COUNT}
            height={height}
            rowHeight={TABLE_SETTINGS.ROW_HEIGHT}
            rowCount={TABLE_SETTINGS.ROW_COUNT}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
    </TableStyled>
  );
};

export default memo(BenchmarkTable);
