import {
  useCallBenchmarkAnalytics,
  useSetClientPortfolioName,
  useSetRadioSelection,
} from '../local-state/hooks';
import { BenchmarkAnalyticsModel } from './models/BencmarkAnalytics.model';
import { BenchmarkAnalyticsInfoModel } from './models/BencmarkAnalyticsInfo.model';
import {
  useGetBenchmarkAnalytics,
  useGetCompsetsPropertyBy,
  useGetCompsetsPropertyByAlgo,
  useGetPropertyBy,
} from './query-hooks';
import { RadioSelectionValue } from '../components/list/utils/types';
import { ClientPropertyFromList } from './utils/types';

export type ClientPropertyFromListUnion = keyof typeof ClientPropertyFromList;
type SelectClientReturnType = {
  clientPortfolioMap: Map<ClientPropertyFromListUnion, string | number | undefined>,
  isLoading: boolean,
};

export const useCreateBencmarkAnalyticsInfoTableData = (): {
  bencmarkInfo: BenchmarkAnalyticsInfoModel[],
} => {
  const { state } = useSetClientPortfolioName();
  const { state: { value: selection } } = useSetRadioSelection();

  const { data } = useGetCompsetsPropertyBy(state.value);
  const { data: dataAlgo } = useGetCompsetsPropertyByAlgo(state.value);

  const tableInfo = selection.includes('savedCompset') ? data : dataAlgo;

  const modifiedDataArray = [] as BenchmarkAnalyticsInfoModel[];

  if (tableInfo?.length && !selection.includes('compsetByMfcAlgorithm')) {
    tableInfo.forEach((itemData) => {
      const mappedClientData = {
        compStatus: itemData.compStatus,
        mfcCompId: itemData.mfcCompId,
        propertyName: itemData.propertyName,
        propertyAddress: itemData.propertyAddress,
        propertyCity: itemData.propertyCity,
        propertyState: itemData.propertyState,
        propertyZipCode: itemData.propertyZipCode,
        propertyCounty: itemData.propertyCounty,
        propertyCMSA: itemData.propertyCMSA,
        propertyDivision: itemData.propertyDivision,
        propertyRegion: itemData.propertyRegion,
        latitude: itemData.latitude,
        longitude: itemData.longitude,
        distanceToSubject: itemData.distanceToSubject,
        yearBuilt: itemData.yearBuilt,
        yearRenov: itemData.yearRenov,
        numUnits: itemData.numUnits,
        subType: itemData.subType,
        affordability: itemData.affordability,
        populationPerSqMile: itemData.populationPerSqMile,
        medianHomeVal: itemData.medianHomeVal,
        medianHHInc: itemData.medianHHInc,
        medianAge: itemData.medianAge,
        medianRent: itemData.medianRent,
        medianOwnerCosts: itemData.medianOwnerCosts,
        renterPCT: itemData.renterPCT,
        rentOver30PctHHIncPCT: itemData.rentOver30PctHHIncPCT,
        bachelorsDegreePCT: itemData.bachelorsDegreePCT,
        unemploymentPCT: itemData.unemploymentPCT,
        mgtFinanceJobPCT: itemData.mgtFinanceJobPCT,
        movedInAfter2010PCT: itemData.movedInAfter2010PCT,
      } as unknown as BenchmarkAnalyticsInfoModel;

      modifiedDataArray.push(mappedClientData);
    });
  }

  if (tableInfo?.length && selection.includes('compsetByMfcAlgorithm')) {
    tableInfo.forEach((itemData) => {
      const mappedClientData = {
        compStatus: itemData.compStatus,
        mfcCompId: itemData.mfcCompId,
        propertyName: itemData.propertyName,
        propertyAddress: itemData.propertyAddress,
        propertyCity: itemData.propertyCity,
        propertyState: itemData.propertyState,
        propertyZipCode: itemData.propertyZipCode,
        propertyCounty: itemData.propertyCounty,
        propertyCMSA: itemData.propertyCMSA,
        propertyDivision: itemData.propertyDivision,
        propertyRegion: itemData.propertyRegion,
        latitude: itemData.latitude,
        longitude: itemData.longitude,
        distanceToSubject: itemData.distanceToSubject,
        yearRenov: itemData.yearRenov,
        numUnits: itemData.numUnits,
        subType: itemData.subType,
        affordability: itemData.affordability,
        populationPerSqMile: itemData.populationPerSqMile,
        medianHomeVal: itemData.medianHomeVal,
        medianHHInc: itemData.medianHHInc,
        medianAge: itemData.medianAge,
        medianRent: itemData.medianRent,
        medianOwnerCosts: itemData.medianOwnerCosts,
        renterPCT: itemData.renterPCT,
        rentOver30PctHHIncPCT: itemData.rentOver30PctHHIncPCT,
        bachelorsDegreePCT: itemData.bachelorsDegreePCT,
        unemploymentPCT: itemData.unemploymentPCT,
        mgtFinanceJobPCT: itemData.mgtFinanceJobPCT,
        movedInAfter2010PCT: itemData.movedInAfter2010PCT,
      } as unknown as BenchmarkAnalyticsInfoModel;

      modifiedDataArray.push(mappedClientData);
    });
  }

  modifiedDataArray.unshift({} as BenchmarkAnalyticsInfoModel);

  return {
    bencmarkInfo: modifiedDataArray,
  };
};

export const useCreateSelectClientPropertyTableData = (): SelectClientReturnType => {
  const { state } = useSetClientPortfolioName();

  const { data, isLoading } = useGetPropertyBy({ name: state.value });

  const affordabilityData = `${data?.affordability ?? ''}${data?.affordabilityDetails ?? ''}`;

  const clientPortfolioMap = new Map<ClientPropertyFromListUnion, string | number | undefined>([
    ['Property name', data?.propertyName],
    ['Address', data?.propertyAddress],
    ['City', data?.propertyCity],
    ['State', data?.propertyState],
    ['Zip', data?.propertyZipCode],
    ['County', data?.propertyCounty],
    ['Year Built', data?.yearBuilt],
    ['Year Renov', data?.yearRenov],
    ['Num Units', data?.numUnits],
    ['SubType', data?.subType],
    ['Affordability', affordabilityData],
  ]);

  return {
    clientPortfolioMap,
    isLoading,
  };
};

export const useCreateBencmarkAnalyticsTableData = (): {
  bencmarkAnalytics: BenchmarkAnalyticsModel[],
  bencmarkInfo: BenchmarkAnalyticsInfoModel[],
  isLoading: boolean,
  isSuccess: boolean,
  propName: string,
  selection: RadioSelectionValue,
  callback: VoidFunction | null,
} => {
  const { state: { value: selection } } = useSetRadioSelection();
  const { state: { value: propName } } = useSetClientPortfolioName();
  const { state: { callback } } = useCallBenchmarkAnalytics();

  const { bencmarkInfo } = useCreateBencmarkAnalyticsInfoTableData();

  const modifiedDataArray = [] as BenchmarkAnalyticsModel[];

  const { data, isSuccess, isLoading } = useGetBenchmarkAnalytics({ propName, selection, callback });
  if (data?.length) {
    data.forEach((itemData) => {
      const mappedClientData = {
        propertyName: itemData.propertyName,
        yearBuilt: itemData.yearBuilt,
        yearBuiltRenov: itemData.yearBuiltRenov,
        numUnits: itemData.numUnits,
        populationPerSqMile: itemData.populationPerSqMile,
        medianHHInc: itemData.medianHHInc,
        medianHomeVal: itemData.medianHomeVal,
        medianAge: itemData.medianAge,
        medianRent: itemData.medianRent,
        medianOwnerCosts: itemData.medianOwnerCosts,
        renterPCT: itemData.renterPCT,
        rentOver30PctHHIncPCT: itemData.rentOver30PctHHIncPCT,
        bachelorsDegreePCT: itemData.bachelorsDegreePCT,
        unemploymentPCT: itemData.unemploymentPCT,
        mngtFinanceJobPCT: itemData.mngtFinanceJobPCT,
        movedInAfter2010PCT: itemData.movedInAfter2010PCT,
        uwValPerUnit: itemData.uwValPerUnit,
        uwCapRate: itemData.uwCapRate,
        occup: itemData.occup,
        revPerUnit: itemData.revPerUnit,
        expPerUnit: itemData.expPerUnit,
        noiPerUnit: itemData.noiPerUnit,
        ncfPerUnit: itemData.ncfPerUnit,
        statementDate: itemData.statementDate,
        expPctRev: itemData.expPctRev,
        noiPctRev: itemData.noiPctRev,
        ncfPctRev: itemData.ncfPctRev,
        capexPctRev: itemData.capexPctRev,
        grosrntPctRev: itemData.grosrntPctRev,
        vacancyPctRev: itemData.vacancyPctRev,
        baserntPctRev: itemData.baserntPctRev,
        retaxesPctRev: itemData.retaxesPctRev,
        propinsPctRev: itemData.propinsPctRev,
        utilitiPctRev: itemData.utilitiPctRev,
        repairsPctRev: itemData.repairsPctRev,
        managemPctRev: itemData.managemPctRev,
        payrollPctRev: itemData.payrollPctRev,
        marketiPctRev: itemData.marketiPctRev,
        grosrntPerUnit: itemData.grosrntPerUnit,
        vacancyPerUnit: itemData.vacancyPerUnit,
        baserntPerUnit: itemData.baserntPerUnit,
        retaxesPerUnit: itemData.retaxesPerUnit,
        propinsPerUnit: itemData.propinsPerUnit,
        utilitiPerUnit: itemData.utilitiPerUnit,
        repairsPerUnit: itemData.repairsPerUnit,
        managemPerUnit: itemData.managemPerUnit,
        payrollPerUnit: itemData.payrollPerUnit,
        marketiPerUnit: itemData.marketiPerUnit,
        hcIncPvtPayPctRev: itemData.hcIncPvtPayPctRev,
        hcIncMedcarePctRev: itemData.hcIncMedcarePctRev,
        hcIncNursingPctRev: itemData.hcIncNursingPctRev,
        hcIncMealsPctRev: itemData.hcIncMealsPctRev,
        hcExpRoomsPctRev: itemData.hcExpRoomsPctRev,
        hcExpMealsPctRev: itemData.hcExpMealsPctRev,
        hcIncPvtPayPerUnit: itemData.hcIncPvtPayPerUnit,
        hcIncMedcarePerUnit: itemData.hcIncMedcarePerUnit,
        hcIncNursingPerUnit: itemData.hcIncNursingPerUnit,
        hcIncMealsPerUnit: itemData.hcIncMealsPerUnit,
        hcExpRoomsPerUnit: itemData.hcExpRoomsPerUnit,
        hcExpMealsPerUnit: itemData.hcExpMealsPerUnit,
        uwNoiPerUnitBenchmark: itemData.uwNoiPerUnitBenchmark,
        uwRevPerUnitBenchmark: itemData.uwRevPerUnitBenchmark,
        uwExpPerUnitBenchmark: itemData.uwExpPerUnitBenchmark,
        capexPerUnit: itemData.capexPerUnit,
        otherinPctRevBenchmark: itemData.otherinPctRevBenchmark,
        otherexPctRevBenchmark: itemData.otherexPctRevBenchmark,
        otherinPerUnitBenchmark: itemData.otherinPerUnitBenchmark,
        generalPerUnit: itemData.generalPerUnit,
        otherexPerUnit: itemData.otherexPerUnit,
        statementEndDate: itemData.statementEndDate,
        statementEndDateRev: itemData.statementEndDateRev,
        generalPctRev: itemData.generalPctRev,
        valTypeBenchmark: itemData.valTypeBenchmark,
        valuationDate: itemData.valuationDate,
        valuationPerUnit: itemData.valuationPerUnit,
        uwCaprateBenchmark: itemData.uwCaprateBenchmark,
        statementType: itemData.statementType,
        baserntPctRevBenchmark: itemData.baserntPctRevBenchmark,
        baserntPerUnitBenchmark: itemData.baserntPerUnitBenchmark,
        otherexPerUnitBenchmark: itemData.otherexPerUnitBenchmark,
        valDatePerUnitBenchmark: itemData.valDatePerUnitBenchmark,
      } as BenchmarkAnalyticsModel;

      modifiedDataArray.push(mappedClientData);
    });
  }

  // This empty object is necessary for correct displaying all rows.
  // Otherwise the first row would be hidden.
  modifiedDataArray.unshift({} as BenchmarkAnalyticsModel);

  return {
    propName,
    selection,
    callback,
    isSuccess,
    bencmarkInfo,
    bencmarkAnalytics: modifiedDataArray,
    isLoading,
  };
};
