import { useCallback, useState } from 'react';

interface Return {
  anchorEl: HTMLElement | null,
  onClose: (event: React.MouseEvent<HTMLElement>) => void,
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  open: boolean
  selected?: string | null
}

export const useHeaderCellModal = (): Return => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  return {
    anchorEl,
    open,
    handleClick,
    onClose,
  };
};

export const useFilterByModal = (): Return => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<null | string>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setSelected(event.currentTarget.innerText);
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return {
    anchorEl,
    open,
    handleClick,
    onClose,
    selected,
  };
};
