import React from 'react';

import { ISavedCompsets } from 'containers/compsets/lib/models/SavedCompsets.interface';

export type PriceState = {price: number, propertyName: string}
export type Action = {newPrice?: PriceState, savedProperties?: ISavedCompsets[], type: string}
export type State = {prices: PriceState[], totalPrice: number, savedProperties: ISavedCompsets[] }
export type Dispatch = (action: Action) => void
export interface ICompsPricesContext {state: State, dispatch: Dispatch}
export enum PriceActionType {
  Add = 'add',
  Delete = 'delete',
  Clear = 'clear',
  SavedProperties = 'saved-properties',
  Default = ''
}

export const CompsPricesContext = React.createContext<{
  state: State,
  dispatch: Dispatch
} | undefined>(undefined);
