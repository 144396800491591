import defaultTheme from '../defaultTheme';
import { PALETTE } from '../palette/palette';

export default {
  styleOverrides: {
    select: {
      marginRight: `${defaultTheme.spacing(1)}px`,
      border: 'none',
      '&:focus': {
        backgroundColor: PALETTE.TRANSPARENT,
      },
    },
    icon: {
      top: 'calc(50% - 10px)',
    },
  },
};
