import { memo } from 'react';

import {
  BUTTONS_MODAL_TEXT,
  BUTTONS_TEXT,
  CONFIRMATION_MODAL_TEXT,
  TITLE_MODAL_TEXT,
} from 'containers/input-property/utils/constants';

import { useModalContext } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/hooks';
import { ActionType } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/ModalContext';
import DialogWindow from './DialogWindow';
import { useModalWindow } from './hooks/useModalWindow';
import { useModifyButtons } from './hooks/useModifyButtons';

import { DeleteButtonStyled } from '../../styled';
import {
  ButtonsContainerStyled,
  CancelButtonStyled,
  ModifyButtonStyled,
} from './styled';

const ModifyButtons = ():JSX.Element => {
  const { cancelClickHandler, archiveClickHandler } = useModifyButtons();
  const { state: { title }, dispatch } = useModalContext();

  const {
    openWindow,
    handleOpen,
    handleClose,
  } = useModalWindow();

  const archiveModalWindow = (): void => {
    handleOpen();
    dispatch({ type: 'archive' });
  };

  const modifyModalWindow = (): void => {
    handleOpen();
    dispatch({ type: 'modify' });
  };

  const confirm = (): void => {
    archiveClickHandler();
    handleClose();
  };

  return (
    <>
      <DialogWindow
        open={openWindow}
        datatype={`${title === ActionType.Modify ? 'modify' : ''}`}
        title={TITLE_MODAL_TEXT.TITLE}
        modalText={
          title === ActionType.Modify
            ? CONFIRMATION_MODAL_TEXT.MODIFY
            : CONFIRMATION_MODAL_TEXT.ARCHIVE
        }
        textConfirm={BUTTONS_MODAL_TEXT.AGREEMENT}
        textClose={BUTTONS_MODAL_TEXT.DISAGREEMENT}
        close={handleClose}
        onConfirm={title === ActionType.Modify ? handleClose : confirm}
      />
      <ButtonsContainerStyled>
        <CancelButtonStyled onClick={cancelClickHandler}>
          {BUTTONS_TEXT.CANCEL.toUpperCase()}
        </CancelButtonStyled>
        <ModifyButtonStyled
          color="secondary"
          variant="contained"
          onClick={modifyModalWindow}
        >
          {BUTTONS_TEXT.MODIFY.toUpperCase()}
        </ModifyButtonStyled>
        <DeleteButtonStyled
          onClick={archiveModalWindow}
          name="delete"
          $customPadding
        >
          {BUTTONS_TEXT.DELETE.toUpperCase()}
        </DeleteButtonStyled>
      </ButtonsContainerStyled>
    </>
  );
};

export default memo(ModifyButtons);
