import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import { QUERY_KEY } from 'common/query-keys';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';

import {
  archivePropertyData,
  fetchPropertyData,
  postPropertyData,
  putPropertyData,
  putPropertyDataFinancials,
  putPropertyDataHealthcare,
  putPropertyDataValuation,
  restorePropertyData,
  checkIsArchivedProperty,
} from 'common/components/BingMap/lib/api';

import {
  IPropertyData,
  IPropertyResponseData,
  MutationDeleteReturnType,
  MutationPostReturnType,
  MutationPutReturnType,
  PropretyUpdateModel,
  PropUpdateModelPartial,
} from 'common/components/BingMap/utils/types';

export function useGetPropertyData(): UseQueryResult<IPropertyResponseData[] | PropretyUpdateModel[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QUERY_KEY.PROPERTIES,
    fetchPropertyData,
    {
      retry: 3,
      initialData: () => queryClient.getQueryData(QUERY_KEY.PROPERTIES),

      onError: (error: Error) => {
        SnackBarUtils.error(`${error.message}.`);
      },
    },
  );
}

export function useCreateProperty(): MutationPostReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newProperty: IPropertyData | unknown) => postPropertyData(newProperty as IPropertyData),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
      },
      onError: (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as MutationPostReturnType;
}

export function useUpdateProperty(): MutationPutReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newProperty: IPropertyData | unknown) => putPropertyData(newProperty as IPropertyData),
    {
      onError: async (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
      },
    },
  );

  return mutation as MutationPutReturnType;
}

export function useUpdatePropertyValuation(): MutationPutReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newProperty: PropUpdateModelPartial | unknown) => putPropertyDataValuation(
      newProperty as PropUpdateModelPartial,
    ),
    {
      onError: async (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
      },
    },
  );

  return mutation as MutationPutReturnType;
}

export function useUpdatePropertyFinancial(): MutationPutReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newProperty: PropUpdateModelPartial | unknown) => putPropertyDataFinancials(
      newProperty as PropUpdateModelPartial,
    ),
    {
      onError: async (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
      },
    },
  );

  return mutation as MutationPutReturnType;
}

export function useUpdatePropertyHealthcare(): MutationPutReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newProperty: PropUpdateModelPartial | unknown) => putPropertyDataHealthcare(
      newProperty as PropUpdateModelPartial,
    ),
    {
      onError: async (_: Error, __: string, rollback) => {
        if (typeof rollback === 'function') {
          rollback();
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
      },
    },
  );

  return mutation as MutationPutReturnType;
}

export function useArchiveProperty(): MutationDeleteReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (propertyName: string) => archivePropertyData(propertyName),
    {
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
        const data = queryClient.getQueryData(QUERY_KEY.IS_ACHIVED) as Record<string, unknown> | undefined;
        queryClient.setQueryData(QUERY_KEY.IS_ACHIVED, {
          ...data, data: true,
        });
      },
      onError: (error: Error, _: string, rollback) => {
        SnackBarUtils.error(error.message);

        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as MutationDeleteReturnType;
}

export function useRestoreProperty(): MutationDeleteReturnType {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => restorePropertyData(),
    {
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEY.PROPERTIES);
        const data = queryClient.getQueryData(QUERY_KEY.IS_ACHIVED) as Record<string, unknown> | undefined;
        queryClient.setQueryData(QUERY_KEY.IS_ACHIVED, {
          ...data, data: false,
        });
      },
      onError: (error: Error, _: string, rollback) => {
        SnackBarUtils.error(error.message);

        if (typeof rollback === 'function') {
          rollback();
        }
      },
    },
  );

  return mutation as MutationDeleteReturnType;
}

export function useIsArchivedProperty(): UseQueryResult<{ data: boolean; }, Error> {
  return useQuery(
    QUERY_KEY.IS_ACHIVED,
    () => checkIsArchivedProperty(),
    {
      initialData: () => ({ data: false }),
      cacheTime: 0,
      onError: (error: Error) => {
        SnackBarUtils.error(error.message);
      },
    },
  );
}
