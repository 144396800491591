import { createContext } from 'react';

export type SelectAction = {propID: string, type: 'add' | 'remove' } | { type: 'remove-all' };
export type SelectDispatch = (action: SelectAction) => void;
export type SelectState = { selected: string[] };

export const SelectedPropertiesContext = createContext<
  { state: SelectState; dispatch: SelectDispatch; } | undefined
  >(undefined);

createContext<(SelectState & SelectDispatch) | undefined>(undefined);
