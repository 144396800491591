import { useEffect } from 'react';

import dotenv from 'dotenv';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import NoSsr from '@mui/material/NoSsr';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';

import AppBar from 'containers/main/app-bar';
import { Footer } from 'containers/main/footer/components';
import HelmetComponent from 'common/components/Helmet/HelmetComponent';
import EditTableProvider from 'common/components/providers/EditTableProvider/EditTableProvider';
import { Routes } from 'common/components/routes/Routes';
import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';

import { AppContextProvider } from 'context/AppContextProvider';

import '@mui/styles';

import { storage } from 'containers/login/utils/local-storage';
import { logIn } from 'containers/login/api';

import theme from './common/theme';
import GlobalStyle from './common/theme/css/globalStyle';

dotenv.config();

export type ThemeType = typeof theme;

function App(): React.ReactElement {
  const timer = 5000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const fetchData = async (): Promise<void> => {
        if (storage.getItem('__expires-at__')) {
          await logIn();
        }
      };

      fetchData();
    }, timer);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <NoSsr fallback={<SuspenseFallback />}>
      <HelmetProvider>
        <BrowserRouter>
          <AppContextProvider>
            <EditTableProvider>
              <ThemeProvider theme={theme}>
                <HelmetComponent />
                <CssBaseline />
                <GlobalStyle />
                <AppBar />
                <Routes />
                <Footer />
              </ThemeProvider>
            </EditTableProvider>
          </AppContextProvider>
        </BrowserRouter>
      </HelmetProvider>
    </NoSsr>
  );
}

export default App;
