/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NewsIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.32917 11.3374C1.04583 11.3374 0 12.3832 0 13.6624C0 14.9457 1.04583 15.9791 2.32917 15.9791C3.61667 15.9791 4.65833 14.9416 4.65833 13.6624C4.65833 12.3832 3.61667 11.3374 2.32917 11.3374V11.3374Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 5V8.32917C2 8.32917 3.92083 8.92083 5.33333 10.3333C6.74583 11.7458 7.33333 13.6625 7.33333 15.6667H10.6667C10.6667 9.8375 5.83333 5 0 5V5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0V3.32917C7.125 3.32917 12.6625 8.87083 12.6625 16H16C16 7.17917 8.83333 0 0 0V0Z" />
  </SvgIcon>
);

export default React.memo(NewsIcon);
