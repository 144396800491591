import { styled } from '@mui/material';

import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';

export const InputPropertyContainerStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.gray,
  height: '100%',
  width: '100%',
}));

export const InputPropertyTabsWrapper = styled(Grid)({
  height: '100%',
});

export const TabStyled = styled(Tab)(({ theme }) => ({
  boxSizing: 'content-box',
  fontSize: theme.typography.htmlFontSize,
  paddingBottom: `${theme.spacing(3.5)} !important`,
}));

export const TabListWrapperStyled = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 6, 0, 8),
}));

export const TabListStyled = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer:after': {
    backgroundColor: theme.palette.background.darkGray,
  },
  '.Mui-selected': {
    color: theme.palette.primary.dark,
    fontWeight: 700,
  },
}));

export const TabPanelWrapperStyled = styled(Grid)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  padding: theme.spacing(4, 7.5),
}));

export const TabPanelStyled = styled(TabPanel)({
  height: '100%',
});
