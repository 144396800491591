import {
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useMappedDataPost } from 'common/components/BingMap/lib/dataMappers';
import { useUpdatePropertyFinancial } from 'common/components/BingMap/lib/query-hooks';
import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import DuplexlTable from 'common/components/DuplexlTable';

import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { useModalWindow } from '../propertyData/requiredTable/Buttons/hooks/useModalWindow';

import {
  BUTTONS_MODAL_TEXT,
  CONFIRMATION_MODAL_TEXT,
  Data,
  getFinTableData,
  TITLE_MODAL_TEXT,
} from './lib/utils';
import {
  FINANCIAL_DATE_TABLE,
  MODIFY_FINANCIALS_BUTTON_TEXT,
} from '../propertyData/valuationTable/utils/constants';

import DialogWindow from '../propertyData/requiredTable/Buttons/DialogWindow';

type RefType = {
    getData: () => Data[];
    disableEnableRows: (disabled: number[], enable: number[]) => void;
    setData: (data: Data[]) => void;
} | null;

type ReduceType = {
  'STATEMENT YEAR': Date;
  CAPEX: string;
  GROUNDR: string;
  OTHEREX: string;
  GENERAL: string;
  PROFESS: string;
  MARKETI: string;
  PAYROLL: string;
  MANAGEM: string;
  REPAIRS: string;
  UTILITI: string;
  PROPINS: string;
  RETAXES: string;
  OTHERIN: string;
  PARKING: string;
  LAUNDRY: string;
  BASERNT: string;
  'VACANCY LOSS': string;
  GROSRNT: string;
  CAPEX0: string;
  EXPENSES: string;
  REVENUE: string;
  OCCUP: string;
  HC_INC_PVTPAY: string;
  HC_INC_MEDCARE: string;
  HC_INC_NURSING: string;
  HC_INC_MEALS: string;
  HC_EXP_ROOMS: string;
  HC_EXP_MEALS: string;
}

const financialFilter = {
  topLineSelected: {
    topLine: true,
    lineItem: false,
    baseRnt: false,
    grosRnt: false,
  },
  lineItemSelected: {
    topLine: false,
    lineItem: true,
    baseRnt: false,
    grosRnt: true,
  },
  grosRntSelected: {
    topLine: false,
    lineItem: true,
    baseRnt: false,
    grosRnt: true,
  },
  baseRntSelected: {
    topLine: false,
    lineItem: true,
    baseRnt: true,
    grosRnt: false,
  },
};

const FinDateTable = ():JSX.Element => {
  const [first, setfirst] = useState<Data[]>([]);
  const tableRef = useRef<RefType>(null);
  const rawTableData = useMappedDataPost();
  const { state: idState } = usePropertyId();
  const [finFilter, setFinFilter] = useState(financialFilter.topLineSelected);
  const [firstSelect, setFirstSelect] = useState<string>('INPUT TOP-LINE FINANCIALS');
  const [secondSelect, setSecondSelect] = useState<string>('USE GROSRNT/VACANCY');
  const isSecondSelectVisible = firstSelect === 'INPUT LINE-ITEMS (CALC TOP-LINE)';
  const mutation = useUpdatePropertyFinancial();
  const selectedIndex = rawTableData?.findIndex((i) => i.propertyName === idState.id);

  const {
    openWindow,
    handleOpen,
    handleClose,
  } = useModalWindow();

  const handleChangeFirst = (event: SelectChangeEvent<string>): void => {
    setFirstSelect(event.target.value);
    if (event.target.value === 'INPUT TOP-LINE FINANCIALS') {
      setSecondSelect('USE GROSRNT/VACANCY');
      setFinFilter(financialFilter.topLineSelected);
      tableRef.current?.disableEnableRows(
        [],
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      );
      return;
    }
    if (event.target.value === 'INPUT LINE-ITEMS (CALC TOP-LINE)') {
      setFinFilter(financialFilter.lineItemSelected);
      tableRef.current?.disableEnableRows(
        [3, 4, 5, 9],
        [1, 2, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      );
    }
  };

  const handleChangeSecond = (event: SelectChangeEvent<string>): void => {
    setSecondSelect(event.target.value);
    if (event.target.value === 'USE GROSRNT/VACANCY') {
      setFinFilter(financialFilter.grosRntSelected);
      tableRef.current?.disableEnableRows(
        [3, 4, 5, 9],
        [1, 2, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      );
      return;
    }
    if (event.target.value === 'USE BASERNT') {
      setFinFilter(financialFilter.baseRntSelected);
      tableRef.current?.disableEnableRows(
        [7, 8],
        [1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      );
    }
  };

  const onModifyFinancialClickHandler = async (): Promise<void> => {
    let capexCounter = 1;
    const initialValue = {};
    const data = tableRef.current?.getData().reduce((previousValue, currentValue) => {
      if (currentValue.name === 'CAPEX' && capexCounter <= 2) {
        if (capexCounter === 1) {
          capexCounter += 1;
          return { ...previousValue, CAPEX0: currentValue.value };
        }
        if (capexCounter === 2) {
          return { ...previousValue, CAPEX: currentValue.value };
        }
      }
      return { ...previousValue, [currentValue.name]: currentValue.value };
    },
    initialValue) as ReduceType;

    let payloadForSend = {};
    if (data !== undefined) {
      payloadForSend = {
        propertyName: idState.id,
        statementEndDateRev: new Date(`${data['STATEMENT YEAR']}`),
        occup: +data.OCCUP / 100,
        rev: +data.REVENUE,
        exp: +data.EXPENSES,
        capex0: +data.CAPEX0,
        grosRnt: +data.GROSRNT,
        vacancy: +data['VACANCY LOSS'],
        baseRnt: +data.BASERNT,
        laundry: +data.LAUNDRY,
        parking: +data.PARKING,
        otherin: +data.OTHERIN,
        retaxes: +data.RETAXES,
        propins: +data.PROPINS,
        utiliti: +data.UTILITI,
        repairs: +data.REPAIRS,
        managem: +data.MANAGEM,
        payroll: +data.PAYROLL,
        marketi: +data.MARKETI,
        profess: +data.PROFESS,
        general: +data.GENERAL,
        otherex: +data.OTHEREX,
        groundr: +data.GROUNDR,
        capex: +data.CAPEX,
        hcIncPvtpay: +data.HC_INC_PVTPAY,
        hcIncMedcare: +data.HC_INC_MEDCARE,
        hcIncNursing: +data.HC_INC_NURSING,
        hcIncMeals: +data.HC_EXP_MEALS,
        hcExpRooms: +data.HC_EXP_ROOMS,
        hcExpMeals: +data.HC_EXP_MEALS,
        useTopLine: finFilter.topLine,
        useLineItem: finFilter.lineItem,
        useBaseRnt: finFilter.baseRnt,
        useGrosRnt: finFilter.grosRnt,
      };
    }
    handleClose();
    await mutation.mutateAsync(payloadForSend);
  };

  useEffect(() => {
    let data: Data[] = [];
    data = getFinTableData(rawTableData, selectedIndex);
    setfirst(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data: Data[] = [];
    data = getFinTableData(rawTableData, selectedIndex);
    tableRef.current?.setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idState]);

  if (first.length === 0) {
    return <div>loading...</div>;
  }

  return (
    <>
      <DialogWindow
        open={openWindow}
        title={`${TITLE_MODAL_TEXT.TITLE}`}
        modalText={`${CONFIRMATION_MODAL_TEXT.FINANCIAL_TABLE}`}
        textConfirm={`${BUTTONS_MODAL_TEXT.AGREEMENT}`}
        textClose={`${BUTTONS_MODAL_TEXT.DISAGREEMENT}`}
        close={handleClose}
        onConfirm={onModifyFinancialClickHandler}
      />
      <Stack gap={4}>
        <div>{FINANCIAL_DATE_TABLE.TITLE.toUpperCase()}</div>
        <Select
          fullWidth
          size="small"
          value={firstSelect}
          onChange={handleChangeFirst}
        >
          <MenuItem value="INPUT TOP-LINE FINANCIALS">INPUT TOP-LINE FINANCIALS</MenuItem>
          <MenuItem value="INPUT LINE-ITEMS (CALC TOP-LINE)">INPUT LINE-ITEMS (CALC TOP-LINE)</MenuItem>
        </Select>
        {isSecondSelectVisible && (
          <Select
            fullWidth
            size="small"
            value={secondSelect}
            onChange={handleChangeSecond}
          >
            <MenuItem value="USE GROSRNT/VACANCY">USE GROSRNT/VACANCY</MenuItem>
            <MenuItem value="USE BASERNT">USE BASERNT</MenuItem>
          </Select>
        )}
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleOpen}
        >
          { !mutation.isLoading && MODIFY_FINANCIALS_BUTTON_TEXT.toUpperCase() }
          { mutation.isLoading && 'Wait...' }
        </Button>
        <DuplexlTable
          ref={tableRef}
          rowData={first}
          disabled={[]}
        />
      </Stack>
    </>
  );
};

export default memo(FinDateTable);
