import { useCallback, useEffect, useState } from 'react';

import { useModalContext } from 'containers/buy-sell-credits/buy-sell-data-credits/local-state/hooks';

import { useScreenDown } from 'common/hooks/screenSize';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { useGetPriceNumComps } from 'containers/compsets/lib/query-hooks';
import { postCompPrice } from 'containers/compsets/lib/api';
import { useCallBenchmarkAnalytics, useSetClientPortfolioName } from '../local-state/hooks';
import { useGetBenchmarkAnalytics } from '../lib/query-hooks';

import {
  useCreateBencmarkAnalyticsTableData,
  useCreateSelectClientPropertyTableData,
} from '../lib/mapped-table-data';
import { ReturnBenchmarkList, ReturnType } from '../lib/utils/types';

import { BENCHMARK } from './constants';

export const useSelectPortfolio = (): ReturnType => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [propertyName, setPropertyName] = useState<string>(BENCHMARK.SELECT_TITLE);

  const { dispatch } = useSetClientPortfolioName();

  const handleClickSelect = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSelect = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    if (event.currentTarget.dataset.value) {
      setPropertyName(event.currentTarget.dataset.value);
      dispatch({ value: event.currentTarget.dataset.value });
    }
    handleCloseSelect();
  }, [dispatch, handleCloseSelect]);

  return {
    anchorEl,
    handleClickSelect,
    handleCloseSelect,
    handleChange,
    propertyName,
  };
};

export const useBenchmarkList = (): ReturnBenchmarkList => {
  const isDownLg = useScreenDown('lg');
  const [disabledSubscribe, setDisabledSubscribe] = useState(true);

  const { clientPortfolioMap, isLoading } = useCreateSelectClientPropertyTableData();

  const { state } = useSetClientPortfolioName();

  const { dispatch: dispatchAnalytics } = useCallBenchmarkAnalytics();
  const { dispatch: dispatchSubscription } = useModalContext();
  const { propName, selection, callback } = useCreateBencmarkAnalyticsTableData();

  const savedCompsets = useGetBenchmarkAnalytics({ propName, selection, callback });
  const { data, mutateAsync } = useGetPriceNumComps();

  const { isSuccess, isLoading: isLoadingAnalytics } = useCreateBencmarkAnalyticsTableData();
  const { state: { title } } = useModalContext();

  const isNoClientPortfolioData = Array.from(clientPortfolioMap.values()).every((x) => !x);

  const getPrice = (): void => {
    mutateAsync({ propertyName: state.value });
  };

  useEffect(() => {
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value]);

  const onBenchmarkAnalyticsCallHandle = async (): Promise<void> => {
    getPrice();
    const result = await savedCompsets.refetch();

    if (!result.isSuccess && data?.data.priceNumComps !== 0) {
      setDisabledSubscribe(false);
      SnackBarUtils.error(`${result?.error?.response?.data.detail}`);
    }

    if (!result.isSuccess && data?.data.priceNumComps === 0) {
      setDisabledSubscribe(true);
      SnackBarUtils.error(`${result?.error?.response?.data.detail}`);
    }
  };

  const onBenchmarkAnalyticsSubscribe = async (): Promise<void> => {
    const cost = await postCompPrice({ propertyName: propName });
    if (cost.headers['info-message'].includes('PREVIOUSLY SUBSCRIBED TO BENCHMARK ANALYTICS')) {
      SnackBarUtils.error(`${propName} ${cost.headers['info-message']}`);
    }
    if (cost.data.compsBalance >= 5) {
      dispatchAnalytics({ type: 'call' });
      if (!isSuccess && data?.data.priceNumComps !== 0) {
        dispatchSubscription({ type: 'subscribe' });
      }
    } else {
      SnackBarUtils.error('PURCHASE COST EXCEEDS COMPS CREDIT BALANCE. PURCHASE ADDITIONAL CREDIT');
    }
  };

  return {
    isDownLg,
    clientPortfolioMap,
    disabledSubscribe,
    isLoading,
    isLoadingAnalytics,
    isSuccess,
    title,
    isNoClientPortfolioData,
    onBenchmarkAnalyticsCallHandle,
    onBenchmarkAnalyticsSubscribe,
  };
};
