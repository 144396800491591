import { PropUpdateModelPartial } from 'common/components/BingMap/utils/types';

export type Data = {
  id?: number;
  disabled?: boolean;
  name: string;
  value: string;
  type: string;
  selectors?: string[];
};

export const TYPE = {
  date: 'date',
  text: 'text',
  dollar: 'dollar',
  percent: 'percent',
  select: 'select',
  header: 'header',
} as const;

type RawTableDataType = PropUpdateModelPartial & {uwValuation: string; uwCapex: string;};

export const getValTableData = (rawTableData: Partial<RawTableDataType>[], selectedIndex: number): Data[] => {
  const res: Data[] = [];
  res.push({
    name: 'INPUT VALUATION AND UNDERWRITTEN FINANCIALS',
    value: '',
    type: TYPE.header,
  });
  res.push({
    name: 'VALUATION DATE',
    value: `${rawTableData[selectedIndex]?.valdate}`,
    type: TYPE.date,
  });
  res.push({
    name: 'VALUATION AMOUNT',
    value: `${rawTableData[selectedIndex]?.uwValuation}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'UW REVENUE',
    value: `${rawTableData[selectedIndex]?.uwRev}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'UW EXPENSES',
    value: `${rawTableData[selectedIndex]?.uwExp}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'UW CAPEX',
    value: `${rawTableData[selectedIndex]?.uwCapex}`,
    type: TYPE.dollar,
  });
  res.push({
    name: 'UW CAPRATE',
    value: `${rawTableData[selectedIndex]?.uwCaprateInput}`,
    type: TYPE.percent,
  });
  return res;
};
