import { FinancialAction, FinancialState, FinancialActionType } from './FinancialContext';

export function financialReducer(state: FinancialState, action: FinancialAction): FinancialState {
  const copy = { ...state };

  switch (action.type) {
    case FinancialActionType.TopLine:
      copy.useTopLine = true;
      copy.useLineItem = false;
      copy.useBaseRnt = false;
      copy.useGrosRnt = false;

      return copy;

    case FinancialActionType.LineItem:
      copy.useLineItem = true;
      copy.useTopLine = false;
      copy.useGrosRnt = true;
      copy.useBaseRnt = false;

      return copy;

    case FinancialActionType.BaseRnt:
      copy.useLineItem = true;
      copy.useTopLine = false;
      copy.useGrosRnt = false;
      copy.useBaseRnt = true;

      return copy;

    case FinancialActionType.GrosRnt:
      copy.useLineItem = true;
      copy.useTopLine = false;
      copy.useGrosRnt = true;
      copy.useBaseRnt = false;

      return copy;

    default:
      return copy;
  }

  return copy;
}
