import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useMappedDataPost } from 'common/components/BingMap/lib/dataMappers';
import { useUpdatePropertyValuation } from 'common/components/BingMap/lib/query-hooks';
import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import DuplexlTable from 'common/components/DuplexlTable';

import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { useModalWindow } from '../propertyData/requiredTable/Buttons/hooks/useModalWindow';

import {
  BUTTONS_MODAL_TEXT, CONFIRMATION_MODAL_TEXT, TITLE_MODAL_TEXT,
} from '../FinDateTable/lib/utils';
import {
  MODIFY_VALUATION_BUTTON_TEXT,
  TRAILING_FINANCIALS,
  VALUATION_DATE_TABLE,
} from '../propertyData/financialTable/utils/constants';

import DialogWindow from '../propertyData/requiredTable/Buttons/DialogWindow';

import { Data, getValTableData } from './lib/utils';

type RefType = {
    getData: () => Data[];
    disableEnableRows: (disabled: number[], enable: number[]) => void;
    setData: (data: Data[]) => void;
} | null;

type ReduceType = {
  'VALUATION DATE': string;
  'VALUATION AMOUNT': string;
  'UW REVENUE': string;
  'UW EXPENSES': string;
  'UW CAPEX': string;
  'UW CAPRATE': string;
};

const ValTable: FC = () => {
  const rawTableData = useMappedDataPost();
  const [first, setfirst] = useState< Data[]>([]);
  const tableRef = useRef<RefType>(null);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [age, setAge] = useState<string>('SOLVE FOR CAPRATE');
  const { state: idState } = usePropertyId();
  const mutation = useUpdatePropertyValuation();
  const selectedIndex = rawTableData?.findIndex((i) => i.propertyName === idState.id);

  const {
    openWindow,
    handleOpen,
    handleClose,
  } = useModalWindow();

  const modifyClickHandler = async (): Promise<void> => {
    const initialValue = {};
    const data = tableRef.current?.getData().reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.name]: currentValue.value,
      }), initialValue,
    ) as ReduceType;
    let payloadForSend = {};
    if (data !== undefined && rawTableData !== undefined) {
      payloadForSend = {
        propertyName: idState.id,
        valdate: new Date(`${data['VALUATION DATE']}`),
        uwValuation: +data['VALUATION AMOUNT'],
        uwOccup: Number(rawTableData[selectedIndex].uwOccup),
        uwRev: +data['UW REVENUE'],
        uwExp: +data['UW EXPENSES'],
        uwCapex: +data['UW CAPEX'],
        uwCaprate: +data['UW CAPRATE'] / 100,
        useTrailingFinancials: isChecked,
        solveForCaprate: age === 'SOLVE FOR CAPRATE',
        solveForVal: age === 'SOLVE FOR VALUATION',
      };
    }

    handleClose();
    await mutation.mutateAsync(payloadForSend);
  };

  const onSelectHandler = (event: SelectChangeEvent<string>): void => {
    setAge(event.target.value);
    if (event.target.value === 'SOLVE FOR CAPRATE') {
      tableRef.current?.disableEnableRows([3, 4, 5, 6], [2]);
      return;
    }
    if (event.target.value === 'SOLVE FOR VALUATION') {
      tableRef.current?.disableEnableRows([2], [3, 4, 5, 6]);
    }
  };

  const onCheckHandler = (): void => {
    if (isChecked) {
      setIsChecked(false);
      tableRef.current?.disableEnableRows([], [3, 4, 5]);
    } else if (age === 'SOLVE FOR CAPRATE') {
      tableRef.current?.disableEnableRows([3, 4, 5], []);
      setIsChecked(true);
    } else {
      tableRef.current?.disableEnableRows([], [3, 4, 5]);
      setIsChecked(true);
    }
  };

  useEffect(() => {
    let data: Data[] = [];
    console.log('rawTableData', rawTableData);

    data = getValTableData(rawTableData, selectedIndex);
    setfirst(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data: Data[] = [];
    data = getValTableData(rawTableData, selectedIndex);
    tableRef.current?.setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idState]);

  if (first.length === 0) {
    return <div>loading...</div>;
  }

  return (
    <>
      <DialogWindow
        open={openWindow}
        title={`${TITLE_MODAL_TEXT.TITLE}`}
        modalText={`${CONFIRMATION_MODAL_TEXT.VALUATION_TABLE}`}
        textConfirm={`${BUTTONS_MODAL_TEXT.AGREEMENT}`}
        textClose={`${BUTTONS_MODAL_TEXT.DISAGREEMENT}`}
        close={handleClose}
        onConfirm={modifyClickHandler}
      />
      <Stack gap={4}>
        <Box>{VALUATION_DATE_TABLE.TITLE.toUpperCase()}</Box>
        <Select
          fullWidth
          size="small"
          value={age}
          onChange={onSelectHandler}
        >
          <MenuItem value="SOLVE FOR CAPRATE">SOLVE FOR CAPRATE</MenuItem>
          <MenuItem value="SOLVE FOR VALUATION">SOLVE FOR VALUATION</MenuItem>
        </Select>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleOpen}
        >
          { !mutation.isLoading && MODIFY_VALUATION_BUTTON_TEXT.toUpperCase() }
          { mutation.isLoading && 'Wait...' }
        </Button>
        <DuplexlTable ref={tableRef} rowData={first} disabled={[3, 4, 5, 6]} />
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Checkbox sx={{ paddingLeft: 0 }} checked={isChecked} onChange={onCheckHandler} />
          <Typography>
            { TRAILING_FINANCIALS.toUpperCase() }
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default ValTable;
