import { memo } from 'react';

import Drawer from '@mui/material/Drawer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';

import { useSetModalMobileMenu } from 'common/hooks/useMobileMenu';
import { GridItemStyled } from '../styled';

interface MobileMenuProps {
  children: React.ReactNode | React.ReactElement;
}

export const MobileMenu = memo(({ children }: MobileMenuProps) => {
  const {
    anchorRef, handleClose, handleDrawerClose, handleDrawerOpen, menuOpen,
  } = useSetModalMobileMenu();

  return (
    <>
      <GridItemStyled
        ref={anchorRef}
        item
        container
        xs={12}
        justifyContent="flex-end"
      >
        <IconButton
          color="default"
          edge="end"
          size="medium"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
      </GridItemStyled>
      <Drawer
        anchor="top"
        open={menuOpen}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper component="div">
            {(children as React.ReactElement).props.children
              .map((element: React.ReactElement, i: number) => (
                <ListItem
                  divider
                  button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={handleDrawerClose}
                >
                  {element}
                </ListItem>
              ))}
          </Paper>
        </ClickAwayListener>
      </Drawer>
    </>
  );
});
