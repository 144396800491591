import { styled } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { AnyComponentType, AppBarExtType } from './theme-types';
import { setAppBar, setDrawer } from './theme-helpers';

export const DRAWER_WIDTH = 100 as const;
export const DEFAULT_DRAWER_WIDTH = 60 as const;

export const AppBarStyled = styled<AppBarExtType>(AppBar, {
  shouldForwardProp: (propName) => propName !== '$isOpen',
})(({ theme, $isOpen }) => ({
  minHeight: 80,
  backgroundColor: theme.palette.background.white,
  ...setAppBar($isOpen, theme),
}));

export const ToolbarStyled = styled<AppBarExtType>(Toolbar, {
  shouldForwardProp: (propName) => propName !== '$isDownMd'
    && propName !== '$noMatchExistedRoutes'
    && propName !== '$isOpen',
})(({ theme, $isOpen }) => ({
  margin: 'auto',
  marginRight: theme.spacing(3),
  paddingRight: theme.spacing(1.25),
  paddingLeft: theme.spacing(1.5),
  borderRight: $isOpen ? 'none' : `1px solid ${theme.palette.background.border}`,
}));

export const ToolbarIconButtonStyled = styled<AppBarExtType>(IconButton, {
  shouldForwardProp: (propName) => propName !== '$isDownMd'
    && propName !== '$noMatchExistedRoutes'
    && propName !== '$isOpen',
})(({ $isOpen }) => ({
  display: $isOpen ? 'none' : 'block',
}));

export const DrawerStyled = styled<AppBarExtType>(Drawer, {
  shouldForwardProp: (propName) => propName !== '$isOpen',
})(({ theme, $isOpen }) => {
  const drawerStyles = { ...setDrawer($isOpen, theme) };

  return {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'break-spaces',
    ...drawerStyles,
    '&>.MuiDrawer-paper': {
      backgroundColor: theme.palette.background.blue,
      ...drawerStyles,
    },
  };
});

export const DrawerToolbarStyled = styled('div')(({ theme }) => ({
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.background.darkBlue,
  ...theme.mixins.toolbar,
}));

export const LogoStyled = styled('img')(({ theme }) => ({
  alignSelf: 'center',
  cursor: 'pointer',
  width: 'auto',
  'max-width': '200px',
  height: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: '180px',
  },
}));

export const GridItemStyled = styled(Grid)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(4),
}));

export const ContentWrapperStyled = styled(Grid)(({ theme }) => ({
  height: '80px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 6),
  },
}));

export const ListItemStyled = styled<AppBarExtType>(ListItem, {
  shouldForwardProp: (propName) => propName !== '$isDownMd'
    && propName !== '$noMatchExistedRoutes'
    && propName !== '$isTabSelected'
    && propName !== '$isOpen',
})(({ theme, $isTabSelected }) => ({
  minHeight: 60,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  paddingTop: `${theme.spacing(3)}`,
  paddingBottom: `${theme.spacing(3)}`,
  backgroundColor: $isTabSelected ? 'rgba(255, 255, 255, 0.2)' : 'inherit',
  '.MuiListItemIcon-root': {
    minWidth: 'inherit',
    margin: 'auto',
  },
}));

export const TypographyTabStyled = styled<AnyComponentType>(Typography)({
  fontWeight: 'bold',
  maxWidth: '15ch',
});
