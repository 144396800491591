import { createContext, useReducer, useMemo } from 'react';

export interface ICheckboxContext {state: EditCompState, dispatch: EditCompsetAction}

export type EditCompState = {
  isCompsetEdit: boolean;
}

export type EditCompsetAction = {
    type: string,
    payload: boolean
}

export type EditComp = {
  isCompsetEdit: boolean;
}

export type EditCompDispatch = (action: EditCompsetAction) => void

type genericType = { state: EditCompState, dispatch: EditCompDispatch} | undefined;

export const EditCompContext = createContext<genericType>(undefined);

const reducer = (_: EditCompState, action: EditCompsetAction): EditCompState => {
  switch (action.type) {
    case 'SET_IS_EDIT_COMPSET':
      return {
        isCompsetEdit: action.payload,
      };

    default:
      throw new Error(`No such action type as ${action.type}!`);
  }
};

export const EditCompsetContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const initState = {
    isCompsetEdit: true,
  };
  const [editCompState, setEditCompState] = useReducer(reducer, initState);
  const editCompValue = useMemo(() => ({
    state: editCompState,
    dispatch: setEditCompState,
  }), [editCompState]);

  return (
    <EditCompContext.Provider value={editCompValue}>
      {children}
    </EditCompContext.Provider>
  );
};
