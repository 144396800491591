import { memo, useMemo } from 'react';

import { GridCellProps } from 'react-virtualized';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import useTooltip from 'common/hooks/useTooltip';

import { TableQuartilesDataCell } from 'containers/research/lib/models/types';
import { formattedDate } from 'containers/buy-sell-credits/admin/table/add-new-user/utils/helpers';

import {
  COLUMN_TITLES,
  ROW_TITLES,
  ROW_TITLES_PROPS,
  ROUND_TITLES,
} from './table-data';

import {
  TableHeadCellStyled,
  TableBodyCellStyled,
} from './styled';

const CellRenderer = ({
  rowIndex, columnIndex, style, data,
}: GridCellProps & { data : TableQuartilesDataCell[]}): JSX.Element | null => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();
  const cellKey = useMemo(
    () => rowIndex + columnIndex + Math.floor(Math.random()),
    [rowIndex, columnIndex],
  );

  const cellNameProps = ROW_TITLES_PROPS[rowIndex - 1]?.cell;
  const cellName = COLUMN_TITLES[columnIndex]?.cell;
  const cellData = data[rowIndex];
  const symbol = COLUMN_TITLES[columnIndex]?.symbol;

  const percent = useMemo(() => `${cellData[cellName]
    ? (Math.round(cellData[cellName] as unknown as number * 10000) / 100).toFixed(1) : 0} 
    ${symbol}`,
  [cellData, cellName, symbol]);

  const dubPercent = useMemo(() => `${cellData[cellName]
    ? (Math.round(cellData[cellName] as unknown as number * 10000) / 100).toFixed(2) : 0}%`,
  [cellData, cellName]);

  const price = useMemo(() => `${cellData[cellName] ? symbol : ''}
   ${cellData[cellName] ? Math.round(cellData[cellName] as unknown as number).toLocaleString('us') : ''}`,
  [cellData, cellName, symbol]);

  const formattedPrice = useMemo(() => {
    const roundTitle = ROUND_TITLES.map((el) => el);
    const cellRoundName = roundTitle.filter((elem) => cellName === elem);

    if (cellRoundName[0] && cellData[cellName] !== null) {
      return `${cellData[cellName] ? symbol : ''} 
      ${cellData[cellName] ? (Math.round(cellData[cellName] as unknown as number * 10) / 10) : ''}`;
    }
    return price;
  }, [cellData, cellName, price, symbol]);

  const int = useMemo(() => ` ${rowIndex
    ? Math.round(cellData[cellName] as unknown as number).toLocaleString('us') : ''}`,
  [cellData, cellName, rowIndex]);

  const entire = useMemo(() => {
    if (cellName === 'valuationDate' && cellData[cellName] !== null) {
      return formattedDate(cellData[cellName] as unknown as Date);
    }
    if (cellName === 'medianAge' && cellData[cellName] !== null) {
      return (Math.round(cellData[cellName] as unknown as number * 10) / 10).toFixed(1);
    }

    if (cellData[cellName] === ' ') return '';
    return typeof cellData[cellName] === 'number'
      ? Math.round(cellData[cellName] as unknown as number)
      : cellData[cellName];
  },
  [cellData, cellName]);

  const displayData = useMemo((): number | string => {
    switch (symbol) {
      case '$':
        return formattedPrice;
      case '%':
        return percent;
      case '2%':
        return dubPercent;
      case 'int':
        return int;
      default:
        return entire;
    }
  }, [symbol, formattedPrice, percent, dubPercent, int, entire]);

  if (!data) return null;

  if (rowIndex === 0) {
    return (
      <TableHeadCellStyled
        component="div"
        style={style}
        key={cellKey}
      >
        {COLUMN_TITLES[columnIndex].title}
        <Box>
          {/** this part temporarily unavailable related with customer's wish */}
          {/* <IconButton>
            <SortIconStyled />
          </IconButton>
          <IconButton>
            <FilterListIcon fontSize="small" />
          </IconButton> */}
        </Box>
      </TableHeadCellStyled>
    );
  }

  if (columnIndex === 2) {
    return (
      <TableBodyCellStyled
        component="div"
        key={cellKey}
        style={style}
      >
        <Typography
          noWrap
          variant="body2"
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
        >
          {ROW_TITLES[rowIndex - 1].title}
        </Typography>
      </TableBodyCellStyled>
    );
  }

  if (columnIndex === 1) {
    return (
      <Tooltip
        open={isOpen}
        title={(
          <Typography variant="body2">
            {cellData[cellNameProps]}
          </Typography>
        )}
        arrow
      >
        <TableBodyCellStyled
          component="div"
          key={cellKey}
          style={style}
        >
          <Typography
            noWrap
            variant="body2"
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            {cellData[cellNameProps]}
          </Typography>
        </TableBodyCellStyled>
      </Tooltip>
    );
  }

  if (columnIndex === 0) {
    return (
      <TableBodyCellStyled
        component="div"
        key={cellKey}
        style={style}
      >
        <Typography
          noWrap
          variant="body2"
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
        >
          {ROW_TITLES_PROPS[rowIndex - 1].title}
        </Typography>
      </TableBodyCellStyled>
    );
  }

  return (
    <Tooltip
      open={isOpen}
      title={(
        <Typography variant="body2">
          {cellData[cellName]}
        </Typography>
      )}
      arrow
    >
      <TableBodyCellStyled
        component="div"
        key={cellKey}
        style={style}
      >
        <Typography
          noWrap
          variant="body2"
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
        >
          {
            displayData
          }
        </Typography>
      </TableBodyCellStyled>
    </Tooltip>
  );
};

export default memo(CellRenderer);
