import { AxiosResponse } from 'axios';

import axiosInstance from 'common/axios';
import { COMPSETS_URL, DATASET_URL } from 'common/globalConstants';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import { CompsFetchData, CompsPriceData } from 'containers/research/lib/models/types';

import { FilterBodyRequest, FilteredCompsets } from './models/FilteredCompsets.interface';

import { CompsetsProperties } from './models/CompsetsProperties.interface';
import { CustomError } from './models/CustomError.interface';

import { CompsetsFromServer } from './models/SubscribedCompsets.interface';
import { ReturnDataType, SubscribeProps } from './models/types';
import { ISavedCompsets } from './models/SavedCompsets.interface';

const axios = axiosInstance;

export const filterCompsets = async <Type extends FilteredCompsets[]>(
  { propertyName, body }: FilterBodyRequest,
): Promise<AxiosResponse<Type>> => {
  let result: AxiosResponse<Type> = {} as AxiosResponse<Type>;

  // eslint-disable-next-line max-len
  const queryURL = `${COMPSETS_URL.COMPSETS}/${COMPSETS_URL.FILTER_PARAMS.FILTER}${encodeURIComponent(propertyName)}&${COMPSETS_URL.FILTER_PARAMS.UNITS}${body.filterUnits}&${COMPSETS_URL.FILTER_PARAMS.YEAR}${body.filterYear}&${COMPSETS_URL.FILTER_PARAMS.SUBTYPE}${body.filterSameSubtype}&${COMPSETS_URL.FILTER_PARAMS.SIMILAR_SUBTYPE}${body.filterSimilarSubtype}&${COMPSETS_URL.FILTER_PARAMS.AFFORDABLE}${body.filterAfforadable}&${COMPSETS_URL.FILTER_PARAMS.U2020W}${body.filter2020UW}&${COMPSETS_URL.FILTER_PARAMS.F2020F}${body.filter2020FF}`;

  try {
    result = await axios.get(queryURL);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as CustomError).response.data.detail}`);
  }

  return result;
};

export const subscribedCompsets = async <Type extends CompsetsFromServer[]>(
  propertyName: string,
): Promise<Type> => {
  let result: AxiosResponse<Promise<Type>> = {} as AxiosResponse<Promise<Type>>;

  if (!propertyName) { return null as never; }
  result = await axios.get(
    `${COMPSETS_URL.COMPSETS}/${propertyName}/${COMPSETS_URL.PROPERTIES}`,
  ).then((data) => data);

  return result.data as Promise<Type>;
};

export const savedCompsets = async <Type extends ISavedCompsets[]>(
  propertyName: string,
): Promise<Type> => {
  let result: AxiosResponse<Promise<Type>> = {} as AxiosResponse<Promise<Type>>;

  if (!propertyName) { return null as never; }
  result = await axios.get(
    `${COMPSETS_URL.COMPSETS}/${propertyName}/${COMPSETS_URL.PROPERTIES}`,
  ).then((data) => data);

  return result.data as Promise<Type>;
};

export const compsetsByAlgorithm = async <Type extends FilteredCompsets[]>(
  propertyName: string,
): Promise<AxiosResponse<Type>> => {
  let result: AxiosResponse<Type> = {} as AxiosResponse<Type>;
  const property = encodeURIComponent(propertyName);

  try {
    result = await axios.get(`${COMPSETS_URL.COMPSETS}/${COMPSETS_URL.ALGORITHM}${property}`);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as CustomError).response.data.detail}`);
  }

  return result;
};

export const fetchCompPrice = async <Type extends CompsPriceData>(
  metrostatsData: { compId: string, propertyName: string; },
): Promise<AxiosResponse<Type>> => {
  let result: AxiosResponse<Type> = {} as AxiosResponse<Type>;

  const body = {
    propertyName: `${metrostatsData.propertyName}`,
    propCompAlgoDemoAvmStats: 'COMP',
    compExidPlidPrid: `${metrostatsData.compId}`,
    metrostatsMdra: '',
    metrostatsSubtype: '',
  } as CompsFetchData;

  try {
    result = await axios.post(`${DATASET_URL.PRICE}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as Error)?.message}`);
  }

  return result;
};

export const postCompPrice = async <Type extends CompsPriceData>(
  metrostatsData: { propertyName: string; },
): Promise<AxiosResponse<Type>> => {
  let result: AxiosResponse<Type> = {} as AxiosResponse<Type>;

  const body = {
    propertyName: `${metrostatsData.propertyName}`,
    propCompAlgoDemoAvmStats: 'ALGO',
    compExidPlidPrid: '',
    metrostatsMdra: '',
    metrostatsSubtype: '',
  } as CompsFetchData;

  try {
    result = await axios.post(`${DATASET_URL.PRICE}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as Error)?.message}`);
  }

  return result;
};

export const subscribeListCompsets = async (body: SubscribeProps[]): Promise<AxiosResponse<unknown>> => {
  let result: AxiosResponse<unknown> = {} as AxiosResponse<unknown>;

  try {
    result = await axios.put(`${COMPSETS_URL.COMPSETS}/${COMPSETS_URL.SUBSCRIBE_LIST}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as { data: { error: string, message: string; }; }).data.message}`);
  }

  return result;
};

export const getCompsetsPropertiesDataList = async (): Promise<CompsetsProperties[]> => {
  let result = null;
  try {
    result = await axios.get(`${COMPSETS_URL.COMPSETS}/${COMPSETS_URL.PROPERTIES}`);

    return result.data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getCompsetsPropertyBy = async (name: string): Promise<CompsetsProperties[]> => {
  let result = null;
  try {
    result = await axios.get(`${COMPSETS_URL.COMPSETS}/${name}/${COMPSETS_URL.PROPERTIES}`);

    return result.data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getInfoPropertyBy = async (name: string): Promise<CompsetsProperties> => {
  let result = null;
  try {
    result = await axios.get(`properties/${name}`);

    return result.data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const addCompset = async (
  compsData: SubscribeProps,
): Promise<AxiosResponse<unknown>> => {
  let result: AxiosResponse<unknown> = {} as AxiosResponse<unknown>;

  const body = {
    propertyName: `${compsData.propertyName}`,
    exidPlidPrid: `${compsData.exidPlidPrid}`,
  };

  try {
    result = await axios.post(`${COMPSETS_URL.COMPSETS}`, body);

    return result;
  } catch (error) {
    SnackBarUtils.error(`${(error as Error)?.message}`);
  }

  return result;
};

export const deleteCompset = async (
  compsData: SubscribeProps,
): Promise<ReturnDataType> => {
  let result: ReturnDataType = {} as ReturnDataType;
  const body = {
    propertyName: `${compsData.propertyName}`,
    exidPlidPrid: `${compsData.exidPlidPrid}`,
  };
  result = await axios.delete(`${COMPSETS_URL.COMPSETS}`, { data: body });

  return result;
};

export const deleteCompsetList = async (
  compsData: SubscribeProps[],
): Promise<ReturnDataType> => {
  let result: ReturnDataType = {} as ReturnDataType;
  result = await axios.delete(`${COMPSETS_URL.COMPSETS_LIST}`, { data: compsData });

  return result;
};

export const putSubscribeCompset = async (
  compsData: SubscribeProps,
): Promise<ReturnDataType> => {
  let result: ReturnDataType = {} as ReturnDataType;
  const body = {
    propertyName: `${compsData.propertyName}`,
    exidPlidPrid: `${compsData.exidPlidPrid}`,
  };
  result = await axios.put(`${COMPSETS_URL.SUBSCRIBE}`, body);

  return result;
};

export const deleteAllsavedCompsets = async (
  propertyName: string,
): Promise<ReturnDataType> => {
  let result: ReturnDataType = {} as ReturnDataType;

  if (!propertyName) { return null as never; }
  result = await axios.delete(
    `${COMPSETS_URL.COMPSETS}/${propertyName}/${COMPSETS_URL.ALL}`,
  ).then((data) => data);

  return result;
};
