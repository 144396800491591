import { memo, useMemo } from 'react';

import { Index, MultiGrid } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useCreatePrintTableData } from 'containers/research/lib/mapped-table-data';
import { TablePrintDataCell } from 'containers/research/lib/models/types';

import CellRenderer from './CellRenderer';
import {
  COLUMN_TITLE_WIDTH,
  FIXED_COLUMN_COUNT,
  FIXED_ROW_COUNT,
  COLUMN_TITLES,
} from './table-data';

import { TableWrapperStyled } from './styled';
import {
  STYLE,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
  STYLE_TOP_RIGHT_GRID,
} from './styled/virtualized-styles';

export const Table = memo((): JSX.Element => {
  const printedData = (useCreatePrintTableData() || null) as TablePrintDataCell[];
  const rowCount = useMemo(() => printedData.length, [printedData.length]);
  const getRowSize = useMemo(() => ({ index }:Index) => (index === 0 ? 32 : 28), []);

  return (
    <TableWrapperStyled>
      <AutoSizer>
        {({ height, width }) => (
          <MultiGrid
            fixedColumnCount={FIXED_COLUMN_COUNT}
            columnWidth={COLUMN_TITLE_WIDTH}
            columnCount={COLUMN_TITLES.length}
            height={height}
            rowHeight={getRowSize}
            rowCount={rowCount}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
            style={STYLE}
            cellRenderer={({ key: mainKey, ...rest }) => (
              <CellRenderer
                {...rest}
                key={mainKey}
                data={printedData}
              />
            )}
            fixedRowCount={FIXED_ROW_COUNT}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
          />
        )}
      </AutoSizer>
    </TableWrapperStyled>
  );
});
