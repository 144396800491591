/* eslint-disable max-len */

export const TEXT = {
  CMBS_SURVEILLANCE: 'CMBS SURVEILLANCE',
  PROPERTY_LEVEL: 'PROPERTY-LEVEL VALUATION AND FORECASTS LOAN-LEVEL STRESS TESTING',
  FREDDIE_MAC: 'FREDDIE MAC CMBS SUBORDINATE AND CONTROLLING CLASS INVESTMENTS',
  PARAGRAPH_1: 'The Predictive Analytics System is a direct outgrowth of the Statval Partner’s Proprietary Investment and Risk Management Expertise related to FREMF Series K Controlling Class Tranches. The Statval CMBS Surveillance Module is our most advanced application. The Module enables owners, buyers, sellers, and brokers of Controlling Class Tranches to quickly obtain a comprehensive understanding of Tranche Risk and Estimated Value in comparison to other deals in the marketplace.',
  PARAGRAPH_2: 'The Module does this by using the most recent financial data pulled from CMBS Trustee Reports and Statval proprietary AVM and Forecast Models to perform a deep-dive Loan and Property Level Valuation, Forecasts, and Stress Tests. The deep-dive identifies those Loans and Properties within a deal that are most likely to require capital to pay debt service, cap ex, and refinance over the next 1-5 years. Statval Automated Value and Forecast Models are specifically calibrated to the COVID market environment. We present our methodology in our White Paper and Interactive Research Reports available on the website Dashboard. ',
  FURTHER_INFORMATION: 'For further information and demos please contact our Director of Business Development',
  EMAIL: 'reports@thirtycapital.com',
  EMAIL_HREF: 'mailto:reports@thirtycapital.com',
  TELEPHONE: '980-209-2865x1002',
  IMAGE_TEXT: 'Our CMBS Surveillance Module is currently provided as a proprietary Excel/VB Analyst Application designed for CMBS specialist. We are in process of implementing the web-enabled version.',
} as const;
