import { styled } from '@mui/material';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

type StyledTableWrapperType = {
  $isLoading?: boolean;
};

export const MapTabContainerStyled = styled(Grid)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  gap: '30px',
  flexWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const MapTabItemStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const PropertyDataStyled = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(5),
}));

export const MapStyled = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(6),
}));

export const ItemWrapperStyled = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(1),
}));

export const ButtonsContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(1, 3),
  [theme.breakpoints.up('xxl')]: {
    padding: theme.spacing(3),
  },
}));

export const ModifyButtonStyled = styled(Button)(({ theme }) => ({
  flex: '5',
  padding: theme.spacing(1, 3),
  [theme.breakpoints.up('xxl')]: {
    padding: theme.spacing(3),
  },
}));

export const CancelButtonStyled = styled(ButtonStyled)(({ theme }) => ({
  padding: theme.spacing(1),
  flex: '2',
  color: theme.palette.blue.main,
  border: `1px solid ${theme.palette.blue.main}`,
  '&:hover': {
    color: theme.palette.blue.light,
    border: `1px solid ${theme.palette.blue.light}`,
  },
  '&:active': {
    color: theme.palette.blue.dark,
    border: `1px solid ${theme.palette.blue.dark}`,
  },
}));

export const DeleteButtonStyled = styled(ButtonStyled, {
  shouldForwardProp: (propName) => propName !== '$customPadding',
})<{ $customPadding?: boolean; }>(({ theme, $customPadding }) => ({
  padding: $customPadding ? theme.spacing(1) : theme.spacing(1, 3),
  flex: '3',
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`,
  '&:hover': {
    color: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.light}`,
  },
  '&:active': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.dark}`,
  },
}));

//= ============= REQUIRED TABLE styles ===============
export const StyledTableWrapper = styled(TableContainer, {
  shouldForwardProp: (propName) => propName !== '$isLoading',
})<StyledTableWrapperType>(({ theme, $isLoading }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  filter: $isLoading ? 'opacity(0.5)' : 'opacity(1)',
  overflow: 'hidden',
  borderRight: '1px solid #BFC9D1',
}));

export const StyledTableCellName = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  color: theme.palette.text.mediumGray,
  padding: theme.spacing(0, 3),
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  borderBottom: 'none',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
  padding: 0,
  borderBottom: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& input': {
    padding: theme.spacing(3.2, 1),
  },
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (propName) => propName !== 'component',
})<{ component?: string; }>(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  '&:last-child': {
    borderBottom: 0,
  },
}));

const CommonInputStyled = styled(TextField)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiInputBase-root': {
    height: '28px',
  },
});

export const TableInputStyled = styled(CommonInputStyled)({
  overflow: 'hidden',
  fieldset: {
    border: 'none',
    outline: 'none',
  },
});

export const FinTableInputStyled = styled(CommonInputStyled)(({ theme }) => ({
  input: {
    padding: theme.spacing(2.2, 0),
    borderRadius: 0,
  },
  '& .MuiInputBase-root': {
    paddingLeft: 12,
  },
  '.Mui-disabled': {
    height: 'calc(100% - 12px)',
  },
}));

export const InputAdornmenStyled = styled(InputAdornment)(({ theme }) => ({
  fontSize: '14px',
  height: 24,
  margin: 0,
  padding: theme.spacing(0, 1),
}));

export const InputStyled = styled(TextField)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0, 2),
  height: '100%',
  margin: theme.spacing(3.5, 0),
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',
  '& .MuiInputBase-root': {
    height: '28px',
    fieldset: {
      border: 'none',
    },
  },
}));

export const TableTitleStyled = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6.5, 0),
  fontWeight: 700,
  height: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SpinnerWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  alignSelf: 'center',
  paddingTop: theme.spacing(6.5, 0),
}));

export const BenchmarkSpinnerWrapper = styled(SpinnerWrapper)(({ theme }) => ({
  marginLeft: theme.spacing(-6),
}));

//= ============ Financial table styles=============

export const TableWrapperStyled = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.borderLight}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  borderRight: '1px solid #BFC9D1',
}));

export const FinTableHeaderWideStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
  fontWeight: 600,
  padding: theme.spacing(3),
  width: '100%',
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  display: 'flex',
}));

export const FinTableHeaderStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  color: theme.palette.text.mediumGray,
  padding: theme.spacing(3),
  display: 'flex',
  flex: 3,
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  width: '80px',
}));

export const FinTableYearCellStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.lightGray,
  color: theme.palette.text.mediumGray,
  padding: theme.spacing(3),
  flex: 1.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderLeft: `1px solid ${theme.palette.background.borderLight}`,
  width: '80px',
}));

export const FinTableCellStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 3,
  backgroundColor: theme.palette.background.white,
  borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  borderLeft: `1px solid ${theme.palette.background.borderLight}`,
  width: '80px',
}));

export const FinTableRowStyled = styled('div')({
  display: 'flex',
  width: '80px',
  flexDirection: 'row',
  '&:last-child div': {
    borderBottom: 0,
  },
});

export const FinancialButtonContainerStyled = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    maxWidth: '50%',
  },
}));
