import { useState } from 'react';

type ReturnType = {
  openWindow: boolean;
  handleOpen: VoidFunction;
  handleClose: VoidFunction;
}

export const useModalWindow = (): ReturnType => {
  const [openWindow, setOpenWindow] = useState(false);

  const handleOpen = (): void => {
    setOpenWindow(true);
  };
  const handleClose = (): void => {
    setOpenWindow(false);
  };

  return {
    openWindow,
    handleOpen,
    handleClose,
  };
};
