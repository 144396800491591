import { memo, Suspense } from 'react';

import { ROUTE } from 'common/components/routes/utils/constants';

import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';

import MapTab from 'containers/input-property/components/mapTab/MapTab';
import DetailsTab from 'containers/input-property/components/detailsTab/DetailsTab';

import { TabPanelStyled } from './utils/styled';

const TabPanelsComponent = ():JSX.Element => (
  <Suspense fallback={<SuspenseFallback />}>
    <TabPanelStyled value={ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT}>
      <MapTab />
    </TabPanelStyled>
    <TabPanelStyled value={ROUTE.ACCOUNT.INPUT_PROPERTY.DETAIL}>
      <DetailsTab />
    </TabPanelStyled>
  </Suspense>
);

export default memo(TabPanelsComponent);
