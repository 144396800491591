import { lazy, memo, Suspense } from 'react';
import { ROUTE } from 'common/components/routes/utils/constants';
import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';
import {
  TabPanelInnerContainerStyled,
  TabPanelPaperStyled,
  TabPanelStyled,
} from './utils/styled';

const CompsetsMapTab = lazy(() => import('./components/mapTab'));
const Detail = lazy(() => import('./components/detail'));

export const TabPanelsComponent = memo(() => (
  <Suspense fallback={<SuspenseFallback />}>
    <TabPanelStyled value={ROUTE.ACCOUNT.COMPSETS.MAP}>
      <TabPanelInnerContainerStyled>
        <CompsetsMapTab />
      </TabPanelInnerContainerStyled>
    </TabPanelStyled>
    <TabPanelStyled value={ROUTE.ACCOUNT.COMPSETS.DETAIL}>
      <TabPanelInnerContainerStyled>
        <TabPanelPaperStyled>
          <Detail />
        </TabPanelPaperStyled>
      </TabPanelInnerContainerStyled>
    </TabPanelStyled>
  </Suspense>
));
