import { styled } from '@mui/material';

import Button from '@mui/material/Button';

export const ButtonsContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(1, 3),
  [theme.breakpoints.up('xxl')]: {
    padding: theme.spacing(3),
  },
}));

export const ModifyButtonStyled = styled(Button)(({ theme }) => ({
  flex: '5',
  padding: theme.spacing(1, 3),
  [theme.breakpoints.up('xxl')]: {
    padding: theme.spacing(3),
  },
}));

export const CancelButtonStyled = styled(ButtonStyled)(({ theme }) => ({
  flex: '2',
  color: theme.palette.blue.main,
  border: `1px solid ${theme.palette.blue.main}`,
  '&:hover': {
    color: theme.palette.blue.light,
    border: `1px solid ${theme.palette.blue.light}`,
  },
  '&:active': {
    color: theme.palette.blue.dark,
    border: `1px solid ${theme.palette.blue.dark}`,
  },
}));

export const DeleteButtonStyled = styled(ButtonStyled)(({ theme }) => ({
  flex: '3',
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`,
  '&:hover': {
    color: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.light}`,
  },
  '&:active': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.dark}`,
  },
}));
