import { styled } from '@mui/material';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';

import building from 'assets/building.jpg';

export const CMBSContainerStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.gray,
  height: '100%',
  width: '100%',
}));

export const CMBSTabsWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(5),
  overflow: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const CMBSSectionStyled = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const TextSectionStyled = styled(Paper)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(6),
}));

export const TitleStyled = styled(Typography, {
  shouldForwardProp: (propName) => propName !== '$maxWidth',
})<{ $maxWidth?: string; }>(({ theme, $maxWidth }) => ({
  maxWidth: $maxWidth,
  fontFamily: theme.typography.fontFamily,
  lineHeight: theme.spacing(6.25),
  fontSize: theme.spacing(4.5),
  fontWeight: 'bold',
  marginBottom: theme.spacing(6),
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  lineHeight: theme.spacing(6),
  fontSize: theme.spacing(4),
  fontWeight: 400,
  marginBottom: theme.spacing(6),
  maxWidth: '85ch',
}));

export const TextBolderStyled = styled(TextStyled, {
  shouldForwardProp: (propName) => propName !== '$maxWidth',
})<{ $maxWidth?: string; }>(({ theme, $maxWidth }) => ({
  maxWidth: $maxWidth,
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

export const ContactsSectionStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(6),
  '&>p': {
    marginBottom: 0,
  },
  '&>svg': {
    marginRight: theme.spacing(4),
  },
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  wordBreak: 'break-all',
  '&:visited': {
    color: theme.palette.text.mediumGray,
  },
}));

export const ImageStyled = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${building})`,
});

export const ImageSectionStyled = styled(Paper)({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

export const ImageTextWrapperStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(7),
  width: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
}));

export const ImageTextStyled = styled(TextStyled)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  textAlign: 'center',
  margin: 0,
  padding: 0,
  '&>p': {
    margin: 0,
    padding: 0,
  },
}));
