import {
  memo, useCallback, useMemo,
} from 'react';
import { useHistory, withRouter, Redirect } from 'react-router-dom';

import TabContext from '@mui/lab/TabContext';

import { ROUTE } from 'common/components/routes/utils/constants';

import { TabListComponent } from './TabListComponent';
import { TabPanelsComponent } from './TabPanelsComponent';

import {
  BuySellCreditsContainerStyled,
  BuySellCreditsTabsWrapper,
  TabListWrapperStyled,
  TabPanelWrapperStyled,
} from './styled';

const BuySellCredits = memo((): JSX.Element => {
  const history = useHistory();

  const routerPath = history.location.pathname;

  const isWrongBuyAndSellPath = useMemo(() => Object.values([
    ROUTE.ACCOUNT.BUY_AND_SELL_DEFAULT,
    ROUTE.ACCOUNT.BUY_AND_SELL.ADMIN,
    // these features in progress, It will be added as soon as possible
    // ROUTE.ACCOUNT.BUY_AND_SELL.BILLING_HISTORY,
    // ROUTE.ACCOUNT.BUY_AND_SELL.PAYMENT_AND_INVOICES,
    ROUTE.ACCOUNT.BUY_AND_SELL.PROFILE,
  ]).every((path) => path !== routerPath),
  [routerPath]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: string): void => {
      history.push(newValue);
    }, [history],
  );

  return (
    <BuySellCreditsContainerStyled>
      <BuySellCreditsTabsWrapper item container wrap="nowrap" direction="column">
        <TabContext value={routerPath}>
          {isWrongBuyAndSellPath
            ? <Redirect to={ROUTE.NOT_FOUND} />
            : (
              <>
                <TabListWrapperStyled item>
                  <TabListComponent
                    onChange={handleChange}
                    value={routerPath}
                  />
                </TabListWrapperStyled>
                <TabPanelWrapperStyled item>
                  <TabPanelsComponent />
                </TabPanelWrapperStyled>
              </>
            )}
        </TabContext>
      </BuySellCreditsTabsWrapper>
    </BuySellCreditsContainerStyled>
  );
});

export default withRouter(BuySellCredits);
