import { styled } from '@mui/material';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const AboutTabStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(3),
  overflow: 'auto',
  textAlign: 'justify',
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.mediumGray,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  lineHeight: 1.5,
}));

export const ContainerStyled = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

});

export const ParagraphStyled = styled(TextStyled)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(7),
}));

export const TitleStyled = styled(TextStyled)(({ theme }) => ({
  fontSize: theme.spacing(5),
  textAlign: 'center',
}));

export const ListsWrapperStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: theme.spacing(7),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr',
  },
}));

export const ListsTitleStyled = styled(TextStyled)(({ theme }) => ({
  fontSize: theme.spacing(4.5),
  fontWeight: 700,
  textAlign: 'center',
  margin: theme.spacing(1),
}));
