import { memo } from 'react';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { SuspenseFallback } from '../SuspenseFallback/SuspenseFallback';
import { ReloadPanel } from './components/ReloadPanel';

interface IProps<T> {
    result: UseQueryResult<T[] | T, Error> | UseMutationResult<unknown, Error, string, unknown>
    children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/ban-types
const QueryLoadingProvider = <T extends object>({ result, children }: IProps<T>): JSX.Element => {
  if (result as UseQueryResult<T[] | T, Error>) {
    if (result.isError) return <ReloadPanel {...result as UseQueryResult<T[] | T, Error>} />;
  }

  if (result.isLoading) {
    return <SuspenseFallback />;
  }

  if (result.error) {
    return <>{result.error.stack}</>;
  }

  return <>{ children }</>;
};

export default memo(QueryLoadingProvider);
