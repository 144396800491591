import React from 'react';

export type Action = {
  currentPropertyEvent?: React.MouseEvent<HTMLElement> | null,
  currentProperty: string | null
}
export type State = {
  currentPropertyEvent: React.MouseEvent<HTMLElement> | null,
  currentProperty: string | null,
}
export type Dispatch = (action: Action) => void
export interface ICurrentPropertyContext {state: State, dispatch: Dispatch}

export const CurrentPropertyContext = React.createContext<{
  state: State,
  dispatch: Dispatch
} | undefined>(undefined);
