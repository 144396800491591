import { memo } from 'react';

import { NUMBER_INPUTS } from 'containers/input-property/utils/constants';
import { FormRegister } from 'containers/input-property/components/mapTab/map/utils/constants';

import SelectInput from './inputs/SelectInput';
import NumberInput from './inputs/NumberInput';
import StringInput from './inputs/StringInput';
import SelectInputAffordability from './inputs/SelectInputAffordability';

const InputRenderer = ({
  row, index, register,
}: {
    row: string,
    index:number,
    register: FormRegister,
}):JSX.Element => {
  if (row === 'SUBTYPE') return <SelectInput row={row} index={index} register={register} />;
  if (row === 'AFFORDABILITY') {
    return <SelectInputAffordability row={row} index={index} register={register} />;
  }

  return (
    NUMBER_INPUTS.includes(row)
      ? <NumberInput index={index} row={row} key={row + index} register={register} />
      : <StringInput index={index} row={row} key={row + index} register={register} />
  );
};

export default memo(InputRenderer);
