import { memo } from 'react';

import { ROUTE } from 'common/components/routes/utils/constants';
import { TABS } from './utils/constants';

import { TabListStyled, TabStyled } from './utils/styled';

interface TabListComponentProps {
  onChange:(_: React.SyntheticEvent<Element, Event>, newValue: string) => void;
  value: string;
}

export const TabListComponent = memo(({ onChange, value }: TabListComponentProps) => (
  <TabListStyled
    value={value}
    onChange={onChange}
    variant="scrollable"
  >
    <TabStyled
      label={TABS.MAP}
      value={ROUTE.ACCOUNT.COMPSETS.MAP}
    />
    <TabStyled
      label={TABS.DETAIL}
      value={ROUTE.ACCOUNT.COMPSETS.DETAIL}
    />
  </TabListStyled>
));
