export const storage = {
  getAccessToken: (): string | null => {
    const token: string | null | undefined = localStorage.getItem('__access-token__');
    if (token) return token;
    return null;
  },
  getRefreshToken: (): string | null => {
    const token: string | null | undefined = localStorage.getItem('__refresh-token__');
    if (token) return token;
    return null;
  },
  getExpiresAt: (): number | null => {
    const ms: string | null | undefined = localStorage.getItem('__expires-in__');
    if (ms) return +ms;
    return null;
  },
  getItem: (key: string): string | null => {
    const value: string | null | undefined = localStorage.getItem(key);
    if (value) return value;
    return null;
  },
  setAccessToken: (token: string): void => localStorage.setItem('__access-token__', token),
  setRefreshToken: (token: string): void => localStorage.setItem('__refresh-token__', token),
  setExpiresAt: (unix: number): void => localStorage.setItem('__expires-at__', unix?.toString()),
  setExpiresIat: (unix: number): void => localStorage.setItem('__iat__', unix?.toString()),
  setItem: (key: string, value: string): void => localStorage.setItem(key, value),
  clearToken: (): void => localStorage.removeItem('__access-token__'),
  clearAll: (): void => localStorage.clear(),
};
