import { memo } from 'react';

import { SuspenseFallback } from 'common/components/SuspenseFallback/SuspenseFallback';
import { IPropertyResponseData } from 'common/components/BingMap/utils/types';

interface IProps {
  result: IPropertyResponseData[] | string
  children: React.ReactNode
}

const LoadingProvider = ({ result, children }: IProps): JSX.Element => {
  if (typeof result === 'string') return <div>{result}</div>;

  if (!result || !result.length) return <SuspenseFallback />;

  return <>{ children }</>;
};

export default memo(LoadingProvider);
