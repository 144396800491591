import React from 'react';

export interface ISideBarContext {state: BarState, dispatch: BarDispatch}

export type BarAction = {sideBarOpen?: boolean, shouldSideBarBeDisplayed?: boolean, type: SideBarActionType}
export type BarState = { sideBarOpen: boolean, shouldSideBarBeDisplayed: boolean, }
export type BarDispatch = (action: BarAction) => void

export enum SideBarActionType {
  SideBarOpen = 'sideBarOpen',
  ShouldSideBarBeDisplayed= 'shouldSideBarBeDisplayed',
  Default = ''
}

export const SideBarContext = React.createContext<{
  state: BarState,
  dispatch: BarDispatch
} | undefined>(undefined);
