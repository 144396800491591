import React, {
  memo, useCallback, useState,
} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';

import useTooltip from 'common/hooks/useTooltip';

import { useCreateNeighborhoodData } from 'containers/research/lib/mapped-table-data';

import {
  MenuItemStyled,
  MenuStyled,
  ButtonStyled,
} from './utils/styled';

const RegionMenu = memo((
  { selectedRegion, setRegion }:
  {selectedRegion: string, setRegion: (selectedRegion: string)=>void},
): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();
  const regions = useCreateNeighborhoodData();

  const handleClickSelect = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSelect = useCallback((): void => {
    setAnchorEl(null);
  }, []);
  const regionSelection = ({ currentTarget }: React.MouseEvent<HTMLLIElement>): void => {
    if (currentTarget.dataset.value) {
      setRegion(currentTarget.dataset.value);
    }
  };

  return (
    <>
      <ButtonStyled
        disableElevation
        onClick={handleClickSelect}
        endIcon={anchorEl
          ? <KeyboardArrowUpIcon />
          : <KeyboardArrowDownIcon />}
        color="primary"
        variant="outlined"
      >
        <Tooltip
          open={isOpen}
          title={(
            <Typography variant="body2">
              {selectedRegion}
            </Typography>
          )}
          arrow
        >
          <Typography
            variant="body2"
            noWrap
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            {selectedRegion}
          </Typography>
        </Tooltip>
      </ButtonStyled>
      <MenuStyled
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseSelect}
        onClick={handleCloseSelect}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left',
        }}
      >
        {regions.map((region) => (
          <MenuItemStyled
            divider
            key={region}
            data-value={region}
            onClick={regionSelection}
          >
            <Typography
              variant="subtitle2"
              noWrap
            >
              {region}
            </Typography>
          </MenuItemStyled>
        ))}
      </MenuStyled>
    </>
  );
});

export default RegionMenu;
