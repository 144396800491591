import * as React from 'react';
import {
  Box, MenuItem, Button, Stack, TextField,
} from '@mui/material';
import { useMappedPropNames } from 'common/components/BingMap/lib/dataMappers';
import { useGetPropertyBy } from 'containers/benchmark/lib/query-hooks';
import { ClientPropertyFromListUnion } from 'containers/benchmark/lib/mapped-table-data';
import { TableDispatchContext, TableStateContext } from 'containers/statval/model';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TooltipWrap from 'common/components/DuplexlTable/ui/TooltipWrap';
import { TextAlign } from 'chart.js';
import axiosInstance from 'common/axios';
import SnackBarUtils from 'common/components/SnackBar/SnackBarUtils';
import SubscriptionModal from '../SubscriptionModal';
import './styles.css';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: '8px',
    width: '100%',
  },
  dense: {
    marginTop: '16px',
  },
  menu: {
    width: '100%',
  },
  icon: {
    color: 'white',
  },
}));

// #region Cell
interface CellProps {
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  radiusTopLeft?: boolean;
  radiusTopRight?: boolean;
  radiusBottomRight?: boolean;
  radiusBottomLeft?: boolean;
  textAlign?: string;
  isBackgrounded?: boolean;
  text: string | number | undefined;
  isTitle?: boolean;
  isDim?: boolean;
}

const Cell = ({
  borderTop = true,
  borderRight = false,
  borderBottom = false,
  borderLeft = true,
  radiusTopLeft = false,
  radiusTopRight = false,
  radiusBottomRight = false,
  radiusBottomLeft = false,
  textAlign = 'left',
  isBackgrounded = false,
  text,
  isTitle = false,
  isDim = false,

}: CellProps):JSX.Element => (
  <TooltipWrap text={text === null || text === undefined ? '' : String(text)}>
    <div style={{
      fontFamily: 'OpenSans-SemiBold, sans-serif',
      fontWeight: 500,
      letterSpacing: 'normal',
      display: 'table-cell',
      verticalAlign: 'inherit',
      color: isDim ? '#85939F' : 'rgb(83, 94, 103)',
      backgroundColor: isBackgrounded ? '#F6F7F9' : 'white',
      textAlign: textAlign as TextAlign,
      width: '50%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: '5px',
      fontSize: isTitle ? '14px' : undefined,
      borderTop: borderTop ? '0.5px solid #BFC9D1' : undefined,
      borderRight: borderRight ? '0.5px solid #BFC9D1' : undefined,
      borderBottom: borderBottom ? '0.5px solid #BFC9D1' : undefined,
      borderLeft: borderLeft ? '0.5px solid #BFC9D1' : undefined,
      borderRadius: `
        ${radiusTopLeft ? '6px' : '0'} 
        ${radiusTopRight ? '6px' : '0'} 
        ${radiusBottomRight ? '6px' : '0'} 
        ${radiusBottomLeft ? '6px' : '0'}
      `,
    }}
    >
      {text}
    </div>
  </TooltipWrap>
);
// #endregion

const Row = ({ children }:{children: JSX.Element[] | JSX.Element}):JSX.Element => (
  <div style={{ display: 'flex' }}>{children}</div>
);

interface ResponceType {
  compsBalance: number;
  exatransactionIdNumActiveLoans: number;
  metrostatsNumProps: number ;
  priceNumComps: number;
  propertyName: string;
  purchaseCreditsToCompleteDataPurchase: boolean;
}

export const PropertyDetail = ():JSX.Element => {
  const [propertyItem, setPropertyItem] = React.useState('');
  const classes = useStyles();
  const dispatch = React.useContext(TableDispatchContext);
  const { isPropNameSelected } = React.useContext(TableStateContext);
  const selectorPropertyNames = useMappedPropNames();
  const { data, isLoading } = useGetPropertyBy({ name: propertyItem });
  const clientPortfolioMap = new Map<
    ClientPropertyFromListUnion | 'Affordability Details', string | number | undefined
  >([
    ['Property name', data?.propertyName],
    ['Address', data?.propertyAddress],
    ['City', data?.propertyCity],
    ['State', data?.propertyState],
    ['Zip', data?.propertyZipCode],
    ['County', data?.propertyCounty],
    ['Year Built', data?.yearBuilt],
    ['Year Renov', data?.yearRenov],
    ['Num Units', data?.numUnits],
    ['SubType', data?.subType],
    ['Affordability', data?.affordability],
    ['Affordability Details', data?.affordabilityDetails],
  ]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onSelectHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    dispatch({
      type: 'SET_TABLE_DATA',
      payload: null,
    });
    dispatch({
      type: 'SET_OBSERVATIONS_SET_TABLE_DATA',
      payload: [],
    });
    setPropertyItem(event.target.value as string);
    dispatch({
      type: 'SET_PROP_NAME',
      payload: event.target.value as string,
    });
    dispatch({
      type: 'SET_IS_PROP_NAME_SELECTED',
      payload: true,
    });
  };

  const onSubscribeHandler = async (): Promise<void> => {
    try {
      const resp = await axiosInstance.post<any>('/dataset/price', {
        cmbs: null,
        compExidPlidPrid: null,
        metrostatsMdra: null,
        metrostatsSubtype: null,
        propCompAlgoDemoAvmStats: 'AVM',
        propertyName: propertyItem,
      });
      const t = resp.data as ResponceType;
      const isAlreadySubscribed = t.priceNumComps === 0;
      if (isAlreadySubscribed) {
        const {
          data: dataA,
        } = await axiosInstance.post(`statval/subscribe?propertyName=${encodeURIComponent(propertyItem)}`);
        SnackBarUtils.error((dataA as {error: boolean; message: string;}).message);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      SnackBarUtils.error('Network error!!!');
    }
  };

  const affordabilityDetailsText = clientPortfolioMap.get('Affordability Details') !== null
    ? clientPortfolioMap.get('Affordability Details') : '';

  return (
    <Box style={{
      backgroundColor: 'white',
      padding: '16px',
      height: '100%',
      borderRadius: '6px',
      // eslint-disable-next-line max-len
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    }}
    >
      <Stack
        style={{ height: '37px' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        PROPERTY DETAIL
      </Stack>
      <div style={{ position: 'relative' }}>
        <TextField
          style={{ zIndex: '1' }}
          size="small"
          id="prop-detail"
          select
          label={propertyItem === '' ? 'Select Clientportfolio' : ''}
          className={classes.textField}
          value={propertyItem}
          onChange={onSelectHandler}
          InputLabelProps={{ shrink: false }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
            classes: { icon: classes.icon },
          }}
          margin="normal"
          variant="outlined"
        >
          {
            selectorPropertyNames.map(
              (property) => <MenuItem key={property} value={property}>{property}</MenuItem>,
            )
          }
        </TextField>
        <div style={{
          position: 'absolute', top: '19px', right: '8px', zIndex: '0',
        }}
        >
          <ExpandMoreIcon />
        </div>
      </div>

      <div style={isLoading ? { filter: 'blur(5px)' } : {}}>
        <div style={{ marginBottom: '32px' }}>
          <Row>
            <Cell
              isTitle
              text="PROPERTY NAME"
              radiusTopLeft
              isBackgrounded
            />
            <Cell
              borderRight
              isDim
              isTitle
              text={clientPortfolioMap.get('Property name')}
              radiusTopRight
            />
          </Row>
          <Row>
            <Cell isTitle text="ADDRESS" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('Address')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="CITY" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('City')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="STATE" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('State')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="ZIP CODE" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('Zip')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="COUNTY" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('County')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="YEAR BUILT" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('Year Built')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="YEAR RENOV" isBackgrounded />
            <Cell
              isTitle
              isDim
              text={
                (() => {
                  if (clientPortfolioMap.has('Year Renov') && clientPortfolioMap.has('Year Built')) {
                    const yearRenov = clientPortfolioMap.get('Year Renov');
                    const yearBuilt = clientPortfolioMap.get('Year Built');

                    return yearRenov !== undefined && yearBuilt !== undefined && yearRenov > yearBuilt
                      ? yearRenov
                      : yearBuilt;
                  }

                  return '';
                })()
              }
              borderRight
            />
          </Row>
          <Row>
            <Cell isTitle text="NUM UNITS" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('Num Units')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="SUBTYPE" isBackgrounded />
            <Cell isTitle isDim text={clientPortfolioMap.get('SubType')} borderRight />
          </Row>
          <Row>
            <Cell isTitle text="AFFORDABILITY" radiusBottomLeft isBackgrounded borderBottom />
            <Cell
              isTitle
              isDim
              text={
                (clientPortfolioMap.get('Affordability') || clientPortfolioMap.get('Affordability Details'))
              && `${clientPortfolioMap.get('Affordability')}
                  ${affordabilityDetailsText}`
              }
              radiusBottomRight
              borderRight
              borderBottom
            />
          </Row>
        </div>
      </div>
      <Button
        disabled={!isPropNameSelected}
        // onClick={() => setIsModalOpen(true)}
        onClick={onSubscribeHandler}
        style={{ backgroundColor: isPropNameSelected ? '#1060AA' : 'rgba(0, 0, 0, 0.12)' }}
        variant="contained"
      >
        SUBSCRIBE TO STATVAL
      </Button>
      <SubscriptionModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Box>
  );
};
