import { memo } from 'react';
import { useQueryClient } from 'react-query';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { QUERY_KEY } from 'common/query-keys';
import QueryLoadingProvider from 'common/components/QueryLoadingProvider';

import { RESEARCH } from 'containers/research/utils/constants';
import { useCreatePrintTableData } from 'containers/research/lib/mapped-table-data';
import { useGetResearch } from 'containers/research/lib/query-hooks';
import { TablePrintDataCell } from 'containers/research/lib/models/types';
import { SpinnerWrapper } from 'common/components/SuspenseFallback/styled';
import { Spinner } from 'common/components/spinner/Spinner';
import ExportDataMenu from './export-menu/ExportDataMenu';
import { Table } from './table';

import { HeaderStyled } from '../styled';

const PrintDataTable = (): JSX.Element => {
  const tableData = useCreatePrintTableData();
  const printedData = (useCreatePrintTableData() || null) as TablePrintDataCell[];

  const fetchedData = useGetResearch();
  const queryClient = useQueryClient();

  const isTableDataInCache = !!queryClient.getQueryData(QUERY_KEY.RESEARCH);
  const isButtonDisabled = !tableData.length;

  const research = useGetResearch();

  if (research.isFetching) {
    return (
      <SpinnerWrapper>
        <Spinner size="large" color="primary" />
      </SpinnerWrapper>
    );
  }

  return (
    <Box height="100%">
      <HeaderStyled>
        <Typography variant="h5">
          { RESEARCH.TITLES.PRINT_DATA }
        </Typography>
        <ExportDataMenu isButtonDisabled={isButtonDisabled} />
      </HeaderStyled>
      <QueryLoadingProvider result={fetchedData}>
        {isTableDataInCache && <Table />}
      </QueryLoadingProvider>
    </Box>
  );
};

export default memo(PrintDataTable);
