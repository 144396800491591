import React from 'react';

export type FiltersState = {
  filterUnits: boolean,
  filterYear: boolean,
  filterSameSubtype: boolean,
  filterAfforadable: boolean,
  filterSimilarSubtype: boolean,
  filter2020FF: boolean,
  filter2020UW: boolean,
}
export type Action = {newFilters?: FiltersState | undefined, type: string}
export type State = FiltersState
export type Dispatch = (action: Action) => void
export interface IFiltersContext {state: State, dispatch: Dispatch}
export enum FiltersActionType {
  Change = 'change',
  Reset = 'reset',
  Default = ''
}

export const FiltersContext = React.createContext<{
  state: State,
  dispatch: Dispatch
} | undefined>(undefined);
