import * as React from 'react';
import {
  StepsSignStyled,
  StepsSignTextStyled,
  StepsSignTextTitleStyled,
  StepsSignTextBoxStyled,
} from './styles';
import { StepsSignConst } from './const';
import { CaprateTable } from './tableIndex';

export const StepsSign = ():JSX.Element => (
  <StepsSignStyled>
    <StepsSignTextStyled>
      <StepsSignTextTitleStyled>
        {StepsSignConst.Title}
      </StepsSignTextTitleStyled>
      <StepsSignTextBoxStyled>
        {StepsSignConst.FirstStep}
      </StepsSignTextBoxStyled>
      <StepsSignTextBoxStyled>
        {StepsSignConst.SecondStep}
      </StepsSignTextBoxStyled>
    </StepsSignTextStyled>
    <CaprateTable />
  </StepsSignStyled>
);
