import { memo } from 'react';
import Divider from '@mui/material/Divider';
import { DividerWrapper } from './styled';

interface HorizontalDividerProps {
spacingTop?: number;
spacingBottom?: number;
}

const HorizontalDivider = memo(({ spacingTop = 0, spacingBottom = 0 }: HorizontalDividerProps) => (
  <DividerWrapper $spacingTop={spacingTop} $spacingBottom={spacingBottom}>
    <Divider />
  </DividerWrapper>
));

export default HorizontalDivider;
