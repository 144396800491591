import { useContext } from 'react';
import {
  IdState,
  IdDispatch,
  PropertyIdContext,
} from './PropertyIdContext';
import {
  ButtonContext,
  ButtonDispatch,
  ButtonState,
} from './ButtonStateContext';
import {
  SelectDispatch,
  SelectedPropertiesContext,
  SelectState,
} from './SelectedPropertiesContext';

export const usePropertyId = (): {
  state: IdState,
  dispatch: IdDispatch,
} => {
  const context = useContext(PropertyIdContext);
  if (context === undefined) {
    throw new Error('usePropertyId must be used within a InputPropertyProvider');
  }

  return context;
};

export const useButtonState = (): {
  state: ButtonState,
  dispatch: ButtonDispatch,
} => {
  const context = useContext(ButtonContext);
  if (context === undefined) {
    throw new Error('ButtonSate must be used within a ButtonProvider');
  }

  return context;
};

export const useSelectedProperties = (): {
  state: SelectState,
  dispatch: SelectDispatch,
} => {
  const context = useContext(SelectedPropertiesContext);
  if (context === undefined) {
    throw new Error('SelectState must be used within a SelectProvider');
  }

  return context;
};
