import React, { memo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from 'containers/login/lib/auth';
import { IUserData } from 'containers/login/lib/models/ResponseData.interface';
import { ROUTE } from './utils/constants';

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode
}

export const PrivateRoute = memo(({ children, ...rest }: PrivateRouteProps):JSX.Element => {
  const { user } = useAuth() as {user: IUserData};

  const userToken = user?.sessionId;

  return (
    userToken
      ? <Route {...rest}>{children}</Route>
      : (
        <Route {...rest}>
          <Redirect to={{ pathname: ROUTE.LOGIN }} />
        </Route>
      )
  );
});
