//= ============= Map tab styles ===============
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';

export const MapTabContainerStyled = styled(Grid)({
  height: '100%',
  width: '100%',
  display: 'flex',
  gap: '30px',
  flexWrap: 'nowrap',
  overflow: 'auto',
});

export const MapTabItemStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100%',
  },
}));

export const ItemWrapperStyled = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
}));
