import { styled } from '@mui/material';

import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';

import { AnyComponentType } from 'common/components/ApplicationBar/styled/theme-types';

const TITLE_HEIGHT = 92 as const;

export const GridWrapper = styled('div')(({ theme }) => ({
  height: `calc(100% - ${TITLE_HEIGHT}px)`,
  '.ReactVirtualized__Grid': {
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
  },

  '&:hover': {
    '.ReactVirtualized__Grid::-webkit-scrollbar': {
      height: '12px !important',
    },
  },
}));

export const TableHeadCellStyled = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.lightGray,
  flexGrow: 0,
  flexShrink: 0,
  width: 'inherit',
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  overflow: 'hidden',
}));
export type TableBodyCellType = AnyComponentType & {
  $isFirstCol?: boolean;
};
export const TableBodyCellStyled = styled<TableBodyCellType>(TableHeadCellStyled, {
  shouldForwardProp: (propName) => propName !== '$isFirstCol',
})(({ theme, $isFirstCol }) => ({
  borderRight: `1px solid ${theme.palette.background.borderLight}`,
  backgroundColor: 'inherit',
  borderRadius: 'inherit',
  padding: $isFirstCol ? 8 : 16,
}));

export const TableBodyCellSelectedStyled = styled(TableBodyCellStyled, {
  shouldForwardProp: (propName) => propName !== '$isFirstCol',
})(({ theme, $isFirstCol }) => ({
  border: $isFirstCol ? 'initial' : `1px solid ${theme.palette.background.blue}`,
  backgroundColor: 'inherit',
  borderRadius: 'inherit',
  padding: $isFirstCol ? 8 : 16,
}));

export const TableHeadRowStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  boxSizing: 'border-box',
});

export const TableBodyStyled = styled('div')<typeof TableBody>({
  height: '100%',
  overflow: 'hidden',
});

export const SortButtonStyled = styled(IconButton)({
  svg: {
    fontSize: '14px',
  },
});

export const HeadLabelWrapperStyled = styled(Typography)({
  '&:hover': {
    cursor: 'pointer',
  },
});

export const HeadLabelWrapperTableStyled = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  [theme.breakpoints.down('xxl')]: {
    fontSize: '12px',
  },
}));

export const SpinnerWrapper = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  alignSelf: 'center',
}));
