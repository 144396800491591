export const BENCHMARK = {
  LIST_TITLE: 'SELECT CLIENT PROPERTY FROM LIST',
  SELECT_TITLE: 'Select Property from Client Portfolio',
  RADIO_1: 'Benchmark VS COMPSET SELECTED BY CLIENT',
  RADIO_2: 'Benchmark VS STATVAL RECOMMENDED COMPSET',
  DISPLAY_BENCHMARK_BUTTON: 'DISPLAY BENCHMARK TABLE',
  SUBSCRIPTION: 'SUBSCRIBE',
  INFO_BUTTON: 'View Comps Used for Calculations',
  BENCHMARK_ANALYTICS: 'BENCHMARK ANALYTICS: CLIENT PROPERTY VERSUS COMPSET',
  INFO_TABLE_TITLE: 'BENCHMARK COMPSET',
  FILTER: 'FILTER: ',
  FILTER_BUTTON: 'FILTER',
  FILTER_SELECT: 'Select filter',
  CHART: 'DISPLAY BENCHMARK CHART',
} as const;

export const FILTER_LIST = {
  HEIGHT: 150,
  ROW_HEIGHT: 35,
  LIST_WIDTH: 230,
} as const;
