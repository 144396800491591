import {
  memo,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { IPropertyResponseData } from 'common/components/BingMap/utils/types';
import { useDetailsTableData } from 'common/components/BingMap/lib/dataMappers';
import LoadingProvider from 'containers/input-property/utils/LoadingProvider';
import { TABLE_SETTINGS } from '../utils/constants';
import CellRenderer from './CellRenderer';
import { TableStyled } from '../styled';

const DetailsPropertyTable = ():JSX.Element => {
  const tableRef = useRef<MultiGrid>(null);
  const getColumnWidth = useMemo(() => () => TABLE_SETTINGS.COLUMN_WIDTH, []);
  const data = useDetailsTableData();

  useEffect(() => {
    const grid = tableRef.current;
    if (grid) {
      grid.forceUpdateGrids();
    }
  }, [data]);

  const memoizedRowHeight = useMemo(() => (
    { index }: {index: number},
  ): number => (index === 0 ? TABLE_SETTINGS.ROW_HEIGHT_HEADER : TABLE_SETTINGS.ROW_HEIGHT), []);

  return (
    <LoadingProvider result={data}>
      <TableStyled>
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              ref={tableRef}
              cellRenderer={({ key: mainKey, ...rest }) => (
                <CellRenderer
                  {...rest}
                  /*
                  * 'key' prop is requiered.
                  * Simple key value does not pass value as expected,
                  * it returns undefined.
                  * That is why here is two keys.
                */
                  mainKey={mainKey}
                  key={mainKey}
                  data={data as IPropertyResponseData[]}
                />
              )}
              overscanRowCount={50}
              columnWidth={getColumnWidth}
              columnCount={data.length + 1}
              enableFixedColumnScroll
              enableFixedRowScroll
              fixedColumnCount={TABLE_SETTINGS.FIXED_COLUMN_COUNT}
              fixedRowCount={TABLE_SETTINGS.FIXED_ROW_COUNT}
              height={height}
              rowHeight={memoizedRowHeight}
              rowCount={TABLE_SETTINGS.ROW_COUNT}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
      </TableStyled>
    </LoadingProvider>
  );
};

export default memo(DetailsPropertyTable);
