export const TITLE = {
  '/login': 'LOGIN',
  '/logout': 'LOGOUT',
  '/home': 'HOME',
  '/home/credits': 'CREDITS',
  '/home/products': 'PRODUCTS',
  '/home/team': 'TEAM',
  '/home/help': 'HELP',
  '/home/contact': 'CONTACTS',
  '/home/about': 'ABOUT',
  '/home/privacy': 'PRIVACY',
} as const;

export const TITLE_MAP = new Map([
  ['/login', 'LOGIN'],
  ['/logout', 'LOGOUT'],
  ['/home', 'HOME'],
  ['/home/credits', 'CREDITS'],
  ['/home/help', 'HELP'],
  ['/home/products', 'PRODUCTS'],
  ['/home/team', 'TEAM'],
  ['/home/contact', 'CONTACTS'],
  ['/home/about', 'ABOUT'],
  ['/home/privacy', 'PRIVACY'],
]);
