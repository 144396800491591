import React from 'react';

import { ActionType, ModalContext, ProviderProps } from './ModalContext';
import { toggleReducer } from './modalReducers';

export const BuyAndSellDataCreditsProvider = ({ children }:ProviderProps) : JSX.Element => {
  const [state, dispatch] = React.useReducer(toggleReducer, { toggle: false, title: ActionType.Default });

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
