import { createContext } from 'react';

export type ClientPortfolioNameAction = { value: string; };

export type ClientPortfolioNameDispatch = (action: ClientPortfolioNameAction) => void;

export type ClientPortfolioNameState = { value: string; };

export const SelectClientPortfolioNameContext = createContext<
  { state: ClientPortfolioNameState; dispatch: ClientPortfolioNameDispatch; } | undefined
>(undefined);
