import React from 'react';

export type Action = {path: string}
export type State = {path: string}
export type Dispatch = (action: Action) => void
export interface ILocationContext {state: State, dispatch: Dispatch}

export const LocationContext = React.createContext<{
  state: State,
  dispatch: Dispatch
} | undefined>(undefined);
