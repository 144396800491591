import { memo, useMemo } from 'react';

import {
  useGetInputValue,
} from 'containers/input-property/components/mapTab/propertyData/requiredTable/hooks/useGetInputValue';
import { FormRegister, RowName } from 'containers/input-property/components/mapTab/map/utils/constants';
import { RequiredFields, REQUIRED_TABLE } from 'containers/input-property/utils/constants';

import { TableInputStyled } from '../../styled';

const StringInput = ({
  row, index, register,
}: {
  row: string,
  index: number,
  register: FormRegister,
  }):JSX.Element => {
  const { value } = useGetInputValue(index);

  const randomKey = useMemo(() => () => Math.floor((Math.random() * 1000)), []);
  const required = useMemo(() => () => REQUIRED_TABLE.REQUIRED_FIELDS.includes(row as RequiredFields),
    [row]);
  const isDisabled = useMemo(() => () => !!value && index === 0,
    [index, value]);

  return (
    <TableInputStyled
      id={row}
      fullWidth
      key={randomKey()}
      defaultValue={value}
      required={required()}
      disabled={isDisabled()}
      {...register(row as RowName)}
    />
  );
};

export default memo(StringInput);
