import { memo } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

interface MemoizedSelectListProps {
  style: React.CSSProperties
}

const Row = memo(
  ({ style }: MemoizedSelectListProps): JSX.Element | null => (
    <FormGroup style={style}>
      <FormControlLabel
        control={<Checkbox color="primary" />}
        label="label"
      />
    </FormGroup>
  ),
);

export default Row;
