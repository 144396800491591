import { memo, useCallback, useMemo } from 'react';
import { useHistory, withRouter, Redirect } from 'react-router-dom';

import TabContext from '@mui/lab/TabContext';

import { ROUTE } from 'common/components/routes/utils/constants';
import MapProvider from 'common/components/BingMap/local-state/MapProvider';
import { TabListComponent } from './TabListComponent';
import { TabPanelsComponent } from './TabPanelsComponent';
import { FiltersContextProvider } from './local-state/FiltersContextProvider';
import {
  CompsetsContainerStyled,
  CompsetsTabsWrapper,
  TabListWrapperStyled,
  TabPanelWrapperStyled,
} from './utils/styled';
import { CheckboxContextProvider } from './local-state/CheckboxContext';

const Compsets = memo((): JSX.Element => {
  const history = useHistory();

  const routerPath = history.location.pathname;

  const isWrongCompsetsPath = useMemo(
    () => Object.values([
      ROUTE.ACCOUNT.COMPSETS.MAP,
      ROUTE.ACCOUNT.COMPSETS.DETAIL,
    ]).every((path) => path !== routerPath),
    [routerPath],
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: string): void => {
      history.push(newValue);
    },
    [history],
  );

  return (
    <MapProvider>
      <CheckboxContextProvider>
        <FiltersContextProvider>
          <CompsetsContainerStyled>
            <CompsetsTabsWrapper item container wrap="nowrap" direction="column">
              <TabContext value={routerPath}>
                {isWrongCompsetsPath ? (
                  <Redirect to={ROUTE.NOT_FOUND} />
                ) : (
                  <>
                    <TabListWrapperStyled item>
                      <TabListComponent
                        onChange={handleChange}
                        value={routerPath}
                      />
                    </TabListWrapperStyled>
                    <TabPanelWrapperStyled item>
                      <TabPanelsComponent />
                    </TabPanelWrapperStyled>
                  </>
                )}
              </TabContext>
            </CompsetsTabsWrapper>
          </CompsetsContainerStyled>
        </FiltersContextProvider>
      </CheckboxContextProvider>
    </MapProvider>
  );
});

export default withRouter(Compsets);
