import { memo } from 'react';

import { RESEARCH } from 'containers/research/utils/constants';
import { CompsPriceData } from 'containers/research/lib/models/types';

import {
  TableContainerStyled,
  GridCellStyled,
} from './styled';

const Table = (
  { data, props }:
  { data: CompsPriceData | undefined, props: {region: string, subtype: string} },
):JSX.Element => (
  <TableContainerStyled>
    <GridCellStyled>
      {RESEARCH.TABLE.GEOGRAPHY}
    </GridCellStyled>
    <GridCellStyled>
      {props.region}
    </GridCellStyled>
    <GridCellStyled>
      {RESEARCH.TABLE.SUBTYPE}
    </GridCellStyled>
    <GridCellStyled>
      {props.subtype}
    </GridCellStyled>
    <GridCellStyled>
      {RESEARCH.TABLE.PROPETRIES}
    </GridCellStyled>
    <GridCellStyled>
      {data?.metrostatsNumProps || ''}
    </GridCellStyled>
    <GridCellStyled>
      {RESEARCH.TABLE.PRICE}
    </GridCellStyled>
    <GridCellStyled>
      {data?.priceNumComps || ''}
    </GridCellStyled>
  </TableContainerStyled>
);

export default memo(Table);
