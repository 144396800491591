import { memo } from 'react';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import SearchOutlined from '@mui/icons-material/Search';

import useSearch from 'common/hooks/useSerach';
import { TEXT } from './constants';
import { FilledInputStyled } from './styled';

export const SearchControl = memo((): JSX.Element => {
  const {
    onSearchInputChange,
    text,
  } = useSearch();

  return (
    <Grid>
      <FormControl fullWidth variant="filled">
        <FilledInputStyled
          multiline={false}
          value={text}
          placeholder={TEXT.SEARCH}
          onChange={onSearchInputChange}
          startAdornment={(
            <InputAdornment position="end">
              <SearchOutlined color="disabled" fontSize="small" />
            </InputAdornment>
          )}
        />
      </FormControl>
    </Grid>
  );
});
