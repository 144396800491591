import { PrintedDataKeys } from 'containers/research/lib/models/printedDataTypes';
import { TablePrintDataTitle } from 'containers/research/lib/models/types';

export const FIXED_COLUMN_COUNT = 0 as const;
export const FIXED_ROW_COUNT = 1 as const;
export const ROW_HEIGHT = 28 as const;
export const COLUMN_WIDTH = 230 as const;
export const COLUMN_TITLE_WIDTH = 220 as const;

export const COLUMN_TITLES: TablePrintDataTitle[] = [
  { title: 'City', cell: PrintedDataKeys.City, symbol: '' },
  { title: 'State', cell: PrintedDataKeys.State, symbol: '' },
  { title: 'Zip', cell: PrintedDataKeys.Zip, symbol: '' },
  { title: 'County', cell: PrintedDataKeys.County, symbol: '' },
  { title: 'CMSA', cell: PrintedDataKeys.Cmsa, symbol: '' },
  { title: 'NCREIF Division', cell: PrintedDataKeys.NcreifDivision, symbol: '' },
  { title: 'NCREIF Region', cell: PrintedDataKeys.NcreifRegion, symbol: '' },
  { title: 'Year Built', cell: PrintedDataKeys.YearBuilt, symbol: '' },
  { title: 'Year Renov', cell: PrintedDataKeys.YearRenov, symbol: '' },
  { title: 'Num Units', cell: PrintedDataKeys.NumUnits, symbol: '' },
  { title: 'SubType', cell: PrintedDataKeys.SubType, symbol: '' },
  { title: 'Affordability', cell: PrintedDataKeys.Affordability, symbol: '' },
  { title: 'Population PerSqMile', cell: PrintedDataKeys.PopulationPerSqMile, symbol: 'int' },
  { title: 'Median HomeVal', cell: PrintedDataKeys.MedianHomeVal, symbol: '$' },
  { title: 'Median HHInc', cell: PrintedDataKeys.MedianHHInc, symbol: '$' },
  { title: 'Median Age', cell: PrintedDataKeys.MedianAge, symbol: '' },
  { title: 'Median Rent', cell: PrintedDataKeys.MedianRent, symbol: '$' },
  { title: 'Median Owner Costs', cell: PrintedDataKeys.MedianOwnerCosts, symbol: '$' },
  { title: 'Renter PCT', cell: PrintedDataKeys.RenterPct, symbol: '%' },
  { title: 'Rent Over30pct HHInc PCT', cell: PrintedDataKeys.rentOver30PctHHIncPCT, symbol: '%' },
  { title: 'Bachelors Degree PCT', cell: PrintedDataKeys.BachelorsDegreePCT, symbol: '%' },
  { title: 'Unemployment PCT', cell: PrintedDataKeys.UnemploymentPCT, symbol: '%' },
  { title: 'Mgt Finance Job PCT', cell: PrintedDataKeys.MgtFinanceJobPCT, symbol: '%' },
  { title: 'MovedIn After2010 PCT', cell: PrintedDataKeys.MovedInAfter2010PCT, symbol: '%' },
  { title: 'Underwriting UWYEAR', cell: PrintedDataKeys.UnderwritingUwYear, symbol: '' },
  { title: 'Top-Line REVYEAR', cell: PrintedDataKeys.TopLineRevYear, symbol: '' },
  { title: 'Full Line-Item FFYEAR', cell: PrintedDataKeys.FullLineItemFfYear, symbol: '' },
  { title: 'Appraisal or Stat Val', cell: PrintedDataKeys.AppraisalOrStatVal, symbol: '' },
  { title: 'VALUATION DATE', cell: PrintedDataKeys.ValuationDate, symbol: '' },
  { title: 'VALUATION PERUNIT', cell: PrintedDataKeys.ValuationPerunit, symbol: '$' },
  { title: 'UW NOI PERUNIT', cell: PrintedDataKeys.UwNoiPerunit, symbol: '$' },
  { title: 'UW CAPRATE', cell: PrintedDataKeys.UwCaprate, symbol: '2%' },
  { title: 'OCCUP', cell: PrintedDataKeys.Occup, symbol: '%' },
  { title: 'REV PERUNIT', cell: PrintedDataKeys.RevPerunit, symbol: '$' },
  { title: 'EXP PERUNIT', cell: PrintedDataKeys.ExpPerunit, symbol: '$' },
  { title: 'NOI PERUNIT', cell: PrintedDataKeys.NoiPerunit, symbol: '$' },
  { title: 'NCF PERUNIT', cell: PrintedDataKeys.NcfPerunit, symbol: '$' },
  { title: 'CAPEX PERUNIT', cell: PrintedDataKeys.CapexPerunit, symbol: '$' },
  { title: 'EXP PCTREV', cell: PrintedDataKeys.ExpPctrev, symbol: '%' },
  { title: 'NOI PCTREV', cell: PrintedDataKeys.NoiPctrev, symbol: '%' },
  { title: 'NCF PCTREV', cell: PrintedDataKeys.NcfPctrev, symbol: '%' },
  { title: 'CAPEX PCTREV', cell: PrintedDataKeys.CapexPctrev, symbol: '%' },
  { title: 'REV  PCT 1YRCH', cell: PrintedDataKeys.RevPct1Yrch, symbol: '%' },
  { title: 'EXP PCT 1YRCH', cell: PrintedDataKeys.ExpPct1Yrch, symbol: '%' },
  { title: 'NOI PCT 1YRCH', cell: PrintedDataKeys.NoiPct1Yrch, symbol: '%' },
  { title: 'REV  PERUNIT 1YRCH', cell: PrintedDataKeys.RevPerunit1Yrch, symbol: '$' },
  { title: 'EXP  PERUNIT 1YRCH', cell: PrintedDataKeys.ExpPerunit1Yrch, symbol: '$' },
  { title: 'NOI  PERUNIT 1YRCH', cell: PrintedDataKeys.NoiPerunit1Yrch, symbol: '$' },
  { title: 'GROSRNT PERUNIT', cell: PrintedDataKeys.GrosrntPerunit, symbol: '$' },
  { title: 'VACANCY PERUNIT', cell: PrintedDataKeys.VacancyPerunit, symbol: '$' },
  { title: 'BASERNT PERUNIT', cell: PrintedDataKeys.BaserntPerunit, symbol: '$' },
  { title: 'RETAXES PERUNIT', cell: PrintedDataKeys.RetaxesPerunit, symbol: '$' },
  { title: 'PROPINS PERUNIT', cell: PrintedDataKeys.PropinsPerunit, symbol: '$' },
  { title: 'UTILITI PERUNIT', cell: PrintedDataKeys.UtilitiPerunit, symbol: '$' },
  { title: 'REPAIRS PERUNIT', cell: PrintedDataKeys.RepairsPerunit, symbol: '$' },
  { title: 'MANAGEM PERUNIT', cell: PrintedDataKeys.ManagemPerunit, symbol: '$' },
  { title: 'PAYROLL PERUNIT', cell: PrintedDataKeys.PayrollPerunit, symbol: '$' },
  { title: 'MARKETI PERUNIT', cell: PrintedDataKeys.MarketiPerunit, symbol: '$' },
  { title: 'GROSRNT PCTREV', cell: PrintedDataKeys.GrosrntPctrev, symbol: '%' },
  { title: 'VACANCY PCTREV', cell: PrintedDataKeys.VacancyPctrev, symbol: '%' },
  { title: 'BASERNT PCTREV', cell: PrintedDataKeys.BaserntPctrev, symbol: '%' },
  { title: 'RETAXES PCTREV', cell: PrintedDataKeys.RetaxesPctrev, symbol: '%' },
  { title: 'PROPINS PCTREV', cell: PrintedDataKeys.PropinsPctrev, symbol: '%' },
  { title: 'UTILITI PCTREV', cell: PrintedDataKeys.UtilitiPctrev, symbol: '%' },
  { title: 'REPAIRS PCTREV', cell: PrintedDataKeys.RepairsPctrev, symbol: '%' },
  { title: 'MANAGEM PCTREV', cell: PrintedDataKeys.ManagemPctrev, symbol: '%' },
  { title: 'PAYROLL PCTREV', cell: PrintedDataKeys.PayrollPctrev, symbol: '%' },
  { title: 'MARKETI PCTREV', cell: PrintedDataKeys.MarketiPctrev, symbol: '%' },
  { title: 'GROSRNT PERUNIT 1YRCH', cell: PrintedDataKeys.GrosrntPerunit1Yrch, symbol: '$' },
  { title: 'VACANCY PERUNIT 1YRCH', cell: PrintedDataKeys.VacancyPerunit1Yrch, symbol: '$' },
  { title: 'BASERNT PERUNIT 1YRCH', cell: PrintedDataKeys.BaserntPerunit1Yrch, symbol: '$' },
  { title: 'RETAXES PERUNIT 1YRCH', cell: PrintedDataKeys.RetaxesPerunit1Yrch, symbol: '$' },
  { title: 'PROPINS PERUNIT 1YRCH', cell: PrintedDataKeys.PropinsPerunit1Yrch, symbol: '$' },
  { title: 'UTILITI PERUNIT 1YRCH', cell: PrintedDataKeys.UtilitiPerunit1Yrch, symbol: '$' },
  { title: 'REPAIRS PERUNIT 1YRCH', cell: PrintedDataKeys.RepairsPerunit1Yrch, symbol: '$' },
  { title: 'MANAGEM PERUNIT 1YRCH', cell: PrintedDataKeys.ManagemPerunit1Yrch, symbol: '$' },
  { title: 'PAYROLL PERUNIT 1YRCH', cell: PrintedDataKeys.PayrollPerunit1Yrch, symbol: '$' },
  { title: 'MARKETI PERUNIT 1YRCH', cell: PrintedDataKeys.MarketiPerunit1Yrch, symbol: '$' },
  { title: 'HC INC PVTPAY PCTREV', cell: PrintedDataKeys.HcIncPvtpayPctrev, symbol: '%' },
  { title: 'HC INC MEDCARE PCTREV', cell: PrintedDataKeys.HcIncMedcarePctrev, symbol: '%' },
  { title: 'HC INC NURSING PCTREV', cell: PrintedDataKeys.HcIncNursingPctrev, symbol: '%' },
  { title: 'HC INC MEALS PCTREV', cell: PrintedDataKeys.HcIncMealsPctrev, symbol: '%' },
  { title: 'HC EXP ROOMS PCTREV', cell: PrintedDataKeys.HcExpRoomsPctrev, symbol: '%' },
  { title: 'HC EXP MEALS PCTREV', cell: PrintedDataKeys.HcExpMealsPctrev, symbol: '%' },
  { title: 'HC INC PVTPAY PERUNIT', cell: PrintedDataKeys.HcIncPvtpayPerunit, symbol: '$' },
  { title: 'HC INC MEDCARE PERUNIT', cell: PrintedDataKeys.HcIncMedcarePerunit, symbol: '$' },
  { title: 'HC INC NURSING PERUNIT', cell: PrintedDataKeys.HcIncNursingPerunit, symbol: '$' },
  { title: 'HC INC MEALS PERUNIT', cell: PrintedDataKeys.HcIncMealsPerunit, symbol: '$' },
  { title: 'HC EXP ROOMS PERUNIT', cell: PrintedDataKeys.HcExpRoomsPerunit, symbol: '$' },
  { title: 'HC EXP MEALS PERUNIT', cell: PrintedDataKeys.HcExpMealsPerunit, symbol: '$' },
];

export const ROUND_TITLES = [
  'grosrntPerunit1Yrch',
  'vacancyPerunit1Yrch',
  'baserntPerunit1Yrch',
  'retaxesPerunit1Yrch',
  'propinsPerunit1Yrch',
  'utilitiPerunit1Yrch',
  'repairsPerunit1Yrch',
  'managemPerunit1Yrch',
  'payrollPerunit1Yrch',
  'marketiPerunit1Yrch',
] as const;
