import { Action, ActionType, State } from './ModalContext';

export function toggleReducer(state: State, action: Action): State {
  let copy = { ...state };
  switch (action.type) {
    case ActionType.BuyCredits: {
      copy = { ...state, toggle: true, title: ActionType.BuyCredits };

      return copy;
    }
    case ActionType.CompsInvoices: {
      copy = { ...state, toggle: true, title: ActionType.CompsInvoices };

      return copy;
    }
    case ActionType.PaymentCreditCart: {
      copy = { ...state, toggle: true, title: ActionType.PaymentCreditCart };

      return copy;
    }
    case ActionType.TransferComps: {
      copy = { ...state, toggle: true, title: ActionType.TransferComps };

      return copy;
    }
    case ActionType.Subscription: {
      copy = { ...state, toggle: true, title: ActionType.Subscription };

      return copy;
    }
    case ActionType.Archive: {
      copy = { ...state, toggle: true, title: ActionType.Archive };

      return copy;
    }
    case ActionType.Modify: {
      copy = { ...state, toggle: true, title: ActionType.Modify };

      return copy;
    }
    case ActionType.Credits: {
      copy = { ...state, toggle: true, title: ActionType.Credits };

      return copy;
    }
    case ActionType.Payment: {
      copy = { ...state, toggle: true, title: ActionType.Payment };

      return copy;
    }
    case ActionType.Close: {
      copy = { ...state, toggle: false, title: ActionType.Default };

      return copy;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
