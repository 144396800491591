export const QUERY_KEY = {
  CLIENTS: 'clients',
  FILTERED_CLIENTS: 'filtered-clients',
  PROFILE: 'profile',
  CREDITS: 'credits',
  PAYMENT: 'payment',
  PRICES: 'prices',
  PROPERTIES: 'properties',
  METROCOUNTS: 'metrocounts',
  NEIGHBORHOOD: 'neighborhood',
  SYBTYPE: 'syb-type',
  RESEARCH: 'research',
  QUARTILES: 'quartiles',
  DATASET_PRICE: 'dataset-price',
  CURRENT_PROPERTY: 'current-property',
  FILTERED_PROPERTIES: 'filtered-properties',
  SUBSCRIBED_COMPSETS: 'subscribed-compsets',
  COMPSETS_PROPERTIES: 'compsets-properties',
  SAVED_COMPSETS: 'saved-compsets',
  IS_ACHIVED: 'is-achived',
} as const;
