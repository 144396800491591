import { Action, State } from './CurrentPropertyContext';

export function setCurrentPropertyReducer(state: State, action: Action): State {
  const copy = { ...state };

  copy.currentProperty = action.currentProperty;
  copy.currentPropertyEvent = action.currentPropertyEvent as React.MouseEvent<HTMLElement>;

  return copy;
}
