import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

const ExelIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 22 28">
    { /* eslint-disable max-len */ }
    <path d="M0.5 1.68C0.5 0.75206 1.25206 0 2.18 0H14.5L21.5 5.87999V26.32C21.5 27.2479 20.748 28 19.82 28H2.18C1.25206 28 0.5 27.2479 0.5 26.32V1.68Z" />
    <path d="M9.05306 18.9293H7.55628L6.53218 17.1257L5.50804 18.9293H4L5.77816 15.7937L4.21386 13.0707H5.72186L6.53214 14.4852L7.33116 13.0707H8.8392L7.28612 15.8055L9.05306 18.9293ZM9.582 18.9293V13.0707H11.0113V17.6561H13.4421V18.9292H9.582V18.9293ZM15.8055 19C15.2428 19 14.7476 18.8114 14.32 18.446C13.9036 18.0923 13.6673 17.6444 13.6335 17.114L14.8602 16.7368C14.894 17.0196 15.0065 17.2672 15.1978 17.4558C15.4004 17.6444 15.6255 17.7387 15.8731 17.7387C16.0756 17.7387 16.2444 17.6916 16.3795 17.5973C16.5033 17.5029 16.5708 17.3733 16.5708 17.2201C16.5708 17.0904 16.5145 16.9843 16.4132 16.89C16.3119 16.8075 16.1769 16.7368 16.0193 16.6778C15.8618 16.6189 15.6705 16.5599 15.4791 16.5128C15.2766 16.4539 15.074 16.3831 14.8827 16.2888C14.6801 16.2063 14.5 16.1002 14.3425 15.9706C14.1736 15.8527 14.0499 15.6759 13.9486 15.4519C13.8473 15.2397 13.791 14.9804 13.791 14.6857C13.791 14.2024 13.9823 13.8016 14.3762 13.4833C14.7701 13.1532 15.2428 13 15.7942 13C16.3457 13 16.8296 13.1414 17.2347 13.4126C17.6399 13.6955 17.8987 14.0609 18 14.5088L16.717 15.0747C16.6608 14.8271 16.5482 14.6267 16.3907 14.4735C16.2331 14.3321 16.0305 14.2495 15.7942 14.2495C15.6141 14.2495 15.4791 14.2967 15.3666 14.3674C15.2653 14.4382 15.2203 14.5443 15.2203 14.6739C15.2203 14.7918 15.2878 14.8979 15.4228 14.9804C15.5579 15.0511 15.7379 15.1101 15.9405 15.1454C16.1431 15.1808 16.3681 15.2397 16.6045 15.3223C16.8521 15.4166 17.0659 15.5109 17.2797 15.6405C17.4823 15.7584 17.6511 15.9588 17.7861 16.2181C17.9324 16.4893 18 16.8076 18 17.173C18 17.7152 17.7974 18.1632 17.3922 18.4932C16.9871 18.8232 16.4583 19 15.8055 19V19Z" fill="white" />
  </SvgIcon>

);

export default React.memo(ExelIcon);
