import { memo } from 'react';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useScreenDown } from 'common/hooks/screenSize';

import { ROUTE } from 'common/components/routes/utils/constants';
import { useLocation } from 'react-router';
import { Links } from './Links';
import { Year } from './Year';
import { Socials } from './Socials';

import { FooterStyled } from './styled';

export const Footer = memo(() => {
  const { pathname } = useLocation();
  const isScreenDownSm = useScreenDown('sm');
  const fullWidth = 12;

  if (!pathname.includes(ROUTE.LOGIN) && !pathname.includes(ROUTE.HOME_DEFAULT)) return null;

  return (
    <FooterStyled
      item
      container
      wrap={isScreenDownSm ? 'wrap' : 'nowrap'}
      direction="row"
      spacing={isScreenDownSm ? 3 : 0}
    >
      { !pathname.includes(ROUTE.LOGIN) && (
        <>
          <Grid item container xs={isScreenDownSm ? fullWidth : 3} justifyContent="center" alignItems="center">
            <Year />
          </Grid>
          <Grid item container xs={isScreenDownSm ? fullWidth : 10} justifyContent="center" alignItems="center">
            <Links />
          </Grid>
          <Grid item container xs={isScreenDownSm ? fullWidth : 3} justifyContent="center">
            <Socials />
          </Grid>
        </>
      )}
    </FooterStyled>
  );
});
