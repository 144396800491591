import { BarAction, BarState, SideBarActionType } from './SideBarContext';

export function tabBarReducer(state: BarState, action: BarAction): BarState {
  const copy = { ...state };

  switch (action.type) {
    case SideBarActionType.ShouldSideBarBeDisplayed:
      copy.shouldSideBarBeDisplayed = action.shouldSideBarBeDisplayed as boolean;

      return copy;
    case SideBarActionType.SideBarOpen:
      copy.sideBarOpen = action.sideBarOpen as boolean;

      return copy;
    default:
      return copy;
  }

  return copy;
}
