export const MODAL_BUTTONS = {
  SUBSCRIPTION_APPLY: 'SUBSCRIBE',
  SUBSCRIPTION_CLOSE: 'CLOSE',
} as const;

export const MODAL_TITLES = {
  REQURED_SUBSCRIPTION: 'Required Subscription',
} as const;

export const MODAL_TEXT = {
  SUBSCRIPTION: 'Subscription required for benchmark analytics cost=5 comps',
} as const;
