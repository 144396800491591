import { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthComponent } from 'containers/login/components';
import { ROUTE } from './utils/constants';

interface MsLoginRouteProps {
  token: string | undefined;
}

export const LoginRoute = memo(({ token }: MsLoginRouteProps) => (token
  ? <Redirect to={ROUTE.HOME_DEFAULT} />
  : <AuthComponent />));
