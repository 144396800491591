import { TableCell, TablePrintDataCell, TableQuartilesDataCell } from './models/types';

import {
  useGetMetrocounts,
  useGetNeighborhood,
  useGetQuartiles,
  useGetResearch,
  useGetSubtype,
} from './query-hooks';

export const useCreateMetrocountsData = (): TableCell[] => {
  const { data } = useGetMetrocounts();
  const modifiedDataArray = [];

  if (data) {
    data.forEach((itemData) => {
      const mappedClientData = {
        numMdraSubtype: itemData.numMdraSubtype || '',
        metroDivisionRegion: itemData.metroDivisionRegion || '',
        subtype: itemData.subtype || '',
        numPropsMdraSubtype: itemData.numPropsMdraSubtype || '',
        endDateMdraSubtype: itemData.endDateMdraSubtype || '',
        orderCalc: itemData.orderCalc || '',
      };

      modifiedDataArray.push(mappedClientData);
    });
  }
  // This empty object is necessary for correct displaying all rows.
  // Otherwise the first row would be hidden.
  modifiedDataArray.unshift({});

  return modifiedDataArray as TableCell[];
};

export const useCreatePrintTableData = (): TablePrintDataCell[] => {
  const { data } = useGetResearch();

  const modifiedDataArray:TablePrintDataCell[] = [];

  if (data) {
    data.forEach((itemData) => {
      const mappedPrintData = {
        city: itemData.city || null,
        state: itemData.state || null,
        zip: itemData.zip || null,
        county: itemData.county || null,
        cmsa: itemData.cmsa || null,
        ncreifDivision: itemData.ncreifDivision || null,
        ncreifRegion: itemData.ncreifRegion || null,
        yearBuilt: itemData.yearBuilt || null,
        yearRenov: itemData.yearRenov || null,
        numUnits: itemData.numUnits || null,
        subType: itemData.subType || null,
        affordability: itemData.affordability || null,
        populationPerSqMile: itemData.populationPerSqMile || null,
        medianHomeVal: itemData.medianHomeVal || null,
        medianHHInc: itemData.medianHHInc || null,
        medianAge: itemData.medianAge || null,
        medianRent: itemData.medianRent || null,
        medianOwnerCosts: itemData.medianOwnerCosts || null,
        renterPct: itemData.renterPct || null,
        rentOver30PctHHIncPCT: itemData.rentOver30PctHHIncPCT || null,
        bachelorsDegreePCT: itemData.bachelorsDegreePCT || null,
        unemploymentPCT: itemData.unemploymentPCT || null,
        mgtFinanceJobPCT: itemData.mgtFinanceJobPCT || null,
        movedInAfter2010PCT: itemData.movedInAfter2010PCT || null,
        underwritingUwYear: itemData.underwritingUwYear || null,
        topLineRevYear: itemData.topLineRevYear || null,
        fullLineItemFfYear: itemData.fullLineItemFfYear || null,
        appraisalOrStatVal: itemData.appraisalOrStatVal || null,
        valuationDate: itemData.valuationDate || null,
        valuationPerunit: itemData.valuationPerunit || null,
        uwNoiPerunit: itemData.uwNoiPerunit || null,
        uwCaprate: itemData.uwCaprate || null,
        occup: itemData.occup || null,
        revPerunit: itemData.revPerunit || null,
        expPerunit: itemData.expPerunit || null,
        noiPerunit: itemData.noiPerunit || null,
        ncfPerunit: itemData.ncfPerunit || null,
        capexPerunit: itemData.capexPerunit || null,
        expPctrev: itemData.expPctrev || null,
        noiPctrev: itemData.noiPctrev || null,
        ncfPctrev: itemData.ncfPctrev || null,
        capexPctrev: itemData.capexPctrev || null,
        revPct1Yrch: itemData.revPct1Yrch || null,
        expPct1Yrch: itemData.expPct1Yrch || null,
        noiPct1Yrch: itemData.noiPct1Yrch || null,
        revPerunit1Yrch: itemData.revPerunit1Yrch || null,
        expPerunit1Yrch: itemData.expPerunit1Yrch || null,
        noiPerunit1Yrch: itemData.noiPerunit1Yrch || null,
        grosrntPerunit: itemData.grosrntPerunit || null,
        vacancyPerunit: itemData.vacancyPerunit || null,
        baserntPerunit: itemData.baserntPerunit || null,
        retaxesPerunit: itemData.retaxesPerunit || null,
        propinsPerunit: itemData.propinsPerunit || null,
        utilitiPerunit: itemData.utilitiPerunit || null,
        repairsPerunit: itemData.repairsPerunit || null,
        managemPerunit: itemData.managemPerunit || null,
        payrollPerunit: itemData.payrollPerunit || null,
        marketiPerunit: itemData.marketiPerunit || null,
        grosrntPctrev: itemData.grosrntPctrev || null,
        vacancyPctrev: itemData.vacancyPctrev || null,
        baserntPctrev: itemData.baserntPctrev || null,
        retaxesPctrev: itemData.retaxesPctrev || null,
        propinsPctrev: itemData.propinsPctrev || null,
        utilitiPctrev: itemData.utilitiPctrev || null,
        repairsPctrev: itemData.repairsPctrev || null,
        managemPctrev: itemData.managemPctrev || null,
        payrollPctrev: itemData.payrollPctrev || null,
        marketiPctrev: itemData.marketiPctrev || null,
        grosrntPerunit1Yrch: itemData.grosrntPerunit1Yrch || null,
        vacancyPerunit1Yrch: itemData.vacancyPerunit1Yrch || null,
        baserntPerunit1Yrch: itemData.baserntPerunit1Yrch || null,
        retaxesPerunit1Yrch: itemData.retaxesPerunit1Yrch || null,
        propinsPerunit1Yrch: itemData.propinsPerunit1Yrch || null,
        utilitiPerunit1Yrch: itemData.utilitiPerunit1Yrch || null,
        repairsPerunit1Yrch: itemData.repairsPerunit1Yrch || null,
        managemPerunit1Yrch: itemData.managemPerunit1Yrch || null,
        payrollPerunit1Yrch: itemData.payrollPerunit1Yrch || null,
        marketiPerunit1Yrch: itemData.marketiPerunit1Yrch || null,
        hcIncPvtpayPctrev: itemData.hcIncPvtpayPctrev || null,
        hcIncMedcarePctrev: itemData.hcIncMedcarePctrev || null,
        hcIncNursingPctrev: itemData.hcIncNursingPctrev || null,
        hcIncMealsPctrev: itemData.hcIncMealsPctrev || null,
        hcExpRoomsPctrev: itemData.hcExpRoomsPctrev || null,
        hcExpMealsPctrev: itemData.hcExpMealsPctrev || null,
        hcIncPvtpayPerunit: itemData.hcIncPvtpayPerunit || null,
        hcIncMedcarePerunit: itemData.hcIncMedcarePerunit || null,
        hcIncNursingPerunit: itemData.hcIncNursingPerunit || null,
        hcIncMealsPerunit: itemData.hcIncMealsPerunit || null,
        hcExpRoomsPerunit: itemData.hcExpRoomsPerunit || null,
        hcExpMealsPerunit: itemData.hcExpMealsPerunit || null,
      };

      modifiedDataArray.push(mappedPrintData as TablePrintDataCell);
    });
    // This empty object is necessary for correct displaying all rows.
    // Otherwise the first row would be hidden.
    modifiedDataArray.unshift({} as never);
  }

  return modifiedDataArray as TablePrintDataCell[];
};

export const useCreateQuartilesTableData = (): TableQuartilesDataCell[] => {
  const { data } = useGetQuartiles();
  console.log('data', data);

  const modifiedDataArray:TableQuartilesDataCell[] = [];

  if (data) {
    data.forEach((itemData) => {
      const mappedPrintData = {
        medianQ1Q4: itemData.medianQ1Q4,
        geography: itemData.geography,
        subType: itemData.subType,
        props: itemData.props,
        yearBuilt: itemData.yearBuilt,
        yearRenov: itemData.yearRenov,
        numUnits: itemData.numUnits,
        populationPerSqMile: itemData.populationPerSqMile,
        medianAge: itemData.medianAge,
        medianHHInc: itemData.medianHHInc,
        medianHomeVal: itemData.medianHomeVal,
        medianRent: itemData.medianRent,
        medianOwnerCosts: itemData.medianOwnerCosts,
        renterPCT: itemData.renterPCT,
        rentOver30PctHHIncPCT: itemData.rentOver30PctHHIncPCT,
        bachelorsDegreePCT: itemData.bachelorsDegreePCT,
        unemploymentPCT: itemData.unemploymentPCT,
        mngtFinanceJobPCT: itemData.mngtFinanceJobPCT,
        movedInAfter2010PCT: itemData.movedInAfter2010PCT,
        valuationDate: itemData.valuationDate,
        valuationPerUnit: itemData.valuationPerUnit,
        uwNoiPerUnitBenchmark: itemData.uwNoiPerUnitBenchmark,
        uwCaprateBenchmark: itemData.uwCaprateBenchmark,
        occup: itemData.occup,
        revPerUnit: itemData.revPerUnit,
        expPerUnit: itemData.expPerUnit,
        noiPerUnit: itemData.noiPerUnit,
        ncfPerUnit: itemData.ncfPerUnit,
        capexPerUnit: itemData.capexPerUnit,
        expPctRev: itemData.expPctRev,
        noiPctRev: itemData.noiPctRev,
        ncfPctRev: itemData.ncfPctRev,
        capexPctRev: itemData.capexPctRev,
        revPct1Yrch: itemData.revPct1Yrch,
        expPct1Yrch: itemData.expPct1Yrch,
        noiPct1Yrch: itemData.noiPct1Yrch,
        revPerUnit1Yrch: itemData.revPerUnit1Yrch,
        expPerUnit1Yrch: itemData.expPerUnit1Yrch,
        noiPerUnit1Yrch: itemData.noiPerUnit1Yrch,
        grosrntPerUnit: itemData.grosrntPerUnit,
        vacancyPerUnit: itemData.vacancyPerUnit,
        baserntPerUnit: itemData.baserntPerUnit,
        retaxesPerUnit: itemData.retaxesPerUnit,
        propinsPerUnit: itemData.propinsPerUnit,
        utilitiPerUnit: itemData.utilitiPerUnit,
        repairsPerUnit: itemData.repairsPerUnit,
        managemPerUnit: itemData.managemPerUnit,
        payrollPerUnit: itemData.payrollPerUnit,
        marketiPerUnit: itemData.marketiPerUnit,
        grosrntPctRev: itemData.grosrntPctRev,
        vacancyPctRev: itemData.vacancyPctRev,
        baserntPctRev: itemData.baserntPctRev,
        retaxesPctRev: itemData.retaxesPctRev,
        propinsPctRev: itemData.propinsPctRev,
        utilitiPctRev: itemData.utilitiPctRev,
        repairsPctRev: itemData.repairsPctRev,
        managemPctRev: itemData.managemPctRev,
        payrollPctRev: itemData.payrollPctRev,
        marketiPctRev: itemData.marketiPctRev,
        grosrntPerUnit1Yrch: itemData.grosrntPerUnit1Yrch,
        vacancyPerUnit1Yrch: itemData.vacancyPerUnit1Yrch,
        baserntPerUnit1Yrch: itemData.baserntPerUnit1Yrch,
        retaxesPerUnit1Yrch: itemData.retaxesPerUnit1Yrch,
        propinsPerUnit1Yrch: itemData.propinsPerUnit1Yrch,
        utilitiPerUnit1Yrch: itemData.utilitiPerUnit1Yrch,
        repairsPerUnit1Yrch: itemData.repairsPerUnit1Yrch,
        managemPerUnit1Yrch: itemData.managemPerUnit1Yrch,
        payrollPerUnit1Yrch: itemData.payrollPerUnit1Yrch,
        marketiPerUnit1Yrch: itemData.marketiPerUnit1Yrch,
        hcIncPvtPayPctRev: itemData.hcIncPvtPayPctRev,
        hcIncMedcarePctRev: itemData.hcIncMedcarePctRev,
        hcIncNursingPctRev: itemData.hcIncNursingPctRev,
        hcIncMealsPctRev: itemData.hcIncMealsPctRev,
        hcExpRoomsPctRev: itemData.hcExpRoomsPctRev,
        hcExpMealsPctRev: itemData.hcExpMealsPctRev,
        hcIncPvtPayPerUnit: itemData.hcIncPvtPayPerUnit,
        hcIncMedcarePerUnit: itemData.hcIncMedcarePerUnit,
        hcIncNursingPerUnit: itemData.hcIncNursingPerUnit,
        hcIncMealsPerUnit: itemData.hcIncMealsPerUnit,
        hcExpRoomsPerUnit: itemData.hcExpRoomsPerUnit,
        hcExpMealsPerUnit: itemData.hcExpMealsPerUnit,
      };

      modifiedDataArray.push(mappedPrintData as TableQuartilesDataCell);
    });
    // This empty object is necessary for correct displaying all rows.
    // Otherwise the first row would be hidden.
    modifiedDataArray.unshift({} as never);
  }

  return modifiedDataArray as TableQuartilesDataCell[];
};

export const useCreateSubtypeData = (): string[] => {
  const { data } = useGetSubtype();

  const modifiedDataArray:string[] = [];

  if (data) {
    data.forEach((itemData) => modifiedDataArray.push(itemData));
  }

  return modifiedDataArray as string[];
};

export const useCreateNeighborhoodData = (): string[] => {
  const { data } = useGetNeighborhood();

  const modifiedDataArray:string[] = [];

  if (data) {
    const newArray = data.map((item) => item.metroDivisionRegion);
    const filteredArray:string[] = Array.from(new Set(newArray));

    return modifiedDataArray.concat(filteredArray) as string[];
  }

  return modifiedDataArray as string[];
};
