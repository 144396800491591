/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const StatisticsFile = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M15 0V6H21L15 0Z" fill="white" />
    <path d="M15 7.5C14.1735 7.5 13.5 6.8265 13.5 6V0H4.5C3.6735 0 3 0.6735 3 1.5V22.5C3 23.328 3.6735 24 4.5 24H19.5C20.328 24 21 23.328 21 22.5V7.5H15ZM9 21H6V16.5H9V21ZM13.5 21H10.5V13.5H13.5V21ZM18 21H15V10.5H18V21Z" />
  </SvgIcon>
);

export default React.memo(StatisticsFile);
