import { usePropertyId } from 'common/components/BingMap/local-state/hooks';
import { useArchiveProperty } from 'common/components/BingMap/lib/query-hooks';
import { useEditColumn } from 'common/components/providers/EditTableProvider/hooks';

type ReturnType = {
  cancelClickHandler: VoidFunction,
  archiveClickHandler: VoidFunction,
}

export const useModifyButtons = (): ReturnType => {
  const { state: idState, dispatch: idDispatch } = usePropertyId();
  const { dispatch: editDispatch } = useEditColumn();

  const cancelClickHandler = ():void => {
    idDispatch({ newValue: null });
    editDispatch({ type: 'clear' });
  };

  const mutation = useArchiveProperty();

  const archiveClickHandler = (): void => {
    if (idState.id) {
      mutation.mutateAsync(idState.id);
    }
    idDispatch({ newValue: null });
  };

  return { cancelClickHandler, archiveClickHandler };
};
