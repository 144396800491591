import { FC } from 'react';
import { TYPE } from '../../lib/constants';
import { Data } from '../../lib/types';
import { TestCell } from '../BasicCell/InputCell';
import CellDate from '../CellDate';
import CellDateRev from '../CellDateRev';
import CellDollar from '../CellDollar';
import CellPercent from '../CellPercent';
import CellSelect from '../CellSelect';
import CellText from '../CellText';
import TooltipWrap from '../TooltipWrap';
import { Header, Root } from './styled';

type PropsType = { data: Data };

const RendererFactory: FC<PropsType> = ({ data }) => {
  switch (data.type) {
    case TYPE.text: {
      if (data.disabled !== undefined && data.id !== undefined) {
        return <CellText disabled={data.disabled} index={data.id} />;
      }
      return <div>Error</div>;
    }

    case TYPE.percent: {
      if (data.disabled !== undefined && data.id !== undefined) {
        return (
          <CellPercent
            disabled={data.disabled}
            value={data.value}
            index={data.id}
          />
        );
      }
      return <div>Error</div>;
    }

    case TYPE.dollar: {
      if (data.disabled !== undefined && data.id !== undefined) {
        return (
          <CellDollar
            disabled={data.disabled}
            value={data.value}
            index={data.id}
          />
        );
      }
      return <div>Error</div>;
    }

    case TYPE.select: {
      if (data.disabled !== undefined && data.id !== undefined && data.selectors !== undefined) {
        return (
          <CellSelect
            disabled={data.disabled}
            selectors={data.selectors}
            index={data.id}
          />
        );
      }
      return <div>Error</div>;
    }

    case TYPE.date: {
      if (data.disabled !== undefined && data.id !== undefined) {
        return <CellDate disabled={data.disabled} index={data.id} />;
      }
      return <div>Error</div>;
    }

    case TYPE.dateRev: {
      if (data.disabled !== undefined && data.id !== undefined) {
        return <CellDateRev disabled={data.disabled} index={data.id} />;
      }
      return <div>Error</div>;
    }

    default:
      return <div>Error: no such type...</div>;
  }
};

const Row: FC<PropsType> = ({ data }) => (
  <>
    {data.type !== TYPE.header && (
      <Root>
        <TestCell isDisabled isColored>
          <TooltipWrap text={data.name}>
            <div>{data.name}</div>
          </TooltipWrap>
        </TestCell>
        <RendererFactory key={data.id} data={data} />
      </Root>
    )}
    {data.type === TYPE.header && (
      <Header>
        <TooltipWrap text={data.name}>
          <div>{data.name}</div>
        </TooltipWrap>
      </Header>
    )}
  </>
);

export default Row;
