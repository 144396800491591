import { styled, Select } from '@mui/material';
import TabList from '@mui/lab/TabList';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TabPanel from '@mui/lab/TabPanel';
import MenuItem from '@mui/material/MenuItem';

import { AnyComponentType, ExtraProps } from 'common/components/ApplicationBar/styled/theme-types';

type RightMenuWrapper = { $isSelectedTab?: boolean; $isDownMd?: boolean; };

type CustomTabListStyledType = RightMenuWrapper;

export const BodyStyled = styled(Grid)({
  flex: 1,
  height: '100%',
});

const HEADER_HEIGHT = '80px' as const;
const FOOTER_HEIGHT = '60px' as const;

export const TabListStyled = styled<CustomTabListStyledType & AnyComponentType>(TabList, {
  shouldForwardProp: (prop) => prop !== '$isSelectedTab' && prop !== '$isDownMd',
})((
  { theme, $isSelectedTab, $isDownMd },
) => ({
  alignSelf: 'flex-end',
  width: 'inherit',

  '.MuiTabs-indicator': {
    display: ($isSelectedTab && !$isDownMd) ? 'inherit' : 'none',
  },

  '.MuiTab-wrapper': {
    color: $isSelectedTab ? 'inherit' : theme.palette.primary.dark,
  },

  '.MuiTouchRipple-root': {
    display: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '.MuiTab-root': {
      padding: `${theme.spacing(4, 0)} !important`,
    },

    '.MuiTabs-flexContainer': {
      alignItems: 'end',
    },
  },
}));

export const FormControlWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
}));

export const RightMenuWrapperStyled = styled('div', {
  shouldForwardProp: (propName) => propName !== '$isDownMd',
})<RightMenuWrapper>(({ theme, $isDownMd }) => ({
  alignSelf: 'center',
  paddingLeft: theme.spacing(1),
  marginTop: theme.spacing(-1),
  display: 'flex',
  width: $isDownMd ? '100%' : 'auto',
  justifyContent: 'right',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  height: '42px',
  width: '110px',
  alignSelf: 'center',
  marginLeft: theme.spacing(8),
  color: theme.palette.text.secondary,
  borderColor: theme.palette.text.secondary,
  marginRight: theme.spacing(4),
  '&:hover': {
    backgroundColor: theme.palette.background.blue,
  },
}));

export const LogoutButtonWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const TabPanelStyled = styled(TabPanel)(({ theme }) => ({
  width: '95%',
  height: `calc(97vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`,
  margin: theme.spacing(5, 2),
  padding: theme.spacing(0, 0, 4, 0),
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

export const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (propName) => propName !== '$isSelected',
})<ExtraProps>(({ theme, $isSelected }) => ({
  backgroundColor: $isSelected ? theme.palette.primary.meduimGray : 'inherit',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),

  '.MuiTypography-subtitle1': {
    color: $isSelected ? theme.palette.secondary.light : 'auto',
  },

  '&:hover': {
    '.MuiTypography-subtitle1': {
      color: $isSelected ? theme.palette.secondary.dark : 'auto',
    },
  },
}));

export const SelectStyled = styled(Select)({
  fieldset: {
    border: 'none',
    outline: 'none',
  },
});
