import {
  memo,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { Index, MultiGrid } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useCreateQuartilesTableData } from 'containers/research/lib/mapped-table-data';
import { TableQuartilesDataCell } from 'containers/research/lib/models/types';

import CellRenderer from './CellRenderer';
import {
  COLUMN_TITLE_WIDTH,
  FIXED_COLUMN_COUNT,
  FIXED_ROW_COUNT,
  COLUMN_TITLES,
} from './table-data';

import { TableWrapperStyled } from './styled';
import {
  STYLE,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
  STYLE_TOP_RIGHT_GRID,
} from './styled/virtualized-styles';
import './printQuartilesStyle.css';

export const TableQuartiles = memo((): JSX.Element => {
  const quartilesData = (useCreateQuartilesTableData() || null) as TableQuartilesDataCell[];
  const rowCount = useMemo(() => quartilesData.length, [quartilesData.length]);
  const getRowSize = useMemo(() => ({ index }:Index) => (index === 0 ? 32 : 28), []);

  const gridRef = useRef<MultiGrid>(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.forceUpdateGrids();
    }
  }, [quartilesData]);

  console.log('quartilesData', quartilesData);

  return (
    <TableWrapperStyled id="print_quartiles" style={{ position: 'relative' }}>
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '2px',
        backgroundColor: '#1262a7',
        top: '119px',
        left: '1px',
      }}
      />
      <AutoSizer>
        {({ height, width }) => (
          <MultiGrid
            ref={gridRef}
            fixedColumnCount={FIXED_COLUMN_COUNT}
            columnWidth={COLUMN_TITLE_WIDTH}
            columnCount={COLUMN_TITLES.length}
            height={height}
            rowHeight={getRowSize}
            rowCount={rowCount}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
            style={STYLE}
            cellRenderer={({ key: mainKey, ...rest }) => (
              <CellRenderer
                {...rest}
                key={mainKey}
                data={quartilesData}
              />
            )}
            fixedRowCount={FIXED_ROW_COUNT}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
          />
        )}
      </AutoSizer>
    </TableWrapperStyled>
  );
});
