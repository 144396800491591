import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

import { MODAL_TITLE } from './utils/modalConstants';
import { Table } from './table';

import {
  BootstrapDialog,
  IconButtonStyled,
  ModalBodyStyled,
} from './utils/styled';

export const ModalWindow = React.memo((
  { open, close }: {open: boolean, close: VoidFunction},
): JSX.Element => (
  <BootstrapDialog
    open={open}
    onClose={close}
  >
    <DialogTitle>
      {MODAL_TITLE.INFORMATION_TABLE}
      <IconButtonStyled onClick={close}>
        <CloseIcon fontSize="medium" />
      </IconButtonStyled>
    </DialogTitle>
    <ModalBodyStyled>
      <Table />
    </ModalBodyStyled>
  </BootstrapDialog>
));
