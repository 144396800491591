import axios from 'axios';

import { storage } from 'containers/login/utils/local-storage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(async (response) => {
  const token = storage.getAccessToken();

  if (token && response.headers) {
    response.headers.Authorization = `Bearer ${token}`;
  }

  return response;
}, async (error) => {
  if (error.response.status === 401) {
    storage.clearAll();
    window.location.replace('/login');
  }
  await Promise.reject(error);
});

axiosInstance.interceptors.request.use(async (request) => {
  const token = storage.getAccessToken();
  if (token && request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
    request.headers['Content-Type'] = 'application/json';
  }

  return request;
}, (error) => Promise.reject(error));
export default axiosInstance;
