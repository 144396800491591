import { memo, useMemo } from 'react';

import { Tooltip, Typography } from '@mui/material';
import { GridCellProps } from 'react-virtualized';

import { INFO_TABLE } from 'containers/benchmark/components/infoTable/table/utils/constants';

import { Cell, FixedCell } from 'containers/input-property/components/detailsTab/styled';

import useTooltip from 'common/hooks/useTooltip';
import { BenchmarkAnalyticsInfoModel } from 'containers/benchmark/lib/models/BencmarkAnalyticsInfo.model';

type ExtendedGridCellProps = GridCellProps & {
  mainKey: string,
  data: BenchmarkAnalyticsInfoModel[];
};

const CellRenderer = ({
  columnIndex,
  rowIndex,
  style,
  mainKey,
  data,
}:ExtendedGridCellProps): JSX.Element => {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  const cellName = INFO_TABLE.ROWS[rowIndex]?.row;
  const dataName = INFO_TABLE.ROWS[rowIndex]?.dataName;
  const cellData = data[columnIndex];
  const symbol = INFO_TABLE.ROWS[rowIndex]?.symbol;

  const percent = useMemo(() => `${Math.round(cellData[dataName] as number * 10000) / 100}
  ${symbol}`,
  [cellData, dataName, symbol]);

  const price = useMemo(() => `${symbol}
   ${Math.floor(cellData[dataName] as number)}`,
  [cellData, dataName, symbol]);

  const milesFromSubject = cellName.includes('Miles From Subject')
    ? `${Math.round(cellData[dataName] as unknown as number * 10) / 10}`
    : cellData[dataName];

  const sqMile = cellName.includes('Population PerSqMile')
    ? Math.round(cellData[dataName] as unknown as number).toLocaleString()
    : milesFromSubject;

  const entire = useMemo(() => {
    if (!cellData || !cellData[dataName]) return '';
    return typeof cellData[dataName] === 'number'
      ? sqMile : cellData[dataName];
  },
  [cellData, dataName, sqMile]);

  if (columnIndex === 0) {
    return (
      <FixedCell key={mainKey} style={style}>
        {cellName}
      </FixedCell>
    );
  }

  if (symbol === '%') {
    return (
      <Cell key={mainKey} style={style}>
        <Tooltip
          open={isOpen}
          title={(
            <Typography variant="body2">
              {cellData && cellData[dataName]}
            </Typography>
          )}
          arrow
        >
          <Typography
            noWrap
            onMouseMove={onShowTooltip}
            onMouseOut={onHideTooltip}
          >
            { percent }
          </Typography>
        </Tooltip>
      </Cell>
    );
  }
  return (
    <Cell key={mainKey} style={style}>
      <Tooltip
        open={isOpen}
        title={(
          <Typography
            variant="body2"
          >
            {cellData ? cellData[dataName] : null}
          </Typography>
        )}
        arrow
      >
        <Typography
          noWrap
          onMouseMove={onShowTooltip}
          onMouseOut={onHideTooltip}
        >
          {
            symbol === '$' ? price : entire
          }
        </Typography>
      </Tooltip>
    </Cell>
  );
};

export default memo(CellRenderer);
