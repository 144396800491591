import { createContext } from 'react';

export type DeleteModalAction = { type: 'delete'; id: string; open: boolean; } |
{ type: 'cancel'; open: boolean; };

export type DeleteModalDispatch = (action: DeleteModalAction) => void;

export type DeleteModalState = { id: string; open: boolean; };

export const DeleteModalContext = createContext<
  { state: DeleteModalState; dispatch: DeleteModalDispatch; } | undefined
>(undefined);

createContext<(DeleteModalState & DeleteModalDispatch) | undefined>(undefined);
