import { memo, useReducer } from 'react';

import { ButtonContext } from './ButtonStateContext';
import { PropertyIdContext } from './PropertyIdContext';
import { buttonStateReducer, propertyIdReducer } from './reducers';

interface IProps {
  children: React.ReactNode;
}

const MapProvider = memo(({ children }: IProps) => {
  const [propertyId, propertyIdDispatch] = useReducer(propertyIdReducer, { id: null });
  const [buttonState, buttonDispatch] = useReducer(buttonStateReducer, { isDisabled: true });

  return (
    <PropertyIdContext.Provider value={{ state: propertyId, dispatch: propertyIdDispatch }}>
      <ButtonContext.Provider value={{ state: buttonState, dispatch: buttonDispatch }}>
        {children}
      </ButtonContext.Provider>
    </PropertyIdContext.Provider>
  );
});

export default MapProvider;
