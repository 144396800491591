export const MAX_WIDTH_MENU = 300 as const;

export const RESEARCH = {
  TITLES: {
    NEW_SUBSCRIPTION: 'NEW SUBSCRIPTION',
    MY_SUBSCRIPTION: 'MY SUBSCRIPTION',
    PRINT_DATA: 'PRINT DATA',
    PRINT_QUARTILES: 'PRINT QUARTILES',
    DATASET: 'DATASET',
  },
  BUTTON_TEXT: {
    SUBSCRIBE_TO_DATASET: 'SUBSCRIBE TO DATASET',
    PRINT_DATA: 'PRINT DATA',
    PRINT_QUARTILES: 'PRINT QUARTILES',
    EXPORT_DATA: 'EXPORT DATA',
    EXPORT_CSV: 'Export CSV',
    EXPORT_EXEL: 'Export XLS',
    EXPORT_EXEL_XLSX: 'Export XLSX',
    REION: 'Metro-Region-Division',
    SUBTYPE: 'Subtype',
    VIEW_DATASET: 'VIEW DATASET',
  },
  TABLE: {
    SUBTYPE: 'SUBTYPE ',
    GEOGRAPHY: 'GEOGRAPHY',
    PROPETRIES: 'PROPETRIES',
    PRICE: 'PRICE (COMPS)',
  },
  CAPRATE: {
    TITLE: 'VALUATION CAPRATE CALCULATION',
    HISTORICAL: 'HISTORICAL CAP RATE ',
    HISTORICAL_10YR: 'HISTORICAL 10YR TRSY YIELD',
    CURRENT_10YR: 'CURRENT 10YR TRSY YIELD',
    TRSY_YIELD: 'TRSY YIELD CHANGE',
    CAPRATE_ADJUSTMENT: 'CAPRATE ADJUSTMENT',
    VALUATION: 'VALUATION CAPRATE',
  },
  PRINT_DATA_PARAMS: 'param',
  DOWNLOAD: 'Downloading...',
  FILE: {
    CSV: { extension: 'csv', type: 'text/csv' },
    XLS: { extension: 'xls', type: 'application/vnd.ms-excel' },
    XLSX: { extension: 'xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  },
} as const;
