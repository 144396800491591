import { styled } from '@mui/material';

export const BasicCell = styled('div')<{
  isColored: boolean;
}>(({ isColored }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  backgroundColor: isColored ? '#e6e6e6' : 'none',
  height: '30px',
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '&:first-of-type': {
    borderRight: '1px solid gray',
  },
  '&:last-child': {
    borderRight: '0px solid gray',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  '.MuiMenuItem-root': {
    textAlign: 'center',
  },
  '.MuiInputBase-input.Mui-disabled': {
    backgroundColor: '#e6e6e6',
  },
}));

export const TestCell = styled('div')<{
  isDisabled: boolean;
  isColored: boolean;
}>(({ isColored }) => ({
  fontFamily: '\'OpenSans-SemiBold\', sans-serif',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  backgroundColor: isColored ? '#e6e6e6' : 'none',
  height: '30px',
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 12px',
  '&:first-of-type': {
    borderRight: '1px solid gray',
  },
  '&:last-child': {
    borderRight: '0px solid gray',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  '.MuiMenuItem-root': {
    textAlign: 'center',
  },
  '& > div': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
