import React, { createRef } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'common/components/SnackBar/SnackBarUtils';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const AppSnackbarProvider = ({ children }: {children: React.ReactElement}): JSX.Element => {
  const notistackRef = createRef<SnackbarProvider>();

  const onClickDismiss = (_: React.MouseEvent<HTMLButtonElement>, key: SnackbarKey):void => {
    notistackRef.current?.closeSnackbar(key as SnackbarKey);
  };

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      maxSnack={3}
      ref={notistackRef}
      dense
      preventDuplicate
      action={(key) => (
        <IconButton color="default" onClick={(e) => onClickDismiss(e, key)} size="large">
          <CloseIcon />
        </IconButton>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
};
