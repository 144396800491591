import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';

import SIDEBAR_TABS, { SideBarTab } from './sidebar-tabs';
import { TabItem } from './SidebarDrawerItem';

import {
  DrawerStyled,
  DrawerToolbarStyled,
  ListItemStyled,
} from './styled';

interface SidebarDrawerProps {
closeDrawer:() => void;
isOpen: boolean;
}

const SidebarDrawer = ({ closeDrawer, isOpen }: SidebarDrawerProps): JSX.Element => {
  const history = useHistory();

  const onTabClickHandle = useCallback((_:undefined, path: string):void => {
    history.push(path);
  }, [history]);

  return (
    <DrawerStyled variant="permanent" $isOpen={isOpen}>
      <DrawerToolbarStyled>
        <IconButton onClick={closeDrawer} size="large">
          {/* <ChevronLeftIcon color="secondary" /> */}
        </IconButton>
      </DrawerToolbarStyled>
      <Divider />
      <List component="div" disablePadding>
        {SIDEBAR_TABS.map((item: SideBarTab, i: number) => (
          <ListItemStyled
            button
            key={item.text}
            $isTabSelected={history.location.pathname.includes(item?.route as string)}
            onClick={() => onTabClickHandle(undefined, item.route as string)}
          >
            <TabItem
              isExpandedMenu={isOpen}
              index={i}
              item={item}
            />
          </ListItemStyled>
        ))}
      </List>
    </DrawerStyled>
  );
};

export default memo(SidebarDrawer);
