import { createContext } from 'react';
import { IColumn } from '../utils/table-data-columns';

export interface FilterContext {
    word: string;
    column: IColumn;
    values: string[];
}

export type FilterAdminAction = { column: string; values: string[]; word?: string; };
export type FilterAdminDispatch = (action: { values: string[]; column: string; word: string }) => void;
export type FilterAdminState = { column: string; values: string[]; word?: string};

export const FilterContext = createContext<
    { state: FilterAdminState; dispatch: FilterAdminDispatch; } | undefined
    >(undefined);
