import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import { ROUTE } from 'common/components/routes/utils/constants';
import { useMainPageTabContext } from 'containers/main/app-bar/local-state/hooks';

import { ILink } from '../constants';
import { LinkWrapperStyled } from './styled';

const LinkItem = ({ instance }: {instance: ILink}): JSX.Element => {
  const { dispatch } = useMainPageTabContext();

  const clickHandle = ():void => {
    dispatch({ setClicked: true });
  };

  return (
    <LinkWrapperStyled>
      <Typography
        component={Link}
        variant="body2"
        color="primary"
        onClick={clickHandle}
        to={`${ROUTE.HOME_DEFAULT}${instance.route}`}
        sx={{ textDecoration: 'none' }}
      >
        {instance.text}
      </Typography>
    </LinkWrapperStyled>
  );
};

export default memo(LinkItem);
