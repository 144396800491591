import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BuildingIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon {...props} viewBox="0 0 31 26">
    {/* eslint-disable-next-line max-len */}
    <path d="M27.2422 24.2249V4.3626H16.0348V24.2249H14.2598V2.58752V0.195312L3.05242 2.06316V24.2249H0V26H30.2946V24.2249H27.2422ZM22.8595 6.91497H24.745V8.69005H22.8595V6.91497ZM22.8595 11.2425H24.745V13.0176H22.8595V11.2425ZM22.8595 15.5699H24.745V17.345H22.8595V15.5699ZM22.8595 19.8975H24.745V21.6725H22.8595V19.8975ZM18.5321 6.91497H20.4175V8.69005H18.5321V6.91497ZM18.5321 11.2425H20.4175V13.0176H18.5321V11.2425ZM18.5321 15.5699H20.4175V17.345H18.5321V15.5699ZM18.5321 19.8975H20.4175V21.6725H18.5321V19.8975ZM9.87711 2.58752H11.7625V4.3626H9.87711V2.58752ZM9.87711 6.91497H11.7625V8.69005H9.87711V6.91497ZM9.87711 11.2425H11.7625V13.0176H9.87711V11.2425ZM9.87711 15.5699H11.7625V17.345H9.87711V15.5699ZM5.54965 6.91497H7.43508V8.69005H5.54965V6.91497ZM5.54965 11.2425H7.43508V13.0176H5.54965V11.2425ZM5.54965 15.5699H7.43508V17.345H5.54965V15.5699ZM5.6048 19.8975H11.7074V24.2249H9.93231V21.6725H7.37987V24.2249H5.6048V19.8975Z" />
  </SvgIcon>
);

export default React.memo(BuildingIcon);
