import { memo } from 'react';

import 'bingmaps';

import BingMap from 'common/components/BingMap';
import Footer from './Footer';
import Header from './Header';

import { MapStyled } from './styled';

const Map = ():JSX.Element => (
  <MapStyled>
    <Header />
    <BingMap />
    <Footer />
  </MapStyled>
);

export default memo(Map);
